import { CancelToken } from 'axios';
import fetchProvider, { fetchProviderForAttachment } from 'utils/Axios';

import { tDictionary, tListParams } from 'types/global';
import { tConfirmationMethod, tReadService, tSurvey } from 'agents/services';
import { tAuditor } from 'agents/auditTemplates';
import { tReadLocation } from 'agents/locations';
import { tWriteQuestion } from 'agents/serviceTemplates';

export const microservicePath = '/audits';

export type tAudit = {
  id: string;
  name: string;
  service_id: string;
  start_time: string;
  end_time: string;
  planned_end_time: string;
  planned_start_time: string;
  remarks?: string | null;
  result?: number;
  survey: tSurvey;
  mobile_group: tDictionary;
  auditor: tAuditor;
  audit_survey_template_id: string;
  audit_survey_template_data: { survey_template: tDictionary } | null;
  confirmation_method: tConfirmationMethod;
  location: Omit<tReadLocation, 'contracts'>;
};

export type tGetAuditListRequest = tListParams & {
  location?: string;
  date?: Date;
  score?: number;
  since?: Date | null;
  to?: Date | null;
  finished_since?: Date | null;
  finished_to?: Date | null;
  start_since?: Date | null;
  auditor_id?: string;
  finished?: boolean;
  mobile_group_ids?: string;
  location_ids?: string;
  result?: string;
};
type tGetAuditListResponse = {
  count: number;
  results: tAudit[];
};

type tWriteAudit = {
  name: string;
  service: string;
  planned_start_time: Date;
  survey: {
    template: string;
    questions: (tWriteQuestion & { template?: string })[];
  };
  confirmation_method: string;
};

type tWriteAuditResponse = {
  confirmation_method: tConfirmationMethod;
  end_time: null;
  name: string;
  planned_end_time: string;
  planned_start_time: string;
  result: number;
  service: tReadService;
  start_time: null;
  survey: tSurvey;
};

export type tAddAuditRequest = tWriteAudit;
type tAddAuditResponse = tWriteAuditResponse;

export type tUpdateAuditRequest = tWriteAudit;
type tUpdateAuditResponse = tWriteAuditResponse;

const audits = {
  getAuditsList: (params: tGetAuditListRequest, cancelToken: CancelToken) =>
    fetchProvider.get<tGetAuditListResponse>(`${microservicePath}`, { params, cancelToken }),
  addAudit: (bodyRequest: tAddAuditRequest) =>
    fetchProvider.post<tAddAuditResponse>(`${microservicePath}`, bodyRequest),
  updateAudit: (id: string, bodyRequest: tUpdateAuditRequest) =>
    fetchProvider.put<tUpdateAuditResponse>(`${microservicePath}/${id}`, bodyRequest),
  deleteAudit: (auditId: string) => fetchProvider.delete<void>(`${microservicePath}/${auditId}`),
  getAuditReport: (id: string) =>
    fetchProviderForAttachment(`/reports/audit/${id}`, {}, { responseType: 'blob' }, 'GET'),
};

export default audits;
