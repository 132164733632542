import { ReactNode } from 'react';
import * as ButtonStyles from 'styles/button';

type tButton = {
  onClick: () => void;
  children: ReactNode;
};

const ButtonPrimary = ({ onClick = () => {}, children = 'Submit' }: tButton) => (
  <ButtonStyles.Button type='button' onClick={onClick}>
    {children}
  </ButtonStyles.Button>
);

export default ButtonPrimary;
