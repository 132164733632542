import { useEffect, useMemo } from 'react';
import axios from 'axios';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import servicesService from 'services/services';
import { defaultCacheStaleTime } from 'services/utils/constants';
import { onError } from 'utils/queryClient';
import { tGetServicesRequest } from 'agents/services';

export type tUseTasksArgs = {
  params: tGetServicesRequest;
};

export const queryCacheName = 'getTasks';

const useTasks = ({ params }: tUseTasksArgs) => {
  const queryClient = useQueryClient();
  const query = useQuery({
    // eslint-disable-next-line max-len
    queryKey: [queryCacheName, params], // TODO name kind depends on service method or create in service name constants????
    queryFn: ({ signal }) => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      const promise = servicesService.getServices({
        ...params,
        cancelToken: source.token,
      });

      // Cancel the request if TanStack Query signals to abort
      signal?.addEventListener('abort', () => {
        source.cancel('Query was cancelled by TanStack Query');
      });

      return promise;
    },
    staleTime: defaultCacheStaleTime, // TODO what length to set by default or per view
  });

  const count = useMemo(() => query?.data?.count ?? 0, [query?.data]);

  const refetchList = () => queryClient.invalidateQueries({ queryKey: [queryCacheName] });

  useEffect(() => {
    if (query.isError) onError(query.error);
  }, [query.isError, query.error]);

  return {
    ...query,
    data: query?.data?.results ?? [],
    count,
    refetchList,
  };
};

export default useTasks;
