import { AxiosResponse, CancelToken } from 'axios';
import i18n from 'i18next';

import usersAgent, {
  tAddUserRequest,
  tGetAreasRequest,
  tGetRolesRequest,
  tGetUsersRequest,
  tUpdateUserRequest,
} from 'agents/users';
import { tDictionary, tDropdownOption } from 'types/global';
import { tCurrentUser, tGetUsersList } from 'types/services/users';
import errorsHandler from './utils/errorsHandler';
import { isReadOnlyOperator, transformRoleName } from 'utils/roleHelpers';

const getAreas = async ({
  limit = 10,
  offset = 0,
  search = '',
  cancelToken,
}: tGetAreasRequest & { cancelToken: CancelToken }) => {
  const params: tGetAreasRequest = { offset };
  if (limit) params.limit = limit;
  if (search) params.search = search;

  const {
    data: { count, results },
  } = await usersAgent.getAreas(params, cancelToken);

  const areasOptions: tDropdownOption<string>[] = results.map(({ id, name }) => ({
    label: name,
    value: id,
  }));

  return { count, results: areasOptions };
};
const getRoles = ({
  limit = 10,
  offset = 0,
  search = '',
  cancelToken,
}: tGetRolesRequest & { cancelToken: CancelToken }) => {
  const params: tGetRolesRequest = { offset };

  if (limit) params.limit = limit;
  if (search) params.search = search;
  return usersAgent.getRoles(params, cancelToken).then((response) => {
    let transformedData: tDropdownOption<string>[] = [];

    const {
      data: { count, results },
    } = response;

    if (!!count) {
      transformedData = results.map(({ id, name }: tDictionary) => {
        return { value: id, label: name };
      });
    }

    return { count, results: transformedData };
  });
};

const addUser = (bodyRequest: tAddUserRequest) =>
  usersAgent
    .addUser(bodyRequest)
    .then((resp: AxiosResponse) => ({ state: true, errors: [], data: {}, status: resp.status }))
    .catch((error) =>
      errorsHandler({
        defaultErrorMessage: i18n.t('Cannot add', { value: i18n.t('user form') }),
        error,
      }),
    );

const updateUser = (userId: string, bodyRequest: tUpdateUserRequest) =>
  usersAgent
    .updateUser(userId, bodyRequest)
    .then((resp: AxiosResponse) => ({ state: true, errors: [], data: {}, status: resp.status }))
    .catch((error) =>
      errorsHandler({
        defaultErrorMessage: i18n.t('Cannot edit', { value: i18n.t('user form') }),
        error,
      }),
    );

const deleteUser = (userId: string) => {
  const defaultError = [
    { key: '__general__', msg: i18n.t('Cannot remove', { value: i18n.t('user form') }) },
  ];

  return usersAgent
    .deleteUser(userId)
    .then((resp: AxiosResponse) => ({ state: true, errors: [], data: {}, status: resp.status }))
    .catch((error) => ({ state: false, errors: error?.response?.data ?? defaultError, data: {} }));
};

const getCurrent = (cancelToken: CancelToken) =>
  usersAgent.getCurrent(cancelToken).then((result) => {
    const {
      data: {
        id,
        first_name: firstName,
        last_name: lastName,
        role: { id: roleId, name: role },
      },
    } = result;

    const CurrentUserData: tCurrentUser = {
      id,
      firstName,
      lastName,
      role,
      systemRole: transformRoleName(role),
      roleId,
      isReadOnly: isReadOnlyOperator(transformRoleName(role)),
    };

    return CurrentUserData;
  });

const getUser = ({ userId }: { userId: string | number }, cancelToken) =>
  usersAgent.getUser(userId, cancelToken).then((response) => {
    const {
      data: {
        first_name,
        last_name,
        email,
        phone_number,
        role: { id: roleId, name: roleName },
        areas: area_ids,
      },
    } = response;

    return {
      first_name,
      last_name,
      email,
      phone_number,
      role_id: {
        value: roleId,
        label: roleName,
      },
      area_ids: !!area_ids.length
        ? area_ids.map((area: tDictionary) => ({ value: area.id, label: area.name }))
        : [],
    };
  });

const getUsers = ({
  limit = 10,
  offset = 0,
  search = '',
  role_id = '',
  cancelToken,
}: tGetUsersRequest & { cancelToken: CancelToken }) => {
  const params: tGetUsersRequest = { offset };

  if (limit) params.limit = limit;
  if (search) params.search = search;
  if (role_id) params.role_id = role_id;

  return usersAgent.getUsers(params, cancelToken).then((response) => {
    let transformedData: tGetUsersList[] = [];

    const {
      data: { count, results },
    } = response;

    if (!!count) {
      transformedData = results.map((userData, index) => {
        const {
          id,
          first_name,
          last_name,
          role: { name: role },
          areas,
          email,
          phone_number,
        } = userData;

        return {
          id,
          itemData: [
            { key: 'order', value: index + 1 + offset },
            { key: 'nameAndSurname', value: `${first_name} ${last_name}` },
            { key: 'role', value: role },
          ],
          extraInfo: {
            departments: areas,
            email,
            phoneNumber: phone_number,
          },
        };
      });
    }

    return { count, results: transformedData };
  });
};

export default {
  getRoles,
  getAreas,
  addUser,
  updateUser,
  deleteUser,
  getCurrent,
  getUsers,
  getUser,
};
