import { useEffect, RefObject } from 'react';

const useOnClickOutside = (
  ref: RefObject<HTMLElement>,
  handler: (ev: MouseEvent | TouchEvent) => void,
) => {
  useEffect(() => {
    const listener = (ev: MouseEvent | TouchEvent) => {
      if (ev.target === null || ref.current === null || ref?.current?.contains(ev.target as Node)) {
        return;
      }

      handler(ev);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
};

export default useOnClickOutside;
