import { createContext, useContext } from 'react';

export type tSidebarContextType = {
  wideMenu: boolean;
  setWideMenu: Function;
};

export const SidebarContext = createContext<tSidebarContextType>({
  wideMenu: false,
  setWideMenu: () => {},
});

export const useSidebar = () => useContext(SidebarContext);
