import { FC } from 'react';
import { Icon } from 'components';

import { tIconEnum } from 'components/Icon/Icon';
import { Section, Text } from './DisplayTaskData.style';

type tDisplayTaskData = {
  text: string;
  icon: tIconEnum;
  schedule?: boolean;
};

const DisplayTaskData: FC<tDisplayTaskData> = ({ text, icon, schedule = false }) => (
  <Section $schedule={schedule}>
    <Icon iconName={icon} width='1.25rem' height='1.25rem' />
    <Text>{text}</Text>
  </Section>
);

export default DisplayTaskData;
