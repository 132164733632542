import { Icon } from 'components';

import { colorSlateBlue } from 'styles/constants';

export type tSelector = {
  checked: boolean;
};

const Selector = ({ checked = false }: tSelector) => (
  <Icon iconName={checked ? 'radioButtonChecked' : 'radioButtonUnchecked'} fill={colorSlateBlue} />
);

export default Selector;
