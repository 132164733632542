import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Link } from 'react-router-dom';

import { tDates } from 'types/global';
import { Datepicker, Icon } from 'components';
import { Flex, FlexElement } from 'styles/GlobalStyledComponents';
import GroupSchedule from '../Maps/GroupSchedule';
import {
  Header,
  Title,
  StatusContainer,
  Status,
  OnlineStatus,
  CircleOnlineStatus,
  HeaderSection,
  Wrapper,
  Section,
  SectionTitle,
  RedirectSvgContainer,
  List,
  ListItem,
  Container,
  Item,
  Separator,
  CalendarContainer,
} from './InfoWindowForGroup.style';
import useMobileGroup from 'services/hooks/useMobileGroup';

type tInfoWindowForGroup = {
  groupId: string;
  withSchedule?: boolean;
};

const InfoWindowForGroup = ({ groupId, withSchedule = true }: tInfoWindowForGroup) => {
  const { t } = useTranslation();
  const [executionTime, setExecutionTime] = useState<Date>(new Date());

  const { data: dataMobileGroup, isLoading } = useMobileGroup({
    itemId: groupId,
  });

  if (isLoading) {
    return <Wrapper>{t('Loading')}...</Wrapper>;
  }

  if (!dataMobileGroup) {
    return <Wrapper>{t('No data to display')}</Wrapper>;
  }

  return (
    <Wrapper>
      <Flex $flex={1}>
        <Header>
          <Title>{dataMobileGroup.name}</Title>
          <Status $status={dataMobileGroup.status}>
            {t(dataMobileGroup.status?.split('_').join(' ') ?? t('Brak statusu dla grupy'))}
          </Status>
          <StatusContainer>
            <CircleOnlineStatus $online={dataMobileGroup.online} />
            <OnlineStatus>{dataMobileGroup.online ? t('Online') : t('Offline')}</OnlineStatus>
          </StatusContainer>
          {!!dataMobileGroup.address && (
            <HeaderSection>
              <Icon iconName='place' />
              {dataMobileGroup.address}
            </HeaderSection>
          )}
          <HeaderSection>
            <Icon iconName='phone' />
            {dataMobileGroup.phone_number}
          </HeaderSection>
        </Header>
        <Separator />
        <Scrollbars autoHide autoHeight autoHeightMax={250}>
          <Container>
            {!!dataMobileGroup.equipment_ids?.length && (
              <Section>
                <SectionTitle>
                  <Icon iconName='homeRepairService' />
                  {t('Equipments')}
                </SectionTitle>
                <List>
                  {dataMobileGroup.equipment_ids?.map((equipment) => (
                    <ListItem key={equipment.value}>{equipment.label}</ListItem>
                  ))}
                </List>
              </Section>
            )}
            {!!dataMobileGroup.qualification_ids?.length && (
              <Section>
                <SectionTitle>
                  <Icon iconName='howToReg' />
                  {t('Qualifications')}
                </SectionTitle>
                <List>
                  {dataMobileGroup.qualification_ids?.map((qualification) => (
                    <ListItem key={qualification.value}>{qualification.label}</ListItem>
                  ))}
                </List>
              </Section>
            )}
            <Section>
              <SectionTitle>
                <Icon iconName='directionsCar' />
                {t('Car')}
              </SectionTitle>
              <Item>{dataMobileGroup.vehicle_id?.label}</Item>
            </Section>
          </Container>
          {withSchedule && <Separator />}
        </Scrollbars>
        {withSchedule && (
          <Section style={{ marginTop: '1rem' }}>
            <SectionTitle style={{ justifyContent: 'space-between' }}>
              <FlexElement>
                <Icon iconName='schedule' />
                {t('Schedule')}
              </FlexElement>
              <Link to='/schedule' state={{ search: dataMobileGroup.name, date: executionTime }}>
                <RedirectSvgContainer>
                  <Icon iconName='launch' width='24px' height='24px' />
                </RedirectSvgContainer>
              </Link>
            </SectionTitle>
            <CalendarContainer>
              <Datepicker
                startDate={executionTime}
                onChange={([date]: tDates) => setExecutionTime(date)}
              />
            </CalendarContainer>
          </Section>
        )}
      </Flex>
      {withSchedule && (
        <Flex $flex={1}>
          <GroupSchedule
            executionTime={executionTime}
            group={{ id: groupId, name: dataMobileGroup.name }}
          />
        </Flex>
      )}
    </Wrapper>
  );
};

export default InfoWindowForGroup;
