import styled from 'styled-components';

type tPicker = {
  $direction: 'column' | 'row';
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`;

export const Picker = styled.div<tPicker>`
  display: flex;
  flex-direction: ${({ $direction }) => $direction};
  justify-content: flex-start;
  @media screen and (max-width: 540px) {
    flex-direction: column;
  }
  button + button {
    margin-top: ${({ $direction }) => ($direction === 'row' ? '0px' : '12px')};
    margin-left: ${({ $direction }) => ($direction === 'row' ? '32px' : '0px')};
  }
`;
