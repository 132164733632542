import styled from 'styled-components';

import {
  boxShadowNotificationsPanel,
  colorSlateBlue,
  colorWhite,
  zIndexBackground,
} from 'styles/constants';

export const Panel = styled.div`
  background: ${colorWhite};
  border-radius: 8px;
  box-shadow: ${boxShadowNotificationsPanel};
  position: absolute;
  right: -20px;
  top: 40px;
  width: 470px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 767px) {
    right: -23.5vw;
  }

  @media screen and (max-width: 599px) {
    right: -35vw;
    max-width: 80vw;
  }

  @media screen and (max-width: 499px) {
    right: -47vw;
  }
  &:after {
    border: 8px solid transparent;
    border-color: transparent transparent ${colorWhite} ${colorWhite};
    box-shadow: ${boxShadowNotificationsPanel};
    content: '';
    height: 0;
    position: absolute;
    right: 24px;
    top: -8px;
    transform: rotate(135deg);
    width: 0;
    z-index: ${zIndexBackground};

    @media screen and (max-width: 767px) {
      right: 24vw;
    }

    @media screen and (max-width: 599px) {
      right: 35.5vw;
    }

    @media screen and (max-width: 499px) {
      right: 47vw;
    }
  }
`;

export const NotificationsContainer = styled.div`
  position: relative;
`;

export const NoResultsCopy = styled.div`
  color: ${colorSlateBlue};
  font-size: 14px;
  font-weight: bold;
  margin-top: 12px;
`;

export const Title = styled.h3`
  padding: 24px;
`;

export const EmptyNotificationsState = styled.div`
  text-align: center;
  padding: 1rem;
`;

export const ListContainer = styled.div`
  padding: 0 24px 24px;
`;

export const SpinnerContainer = styled.div`
  position: relative;
  max-height: 100px;
  > div {
    transform: scale(0.25);
    top: -65px;
  }
`;
