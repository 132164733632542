import { useEffect, useState } from 'react';

type tDebounce = {
  delay?: number;
  searchValue: string;
};

const useDebounce = ({ delay = 500, searchValue }: tDebounce): string => {
  const [debouncedSearchValue, setDebouncedSearchValue] = useState<string>(searchValue);

  useEffect(() => {
    const debounceHandler = setTimeout(() => {
      setDebouncedSearchValue(searchValue);
    }, delay);

    return () => {
      clearTimeout(debounceHandler);
    };
  }, [searchValue, delay]);

  return debouncedSearchValue;
};

export default useDebounce;
