import styled, { css } from 'styled-components';

import { Button } from 'styles/button';
import {
  colorBlack,
  colorEchoBlue,
  colorGhostWhite,
  colorWhite,
  gradientPurple,
} from 'styles/constants';

type tDayButton = {
  $active: boolean;
};

export const ButtonsContainer = styled.div`
  button + button {
    margin-left: 16px;
  }
`;

export const Container = styled.div`
  border: 1px solid ${colorEchoBlue};
  border-radius: 8px;
  padding: 12px;
`;

export const DayButton = styled(Button)<tDayButton>`
  border-radius: 100%;
  font-size: 12px;
  height: 32px;
  position: relative;
  width: 32px;
  > span {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  ${({ $active }) =>
    $active
      ? css`
          background: ${gradientPurple};
          color: ${colorWhite};
        `
      : css`
          background: ${colorGhostWhite};
          color: ${colorBlack};
        `}
`;

export const DropdownContainer = styled.div`
  flex: 1;
  margin-left: 6px;
  > div {
    margin-bottom: 0;
    > label {
      margin: 0;
    }
  }
`;

export const InputContainer = styled.div`
  flex: 1;
  margin-right: 6px;
  > input {
    width: calc(100% - 32px);
  }
`;
