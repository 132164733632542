import { useEffect, useState } from 'react';
import { KeycloakInitOptions } from 'keycloak-js';

import { Spinner } from 'components';
import notify from 'utils/notify';
import authClient from 'utils/authClient';

export const initValues: KeycloakInitOptions = { onLoad: 'check-sso', checkLoginIframe: false };

const KeycloakProvider = ({ children }) => {
  const [state, setState] = useState({
    initialized: false,
    isAuthenticated: false,
    isLoading: true,
  });

  const updateState = () => () => {
    const isAuthenticated = authClient?.authenticated ?? false;
    setState({
      initialized: true,
      isAuthenticated,
      isLoading: false,
    });

    if (isAuthenticated) {
      const { token } = authClient;
      if (token) {
        window.localStorage.setItem('tokenGM', token);
      }
    } else {
      window.localStorage.removeItem('tokenGM');
    }
  };

  const refreshToken = () => () => {
    authClient.updateToken(5);
  };

  useEffect(() => {
    authClient.onReady = updateState();
    authClient.onAuthSuccess = updateState();
    authClient.onAuthRefreshSuccess = updateState();
    authClient.onAuthLogout = updateState();
    authClient.onTokenExpired = refreshToken();

    authClient.init(initValues).catch((e) => notify({ copy: e.msg, type: 'error' }));
  }, []);

  if (!state.initialized || state.isLoading) {
    return <Spinner dataId='init-spinner' />;
  }

  return children;
};

export default KeycloakProvider;
