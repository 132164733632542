import styled from 'styled-components';

import { colorBlack } from 'styles/constants';
import { Button } from 'styles/button';

export const Label = styled.div`
  color: ${colorBlack};
  font-size: 12px;
  line-height: 15px;
  padding-left: 12px;
  &:first-letter {
    text-transform: uppercase;
  }
`;

export const RadioButton = styled(Button)`
  align-items: center;
  display: flex;
  padding: 0px;
`;
