import { ReactNode, useState, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { Flex, FlexElement } from 'styles/GlobalStyledComponents';
import { ButtonCancel, ButtonConfirm, Icon, Modal, TextArea } from 'components';
import { Buttons, Container, IconContainer, Title } from './DeleteTaskModal.style';

type tDeleteTaskModal = {
  children: ReactNode;
  text: string;
  onCancel: () => void;
  onConfirm: (reason: string) => void;
  errors?: string[];
  withReason?: boolean;
};

const DeleteTaskModal = ({
  children,
  onCancel,
  text,
  onConfirm,
  errors = [],
  withReason = true,
}: tDeleteTaskModal) => {
  const { t } = useTranslation();
  const [reason, setReason] = useState<string>('');
  const submit = () => onConfirm(reason);

  return (
    <Modal visible>
      <Container>
        <IconContainer>
          <Icon iconName='deleteTrash' width='11.25rem' height='6.25rem' />
        </IconContainer>
        <Title>{text}</Title>
        {children}
        {withReason && (
          <FlexElement>
            <Flex $flex={1} style={{ marginLeft: '6px' }}>
              <TextArea
                label={t('Reason for withdrawal of the task')}
                value={reason}
                onChange={({ target: { value } }: ChangeEvent<HTMLTextAreaElement>) =>
                  setReason(value)
                }
                errors={errors}
                dataTestId='deleteTask__reasonInput'
              />
            </Flex>
          </FlexElement>
        )}
        <Buttons>
          <ButtonCancel onClick={onCancel}>{t('Cancel')}</ButtonCancel>
          <ButtonConfirm onClick={submit}>{t('Delete')}</ButtonConfirm>
        </Buttons>
      </Container>
    </Modal>
  );
};

export default DeleteTaskModal;
