import React, { Suspense, useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { PageWrapper, Sidebar, Spinner, Topbar } from 'components';
import { CurrentUserContext } from 'contexts/currentUser';
import { SidebarContext } from 'contexts/sidebar';
import { roleLocalAdministrator } from 'utils/constants';
import { useRolesForDropdown } from 'services/hooks/useUsers';
import { tCurrentUser } from 'types/services/users';
import authClient from 'utils/authClient';
import { requestPermission } from 'utils/pushNotification';

type tLayout = {
  userData?: tCurrentUser;
};

const Layout = ({ userData }: tLayout) => {
  const [showRwdMenu, setShowRwdMenu] = useState<boolean>(false);
  const [wideMenu, setWideMenu] = useState<boolean>(false);

  const { rolesOptions } = useRolesForDropdown({
    enabled: userData?.systemRole === roleLocalAdministrator,
  });

  useEffect(() => {
    const result = window.localStorage.getItem('wideMenu');

    if (!!result) {
      const helper = JSON.parse(result);
      if (wideMenu !== helper) {
        setWideMenu(helper);
      }
    }
  }, []);

  useEffect(() => {
    window.localStorage.setItem('wideMenu', JSON.stringify(wideMenu));
  }, [wideMenu]);

  useEffect(() => {
    if (authClient?.authenticated) {
      requestPermission();
    }
  }, [authClient?.authenticated]);

  if (!userData) return <Navigate to='/login' />;

  return (
    <SidebarContext.Provider value={{ wideMenu, setWideMenu }}>
      <PageWrapper>
        <CurrentUserContext.Provider value={{ user: userData, roles: rolesOptions }}>
          <Topbar setShowRwdMenu={() => setShowRwdMenu(!showRwdMenu)} />
          <Sidebar setShowRwdMenu={setShowRwdMenu} showRwdMenu={showRwdMenu} />
          <Suspense fallback={<Spinner />}>
            <Outlet />
          </Suspense>
        </CurrentUserContext.Provider>
      </PageWrapper>
    </SidebarContext.Provider>
  );
};

export default Layout;
