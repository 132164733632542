import { tError } from 'types/global';

type tErrors = tError[];
type tFields = string[];

const handleUnexpectedFormErrors = (errors: tErrors, fields: tFields): tErrors =>
  errors.filter((error) => !fields.some((field: string) => error.key === field));

export const handleExpectedFormErrors = (errors: tErrors, fields: tFields): tErrors =>
  errors.filter((error) => fields.some((field: string) => error.key === field));

export default handleUnexpectedFormErrors;
