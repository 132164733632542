import { FC } from 'react';
import iconsMap from 'icons/iconsMap';
import { tKeyValueGeneric } from 'types/global';

export type tIconEnum =
  | ''
  | 'alert'
  | 'addCircleButton'
  | 'addPlus'
  | 'assignTask'
  | 'close'
  | 'search'
  | 'arrowDown'
  | 'arrowUp'
  | 'arrowDropDown'
  | 'calendarToday'
  | 'directionsCar'
  | 'error'
  | 'factCheck'
  | 'fileDownload'
  | 'filePresent'
  | 'firstPage'
  | 'lastPage'
  | 'navigateBefore'
  | 'navigateNext'
  | 'moreHoriz'
  | 'moreVert'
  | 'menu'
  | 'client'
  | 'contract'
  | 'home'
  | 'launch'
  | 'location'
  | 'inspection'
  | 'inventory'
  | 'login'
  | 'notifications'
  | 'people'
  | 'power'
  | 'product'
  | 'schedule'
  | 'user'
  | 'userAccount'
  | 'homeRepairService'
  | 'howToReg'
  | 'place'
  | 'remove'
  | 'edit'
  | 'dateRange'
  | 'noTasks'
  | 'noResults'
  | 'alarm'
  | 'deleteGraphic'
  | 'done'
  | 'contentPasteOff'
  | 'password'
  | 'phone'
  | 'pen'
  | 'info'
  | 'radioButtonUnchecked'
  | 'radioButtonChecked'
  | 'checkCircle'
  | 'success'
  | 'reports'
  | 'taskStatuses'
  | 'filter'
  | 'deleteTrash'
  | 'clockStart'
  | 'notificationAlarm'
  | 'notificationTimeDelay'
  | 'notificationAlarmResolved'
  | 'notificationStartAfterTime'
  | 'notificationDefault'
  | 'notificationMobileGroupAssigned'
  | 'notificationServiceAssigned'
  | 'notificationAuditNextDay'
  | 'reportDownload'
  | 'photoPreview'
  | 'unassignTask'
  | 'refresh'
  | 'menuOpen'
  | 'clearAll';

export type tIcon = {
  extraStyles?: tKeyValueGeneric<string>;
  fill?: string;
  height?: string;
  iconName: tIconEnum;
  width?: string;
  dataId?: string;
};

const Icon: FC<tIcon> = ({
  extraStyles = {},
  fill = 'black',
  height = '1.5rem',
  iconName = '',
  width = '1.5rem',
  dataId,
}): JSX.Element | null => {
  const IconComponent = iconsMap[iconName];

  if (!IconComponent) return null;

  return (
    <IconComponent data-testid={dataId} fill={fill} style={{ ...extraStyles, width, height }} />
  );
};

export default Icon;
