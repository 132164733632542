import { ReactNode } from 'react';

import { Icon } from 'components';
import { tKeyValueGeneric } from 'types/global';
import { colorLightSlateGrey } from 'styles/constants';
import {
  roleAuditor,
  roleLocalAdministrator,
  roleOperator,
  roleReadOnlyOperator,
  roleSystemAdministrator,
} from 'utils/constants';

export type tRoute = {
  id: number;
  path: string;
  sidebar: string;
  icon: ReactNode;
};

type tRoutes = tKeyValueGeneric<tRoute[]>;

const operatorRouteHelper = [
  {
    id: 11,
    path: '/home',
    sidebar: 'Dashboard',
    icon: <Icon iconName='home' fill={colorLightSlateGrey} />,
  },
  {
    id: 12,
    path: '/schedule',
    sidebar: 'Schedule',
    icon: <Icon iconName='schedule' fill={colorLightSlateGrey} />,
  },
  {
    id: 13,
    path: '/task-statuses',
    sidebar: 'Task statuses',
    icon: <Icon iconName='taskStatuses' fill={colorLightSlateGrey} />,
  },
  {
    id: 14,
    path: '/reports',
    sidebar: 'Reports',
    icon: <Icon iconName='reports' fill={colorLightSlateGrey} />,
  },
];

const routes: tRoutes = {
  [roleOperator]: operatorRouteHelper,
  [roleReadOnlyOperator]: operatorRouteHelper,
  [roleAuditor]: [
    {
      id: 21,
      path: '/home',
      sidebar: 'Mobile Groups',
      icon: <Icon iconName='people' fill={colorLightSlateGrey} />,
    },
    {
      id: 22,
      path: '/schedule',
      sidebar: 'Schedule',
      icon: <Icon iconName='schedule' fill={colorLightSlateGrey} />,
    },
    {
      id: 23,
      path: '/audits',
      sidebar: 'Audits',
      icon: <Icon iconName='inspection' fill={colorLightSlateGrey} />,
    },
  ],
  [roleSystemAdministrator]: [
    {
      id: 31,
      path: '/home',
      sidebar: 'Users',
      icon: <Icon iconName='user' fill={colorLightSlateGrey} />,
    },
  ],
  [roleLocalAdministrator]: [
    {
      id: 41,
      path: '/home',
      sidebar: 'Clients',
      icon: <Icon iconName='client' fill={colorLightSlateGrey} />,
    },
    {
      id: 42,
      path: '/equipment',
      sidebar: 'Equipment',
      icon: <Icon iconName='homeRepairService' fill={colorLightSlateGrey} />,
    },
    {
      id: 43,
      path: '/qualifications',
      sidebar: 'Qualifications',
      icon: <Icon iconName='howToReg' fill={colorLightSlateGrey} />,
    },
    {
      id: 44,
      path: '/products',
      sidebar: 'Products',
      icon: <Icon iconName='product' fill={colorLightSlateGrey} />,
    },
    {
      id: 45,
      path: '/contracts',
      sidebar: 'Contracts',
      icon: <Icon iconName='contract' fill={colorLightSlateGrey} />,
    },
    {
      id: 46,
      path: '/locations',
      sidebar: 'Locations',
      icon: <Icon iconName='location' fill={colorLightSlateGrey} />,
    },
    {
      id: 47,
      path: '/cars',
      sidebar: 'Cars',
      icon: <Icon iconName='directionsCar' fill={colorLightSlateGrey} />,
    },
    {
      id: 48,
      path: '/mobile-groups',
      sidebar: 'Mobile Groups',
      icon: <Icon iconName='people' fill={colorLightSlateGrey} />,
    },
    {
      id: 49,
      path: '/service-templates',
      sidebar: 'Service templates',
      icon: <Icon iconName='inventory' fill={colorLightSlateGrey} />,
    },
    {
      id: 50,
      path: '/audit-templates',
      sidebar: 'Audit templates',
      icon: <Icon iconName='factCheck' fill={colorLightSlateGrey} />,
    },
    {
      id: 14,
      path: '/reports',
      sidebar: 'Reports',
      icon: <Icon iconName='reports' fill={colorLightSlateGrey} />,
    },
  ],
};

export default routes;
