import { FC, RefObject, createRef, useEffect, useState, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon, Label } from 'components';
import { FlexElement } from 'styles/GlobalStyledComponents';

import { colorRadicalRed, colorSlateBlue } from 'styles/constants';
import {
  ErrorCopy,
  ErrorsContainer,
  FilesList,
  FileUploadInput,
  Wrapper,
} from './FileUpload.style';
import { checkFileError, setHumanFileSize } from './utils';
import FileWrapper from './FileWrapper';

export type tFile = {
  id: string | number;
  file: File;
};

type tFileUpload = {
  extensions: string[];
  fileLimit?: null | number;
  multiple?: boolean;
  optional?: boolean;
  // eslint-disable-next-line no-unused-vars
  onChange?: (files: tFile[]) => void;
};

const FileUpload: FC<tFileUpload> = ({
  extensions,
  fileLimit = 1,
  multiple = false,
  optional = false,
  onChange = () => {},
}) => {
  const { t } = useTranslation();
  const inputFileRef: RefObject<HTMLInputElement> = createRef();
  const [files, setFiles] = useState<tFile[]>([]);
  const [uploadErrors, setUploadErrors] = useState<string[]>([]);

  const onChangeHandler = (capturedFiles: FileList | null) => {
    if (!capturedFiles) {
      setUploadErrors(['No files uploaded']);
      return;
    }

    const filesToAdd = capturedFiles;

    if (fileLimit !== null && Array.from(filesToAdd).length > fileLimit) {
      setUploadErrors([`You can't add that many files`]);
      return;
    }

    const errors: string[] = Array.from(filesToAdd)
      .map((file: File) => checkFileError(file, extensions))
      .flat();

    setUploadErrors(Array.from(new Set(errors)));

    if (errors.length) {
      return;
    }

    setFiles([
      ...files,
      ...Array.from(filesToAdd).map((file: File, index: number) => ({
        id: new Date().getTime() + index,
        file,
      })),
    ]);
  };

  const removeItem = (id: string | number) => {
    setFiles(files.filter((file: { id: string | number }) => file.id !== id));
  };

  const handleClick = () => {
    if (inputFileRef.current) {
      inputFileRef.current.click();
    }
  };

  useEffect(() => {
    onChange(files);
  }, [files]);

  const uploadButton = (items: boolean) => (
    <Wrapper onClick={handleClick} style={{ marginTop: items ? '12px' : '0px' }}>
      <FileUploadInput
        ref={inputFileRef}
        type='file'
        name='attachments'
        accept={extensions.map((extension: string) => `.${extension}`).join(', ')}
        multiple={multiple}
        key={files.length + 1}
        onChange={({ target: { files } }: ChangeEvent<HTMLInputElement>) => onChangeHandler(files)}
        data-testid='fileUpload__input'
      />
      <Icon iconName='addPlus' fill={colorSlateBlue} />
    </Wrapper>
  );

  return (
    <>
      <Label optional={optional}>{t('Attachment')}</Label>
      {!!files.length && (
        <FilesList>
          {files.map((item: { id: string | number; file: File }) => {
            const {
              id,
              file: { name, size },
            } = item;
            const { information, sizeTypeMessage } = setHumanFileSize(size);

            return (
              <FileWrapper
                key={id}
                id={id}
                name={name}
                sizeTypeMessage={sizeTypeMessage}
                information={information}
                removeItem={removeItem}
              />
            );
          })}
        </FilesList>
      )}
      {(!fileLimit || fileLimit !== files.length) && uploadButton(!!files.length)}
      {!!uploadErrors.length && (
        <ErrorsContainer>
          {uploadErrors.map((error: string) => (
            <FlexElement key={error.replace(/ /g, '_')}>
              <Icon iconName='error' fill={colorRadicalRed} height='1rem' width='1rem' />
              <ErrorCopy>{t(error)}</ErrorCopy>
            </FlexElement>
          ))}
        </ErrorsContainer>
      )}
    </>
  );
};

export default FileUpload;
