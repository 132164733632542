import { tDropdownOption } from 'types/global';
import { tOption } from 'components/RadioPicker/RadioPicker';
import { tErrorAction, tResetForm, tSaveAction } from 'types/form';
import { ERROR, RESET_FORM, SAVE } from 'utils/constants';

type tAssignAuditToScheduleFormState = {
  audit: {
    value: tDropdownOption<string> | null;
    error: string;
  };
  start_time: {
    value: string;
    error: string;
  };
  confirmation_method: {
    value: tOption<'signature' | 'no_confirmation'> | null;
    error: string;
  };
};

export const initialState: tAssignAuditToScheduleFormState = {
  audit: {
    value: null,
    error: '',
  },
  start_time: {
    value: '',
    error: '',
  },
  confirmation_method: {
    value: null,
    error: '',
  },
};

type tActions =
  | tSaveAction<tAssignAuditToScheduleFormState>
  | tErrorAction<tAssignAuditToScheduleFormState>
  | tResetForm;

function addAssignAuditToSchedule(state: tAssignAuditToScheduleFormState, action: tActions) {
  switch (action.type) {
    case SAVE:
      return {
        ...state,
        [action.name]: { ...state[action.name], value: action.value, error: '' },
      };
    case ERROR:
      return { ...state, [action.name]: { ...state[action.name], error: action.error } };
    case RESET_FORM:
      return { ...initialState };
    default:
      throw new Error();
  }
}

export default addAssignAuditToSchedule;
