const allowedExtensions: string[] = [
  'image/png',
  'png',
  'image/jpeg',
  'jpg',
  'application/pdf',
  'pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'docx',
  'doc',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'xlsx',
  'xls',
  'application/vnd.ms-excel',
  'application/msword',
];

const checkFileExtension = (fileExtension: string, extensions: string[]): boolean => {
  if (!extensions.includes(fileExtension) || !allowedExtensions.includes(fileExtension)) {
    return false;
  }

  return true;
};

export default checkFileExtension;
