export const mockCustomSteps = [
  {
    id: '1',
    name: '',
    isRemovable: false,
    type: null,
  },
];

export const mockServiceTypes = [
  { value: 1, label: 'Dodaj z szablonu' },
  { value: 2, label: 'Dodaj własne' },
];
