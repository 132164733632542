import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import servicesService from 'services/services';

import { DeleteTaskModal } from 'components';
import { tError } from 'types/global';
import { tErrorsHandlerResponse } from 'services/utils/errorsHandler';

import { DisplayTaskLocationAndScheduler } from 'components/Tasks/DisplayTaskData';

import { Title } from './DeleteTaskModalWithData.style';
import { tTaskShortData } from 'types/services/tasks';

type tDeleteTaskModalWithData = {
  task: tTaskShortData | null;
  onSuccess: () => void;
  onCancel: () => void;
  text?: string;
};

const DeleteTaskModalWithData = ({
  task,
  onSuccess,
  onCancel,
  text = '',
}: tDeleteTaskModalWithData) => {
  const { t } = useTranslation();
  const [errors, setErrors] = useState<string[]>([]);

  const removeTask = async (reason: string) => {
    if (task === null) return;
    if (!!reason) {
      const result: tErrorsHandlerResponse = await servicesService.cancel({
        id: task.id,
        reason,
      });
      if (result.state) {
        onSuccess();
      } else {
        const errors: string[] = result.errors.map((error: tError) => error.msg);
        setErrors(errors);
      }
    } else {
      setErrors([t('Field required')]);
    }
  };

  return (
    <DeleteTaskModal onConfirm={removeTask} onCancel={onCancel} text={text} errors={errors}>
      <Title>{task?.name ?? '-'}</Title>
      <DisplayTaskLocationAndScheduler task={task} />
      <div style={{ marginBottom: '1rem' }} />
    </DeleteTaskModal>
  );
};

export default DeleteTaskModalWithData;
