import { Dispatch } from 'react';

import { tError, tKeyValueGeneric } from 'types/global';
import { ERROR } from 'utils/constants';
import { tErrorAction } from 'types/form';

type tMatchField = tKeyValueGeneric<string>;
export type tErrorsSetter = {
  errors: tError[];
  formFields: string[];
  dispatch: Dispatch<tErrorAction>;
  matchFields?: tMatchField[];
};

const errorsSetter = ({ errors, formFields, dispatch, matchFields = [] }: tErrorsSetter) => {
  formFields.forEach((formField: string) => {
    const errorObject = errors
      .filter((errorItem: { key: string }) => errorItem.key === formField)
      .map((errorItem: { msg: string }) => errorItem.msg);

    let formFieldHelper = formField;
    const match = matchFields.find((field) => field.apiField === formField);

    if (match) {
      formFieldHelper = match.formField;
    }
    if (!!errorObject.length) {
      dispatch({
        type: ERROR,
        name: formFieldHelper,
        error: errorObject.join(', '),
      });
    }
  });
};

export default errorsSetter;
