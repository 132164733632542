import styled, { css } from 'styled-components';

import { IconButton } from 'styles/button';
import {
  boxShadowCommon,
  colorLightSlateGrey,
  colorRadicalRed,
  colorSlateBlue,
  colorWhite,
  zIndexFixed,
} from 'styles/constants';
import { ChipElement } from 'components/Chip/Chip.style';
import { Loader } from '../Spinner/Spinner.style';

type tCheckboxContainer = {
  $gap: string;
  $display?: string;
};

type tMultiSelectContainer = {
  $error: boolean;
  $isLoading: boolean;
};
export const CheckboxContainer = styled.div<tCheckboxContainer>`
  margin-bottom: ${({ $gap }) => $gap};
  ${({ $display }) =>
    $display &&
    css`
      display: ${$display};
    `}
`;

export const IconButtonContainer = styled(IconButton)`
  line-height: 8px;
  margin-left: 4px;
  margin-right: -4px;
  margin-top: -1px;
  > svg {
    > path {
      fill: ${colorWhite};
    }
  }
`;

export const MultiSelectContainer = styled.div<tMultiSelectContainer>`
  display: flex;
  flex-wrap: wrap;
  gap: 4px 8px;
  border: 1px solid ${({ $error }) => ($error ? colorRadicalRed : colorLightSlateGrey)};
  border-radius: 16px;
  font-size: 12px;
  min-height: 14px;
  padding: 8px 48px 8px 16px;
  position: relative;

  &:hover {
    border-color: ${colorSlateBlue};
  }

  ${({ $isLoading }) =>
    $isLoading &&
    css`
      ${Loader} {
        margin: auto;
        transform: scale(0.2);
        position: absolute;
        top: -39px;
        right: -27px;
      }
    `}

  // TODO: Remove margin of ChipElement in Chip.style.ts after replacing all multiselect dropdown with the new version
  ${ChipElement} {
    margin: 0;
  }
`;

export const Placeholder = styled.span`
  align-self: center;
  margin-right: 0.5rem;
`;

export const NoResultsCopy = styled.div`
  color: ${colorSlateBlue};
  font-size: 14px;
  font-weight: bold;
  margin-top: 12px;
`;

export const OptionsPanel = styled.div<{ $positionList?: string }>`
  box-shadow: ${boxShadowCommon};
  padding: 12px;
  margin: 12px 6px 6px;
  border-radius: 16px;
  background: white;

  ${({ $positionList }) =>
    !!$positionList &&
    css`
      position: ${$positionList};
      z-index: ${zIndexFixed};
      left: 0;
      right: 0;
    `};

  @media screen and (max-width: 1000px) {
    position: relative;
    z-index: auto;
  }
`;

export const SearchContainer = styled.div`
  margin: 1rem auto 1.5rem;
  width: 100%;
  > div {
    max-width: calc(100% - 56px);
    width: 100%;
  }
`;

export const ActionContainer = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: center;
`;

export const ArrowContainer = styled.div`
  cursor: pointer;
  height: 24px;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
`;
