import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DeleteTaskModalWithData, UnassignTaskFromGroupConfirmation } from 'components';

import { ActionButton } from './TaskActionButtons.style';
import { tTaskShortData } from 'types/services/tasks';

type tTaskActionButtons = {
  task: tTaskShortData;
  group: { id: string; name: string };
  onSuccess?: (action: 'unassign' | 'deleted') => void;
};

const TaskActionButtons = ({ task, group, onSuccess = () => {} }: tTaskActionButtons) => {
  const { t } = useTranslation();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showUnassignModal, setShowUnassignModal] = useState<boolean>(false);

  const openDeleteModal = () => setShowDeleteModal(true);

  const closeDeleteModal = () => setShowDeleteModal(false);

  const deleteModalHandler = () => {
    closeDeleteModal();
    onSuccess('deleted');
  };

  const openUnassignModal = () => setShowUnassignModal(true);

  const closeUnassignModal = () => setShowUnassignModal(false);

  const unassignModalHandler = () => {
    closeUnassignModal();
    onSuccess('unassign');
  };

  if (!task.id) return null;
  return (
    <>
      {showDeleteModal ? (
        <DeleteTaskModalWithData
          onSuccess={deleteModalHandler}
          onCancel={closeDeleteModal}
          text={`${t('Are you sure you want to delete the job')}?`}
          task={task}
        />
      ) : null}
      {showUnassignModal ? (
        <UnassignTaskFromGroupConfirmation
          onSuccess={unassignModalHandler}
          onCancel={closeUnassignModal}
          task={task}
          group={group}
        />
      ) : null}
      <ActionButton onClick={openUnassignModal}>{t('Undo the task')}</ActionButton>
      <ActionButton onClick={openDeleteModal}>{t('Remove the task')}</ActionButton>
    </>
  );
};

export default TaskActionButtons;
