import { ERROR, SAVE } from 'utils/constants';
import { tErrorAction, tSaveAction } from 'types/form';

type tAssignTaskFormState = {
  startTime: {
    value: string;
    error: '';
  };
  endTime: {
    value: string;
    error: '';
  };
  startDate: {
    value: Date;
    error: '';
  };
  endDate: {
    value: Date;
    error: '';
  };
};

export const createInitialState: (date: Date) => tAssignTaskFormState = (date: Date) => ({
  startTime: {
    value: '00:00',
    error: '',
  },
  endTime: {
    value: '00:00',
    error: '',
  },
  startDate: {
    value: date,
    error: '',
  },
  endDate: {
    value: date,
    error: '',
  },
});

type tActions = tSaveAction<tAssignTaskFormState> | tErrorAction<tAssignTaskFormState>;

function addAssignTaskModalReducer(state: tAssignTaskFormState, action: tActions) {
  switch (action.type) {
    case SAVE:
      return {
        ...state,
        [action.name]: { ...state[action.name], value: action.value, error: '' },
      };
    case ERROR:
      return { ...state, [action.name]: { ...state[action.name], error: action.error } };
    default:
      throw new Error();
  }
}

export default addAssignTaskModalReducer;
