import { createContext, useContext } from 'react';
import { tGetServiceData, tGetServiceDataWithOffset } from 'types/services/services';
import { tGroup } from 'types/services/groups';

export type tMapsContextType = {
  groups?: tGroup[];
  tasks: tGetServiceDataWithOffset[];
  task: tGetServiceData | null;
  setActiveTask: Function;
  getTasks: Function;
  loadMoreTasks: Function;
  getGroups?: Function;
  loadingGroups?: boolean;
  loadingTasks: boolean;
  search: string;
  setSearch: Function;
  refetchEditedPage: (task: tGetServiceDataWithOffset) => void;
  refetchPageDelete: (task: tGetServiceDataWithOffset) => void;
};

export const MapsContext = createContext<tMapsContextType>({
  groups: [],
  tasks: [],
  task: null,
  setActiveTask: () => {},
  getTasks: () => {},
  loadMoreTasks: () => {},
  getGroups: () => {},
  loadingGroups: false,
  loadingTasks: false,
  search: '',
  setSearch: () => {},
  refetchEditedPage: () => {},
  refetchPageDelete: () => {},
});

export const useMaps = () => useContext(MapsContext);
