import styled from 'styled-components';

import { boxShadowCommon, colorRadicalRed, colorSlateBlue, colorWhite } from 'styles/constants';

type tBottomGap = {
  $gap: string;
};

type tFlex = {
  $flex: number;
};

export const BottomGap = styled.div<tBottomGap>`
  margin-bottom: ${({ $gap }) => $gap};
`;

export const Container = styled.section`
  background: ${colorWhite};
  border-radius: 8px;
  box-shadow: ${boxShadowCommon};
  padding: 32px 24px;
  @media screen and (max-width: 520px) {
    padding: 16px;
  }
`;

export const DetailLabel = styled.div`
  margin-bottom: 4px;
  color: ${colorSlateBlue};
  font-weight: bold;
  font-size: 14px;
`;

export const DetailList = styled.ul`
  margin: 0;
  padding: 0 0 0 16px;
  li:not(:last-child) {
    margin-bottom: 4px;
  }
`;

export const DetailTd = styled.td`
  font-size: 12px;
  padding: 16px;
  vertical-align: top;
`;

export const Flex = styled.div<tFlex>`
  flex: ${({ $flex }) => $flex};
`;

export const FlexBoxWithCalendar = styled.div`
  flex: 1;
  > div {
    display: block;
    .react-datepicker-wrapper {
      display: block;
      button {
        width: 100%;
        padding-left: 1rem;
        justify-content: flex-start;
      }
    }
  }
`;

export const FlexElement = styled.div`
  display: flex;
`;

export const SpaceBetween = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
`;

export const InputFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`;

export const ResponseErrorCopy = styled.div`
  color: ${colorRadicalRed};
  font-weight: bold;
`;

type tSearchContainer = {
  $marginBottom?: string;
  $fullWidth?: boolean;
};

export const SearchContainer = styled.div<tSearchContainer>`
  margin-bottom: ${({ $marginBottom }) => $marginBottom ?? '2rem'};
  min-height: 32px;
  flex: ${({ $fullWidth }) => ($fullWidth ? 1 : 'unset')};
  width: ${({ $fullWidth }) => ($fullWidth ? '100%' : 'unset')};
  @media screen and (max-width: 1000px) {
    margin-bottom: 16px !important;
    flex: 1;
    width: 100%;
  }
`;
