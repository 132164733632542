import { Container, Loader } from './Spinner.style';

type tSpinner = {
  withContainer?: boolean;
  dataId?: string;
};

const Spinner = ({ withContainer = true, dataId = 'spinner' }: tSpinner) => {
  if (withContainer) {
    return (
      <Container data-testid={dataId ? `${dataId}-container` : undefined}>
        <Loader data-testid={dataId} />
      </Container>
    );
  }

  return <Loader data-testid={dataId} />;
};

export default Spinner;
