import styled from 'styled-components';

export const Info = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  svg {
    margin-right: 12px;
    fill: #2579e8;
  }
`;

export const InfoText = styled.p`
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  font-weight: bold;
`;

export const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: -17px;
  margin-bottom: -17px;
  min-height: 67px;
  max-height: calc(55vh + 17px);
  height: calc(55vh + 17px);
`;
