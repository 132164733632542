import styled, { css } from 'styled-components';

import { IconButton } from 'styles/button';
import {
  colorBlack,
  colorEchoBlue,
  colorEclipse,
  colorLightSlateGrey,
  colorMagnolia,
  colorSlateBlue,
  colorSolitude,
  colorWhite,
  zIndexPopover,
} from 'styles/constants';

type tPickerBox = {
  disabled?: boolean;
  $center?: boolean;
  $position?: string;
};

export const Container = styled.div`
  align-items: flex-start;
  display: flex;
  position: relative;
  @media screen and (max-width: 1000px) {
    width: 100%;
  }
`;

export const DateCopy = styled.div<{ $disabled?: boolean }>`
  color: ${({ $disabled = false }) => ($disabled ? colorEchoBlue : colorBlack)};
  font-weight: normal;
  font-size: 12px;
  margin-left: 8px;
  padding-top: 2px;
`;

export const DatePickerWrapper = styled.div<Pick<tPickerBox, '$center' | '$position'>>`
  position: relative;
  @media screen and (max-width: 1000px) {
    width: 100%;
  }
  .react-datepicker {
    border: none;
    border-radius: 8px;
    padding: 12px;
    &-popper {
      margin-top: 4px;
      transform: translate3d(
        ${({ $center }) => ($center ? '-42px' : '-1px')},
        32px,
        0px
      ) !important;
      z-index: ${zIndexPopover};
      @media screen and (max-width: 1000px) {
        ${({ $position }) =>
          $position &&
          css`
            position: ${$position} !important;
            top: -30px !important;
            z-index: auto;
          `}
      }
    }
    &__header {
      background-color: ${colorWhite};
      border: none;
    }
    &__day {
      border: 1px solid transparent;
      border-radius: 4px;
      font-size: 12px;
      outline: none;
      &--keyboard-selected {
        background-color: ${colorWhite};
        border: none;
        color: ${colorBlack};
      }
      &--today {
        background-color: ${colorWhite};
        border: 1px solid ${colorSlateBlue};
        color: ${colorBlack};
        font-weight: normal;
      }
      &--outside-month {
        color: ${colorEchoBlue};
      }
      &--in-range,
      &--in-selecting-range {
        background: ${colorMagnolia};
        color: ${colorBlack};
      }
      &--range-start,
      &--range-end,
      &--selected,
      &:hover {
        background-color: ${colorSlateBlue};
        border: 1px solid ${colorSlateBlue};
        color: ${colorWhite};
      }
    }
    &__day-names {
      margin-top: 16px;
    }
    &__day-name {
      color: ${colorLightSlateGrey};
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
    }
    &__current-month {
      color: ${colorEclipse};
      font-size: 12px;
      font-weight: bold;
      line-height: 14px;
      &:first-letter {
        text-transform: uppercase;
      }
    }
    &__navigation {
      outline: none;
      border-width: 0 2px 2px 0;
      top: 12px;
      &--next {
        right: 10px;
      }
      &--previous {
        left: 10px;
      }
      &-icon {
        &::before {
          border-color: ${colorSlateBlue} !important;
          border-width: 2px 2px 0 0;
        }
      }
    }
  }
`;

export const PickerBox = styled(IconButton)<tPickerBox>`
  align-items: center;
  border: 1px solid;
  display: flex;
  justify-content: ${({ $center }) => ($center ? 'center' : 'flex-start')};
  line-height: 28px;
  min-width: 180px;
  padding-left: ${({ $center }) => ($center ? '0px' : '1rem')};
  ${({ disabled = false }) =>
    disabled
      ? css`
          background: ${colorSolitude};
          border: 1px solid ${colorEchoBlue};
          cursor: not-allowed;
          > svg {
            fill: ${colorEchoBlue};
          }
        `
      : css`
          background: ${colorWhite};
          border: 1px solid ${colorLightSlateGrey};
        `};
  @media screen and (max-width: 1000px) {
    width: 100%;
  }
`;
