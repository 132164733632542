import Icon from '../Icon/Icon';

import { Wrapper, Text } from './Error.style';
import useDynamicTranslation from 'hooks/useDynamicTranslation';

type tProps = {
  text: string;
};
const Error = ({ text }: tProps) => {
  const { dt } = useDynamicTranslation();
  return (
    <Wrapper>
      <Icon iconName='info' width='1rem' height='1rem' />
      <Text>{dt(text)}</Text>
    </Wrapper>
  );
};

export default Error;
