import styled from 'styled-components';

import { colorRadicalRed, colorSlateBlue } from 'styles/constants';

export const ErrorCopy = styled.div`
  color: ${colorRadicalRed};
  font-size: 12px;
  font-weight: bold;
  line-height: 1.5;
  margin-left: 8px;
`;

export const ErrorsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 12px;
`;

export const FilesList = styled.div`
  > * {
    margin-bottom: 8px;
  }
  > *:last-child {
    margin-bottom: 0px;
  }
`;

export const FileUploadInput = styled.input`
  display: none;
  visibility: hidden;
`;

export const Wrapper = styled.div`
  align-items: center;
  border: 2px dashed ${colorSlateBlue};
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  height: 40px;
  width: 40px;
`;
