import { CancelToken } from 'axios';
import fetchProvider from 'utils/Axios';
import { tDictionary, tListParams } from 'types/global';
import { tReadCar } from 'agents/cars';
import { tReadUser } from 'agents/users';

const microservicePath = '/mobile_groups';

type tGPSLocation = { latitude: number; longitude: number };

export type tStatuses = 'free' | 'busy' | 'accident' | 'notactive' | 'temporarily_free';

type tMobileGroup = {
  name: string;
  email: string;
  phone_number: string;
  profit_center?: string | null;
  external_id?: string | null;
  ro_object?: string | null;
};

type tWriteMobileGroup = tMobileGroup & {
  service_manager_ids: string[];
  area_id: string | null;
  auditor_id?: string;
  qualification_ids: string[];
  equipment_ids: string[];
  vehicle_id: string | null;
};

type tReadMobileGroup = tMobileGroup & {
  id: string;
  username: string;
  client_system_id?: string | null;
  status: tStatuses;
  online: boolean;
  auditor: tReadUser;
  vehicle: tReadCar;
  service_managers: tReadUser[];
  area: tDictionary;
  equipments: tDictionary[];
  qualifications: tDictionary[];
  gps_locations: tGPSLocation[];
};

type tDeleteMobileGroupResponse = tReadMobileGroup;

export type tAddMobileGroupRequest = tWriteMobileGroup;
type tAddMobileGroupResponse = tReadMobileGroup;

export type tGetMobileGroupsRequest = tListParams & {
  equipment_ids?: string;
  qualification_ids?: string;
  auditor_id?: string;
};
type tGetMobileGroupsResponse = { count: number; results: tReadMobileGroup[] };

type tGetMobileGroupResponse = tReadMobileGroup;

export type tUpdateMobileGroupRequest = tWriteMobileGroup;
type tUpdateMobileGroupResponse = tReadMobileGroup;

const mobileGroups = {
  deleteMobileGroup: (mobileGroupId: string) =>
    fetchProvider.delete<tDeleteMobileGroupResponse>(`${microservicePath}/${mobileGroupId}`),
  addMobileGroup: (bodyRequest: tAddMobileGroupRequest) =>
    fetchProvider.post<tAddMobileGroupResponse>(`${microservicePath}`, bodyRequest),
  getMobileGroups: (params: tGetMobileGroupsRequest, cancelToken: CancelToken) =>
    fetchProvider.get<tGetMobileGroupsResponse>(`${microservicePath}`, { params, cancelToken }),
  getMobileGroup: (mobileGroupId: string, cancelToken: CancelToken) =>
    fetchProvider.get<tGetMobileGroupResponse>(`${microservicePath}/${mobileGroupId}`, {
      cancelToken,
    }),
  updateMobileGroup: (mobileGroupId: string, bodyRequest: tUpdateMobileGroupRequest) =>
    fetchProvider.put<tUpdateMobileGroupResponse>(
      `${microservicePath}/${mobileGroupId}`,
      bodyRequest,
    ),
};

export default mobileGroups;
