import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon, Input } from 'components';
import { IconButton } from 'styles/button';
import { colorLightSlateGrey } from 'styles/constants';
import { SearchContainer } from './Search.style';

type tSearch = {
  /* eslint-disable-next-line no-unused-vars */
  onChange: ({ target: { value } }) => void;
  resetValue: () => void;
  value: string;
  placeholder?: string;
  dataId?: string;
  forTasksList?: boolean;
};

const Search: FC<tSearch> = ({
  onChange,
  resetValue,
  value,
  placeholder = 'Search',
  dataId,
  forTasksList = false,
}): JSX.Element => {
  const { t } = useTranslation();

  return (
    <SearchContainer $forTasksList={forTasksList}>
      <Icon iconName='search' fill={colorLightSlateGrey} />
      <Input
        placeholder={t(placeholder)}
        onChange={onChange}
        value={value}
        search
        type='text'
        dataTestId={dataId}
      />
      {value.length > 0 && (
        <IconButton onClick={resetValue}>
          <Icon
            iconName='close'
            height='18px'
            width='18px'
            extraStyles={{ position: 'absolute', right: '12px' }}
          />
        </IconButton>
      )}
    </SearchContainer>
  );
};

export default Search;
