import { CancelToken } from 'axios';
import fetchProvider, { fetchProviderForAttachment } from 'utils/Axios';

import { tDictionary, tDropdownOption, tListParams } from 'types/global';
import { tReadLocation } from 'agents/locations';
import { tReadContract } from 'agents/contracts';
import { tQuestion, tWriteQuestion } from 'agents/serviceTemplates';
import { components } from 'types/api';
import { tReadAttachment } from 'agents/attachments';

export const microservicePath = '/services';

export type tConfirmationMethod = 'pin_and_signature' | 'signature' | 'no_confirmation';

type tGetConfirmationMethodsResponse = {
  count: number;
  results: tDropdownOption<tConfirmationMethod>[];
};

type tGetPeriodicityIntervalTypeResponse = {
  count: number;
  results: tDropdownOption<'day' | 'week' | 'month'>[];
};

export enum eStatuses {
  NEW = 'NEW',
  CANCELLED = 'CANCELLED',
  WAITING_FOR_ACCEPTANCE = 'WAITING_FOR_ACCEPTANCE',
  ASSIGNED = 'ASSIGNED',
  ABORTED = 'ABORTED',
  STARTED = 'STARTED',
  FINISHED = 'FINISHED',
}

type tServiceTimeParams = {
  earliest_start_time_before?: Date;
  earliest_start_time_after?: Date;
  latest_finish_time_after?: Date;
  start_time_after?: Date;
  start_time_before?: Date;
  finish_time_after?: Date;
  finish_time_before?: Date;
  planned_start_time_after?: Date;
  planned_start_time_before?: Date;
};

export type tReadService = {
  id: string;
  name: string;
  location: tReadLocation;
  priority: 'NORMAL' | 'HIGH';
  status: eStatuses;
  aborted_at: string | null;
  abortion_reason: string | null;
  cancellation_reason: string | null;
  zco: string;
  mobile_group: tDictionary | null;
  client: tDictionary;
  earliest_start_time: string;
  latest_finish_time: string;
  equipments: tDictionary[];
  qualifications: tDictionary[];
  confirmation: {
    confirmation_result: 'accepted' | 'accepted_automatically' | 'rejected' | null;
    no_pin_reason: string | null;
  };
  schedule_end_time: string | null;
  schedule_start_time: string | null;
  start_time: string | null;
  end_time: string | null;
};

export type tSurvey = {
  name: string;
  questions: (tQuestion & {
    position: number;
    template: null | { id: string };
    answer:
      | null
      | components['schemas']['ReadImageAnswer']
      | components['schemas']['SingleChoiceAnswer']
      | components['schemas']['FreeTextAnswer'];
  })[];
};

type tReadServiceWithDetails = tReadService & {
  confirmation_method: tConfirmationMethod;
  contract: tReadContract;
  description: string;
  estimated_execution_time: string;
  mobile_group_service_template: { id: string; survey_template: tDictionary };
  product: tDictionary;
  survey: tSurvey;
  attachment: tReadAttachment | null;
};

export type tPeriodicity = {
  interval_value: number;
  interval_type: string;
  begin: Date;
  end: Date;
  days?: number[];
};

type tWriteService = {
  product: string;
  contract: string;
  location: string;
  priority: 'NORMAL' | 'HIGH';
  name: string;
  survey: {
    service_template?: string;
    questions: (tWriteQuestion & { template?: string })[];
  };
  earliest_start_time?: Date;
  latest_finish_time?: Date;
  periodicity?: tPeriodicity; // set periodicity only at adding the service
  estimated_execution_time: string;
  qualifications: string[];
  equipments: string[];
  description: string;
  confirmation_method: null | tConfirmationMethod;
  attachment?: string | null;
  zco: string;
};

export type tGetServicesRequest = tListParams &
  tServiceTimeParams & {
    status?: eStatuses;
    mobile_group_ordering?: 'descending' | 'ascending';
    sort_order?: 'desc' | 'asc';
    sort_by?: string;
  };
type tGetServicesResponse = { count: number; results: tReadService[] };

export type tGetServicesSummaryRequest = components['schemas']['StatusSummaryFilters'];
type tGetServiceSummaryResponse = {
  new: number;
  cancelled: number;
  waiting_for_acceptance: number;
  assigned: number;
  aborted: number;
  started: number;
  finished: number;
};

type tGetServiceResponse = tReadServiceWithDetails;

export type tAddServiceRequest = tWriteService;
export type tUpdateServiceRequest = tWriteService;

export type tAssignServiceToMobileGroupRequest = {
  mobile_group: string;
  start_time: Date;
  end_time: Date;
};

const services = {
  getConfirmationMethods: (cancelToken: CancelToken) =>
    fetchProvider.get<tGetConfirmationMethodsResponse>('/confirmation_methods', { cancelToken }),
  getPeriodicityIntervalTypes: (cancelToken: CancelToken) =>
    fetchProvider.get<tGetPeriodicityIntervalTypeResponse>(
      `${microservicePath}/periodicity_interval_types`,
      { cancelToken },
    ),
  getServices: (params: tGetServicesRequest, cancelToken: CancelToken) =>
    fetchProvider.get<tGetServicesResponse>(microservicePath, { params, cancelToken }),
  getServicesSummary: (params: tGetServicesSummaryRequest) =>
    fetchProvider.post<tGetServiceSummaryResponse>(`${microservicePath}/status_summary`, params),
  getService: (id: string, cancelToken: CancelToken) =>
    fetchProvider.get<tGetServiceResponse>(`${microservicePath}/${id}`, { cancelToken }),
  addService: (bodyRequest: tAddServiceRequest) =>
    fetchProvider.post<void>(microservicePath, bodyRequest),
  updateService: (id: string, bodyRequest: tUpdateServiceRequest) =>
    fetchProvider.put<void>(`${microservicePath}/${id}`, bodyRequest),
  cancel: (id: string, reason: string) =>
    fetchProvider.post(`${microservicePath}/${id}/cancellation`, { reason }),
  assignServiceToMobileGroup: (id: string, bodyRequest: tAssignServiceToMobileGroupRequest) =>
    fetchProvider.post(`/services/${id}/assignment`, bodyRequest),
  getTaskReport: (id: string) =>
    fetchProviderForAttachment(`/reports/service/${id}`, {}, { responseType: 'blob' }, 'GET'),
};

export default services;
