import { ReactComponent as alert } from './alert_24dp.svg';
import { ReactComponent as addCircleButton } from './add_circle_outline-24px.svg';
import { ReactComponent as addPlus } from './add_24dp.svg';
import { ReactComponent as arrowDown } from './round-keyboard_arrow_down-24px.svg';
import { ReactComponent as arrowUp } from './round-keyboard_arrow_up-24px.svg';
import { ReactComponent as arrowDropDown } from './arrow_drop_down-24px.svg';
import { ReactComponent as assignTask } from './assign-task.svg';
import { ReactComponent as calendarToday } from './calendar_today-24px.svg';
import { ReactComponent as directionsCar } from './directions_car_24dp.svg';
import { ReactComponent as error } from './error_24dp.svg';
import { ReactComponent as factCheck } from './fact_check-24px.svg';
import { ReactComponent as fileDownload } from './file_download_24dp.svg';
import { ReactComponent as filePresent } from './file_present_24dp.svg';
import { ReactComponent as firstPage } from './first_page-24px.svg';
import { ReactComponent as lastPage } from './last_page-24px.svg';
import { ReactComponent as navigateBefore } from './navigate_before-24px.svg';
import { ReactComponent as navigateNext } from './navigate_next-24px.svg';
import { ReactComponent as moreHoriz } from './more_horiz-24px.svg';
import { ReactComponent as moreVert } from './more_vert_24dp.svg';
import { ReactComponent as menu } from './menu-24px.svg';
import { ReactComponent as search } from './search-24px.svg';
import { ReactComponent as client } from './client.svg';
import { ReactComponent as contract } from './contract.svg';
import { ReactComponent as inspection } from './inspection.svg';
import { ReactComponent as inventory } from './inventory-24px.svg';
import { ReactComponent as home } from './home.svg';
import { ReactComponent as launch } from './launch_24dp.svg';
import { ReactComponent as location } from './location.svg';
import { ReactComponent as login } from './login.svg';
import { ReactComponent as notifications } from './notifications.svg';
import { ReactComponent as place } from './place.svg';
import { ReactComponent as howToReg } from './how_to_reg.svg';
import { ReactComponent as homeRepairService } from './home_repair_service.svg';
import { ReactComponent as close } from './close-24px.svg';
import { ReactComponent as edit } from './mode_edit-24px.svg';
import { ReactComponent as remove } from './delete-24px.svg';
import { ReactComponent as dateRange } from './date_range-24px.svg';
import { ReactComponent as people } from './people.svg';
import { ReactComponent as power } from './power.svg';
import { ReactComponent as product } from './product.svg';
import { ReactComponent as schedule } from './schedule.svg';
import { ReactComponent as user } from './user.svg';
import { ReactComponent as userAccount } from './account_circle_24dp.svg';
import { ReactComponent as noTasks } from './no-tasks.svg';
import { ReactComponent as noResults } from './no-results.svg';
import { ReactComponent as alarm } from './alarm.svg';
import { ReactComponent as deleteGraphic } from './delete-graphic.svg';
import { ReactComponent as done } from './done-24px.svg';
import { ReactComponent as contentPasteOff } from './content_paste_off-24px.svg';
import { ReactComponent as password } from './password-24px.svg';
import { ReactComponent as phone } from './phone_24dp.svg';
import { ReactComponent as pen } from './pen.svg';
import { ReactComponent as info } from './info-24px.svg';
import { ReactComponent as radioButtonUnchecked } from './radio_button_unchecked-24px.svg';
import { ReactComponent as radioButtonChecked } from './radio_button_checked_24dp.svg';
import { ReactComponent as checkCircle } from './check_circle-24px.svg';
import { ReactComponent as success } from './success_24dp.svg';
import { ReactComponent as reports } from './reports.svg';
import { ReactComponent as taskStatuses } from './task-statuses.svg';
import { ReactComponent as filter } from './filter_alt_black_24dp.svg';
import { ReactComponent as deleteTrash } from './delete-trash.svg';
import { ReactComponent as clockStart } from './clock-start.svg';
import { ReactComponent as notificationAlarm } from './notification-alarm.svg';
import { ReactComponent as notificationTimeDelay } from './notification-time-delay.svg';
import { ReactComponent as notificationAlarmResolved } from './notification-alarm-resolved.svg';
import { ReactComponent as notificationStartAfterTime } from './notification-start-after-time.svg';
import { ReactComponent as notificationServiceAssigned } from './notification-service-assigned.svg';
import { ReactComponent as notificationMobileGroupAssigned } from './notification-mobile-group-assigned.svg';
import { ReactComponent as notificationDefault } from './notification-default.svg';
import { ReactComponent as reportDownload } from './file-download.svg';
import { ReactComponent as photoPreview } from './photo-preview.svg';
import { ReactComponent as unassignTask } from './unassign-task.svg';
import { ReactComponent as notificationAuditNextDay } from './fact_check_black_24dp.svg';
import { ReactComponent as refresh } from './refresh_black_24dp.svg';
import { ReactComponent as menuOpen } from './chevron_left_black_24dp.svg';
import { ReactComponent as clearAll } from './clear_all.svg';

export default {
  clearAll,
  menuOpen,
  refresh,
  photoPreview,
  unassignTask,
  notificationServiceAssigned,
  notificationMobileGroupAssigned,
  notificationDefault,
  notificationStartAfterTime,
  notificationAlarmResolved,
  notificationTimeDelay,
  notificationAlarm,
  notificationAuditNextDay,
  alert,
  addCircleButton,
  addPlus,
  arrowDown,
  arrowUp,
  arrowDropDown,
  assignTask,
  calendarToday,
  directionsCar,
  error,
  factCheck,
  fileDownload,
  filePresent,
  firstPage,
  lastPage,
  navigateBefore,
  navigateNext,
  moreHoriz,
  moreVert,
  menu,
  search,
  client,
  contract,
  home,
  launch,
  location,
  inspection,
  inventory,
  login,
  notifications,
  place,
  howToReg,
  homeRepairService,
  close,
  edit,
  remove,
  dateRange,
  people,
  power,
  product,
  schedule,
  user,
  userAccount,
  noTasks,
  noResults,
  alarm,
  deleteGraphic,
  done,
  contentPasteOff,
  password,
  phone,
  pen,
  info,
  radioButtonUnchecked,
  radioButtonChecked,
  checkCircle,
  success,
  reports,
  taskStatuses,
  filter,
  deleteTrash,
  clockStart,
  reportDownload,
};
