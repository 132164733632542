import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import auditTemplates from './pl/auditTemplates.json';
import cars from './pl/cars.json';
import clients from './pl/clients.json';
import common from './pl/common.json';
import contracts from './pl/contracts.json';
import equipment from './pl/equipment.json';
import errors from './pl/errors.json';
import groups from './pl/groups.json';
import locations from './pl/locations.json';
import maps from './pl/maps.json';
import menu from './pl/menu.json';
import mobileGroups from './pl/mobileGroups.json';
import pagination from './pl/pagination.json';
import products from './pl/products.json';
import qualifications from './pl/qualifications.json';
import schedule from './pl/schedule.json';
import services from './pl/services.json';
import serviceTemplates from './pl/serviceTemplates.json';
import tasks from './pl/tasks.json';
import tasksStatuses from './pl/tasksStatuses.json';
import users from './pl/users.json';
import audits from './pl/audits.json';
import notifications from './pl/notifications.json';
import reports from './pl/reports.json';
import dynamicErrors from './pl/dynamicErrors.json';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const DEFAULT_LANGUAGE = 'pl';

  const resources = {
    pl: {
      translation: {
        ...dynamicErrors,
        ...notifications,
        ...reports,
        ...audits,
        ...auditTemplates,
        ...cars,
        ...clients,
        ...common,
        ...contracts,
        ...equipment,
        ...errors,
        ...groups,
        ...locations,
        ...maps,
        ...menu,
        ...mobileGroups,
        ...pagination,
        ...products,
        ...qualifications,
        ...schedule,
        ...services,
        ...serviceTemplates,
        ...tasks,
        ...tasksStatuses,
        ...users,
      },
    },
  };

  const fallbackLng = {
    'en-EN': ['en'],
    default: [DEFAULT_LANGUAGE],
  };

  i18n.use(initReactI18next).init({
    resources,
    lng: DEFAULT_LANGUAGE,
    keySeparator: false,
    nsSeparator: false,
    fallbackLng,
    interpolation: {
      escapeValue: false,
    },
  });

  return i18n;
};
