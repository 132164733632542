import axios from 'axios';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';

import usersService from 'services/users';
import { onError } from 'utils/queryClient';
import { defaultCacheStaleTime } from 'services/utils/constants';

export const useUserQueryCacheName = 'getUser';

const useUser = ({ itemId }) => {
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey: [useUserQueryCacheName, itemId],
    queryFn: ({ signal }) => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      const promise = usersService.getUser(
        {
          userId: itemId,
        },
        source.token,
      );

      // Cancel the request if TanStack Query signals to abort
      signal?.addEventListener('abort', () => {
        source.cancel('Query was cancelled by TanStack Query');
      });

      return promise;
    },
    enabled: !!itemId,
    staleTime: defaultCacheStaleTime, // TODO what length to set by default or per view
  });

  const invalidateQuery = () =>
    queryClient.invalidateQueries({
      queryKey: [useUserQueryCacheName, itemId],
      refetchType: 'none',
    });

  useEffect(() => {
    if (query.isError) onError(query.error);
  }, [query.isError, query.error]);

  return {
    ...query,
    invalidateQuery,
  };
};
export default useUser;

export const useCurrentQueryCacheName = 'getCurrent';

export const useCurrent = (enabled = true) => {
  const query = useQuery({
    queryKey: [useCurrentQueryCacheName],
    queryFn: ({ signal }) => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      const promise = usersService.getCurrent(source.token);

      // Cancel the request if TanStack Query signals to abort
      signal?.addEventListener('abort', () => {
        source.cancel('Query was cancelled by TanStack Query');
      });

      return promise;
    },
    staleTime: defaultCacheStaleTime, // TODO what length to set by default or per view
    enabled,
  });

  useEffect(() => {
    if (query.isError) onError(query.error);
  }, [query.isError, query.error]);

  return {
    ...query,
  };
};
