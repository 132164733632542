import styled from 'styled-components';

import { colorRadicalRed } from 'styles/constants';

export const Danger = styled.div`
  background: ${colorRadicalRed};
  border-radius: 20px;
  color: white;
  display: inline-block;
  padding: 2px 8px;
`;

export const ContentContainer = styled.div`
  display: flex;
  font-weight: bold;
  align-items: center;
  > svg {
    margin-right: 4px;
    top: -1px;
    position: relative;
  }
`;
