import styled from 'styled-components';

import loginImg from 'assets/img/login-background.jpg';
import { colorGhostWhite, colorSlateBlue, gradientPurple } from 'styles/constants';

export const BottomBlock = styled.div`
  background-color: ${colorGhostWhite};
  flex: 50%;
`;

export const ImageContainer = styled.div`
  background: ${colorSlateBlue};
  flex: 50%;
  position: relative;
`;

export const LoginImage = styled.div`
  background-image: url(${loginImg});
  background-position: 100% 0;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
`;

export const Tint = styled.div`
  background: ${gradientPurple};
  height: 100%;
  opacity: 80%;
  position: absolute;
  top: 0;
  width: 100%;
`;

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: relative;
`;
