import { AxiosResponse, CancelToken } from 'axios';
import i18n from 'i18next';

import auditTemplatesAgent, {
  tAddAuditTemplateRequest,
  tGetAuditTemplatesRequest,
  tUpdateAuditTemplateRequest,
} from 'agents/auditTemplates';
import { tGetAuditTemplate } from 'types/services/auditTemplates';
import { tTableTransformedItem } from 'types/global';
import errorsHandler from './utils/errorsHandler';

export type tAuditTemplate = tTableTransformedItem;

const deleteAuditTemplate = (auditTemplateId: string) => {
  const defaultError = [
    { key: '__general__', msg: i18n.t('Cannot remove', { value: i18n.t('survey template form') }) },
  ];

  return auditTemplatesAgent
    .deleteAuditTemplate(auditTemplateId)
    .then((resp: AxiosResponse) => ({ state: true, errors: [], data: {}, status: resp.status }))
    .catch((error) => ({ state: false, errors: error?.response?.data ?? defaultError, data: {} }));
};

const getAuditTemplates = ({
  limit = 10,
  offset = 0,
  search = '',
  cancelToken,
}: tGetAuditTemplatesRequest & { cancelToken: CancelToken }) => {
  const params: tGetAuditTemplatesRequest = { offset };

  if (limit) params.limit = limit;
  if (search) params.search = search;

  return auditTemplatesAgent.getAuditTemplates(params, cancelToken).then((response) => {
    let transformedData: tGetAuditTemplate[] = [];

    const {
      data: { count, results },
    } = response;

    if (!!count) {
      transformedData = results.map((auditTemplateData, index) => {
        const {
          id,
          auditors,
          survey_template: { name, question_templates },
        } = auditTemplateData;

        return {
          id,
          itemData: [
            { key: 'order', value: index + 1 + offset },
            { key: 'name', value: name },
          ],
          extraInfo: {
            auditors: !!auditors.length
              ? auditors.map(({ id, first_name, last_name }) => ({
                  id,
                  first_name,
                  last_name,
                }))
              : [],
            questions: !!question_templates.length
              ? question_templates.map(({ id, title, kind }) => ({
                  id,
                  title,
                  kind,
                }))
              : [],
          },
        };
      });
    }

    return { count, results: transformedData };
  });
};

const addAuditTemplate = (bodyRequest: tAddAuditTemplateRequest) =>
  auditTemplatesAgent
    .addAuditTemplate(bodyRequest)
    .then((resp: AxiosResponse) => ({ state: true, errors: [], data: {}, status: resp.status }))
    .catch((error) =>
      errorsHandler({
        defaultErrorMessage: i18n.t('Cannot add', { value: i18n.t('survey template form') }),
        error,
      }),
    );

const updateAuditTemplate = (auditTemplateId: string, bodyRequest: tUpdateAuditTemplateRequest) =>
  auditTemplatesAgent
    .updateAuditTemplate(auditTemplateId, bodyRequest)
    .then((resp: AxiosResponse) => ({ state: true, errors: [], data: {}, status: resp.status }))
    .catch((error) =>
      errorsHandler({
        defaultErrorMessage: i18n.t('Cannot edit', { value: i18n.t('survey template form') }),
        error,
      }),
    );

const getAuditTemplate = (
  { auditTemplateId }: { auditTemplateId: string },
  cancelToken: CancelToken,
) =>
  auditTemplatesAgent.getAuditTemplate(auditTemplateId, cancelToken).then((response) => {
    const { data } = response;

    return { ...data };
  });

export default {
  deleteAuditTemplate,
  getAuditTemplates,
  addAuditTemplate,
  updateAuditTemplate,
  getAuditTemplate,
};
