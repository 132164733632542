import { ReactNode, FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Label } from 'components';
import { Container } from './Fieldset.style';

type tFieldset = {
  children: ReactNode;
  isRemovable?: boolean;
  label?: string;
  withContainerGap?: boolean;
  error?: boolean;
};

const Fieldset: FC<tFieldset> = ({
  children,
  isRemovable = false,
  label = '',
  withContainerGap = false,
  error = false,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {!!label && <Label>{t(label)}</Label>}
      <Container $gap={withContainerGap} $isRemovable={isRemovable} $error={error}>
        {children}
      </Container>
    </>
  );
};

export default Fieldset;
