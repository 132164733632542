import { Selector } from 'components';

import { tOption } from './RadioPicker';
import { Label, RadioButton } from './PickerItem.style';

type tPickerItem<T extends string | number> = {
  option: tOption<T>;
  checked: boolean;
  /* eslint-disable-next-line no-unused-vars */
  onChange: (option: tOption<T>) => void;
};

const PickerItem = <T extends string | number>({ option, checked, onChange }: tPickerItem<T>) => (
  <RadioButton onClick={() => onChange(option)}>
    <Selector checked={checked} />
    <Label>{option.label}</Label>
  </RadioButton>
);

export default PickerItem;
