import { isAxiosError } from 'axios';
import i18n from 'i18next';
import { QueryClient } from '@tanstack/react-query';

import { checkIfTheUserShouldBeLoggedOut } from './logout';
import notify from './notify';
import { InfiniteData } from '@tanstack/react-query/build/modern/index';

export const clearQueryCache = (queryClient: QueryClient) => queryClient.clear();

export const onError = (error: unknown) => {
  if (isAxiosError(error)) {
    const { response } = error;
    const status = response?.status;
    if (status) {
      checkIfTheUserShouldBeLoggedOut(status);
      if (status !== 401 && status !== 403) {
        notify({
          copy: i18n.t('An error occurred (response status: {{status}})', { status }),
          type: 'error',
        });
      }
    }
  } else if (error instanceof Error) {
    notify({
      copy: error.message,
      type: 'error',
    });
  }
};

export const setInfiniteQueryData = <T extends Array<{ id: string }>>(
  queryClient: QueryClient,
  queryKey: string[],
  updatedElementId: string,
  updatedElementFields: Partial<T[0]>,
) => {
  queryClient.setQueryData(
    queryKey,
    (oldData: InfiniteData<{ count: number; results: T }> | undefined) => {
      return oldData
        ? {
            ...oldData,
            pages: oldData.pages.map((page) => ({
              ...page,
              results: page.results.map((element) =>
                element.id === updatedElementId ? { ...element, ...updatedElementFields } : element,
              ),
            })),
          }
        : oldData;
    },
  );
};
