import { useState, useEffect, useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { VariableSizeList as VList } from 'react-window';
import { positionValues, Scrollbars } from 'react-custom-scrollbars-2';
import debounce from 'lodash.debounce';
import differenceBy from 'lodash.differenceby';
import intersectionBy from 'lodash.intersectionby';
import uniqBy from 'lodash.uniqby';

import useOnClickOutside from 'hooks/useOnClickOutside';
import { ButtonPrimary, Checkbox, Chip, Error, Icon, Label, Search, Spinner } from 'components';
import { tDropdownOption } from 'types/global';
import { Container, RemoveSelectButton, NoMatch } from 'components/DropDown/DropDown.style';
import {
  CheckboxContainer,
  IconButtonContainer,
  MultiSelectContainer,
  SearchContainer,
  OptionsPanel,
  Placeholder,
  ActionContainer,
} from './MultiSelect.style';
import { ArrowContainer } from 'components/MultiSelect/MultiSelect.style';
import { colorLightSlateGrey } from 'styles/constants';
import { useSetSizeRowVList } from 'hooks/useSetSizeRowVList';
import VListItem from 'components/MultiSelect/VListItem';

export type tDropdown<T> = {
  options: tDropdownOption<T>[];
  /* eslint-disable-next-line no-unused-vars */
  onChange?: (val: tDropdownOption<T>[]) => void;
  value: tDropdownOption<T>[];
  error?: string;
  label?: string;
  disabled?: boolean;
  positionList?: string;
  isOptional?: boolean;
  searchValue?: string;
  setSearchValue?: (search: string) => void;
  marginBottom?: string;
  showDeleteSelectedValue?: boolean;
  isInfinityScroll?: boolean;
  infinityScrollFetchFunction?: () => Promise<void>;
  isLoading?: boolean;
  renderChosenOptions?: boolean;
  labelInside?: boolean;
  dataTestId?: string;
  actionButton?: {
    label: string;
    callback: (chosenOptions: tDropdownOption<T>[]) => void;
  };
};

const MultiSelect = <T,>({
  options,
  onChange = () => {},
  value,
  error = '',
  label = '',
  disabled = false,
  positionList = '',
  isOptional = false,
  marginBottom = '1rem',
  showDeleteSelectedValue = true,
  searchValue = '',
  setSearchValue,
  isInfinityScroll = false,
  infinityScrollFetchFunction = async () => {},
  isLoading = false,
  renderChosenOptions = true,
  labelInside = false,
  dataTestId,
  actionButton,
}: tDropdown<T>) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLInputElement>(null);
  const listRef = useRef<VList>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [show, setShow] = useState<boolean>(false);
  const { setSize, getSize } = useSetSizeRowVList(listRef);

  const debouncedFetchFunction = debounce(infinityScrollFetchFunction, 1000, {
    leading: true,
    trailing: false,
  });

  const handleScroll = ({ scrollTop, scrollHeight, clientHeight }: positionValues) => {
    // scrollTop / (scrollHeight - clientHeight) = 1 if scroll bar reach bottom
    const padding = 100;
    const result = (scrollTop + padding) / (scrollHeight - clientHeight);
    if (!!listRef.current) listRef.current.scrollTo(scrollTop);
    if (result >= 1 && isInfinityScroll) {
      debouncedFetchFunction();
    }
  };

  // Add or remove selected option from the list of chosen options
  const select = (option: tDropdownOption<T>) => {
    const helperArray = [...value];
    const alreadyChosenIndex = value.findIndex(
      ({ value: chosenValue }) => chosenValue === option.value,
    );

    if (alreadyChosenIndex !== -1) {
      helperArray.splice(alreadyChosenIndex, 1);
    } else {
      helperArray.push(option);
    }
    onChange(helperArray);
  };

  const removeChip = (option: tDropdownOption<T>) => {
    const helperArray = value.filter(({ value }) => value !== option.value);
    onChange(helperArray);
  };

  // When we select some options, and then we use the search,
  // there may be chosen options in the multiselect that currently are not part of the options list.
  // In order for selectAll to work properly we check if
  // chosen list includes all the current options.
  const areAllOptionsSelected = useMemo(
    () => options.length > 0 && intersectionBy(value, options, 'value').length === options.length,
    [options, value],
  );

  const toggleSelectAll = () => {
    let result: tDropdownOption<T>[];
    if (areAllOptionsSelected) {
      // Prevents deletion of a chosen option that is not part of the current options list.
      result = differenceBy(value, options, 'value');
    } else {
      // Prevents the creation of duplicates.
      result = uniqBy(value.concat(options), 'value');
    }
    onChange(result);
  };

  const handleActionButtonCallback = () => {
    actionButton?.callback(value);
    setShow(false);
  };

  useOnClickOutside(ref, () => setShow(false));

  useEffect(() => {
    if (show && inputRef?.current) {
      inputRef.current.focus();
    }
  }, [show, isLoading]);

  return (
    <Container $marginBottom={marginBottom} ref={ref}>
      {!!label && !labelInside && <Label optional={isOptional}>{label}</Label>}
      <MultiSelectContainer
        $error={!!error}
        onClick={() => setShow(!show)}
        data-testid={dataTestId}
        $isLoading={isLoading}
      >
        {renderChosenOptions && (
          <>
            {value.length === 0 && <Placeholder>{t(labelInside ? label : 'Select')}</Placeholder>}
            {value.length > 0 &&
              value.map((option) => (
                <Chip
                  key={String(option.value)}
                  variant='purple'
                  copy={option.label}
                  buttons={
                    <>
                      <IconButtonContainer onClick={() => removeChip(option)}>
                        <Icon iconName='close' width='1rem' height='1rem' />
                      </IconButtonContainer>
                    </>
                  }
                />
              ))}
          </>
        )}
        {!renderChosenOptions && (
          <>
            <Placeholder>{t(labelInside ? label : 'Select')}</Placeholder>
            {!!value.length && <Chip variant='purple' copy={value.length.toString()} />}
          </>
        )}
        {isLoading ? (
          <Spinner withContainer={false} />
        ) : (
          <>
            {value.length > 0 && showDeleteSelectedValue && (
              <RemoveSelectButton onClick={() => onChange([])}>
                <Icon iconName='close' width='1rem' height='1rem' />
              </RemoveSelectButton>
            )}
            <ArrowContainer>
              <Icon
                iconName='arrowDropDown'
                fill={colorLightSlateGrey}
                extraStyles={show ? { transform: 'rotate(180deg)' } : {}}
              />
            </ArrowContainer>
          </>
        )}
      </MultiSelectContainer>
      {!!error && <Error text={error} />}
      {show && !disabled && (
        <OptionsPanel $positionList={positionList}>
          {!!setSearchValue && (
            <SearchContainer>
              <Search
                value={searchValue}
                onChange={({ target: { value } }) => setSearchValue(value)}
                resetValue={() => setSearchValue('')}
              />
            </SearchContainer>
          )}
          {options.length > 0 && (
            <>
              <CheckboxContainer $gap='16px'>
                <Checkbox
                  checked={areAllOptionsSelected}
                  onChange={toggleSelectAll}
                  label='Pick from the list'
                />
              </CheckboxContainer>
              <Scrollbars autoHeight autoHeightMax={300} onUpdate={handleScroll}>
                <VList
                  height={options.length > 5 ? 150 : options.length * 30}
                  itemCount={options.length}
                  itemSize={getSize}
                  width='100%'
                  ref={listRef}
                  style={{ overflow: 'unset' }}
                >
                  {({ index, style }) => (
                    <VListItem
                      style={{ ...style }}
                      key={String(options[index].value)}
                      checked={!!value.find(({ value }) => value === options[index].value)}
                      onChange={() => select(options[index])}
                      label={options[index].label}
                      setItemSize={(size: number) => setSize(index, size)}
                    />
                  )}
                </VList>
              </Scrollbars>
            </>
          )}
          {options.length === 0 && !isLoading && <NoMatch>{t('No match')}</NoMatch>}
          {options.length === 0 && isLoading && <NoMatch>{t('Loading')}...</NoMatch>}
          {!!actionButton && (
            <ActionContainer>
              <ButtonPrimary
                key='MultiSelectAction'
                handleClick={handleActionButtonCallback}
                text={t(actionButton.label)}
              />
            </ActionContainer>
          )}
        </OptionsPanel>
      )}
    </Container>
  );
};

export default MultiSelect;
