import {
  colorBlack,
  colorDodgerBlue,
  colorGrandis,
  colorMediumSeaGreen,
  colorRadicalRed,
  colorSlateBlue,
} from 'styles/constants';

const setChipBackground = (variant: string): string => {
  switch (variant) {
    case 'black':
      return colorBlack;
    case 'blue':
      return colorDodgerBlue;
    case 'green':
      return colorMediumSeaGreen;
    case 'red':
      return colorRadicalRed;
    case 'yellow':
      return colorGrandis;
    case 'purple':
    default:
      return colorSlateBlue;
  }
};

export default setChipBackground;
