import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, Messaging, isSupported, onMessage } from 'firebase/messaging';
import { getAnalytics } from 'firebase/analytics';
import { format } from 'date-fns';
import i18n from 'i18next';
import { Link } from 'react-router-dom';

import notificationsService from 'services/notifications';
import { icons } from 'components/NotificationsPanel/SingleNotification';
import setNotificationType from 'utils/setNotificationType';

import notify from 'utils/notify';

let firebaseApp;
let messaging: Messaging;

const getTokenHandler = () => {
  const vapidKey = window.__env__.FIREBASE?.usePublicVapidKey;
  if (!vapidKey) return;
  getToken(messaging, { vapidKey })
    .then((token) => {
      if (token) {
        notificationsService.createDevice({ registration_id: token });
      } else {
        notify({
          copy: i18n.t('Error while retrieving notifications. Allow notifications and try again'),
          type: 'error',
        });
      }
    })
    .catch((e) => {
      notify({
        copy: e.message,
        type: 'error',
      });
    });
};

const onMessageHandler = () => {
  onMessage(messaging, function (message) {
    const { notification } = message;
    if (notification?.body) {
      notify({
        copy: notification?.title ?? '-',
        type: 'info',
        bolded: true,
        additionalInformation: notification?.body,
      });
    }
    if (!message?.data?.short_message) return;

    const { short_message, type, created_at } = message?.data;
    const typeHelper: string = setNotificationType(type);
    notify({
      copy: short_message,
      type: icons[typeHelper] ?? 'info',
      bolded: true,
      additionalInformation: (
        <>
          {format(!!created_at ? new Date(created_at) : new Date(), 'HH:mm')}
          {type === 'REPORT_CREATED' ? (
            <>
              <span> - </span>
              <Link to='/reports' reloadDocument>
                {i18n.t('Go to reports')}
              </Link>
            </>
          ) : null}
        </>
      ),
    });
  });
};

const launchPushNotifications = (getToken: boolean) =>
  isSupported()
    .then((supported) => {
      if (supported) {
        const firebaseConfig = !!window?.__env__?.FIREBASE
          ? {
              ...window.__env__.FIREBASE,
            }
          : {};
        const changedConfig = { ...firebaseConfig };
        delete changedConfig.usePublicVapidKey;
        firebaseApp = initializeApp(changedConfig);
        messaging = getMessaging(firebaseApp);
        getAnalytics(firebaseApp);
        if (getToken) getTokenHandler();
        onMessageHandler();
      } else {
        notify({
          copy: i18n.t(
            'You must use Chrome or Edge browsers to ensure that notifications work properly',
          ),
          type: 'error',
        });
      }
    })
    .catch((e) => {
      notify({
        copy: e.message,
        type: 'error',
      });
    });

const requestNotificationPermissionOnDenied = async () => {
  const permission = await window.Notification.requestPermission();
  return new Promise((resolve) => resolve(permission !== 'granted'));
};

const requestPermission = (getToken = true as boolean) => {
  Notification.requestPermission().then(async (permission) => {
    if (permission === 'granted') {
      launchPushNotifications(getToken);
    } else {
      const result = await requestNotificationPermissionOnDenied();
      if (result) {
        launchPushNotifications(true);
      }
    }
  });
};

export { requestPermission };
