import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { LabelStyle, OptionalText } from './Label.style';

type tLabel = {
  children: JSX.Element[] | JSX.Element | string;
  optional?: boolean;
  optionalText?: string;
};

const Label: FC<tLabel> = ({ children, optional = false, optionalText = '' }): JSX.Element => {
  const { t } = useTranslation();
  return (
    <LabelStyle>
      {children}
      {!!optional && (
        <OptionalText>
          {!!optionalText ? `(${t(optionalText)})` : `(${t('Optional')})`}
        </OptionalText>
      )}
    </LabelStyle>
  );
};

export default Label;
