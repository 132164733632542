import { tPosition } from 'types/global';
import blackPin from './images/black.png';
import activeBlackPin from './images/black-shadow.png';
import greenPin from './images/green.png';
import activeGreenPin from './images/green-shadow.png';
import yellowPin from './images/yellow.png';
import activeYellowPin from './images/yellow-shadow.png';
import bluePin from './images/blue.png';
import activeBluePin from './images/blue-shadow.png';
import redPin from './images/red.png';
import activeRedPin from './images/red-shadow.png';

export const createKey = (location: tPosition) => {
  return `${location.lat}-${location.lng}`;
};

export const getImageForGroupStatus = (status: string, active = false as boolean) => {
  switch (status) {
    case 'free':
      if (active) return activeGreenPin;
      return greenPin;
    case 'temporarily_free':
      if (active) return activeYellowPin;
      return yellowPin;
    case 'busy':
      if (active) return activeBluePin;
      return bluePin;
    case 'accident':
      if (active) return activeRedPin;
      return redPin;
    case 'notactive':
      if (active) return activeBlackPin;
      return blackPin;
    default:
      if (active) return activeBlackPin;
      return blackPin;
  }
};

export const getImage = (status: string, active = false as boolean) => ({
  url: getImageForGroupStatus(status, active),
  anchor: new google.maps.Point(16, 0),
});

export const setTimelinePosition = () => {
  const time = new Date();
  const hours = time.getHours();
  const minutes = time.getMinutes();
  const position = hours * 48 + (minutes / 60) * 48;

  return position;
};
