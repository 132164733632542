import fetchProvider from 'utils/Axios';
import { CancelToken } from 'axios';
import { tDictionary, tDropdownOption, tListParams, tQuestionType } from 'types/global';

export const microservicePath = '/mobile_group_service_templates';

export type tQuestion = {
  id: string;
  title: string;
  kind: tQuestionType;
};

export type tWriteQuestion = Omit<tQuestion, 'id' | 'kind'> & { kind: tQuestionType | null };

export type tReadServiceTemplate = {
  id: string;
  product: tDictionary;
  survey_template: {
    id: string;
    name: string;
    question_templates: tQuestion[];
  };
};

type tWriteServiceTemplate = {
  product_id: string | null;
  survey_template: {
    name: string;
    question_templates: tWriteQuestion[];
  };
};

type tDeleteServiceTemplateResponse = tReadServiceTemplate;

export type tGetServiceTemplatesRequest = tListParams & { product_id?: string };
type tGetServiceTemplatesResponse = { count: number; results: tReadServiceTemplate[] };

type tGetServiceTemplateResponse = tReadServiceTemplate;

export type tAddServiceTemplateRequest = tWriteServiceTemplate;
type tAddServiceTemplateResponse = tReadServiceTemplate;

type tGetQuestionTypesResponse = { count: number; results: tDropdownOption<tQuestionType>[] };

export type tUpdateServiceTemplateRequest = tWriteServiceTemplate;
type tUpdateServiceTemplateResponse = tReadServiceTemplate;

const serviceTemplates = {
  deleteServiceTemplate: (serviceTemplateId: string) =>
    fetchProvider.delete<tDeleteServiceTemplateResponse>(
      `${microservicePath}/${serviceTemplateId}`,
    ),
  getServiceTemplates: (params: tGetServiceTemplatesRequest, cancelToken: CancelToken) =>
    fetchProvider.get<tGetServiceTemplatesResponse>(microservicePath, { params, cancelToken }),
  getServiceTemplate: (serviceTemplateId: string, cancelToken: CancelToken) =>
    fetchProvider.get<tGetServiceTemplateResponse>(`${microservicePath}/${serviceTemplateId}`, {
      cancelToken,
    }),
  addServiceTemplate: (bodyRequest: tAddServiceTemplateRequest) =>
    fetchProvider.post<tAddServiceTemplateResponse>(`${microservicePath}`, bodyRequest),
  getQuestionTypes: (cancelToken: CancelToken) =>
    fetchProvider.get<tGetQuestionTypesResponse>('/question_types', { cancelToken }),
  updateServiceTemplate: (serviceTemplateId: string, bodyRequest: tUpdateServiceTemplateRequest) =>
    fetchProvider.put<tUpdateServiceTemplateResponse>(
      `${microservicePath}/${serviceTemplateId}`,
      bodyRequest,
    ),
};

export default serviceTemplates;
