import styled from 'styled-components';

import { IconButton } from 'styles/button';
import { colorEclipse, colorLightSlateGrey, colorRadicalRed } from 'styles/constants';

type tFileDetailsCopy = {
  $type: string;
};

export const CloseButton = styled(IconButton)`
  margin-left: 6px;
`;

export const FileDetailsCopy = styled.div<tFileDetailsCopy>`
  color: ${({ $type }) => ($type === 'error' ? colorRadicalRed : colorLightSlateGrey)};
  font-size: 12px;
`;

export const FileInformationContainer = styled.div`
  margin-left: 12px;
  margin-right: 6px;
`;

export const FileItem = styled.div`
  display: flex;
`;

export const FileNameCopy = styled.div`
  color: ${colorEclipse};
  font-size: 12px;
  margin-bottom: 8px;
`;
