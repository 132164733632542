import { CancelToken } from 'axios';

import fetchProvider from 'utils/Axios';
import { tListParams } from 'types/global';

const microservicePath = '/notifications';

export type tCreateDeviceRequest = { registration_id: string };
type tCreateDeviceResponse = tCreateDeviceRequest;

export type tGetNotificationsRequest = Omit<tListParams, 'search'>;
type tGetNotificationsResponse = {
  count: number;
  results: {
    id: string;
    type:
      | 'MOBILE_GROUP_ASSIGNED'
      | 'ACCIDENT_NEW'
      | 'ACCIDENT_RESOLVED'
      | 'SERVICE_DELAYED_START'
      | 'SERVICE_NOT_ACCEPTED_TIME_EXPIRED'
      | 'SERVICE_ASSIGNED'
      | 'AUDIT_NEXT_DAY'
      | 'REPORT_CREATED'
      | 'AUDIT_FINISHED';
    message: string;
    read_at: string | null;
    created_at: string;
  }[];
};

type tGetUnreadNotificationsCountResponse = { unread_count: number };

export const notifications = {
  createDevice: (bodyRequest: tCreateDeviceRequest) =>
    fetchProvider.post<tCreateDeviceResponse>(`${microservicePath}/devices`, bodyRequest),
  getNotifications: (params: tGetNotificationsRequest, cancelToken: CancelToken) =>
    fetchProvider.get<tGetNotificationsResponse>(`${microservicePath}`, { params, cancelToken }),
  getUnreadNotificationsCount: (cancelToken: CancelToken) =>
    fetchProvider.get<tGetUnreadNotificationsCountResponse>(`${microservicePath}/unread_count`, {
      cancelToken,
    }),
  setNotificationAsRead: (notificationId: string) =>
    fetchProvider.post<void>(`${microservicePath}/${notificationId}/read`),
  setNotificationAsUnread: (notificationId: string) =>
    fetchProvider.post<void>(`${microservicePath}/${notificationId}/unread`),
};

export default notifications;
