import { CancelToken } from 'axios';

import fetchProvider from 'utils/Axios';
import { tListParams, tQuestionType } from 'types/global';
import { tQuestion } from 'agents/serviceTemplates';

export const microservicePath = '/auditor_survey_templates';

export type tAuditor = {
  id: string;
  first_name: string;
  last_name: string;
};

export type tReadAuditTemplate = {
  id: string;
  auditors: tAuditor[];
  survey_template: {
    id: string;
    name: string;
    question_templates: tQuestion[];
  };
};

type tWriteServiceTemplate = {
  auditors_ids?: string[];
  survey_template: {
    name: string;
    question_templates: (Omit<tQuestion, 'id' | 'kind'> & { kind: tQuestionType | null })[];
  };
};

export type tDeleteAuditTemplateResponse = tReadAuditTemplate;

export type tGetAuditTemplatesRequest = tListParams;
export type tGetAuditTemplatesResponse = { count: number; results: tReadAuditTemplate[] };

export type tAddAuditTemplateRequest = tWriteServiceTemplate;
type tAddAuditTemplateResponse = tReadAuditTemplate;

export type tUpdateAuditTemplateRequest = tWriteServiceTemplate;
type tUpdateAuditTemplateResponse = tReadAuditTemplate;

export type tGetAuditTemplateResponse = tReadAuditTemplate;

const auditTemplates = {
  deleteAuditTemplate: (auditTemplateId: string) =>
    fetchProvider.delete<tDeleteAuditTemplateResponse>(`${microservicePath}/${auditTemplateId}`),
  getAuditTemplates: (params: tGetAuditTemplatesRequest, cancelToken: CancelToken) =>
    fetchProvider.get<tGetAuditTemplatesResponse>(microservicePath, { params, cancelToken }),
  addAuditTemplate: (bodyRequest: tAddAuditTemplateRequest) =>
    fetchProvider.post<tAddAuditTemplateResponse>(microservicePath, bodyRequest),
  updateAuditTemplate: (auditTemplateId: string, bodyRequest: tUpdateAuditTemplateRequest) =>
    fetchProvider.put<tUpdateAuditTemplateResponse>(
      `${microservicePath}/${auditTemplateId}`,
      bodyRequest,
    ),
  getAuditTemplate: (auditTemplateId: string, cancelToken: CancelToken) =>
    fetchProvider.get<tGetAuditTemplateResponse>(`${microservicePath}/${auditTemplateId}`, {
      cancelToken,
    }),
};

export default auditTemplates;
