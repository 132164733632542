import authClient from 'utils/authClient';

export const checkFetchStatus = (status) => {
  if (status === 401 || status === 403) {
    sessionStorage.setItem('fetchStatus', status);
    sessionStorage.removeItem('login');
    authClient.logout({
      redirectUri: `${window.location.origin}/home`,
    });
  }
};

export const checkIfTheUserShouldBeLoggedOut = checkFetchStatus;
