import { IMaskInputProps, IMaskMixin } from 'react-imask';
import { InputElement } from 'components/Input/Input.style';
import { tInput } from 'components/Input/Input';

export type tMaskInputProps = Pick<tInput, 'error' | 'search' | 'disabled'>;

export const MaskedStyledInput = IMaskMixin<
  HTMLInputElement,
  IMaskInputProps<HTMLInputElement> & tMaskInputProps
>(({ inputRef, error, search, disabled = false, ...props }) => (
  <InputElement {...props} $error={!!error} $search={search} disabled={disabled} ref={inputRef} />
));
