import styled from 'styled-components';

import { Button, IconButton } from 'styles/button';
import { colorRadicalRed, colorSlateBlue, colorWhite } from 'styles/constants';

export const AddQuestionButton = styled(Button)`
  align-items: center;
  color: ${colorSlateBlue};
  display: flex;
  font-size: 12px;
  font-weight: bold;
  margin: auto;
  text-transform: uppercase;
`;

export const StepContainer = styled.div`
  position: relative;
`;

export const Container = styled.div`
  > ${StepContainer} {
    > div {
      padding-bottom: 0;
    }
  }
`;

export const Copy = styled.span`
  padding-left: 4px;
  padding-top: 1px;
`;

export const IconButtonContainer = styled(IconButton)`
  background: ${colorRadicalRed};
  width: 1.5rem;
  border-radius: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: 12px;
  right: 12px;
  > svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    > path {
      fill: ${colorWhite};
    }
  }
`;

export const ReadonlyValueCopy = styled.div`
  font-size: 12px;
`;
