import { FC, ReactNode } from 'react';

import { Spinner } from 'components';
import { Wrapper } from './Container.style';

type tConatiner = {
  isLoading?: boolean;
  children?: ReactNode;
};

const Container: FC<tConatiner> = ({ isLoading = false, children }) => (
  <Wrapper>
    {isLoading && <Spinner />}
    {children}
  </Wrapper>
);

export default Container;
