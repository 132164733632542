import React, { lazy, ReactNode, useEffect, useMemo } from 'react';
import { Navigate, Route, useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { Spinner } from 'components';
import {
  roleAuditor,
  roleLocalAdministrator,
  roleOperator,
  roleReadOnlyOperator,
  roleSystemAdministrator,
} from 'utils/constants';
import { SentryRoutes } from 'utils/sentry';
import Layout from 'components/Layout/Layout';
import { useCurrent } from 'services/hooks/useUser';
import Login from 'views/Login/Login';
import authClient from 'utils/authClient';
import ErrorBoundaryInfo from 'components/ErrorBoundary/ErrorBoundaryInfo';

const AuditTemplates = lazy(() => import('views/AuditTemplates'));
const Cars = lazy(() => import('views/Cars'));
const Clients = lazy(() => import('views/Clients'));
const Contracts = lazy(() => import('views/Contracts'));
const Equipment = lazy(() => import('views/Equipment'));
const Locations = lazy(() => import('views/Locations'));
const Maps = lazy(() => import('views/Maps'));
const MobileGroups = lazy(() => import('views/MobileGroups'));
const Products = lazy(() => import('views/Products'));
const Qualifications = lazy(() => import('views/Qualifications'));
const ServiceTemplates = lazy(() => import('views/ServiceTemplates'));
const TasksSchedule = lazy(() => import('views/TasksSchedule'));
const AuditorTasksSchedule = lazy(() => import('views/AuditorTasksSchedule'));
const TasksStatuses = lazy(() => import('views/TasksStatuses'));
const Users = lazy(() => import('views/Users'));
const Reports = lazy(() => import('views/Reports'));
const Audits = lazy(() => import('views/Audits'));

const AppRouter = () => {
  const { data, error, isFetching, refetch } = useCurrent(!!authClient?.authenticated);
  const userRole = useMemo(() => data?.systemRole ?? '', [data?.systemRole]);
  const location = useLocation();

  useEffect(() => {
    if (data?.id) Sentry.setUser({ id: data.id });
    return () => {
      Sentry.setUser(null);
    };
  }, [data?.id]);

  const setMenuItems = (role: string): ReactNode => {
    switch (role) {
      case roleSystemAdministrator:
        return (
          <>
            <Route path='/home' element={<Users />} />
          </>
        );
      case roleLocalAdministrator:
        return (
          <>
            <Route path='/home' element={<Clients />} />
            <Route path='/equipment' element={<Equipment />} />
            <Route path='/qualifications' element={<Qualifications />} />
            <Route path='/products' element={<Products />} />
            <Route path='/contracts' element={<Contracts />} />
            <Route path='/locations' element={<Locations />} />
            <Route path='/cars' element={<Cars />} />
            <Route path='/mobile-groups' element={<MobileGroups />} />
            <Route path='/service-templates' element={<ServiceTemplates />} />
            <Route path='/audit-templates' element={<AuditTemplates />} />
            <Route path='/reports' element={<Reports />} />
          </>
        );
      case roleAuditor:
        return (
          <>
            <Route path='/home' element={<MobileGroups readOnly />} />
            <Route path='/schedule' element={<AuditorTasksSchedule />} />
            <Route path='/audits' element={<Audits />} />
          </>
        );
      case roleOperator:
      case roleReadOnlyOperator:
        return (
          <>
            <Route path='/home' element={<Maps />} />
            <Route path='/schedule' element={<TasksSchedule />} />
            <Route path='/task-statuses' element={<TasksStatuses />} />
            <Route path='/reports' element={<Reports />} />
          </>
        );
      default:
        return null;
    }
  };

  if (error) {
    return <ErrorBoundaryInfo onClick={refetch} />;
  }

  return isFetching ? (
    <Spinner />
  ) : (
    <SentryRoutes>
      <Route element={<Layout userData={data} />}>
        {setMenuItems(userRole)}
        <Route path='*' element={<Navigate to='/home' />} />
      </Route>
      <Route path='/login' element={<Login pathname={location?.pathname ?? '/login'} />} />
    </SentryRoutes>
  );
};
export default AppRouter;
