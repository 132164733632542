import styled from 'styled-components';

import { Button } from 'styles/button';
import { colorSlateBlue } from 'styles/constants';

export const ActionButton = styled(Button)`
  padding: 8px 8px;
  &:hover {
    color: ${colorSlateBlue};
  }
`;
