import { BadgeElement } from './Badge.style';

type tBadge = {
  count: number;
};

const Badge = ({ count }: tBadge) => {
  if (!count) return null;

  return <BadgeElement>{count > 99 ? '99+' : +count}</BadgeElement>;
};

export default Badge;
