import { useTranslation } from 'react-i18next';
import Icon from 'components/Icon';

import { Button } from 'styles/button';
import { Container } from './ErrorBoudary.style';

type tErrorBoundaryInfo = {
  onClick: () => void;
};

const ErrorBoundaryInfo = ({ onClick }: tErrorBoundaryInfo) => {
  const { t } = useTranslation();
  return (
    <Container>
      <Icon iconName='alarm' width='30rem' height='15rem' />
      <h1>{t('Something went wrong')}.</h1>
      <Button type='button' onClick={onClick}>
        {t('Refresh page')}
      </Button>
    </Container>
  );
};

export default ErrorBoundaryInfo;
