import { format } from 'date-fns';
import { defaultDateFormat } from 'utils/constants';

const formatDate = (start: Date | string, end: Date | string): string => {
  const startDate = new Date(start);
  const endDate = new Date(end);
  if (format(startDate, defaultDateFormat) !== format(endDate, defaultDateFormat)) {
    return `${format(startDate, defaultDateFormat)} - ${format(
      endDate,
      defaultDateFormat,
    )}; ${format(startDate, 'HH:mm')} - ${format(endDate, 'HH:mm')}`;
  }

  return `${format(startDate, defaultDateFormat)}; ${format(startDate, 'HH:mm')} - ${format(
    endDate,
    'HH:mm',
  )}`;
};

export default formatDate;
