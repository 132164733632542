import styled, { css } from 'styled-components';

import { Button } from 'styles/button';
import {
  colorSlateBlue,
  colorSolitude,
  colorLightSlateBlue,
  colorLightSlateGrey,
  colorWhite,
} from 'styles/constants';

type tTabButton = {
  $active: boolean;
};

export const ItemsCount = styled.div`
  background: ${colorLightSlateGrey};
  border-radius: 50%;
  color: ${colorWhite};
  display: inline-block;
  font-size: 10px;
  margin-left: 6px;
  min-width: 12px;
  padding: 4px;
  text-align: center;
`;

export const TabButton = styled(Button)<tTabButton>`
  align-items: center;
  border: 1px solid ${colorSolitude};
  border-radius: 8px 8px 0px 0px;
  color: ${colorLightSlateGrey};
  display: flex;
  font-size: 16px;
  font-weight: bold;
  margin-right: 4px;
  padding: 16px 32px;
  position: relative;
  ${({ $active }) =>
    $active &&
    css`
      color: ${colorSlateBlue};
      > ${ItemsCount} {
        background: ${colorSlateBlue};
      }
      &:after {
        background: linear-gradient(90deg, ${colorLightSlateBlue} 0%, ${colorSlateBlue} 100%);
        bottom: 0;
        content: '';
        height: 4px;
        left: 0;
        position: absolute;
        right: 0;
      }
    `}
`;

export const TabsContainer = styled.div`
  display: flex;
  overflow-x: auto;
`;
