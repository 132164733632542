import i18n from 'i18next';
import { Icon } from 'components';
import { Button } from './ButtonPrimary.style';
import { tIconEnum } from '../Icon/Icon';

type tButtonPrimary = {
  handleClick: () => void;
  icon?: tIconEnum;
  text?: string;
  disabled?: boolean;
};

const ButtonPrimary = ({
  handleClick = () => {},
  icon = '',
  text = 'Accept',
  disabled = false,
}: tButtonPrimary) => (
  <Button type='button' onClick={handleClick} disabled={disabled}>
    {!!icon && <Icon iconName={icon} fill='white' width='1.125rem' height='1.125rem' />}
    <div style={!!icon ? { marginLeft: '12px' } : {}}>{i18n.t(`${text}`)}</div>
  </Button>
);

export default ButtonPrimary;
