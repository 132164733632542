import { useState } from 'react';

import renderActions from './utils/renderActions';
import { tAction, tDetail, tTableItem, tPhoneNumbers } from './Table';
import {
  Container,
  ParagraphWithSmallMargin,
  TableActionCell,
  TableCell,
  TableExpandCell,
  TableRow,
} from './Table.style';
import ExpandingButtonPanel from './ExpandingButtonPanel';
import { tTableTransformedItem, tTableTransformedItemData } from 'types/global';

type tTableRowWithDetails<T extends readonly tTableTransformedItemData[]> = {
  actions: tAction<tTableTransformedItem<T>>[];
  data: T;
  detail: null | tDetail;
  item: tTableItem<T>;
};

const TableRowWithDetails = <T extends readonly tTableTransformedItemData[]>({
  actions = [],
  data,
  detail = null,
  item,
}: tTableRowWithDetails<T>) => {
  const [showDetail, setShowDetails] = useState(false);

  const handleClick = () => {
    setShowDetails(!showDetail);
  };

  return (
    <>
      <TableRow>
        <TableExpandCell>
          <ExpandingButtonPanel
            detail={detail}
            onClick={handleClick}
            active={showDetail}
            dataId={`table-expand-button-${item.id}`}
          />
        </TableExpandCell>
        {data.map((itemCell) => {
          const { key: itemCellKey, value } = itemCell;
          if (Array.isArray(value)) {
            return (
              <TableCell key={itemCellKey}>
                <Container>
                  {value.map(
                    (
                      val: tPhoneNumbers, // TODO: Check this dangerous typing. "val" may have a different type than tPhoneNumbers
                      index: number,
                    ) => (
                      <ParagraphWithSmallMargin key={`${itemCellKey}-${val.number}-${index}`}>
                        {val.number}
                      </ParagraphWithSmallMargin>
                    ),
                  )}
                </Container>
              </TableCell>
            );
          } else return <TableCell key={itemCellKey}>{value}</TableCell>;
        })}
        {!!actions.length && <TableActionCell>{renderActions({ actions, item })}</TableActionCell>}
      </TableRow>
      {showDetail && <TableRow>{detail?.content}</TableRow>}
    </>
  );
};

export default TableRowWithDetails;
