import {
  Fragment,
  useState,
  useEffect,
  useRef,
  MutableRefObject,
  MouseEvent,
  useMemo,
} from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useTranslation } from 'react-i18next';
import { format, differenceInMinutes, isToday } from 'date-fns';

import { useMaps } from 'contexts/maps';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { Icon, AssignTaskModal, AssignTaskToGroup, Tooltip, TaskActionButtons } from 'components';
import { colorAeroBlue, colorSolitude } from 'styles/constants';
import { timelinePositionInterval } from 'utils/constants';
import Timeline from './Timeline';
import {
  GridContainer,
  GridItem,
  HourCopy,
  HoursSection,
  IconButtonContainer,
  Task,
  TaskActions,
  TaskHours,
  TaskSection,
  TaskTimes,
  Wrapper,
} from './GroupSchedule.style';
import { useCurrentUser } from 'contexts/currentUser';
import { getSinceAndToOfDay } from 'utils/dates';
import useSchedules from 'services/hooks/useSchedules';

type tScheduleTask = {
  address: string;
  id: string;
  name: string;
  start: string;
  end: string;
  duration: number;
  startDate: string;
  scheduleId?: string;
  key?: number;
};

type tSchedule = {
  key: number;
  hour: string;
  tasks: tScheduleTask[];
};

type tGroupSchedule = {
  executionTime: Date;
  group: {
    id: string;
    name: string;
  };
};

const scheduleInit: tSchedule[] = [
  { key: 1, hour: '00:00', tasks: [] },
  { key: 2, hour: '01:00', tasks: [] },
  { key: 3, hour: '02:00', tasks: [] },
  { key: 4, hour: '03:00', tasks: [] },
  { key: 5, hour: '04:00', tasks: [] },
  { key: 6, hour: '05:00', tasks: [] },
  { key: 7, hour: '06:00', tasks: [] },
  { key: 8, hour: '07:00', tasks: [] },
  { key: 9, hour: '08:00', tasks: [] },
  { key: 10, hour: '09:00', tasks: [] },
  { key: 11, hour: '10:00', tasks: [] },
  { key: 12, hour: '11:00', tasks: [] },
  { key: 13, hour: '12:00', tasks: [] },
  { key: 14, hour: '13:00', tasks: [] },
  { key: 15, hour: '14:00', tasks: [] },
  { key: 16, hour: '15:00', tasks: [] },
  { key: 17, hour: '16:00', tasks: [] },
  { key: 18, hour: '17:00', tasks: [] },
  { key: 19, hour: '18:00', tasks: [] },
  { key: 20, hour: '19:00', tasks: [] },
  { key: 21, hour: '20:00', tasks: [] },
  { key: 22, hour: '21:00', tasks: [] },
  { key: 23, hour: '22:00', tasks: [] },
  { key: 24, hour: '23:00', tasks: [] },
];

const GroupSchedule = ({ executionTime, group }: tGroupSchedule) => {
  const scrollbarRef = useRef<Scrollbars>(null);
  const { t } = useTranslation();
  const { user } = useCurrentUser();
  const { getTasks, task, setActiveTask } = useMaps();

  const schedulesParams = useMemo(() => {
    const { since, to } = getSinceAndToOfDay(executionTime);
    return {
      mobile_groups: group.id,
      since,
      to,
      offset: 0,
    };
  }, [executionTime, group.id]);

  const {
    data: dataMobileGroupSchedule,
    isFetching: loadingMobileGroupSchedule,
    refetchList: fetchDataMobileGroupSchedule,
  } = useSchedules({ params: schedulesParams });

  const [schedule, setSchedule] = useState<tSchedule[]>(scheduleInit);
  const ref = useRef() as MutableRefObject<HTMLInputElement>;
  const [now, setNow] = useState<Date>(new Date());
  const [showAssignTaskModal, setShowAssignTaskModal] = useState<boolean>(false);

  const onSuccess = (action) => {
    if (action === 'unassign') {
      getTasks();
    }
    fetchDataMobileGroupSchedule();
  };

  useEffect(() => {
    setSchedule(scheduleInit);
  }, [group.id, executionTime]);

  useEffect(() => {
    const timelinePositionUpdater = setInterval(() => {
      setNow(new Date());
    }, timelinePositionInterval);

    return () => clearInterval(timelinePositionUpdater);
  });

  useEffect(() => {
    if (!dataMobileGroupSchedule?.length) {
      setSchedule(scheduleInit);
    } else {
      const [mobileGroupData] = dataMobileGroupSchedule;
      const { entries } = mobileGroupData;

      const { since: start, to: end } = getSinceAndToOfDay(executionTime);

      const transformedEntries: tScheduleTask[] = entries
        .filter((item) => {
          return new Date(item.since) >= start && new Date(item.to) <= end;
        })
        .map((item) => ({
          address: item.address,
          key: Number(format(new Date(item.since), 'HH')) + 1,
          id: item.id,
          name: item.name,
          start: format(new Date(item.since), 'HH:mm'),
          end: format(new Date(item.to), 'HH:mm'),
          duration: differenceInMinutes(new Date(item.to), new Date(item.since)),
          startDate: item.since,
          scheduleId: item.scheduleId,
        }));

      const updatedSchedules: tSchedule[] = scheduleInit.map((scheduleInitItem) => {
        const foundItem = transformedEntries.filter(
          (transformedEntryItem) => transformedEntryItem.key === scheduleInitItem.key,
        );

        if (foundItem.length > 0) {
          return {
            ...scheduleInitItem,
            tasks: [...scheduleInitItem.tasks, ...foundItem],
          };
        }

        return scheduleInitItem;
      });

      setSchedule(updatedSchedules);
    }
  }, [dataMobileGroupSchedule]);

  const [taskActions, setTaskActions] = useState<{ state: boolean; id: number | string }>({
    state: false,
    id: '',
  });

  useOnClickOutside(ref, () => setTaskActions({ ...taskActions, state: false }));

  const assignTaskToMobileGroup = () => {
    if (!!task && !showAssignTaskModal && !user?.isReadOnly) {
      setShowAssignTaskModal(true);
    }
  };

  const renderTasks = (tasks: tScheduleTask[], gridItemKey: number) => {
    if (!tasks?.length) return <TaskSection />;

    return (
      <TaskSection onClick={(e: MouseEvent) => e.stopPropagation()}>
        {tasks.map((task) => {
          if (task === undefined) return null;
          const { start, end, duration, id, name, address, startDate, scheduleId } = task;
          const taskStartMinutes = Number(start.split(':')[1]);
          const taskInTime = now.getTime() >= new Date(startDate).getTime();

          return (
            <Fragment key={id}>
              {taskActions.state && id === taskActions.id && (
                <TaskActions
                  ref={ref}
                  style={{
                    top:
                      gridItemKey === 24 || gridItemKey === 23
                        ? '-30px'
                        : `${(taskStartMinutes / 10) * 8 + 6}px`,
                  }}
                >
                  <TaskActionButtons
                    task={{
                      id,
                      name,
                      start: new Date(`${format(new Date(startDate), 'yyyy-MM-dd')} ${start}`),
                      end: new Date(`${format(new Date(startDate), 'yyyy-MM-dd')} ${end}`),
                      address,
                      scheduleId,
                    }}
                    onSuccess={onSuccess}
                    group={group}
                  />
                </TaskActions>
              )}
              <Task
                key={id}
                style={{
                  top: `${(taskStartMinutes / 15) * 12}px`,
                  minHeight: '24px',
                  height: `${(duration / 60) * 48}px`,
                  background: taskInTime ? colorSolitude : colorAeroBlue,
                }}
              >
                <TaskHours>
                  <Tooltip
                    direction='left'
                    title={name}
                    tipId={id}
                    content={<div>{address}</div>}
                    renderInPortal
                  >
                    <TaskTimes>
                      {start} - {end}
                    </TaskTimes>
                  </Tooltip>
                  {!taskInTime && !user?.isReadOnly && (
                    <IconButtonContainer
                      onClick={() => setTaskActions({ ...taskActions, state: true, id })}
                    >
                      <Icon iconName='moreVert' width='16px' height='16px' />
                    </IconButtonContainer>
                  )}
                </TaskHours>
              </Task>
            </Fragment>
          );
        })}
      </TaskSection>
    );
  };

  const setAssignCondition = ({ nowDay, executionTimeDay, blockedHours, key }) => {
    if (nowDay === executionTimeDay) return key > blockedHours;
    if (nowDay < executionTimeDay) return true;

    return false;
  };

  const renderSchedule = (schedule: tSchedule[]) =>
    schedule.map((item: tSchedule) => {
      const { key, hour, tasks } = item;
      const blockedHours = Number(format(new Date(now), 'H'));
      const nowDay = Number(format(new Date(now), 'd'));
      const executionTimeDay = Number(format(new Date(executionTime), 'd'));
      return (
        <GridItem
          key={key}
          data-testid={`scheduleGridItem__${key}`}
          onClick={
            setAssignCondition({ nowDay, executionTimeDay, blockedHours, key })
              ? assignTaskToMobileGroup
              : () => {}
          }
        >
          {key === 1 ? (
            <HoursSection />
          ) : (
            <HoursSection>
              <HourCopy>{hour}</HourCopy>
            </HoursSection>
          )}
          {renderTasks(tasks, item.key)}
        </GridItem>
      );
    });

  const onSuccessHandler = () => {
    setActiveTask(null);
    fetchDataMobileGroupSchedule();
    getTasks();
    setShowAssignTaskModal(false);
  };

  useEffect(() => {
    if (!!scrollbarRef?.current && !loadingMobileGroupSchedule && isToday(executionTime)) {
      // @ts-ignore view is not included in @types/react-custom-scrollbars-2
      scrollbarRef.current.view.scroll({
        top: 48 * (Number(format(new Date(now), 'H')) - 2),
        behavior: 'smooth',
      });
    }
  }, [scrollbarRef, loadingMobileGroupSchedule]);

  if (loadingMobileGroupSchedule) return <>{t('Loading')}...</>;
  return (
    <>
      {showAssignTaskModal && task && (
        <AssignTaskModal
          taskId={task.id}
          onSuccess={onSuccessHandler}
          onCancel={() => setShowAssignTaskModal(false)}
          group={group}
          executionTime={executionTime}
        >
          <AssignTaskToGroup task={task} executionTime={null} />
        </AssignTaskModal>
      )}
      <Wrapper>
        <Scrollbars autoHide ref={scrollbarRef}>
          <GridContainer>
            {isToday(executionTime) && <Timeline />}
            {renderSchedule(schedule)}
          </GridContainer>
        </Scrollbars>
      </Wrapper>
    </>
  );
};

export default GroupSchedule;
