import styled from 'styled-components';

import { ReactComponent as GMLogo } from 'assets/svg/logo_horizontal.svg';
import { boxShadowLight, zIndexModalBackdrop } from 'styles/constants';

export const Bar = styled.div`
  align-items: center;
  background: white;
  box-shadow: ${boxShadowLight};
  display: flex;
  height: 64px;
  justify-content: space-between;
  left: 0;
  padding: 0 32px 0 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: ${zIndexModalBackdrop};
`;

export const IconsContainer = styled.div`
  align-items: center;
  display: flex;
  margin-right: 1rem;
`;

export const Logo = styled(GMLogo)`
  height: 30px;
  width: 100%;
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 204px;
`;

export const MenuButtonContainer = styled.div`
  display: none;
  margin-left: 16px;
  @media screen and (max-width: 1000px) {
    display: block;
  }
`;

export const RoleCopy = styled.div`
  font-size: 12px;
`;

export const UserCopy = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 4px;
`;

export const UserPanel = styled.div`
  margin-left: 8px;
`;
