import styled from 'styled-components';
import { colorSlateBlue, boxShadowCommon, colorWhite } from 'styles/constants';

export const Container = styled.div`
  background: ${colorWhite};
  box-shadow: ${boxShadowCommon};
  border-radius: 8px;
  margin-right: 8px;
  padding: 27px 24px;
  height: calc(100vh - 16rem);
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1199px) {
    width: 25vw;
  }
  @media screen and (max-width: 767px) {
    max-width: 100%;
    margin-bottom: 16px;
    width: unset;
  }
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.6875rem;
  @media screen and (min-width: 767px) and (max-width: 1000px) {
    flex-direction: column;
  }
  @media screen and (max-width: 399px) {
    flex-direction: column;
  }
`;

export const Title = styled.h3`
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  max-height: 100%;
  overflow-y: auto;
  padding: 3px;
  max-height: calc(100vh - 22rem);
  @media screen and (max-width: 767px) {
    max-height: 250px;
  }
`;

export const NoTasks = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
`;

export const Text = styled.p`
  margin: 0;
  padding: 0;
  color: ${colorSlateBlue};
  margin-top: 12px;
  font-size: 14px;
  line-height: 17px;
  font-weight: bold;
`;
