import { CancelToken } from 'axios';
import fetchProvider from 'utils/Axios';
import { tDictionary, tListParams } from 'types/global';

const microservicePath = '/contracts';

export type tReadContract = {
  name: string;
  number: string;
  id: string;
  client: tDictionary;
  product: tDictionary;
};

type tWriteContract = {
  name: string;
  number: string;
  client_id: string;
  product_id: string;
};

type tDeleteContractResponse = tReadContract;

export type tAddContractRequest = tWriteContract;
type tAddContractResponse = tReadContract;

type tGetContractResponse = tReadContract;

export type tGetContractsRequest = tListParams & {
  client_id?: string;
  product_id?: string;
  location_id?: string;
  number?: string;
};
type tGetContractsResponse = { count: number; results: tReadContract[] };

export type tUpdateContractRequest = tWriteContract;
type tUpdateContractResponse = tReadContract;

const contracts = {
  deleteContract: (contractId: string) =>
    fetchProvider.delete<tDeleteContractResponse>(`${microservicePath}/${contractId}`),
  addContract: (bodyRequest: tAddContractRequest) =>
    fetchProvider.post<tAddContractResponse>(`${microservicePath}`, bodyRequest),
  getContract: (contractId: string, cancelToken: CancelToken) =>
    fetchProvider.get<tGetContractResponse>(`${microservicePath}/${contractId}`, { cancelToken }),
  getContracts: (params: tGetContractsRequest, cancelToken: CancelToken) =>
    fetchProvider.get<tGetContractsResponse>(`${microservicePath}`, { params, cancelToken }),
  updateContract: (contractId: string, bodyRequest: tUpdateContractRequest) =>
    fetchProvider.put<tUpdateContractResponse>(`${microservicePath}/${contractId}`, bodyRequest),
};

export default contracts;
