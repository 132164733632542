import { useTranslation } from 'react-i18next';

import { tTableTab } from 'types/global';
import { TabButton, ItemsCount, TabsContainer } from './TableTabs.style';

type tTableTabs = {
  tabs?: tTableTab[];
  setActiveTab?: (tab: string) => void;
  activeTab?: string;
  showCount?: boolean;
  name: string;
};

const TableTabs = ({
  tabs = [],
  setActiveTab = () => {},
  activeTab = '',
  showCount = true,
  name = '',
}: tTableTabs) => {
  const { t } = useTranslation();

  if (!tabs.length) {
    return null;
  }

  const cacheTabItem: Function = (type: string, name: string): void => {
    window.localStorage.setItem(`tabs:${name}`, type);

    setActiveTab(type);
  };

  return (
    <TabsContainer>
      {tabs.map((tab: tTableTab) => {
        const { name: tabName, count, id, type } = tab;

        return (
          <TabButton $active={type === activeTab} key={id} onClick={() => cacheTabItem(type, name)}>
            {t(tabName)} {showCount && <ItemsCount>{count}</ItemsCount>}
          </TabButton>
        );
      })}
    </TabsContainer>
  );
};

export default TableTabs;
