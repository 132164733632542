import styled from 'styled-components';

import { boxShadowCommon } from 'styles/constants';

type tFormModal = {
  $paddingVertical?: boolean;
};

export const Title = styled.h3`
  box-shadow: ${boxShadowCommon};
  padding: 2rem 1.5rem 1.5rem;
`;

export const Buttons = styled.div`
  box-shadow: ${boxShadowCommon};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1.5rem 2rem;
  & > * {
    margin-left: 0.5rem;
  }
`;

export const FieldsWrapper = styled.div<tFormModal>`
  ${({ $paddingVertical }) => ($paddingVertical ? 'padding: 1.5rem' : 'padding: 0 1.5rem')};
`;
