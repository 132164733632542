import { ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Fieldset, DropDown, Icon, Input, Label, Error } from 'components';
import { BottomGap, InputFormContainer } from 'styles/GlobalStyledComponents';
import { colorSlateBlue } from 'styles/constants';
import { tStep, tDropdownOption } from 'types/global';
import {
  AddQuestionButton,
  Container,
  Copy,
  IconButtonContainer,
  ReadonlyValueCopy,
  StepContainer,
} from './Steps.style';

type tSteps = {
  readonly?: boolean;
  setSteps: (steps: tStep[]) => void;
  steps: tStep[];
  questionTypes?: tDropdownOption<string>[];
  error?: string;
};

type tUpdateFieldChanged = {
  field: string;
  itemId: number | string;
  updatedValue: string | ChangeEvent<HTMLInputElement> | tDropdownOption<string> | null;
};

const Steps: FC<tSteps> = ({
  readonly = false,
  steps,
  setSteps,
  questionTypes = [],
  error = '',
}) => {
  const { t } = useTranslation();
  const removeItem = (itemIdToRemove: number | string) =>
    setSteps([...steps.filter((step: tStep) => step.id !== itemIdToRemove)]);

  const updateFieldChanged = ({ field, itemId, updatedValue }: tUpdateFieldChanged) =>
    setSteps(
      steps.map((stepItem: tStep) => {
        if (stepItem.id === itemId) {
          return { ...stepItem, [field]: updatedValue };
        }

        return stepItem;
      }),
    );

  return (
    <Container>
      {steps.map((step: tStep) => {
        const { id, isRemovable, name, type } = step;

        return (
          <StepContainer key={id}>
            <Fieldset withContainerGap isRemovable={isRemovable} error={!!error}>
              <InputFormContainer>
                <Input
                  readonly={readonly}
                  label='Question'
                  placeholder='Np. Podstawowe prace porządkowe'
                  value={name}
                  type='text'
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    updateFieldChanged({
                      field: 'name',
                      itemId: id,
                      updatedValue: e.target.value,
                    })
                  }
                />
              </InputFormContainer>
              {readonly ? (
                <BottomGap $gap='12px'>
                  <Label>{t('Answer type')}</Label>
                  <ReadonlyValueCopy>{type?.label ?? '-'}</ReadonlyValueCopy>
                </BottomGap>
              ) : (
                <DropDown
                  value={type}
                  options={questionTypes}
                  onChange={(option) =>
                    updateFieldChanged({ field: 'type', itemId: id, updatedValue: option })
                  }
                  label={t('Answer type')}
                  isLoading={false}
                />
              )}
              {step.isRemovable && (
                <IconButtonContainer onClick={() => removeItem(step.id)}>
                  <Icon iconName='close' width='1rem' height='1rem' />
                </IconButtonContainer>
              )}
            </Fieldset>
          </StepContainer>
        );
      })}
      {!!error && <Error text={error} />}
      {!readonly && (
        <AddQuestionButton
          onClick={() =>
            setSteps([
              ...steps,
              {
                id: new Date().getTime().toString(),
                name: '',
                isRemovable: true,
                type: null,
              },
            ])
          }
        >
          <Icon iconName='addCircleButton' fill={colorSlateBlue} width='1rem' height='1rem' />
          <Copy>{t('Add next question')}</Copy>
        </AddQuestionButton>
      )}
    </Container>
  );
};

export default Steps;
