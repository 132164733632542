import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

import { tNotification } from 'types/services/notifications';
import { Icon, Tooltip } from 'components';
import { colorRadicalRed, colorSlateBlue } from 'styles/constants';
import {
  Container,
  Message,
  StateButton,
  NotificationMessage,
  Time,
} from './SingleNotification.style';
import {
  useSetNotificationAsRead,
  useSetNotificationAsUnRead,
} from 'services/hooks/useNotification';

export const icons = {
  alarm: 'notificationAlarm',
  timeDelay: 'notificationTimeDelay',
  alarmResolved: 'notificationAlarmResolved',
  startAfterTime: 'notificationStartAfterTime',
  mobileGroupAssigned: 'notificationMobileGroupAssigned',
  serviceAssigned: 'notificationServiceAssigned',
  auditNextDay: 'notificationAuditNextDay',
  noType: 'notificationDefault',
};

const SingleNotification = ({ id, type, unread, time, date, msg }: tNotification) => {
  const { t } = useTranslation();
  const { mutate: setAsRead } = useSetNotificationAsRead();
  const { mutate: setAsUnread } = useSetNotificationAsUnRead();

  const timeWithDate = `${date} ${time}`;
  const setNotificationStatus = async () => {
    if (unread) setAsRead(id);
    else setAsUnread(id);
  };

  return (
    <Container $unread={unread}>
      <Icon fill={type === 'alarm' ? colorRadicalRed : colorSlateBlue} iconName={icons[type]} />
      <Message>
        <NotificationMessage>{parse(msg)}</NotificationMessage>
        <Time>{timeWithDate}</Time>
      </Message>
      <Tooltip direction='left' tipId={id} content={unread ? t('Set as read') : t('Set as unread')}>
        <StateButton onClick={() => setNotificationStatus()} $unread={unread} />
      </Tooltip>
    </Container>
  );
};

export default SingleNotification;
