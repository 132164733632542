const setNotificationType = (type: string): string => {
  switch (type) {
    case 'SERVICE_NOT_ACCEPTED_TIME_EXPIRED':
      return 'timeDelay';
    case 'SERVICE_DELAYED_START':
      return 'startAfterTime';
    case 'ACCIDENT_RESOLVED':
      return 'alarmResolved';
    case 'ACCIDENT_NEW':
      return 'alarm';
    case 'MOBILE_GROUP_ASSIGNED':
      return 'mobileGroupAssigned';
    case 'SERVICE_ASSIGNED':
      return 'serviceAssigned';
    case 'AUDIT_NEXT_DAY':
      return 'auditNextDay';
    default:
      return 'noType';
  }
};

export default setNotificationType;
