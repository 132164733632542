import i18n from 'i18next';
import { AxiosResponse, CancelToken } from 'axios';

import productsAgent, {
  tAddProductRequest,
  tGetProductsRequest,
  tUpdateProductRequest,
} from 'agents/products';
import { tDropdownOption } from 'types/global';
import errorsHandler, { tErrorsHandlerResponse } from './utils/errorsHandler';
import { tGetProduct } from 'types/services/products';

const addProduct = (bodyRequest: tAddProductRequest): Promise<tErrorsHandlerResponse> =>
  productsAgent
    .addProduct(bodyRequest)
    .then((resp: AxiosResponse) => ({
      state: true,
      errors: [],
      data: resp.data,
      status: resp.status,
    }))
    .catch((error) =>
      errorsHandler({
        defaultErrorMessage: i18n.t('Cannot add', { value: i18n.t('product form') }),
        error,
      }),
    );

const updateProduct = (
  productId: string,
  bodyRequest: tUpdateProductRequest,
): Promise<tErrorsHandlerResponse> =>
  productsAgent
    .updateProduct(productId, bodyRequest)
    .then((resp: AxiosResponse) => ({
      state: true,
      errors: [],
      data: resp.data,
      status: resp.status,
    }))
    .catch((error) =>
      errorsHandler({
        defaultErrorMessage: i18n.t('Cannot edit', { value: i18n.t('product form') }),
        error,
      }),
    );

const deleteProduct = (productId: string): Promise<tErrorsHandlerResponse> => {
  const defaultError = [
    { key: '__general__', msg: i18n.t('Cannot remove', { value: i18n.t('product form') }) },
  ];

  return productsAgent
    .deleteProduct(productId)
    .then((resp: AxiosResponse) => ({
      state: true,
      errors: [],
      data: resp.data,
      status: resp.status,
    }))
    .catch((error) => ({ state: false, errors: error?.response?.data ?? defaultError, data: {} }));
};

const getProduct = ({ productId }: { productId: string }, cancelToken: CancelToken) =>
  productsAgent.getProduct(productId, cancelToken).then((response) => {
    const {
      data: { name, equipments, qualifications, client_system_id },
    } = response;

    const transformedEquipments: tDropdownOption<string>[] = !equipments.length
      ? []
      : equipments.map((item) => ({
          label: item.name,
          value: item.id,
        }));

    const transformedQualifications: tDropdownOption<string>[] = !qualifications.length
      ? []
      : qualifications.map((item) => ({
          label: item.name,
          value: item.id,
        }));

    return {
      name,
      equipments: transformedEquipments,
      qualifications: transformedQualifications,
      client_system_id,
    };
  });

const getProducts = ({
  limit = 10,
  offset = 0,
  search = '',
  client_id = '',
  cancelToken,
}: tGetProductsRequest & { cancelToken: CancelToken }) => {
  const params: tGetProductsRequest = { offset };
  if (limit) params.limit = limit;
  if (search) params.search = search;
  if (client_id) params.client_id = client_id;

  return productsAgent.getProducts(params, cancelToken).then((response) => {
    let transformedData: tGetProduct[] = [];

    const {
      data: { count, results },
    } = response;

    if (!!count) {
      transformedData = results.map((productData, index) => {
        const { id, name, equipments, qualifications, client_system_id } = productData;

        return {
          id,
          itemData: [
            { key: 'order', value: index + 1 + offset },
            { key: 'name', value: name },
            { key: 'clientSystemId', value: !!client_system_id ? client_system_id : '-' },
          ],
          extraInfo: {
            equipments,
            qualifications,
          },
        };
      });
    }

    return { count, results: transformedData };
  });
};

export default {
  addProduct,
  updateProduct,
  deleteProduct,
  getProduct,
  getProducts,
};
