import { CancelToken } from 'axios';
import fetchProvider from 'utils/Axios';
import { tDictionary, tListParams } from 'types/global';

const microservicePath = '/equipments';

type tReadEquipment = tDictionary;
type tWriteEquipment = Pick<tDictionary, 'name'>;

type tDeleteEquipmentResponse = tReadEquipment;

export type tAddEquipmentRequest = tWriteEquipment;
type tAddEquipmentResponse = tReadEquipment;

type tGetEquipmentResponse = tReadEquipment;

export type tGetEquipmentsRequest = tListParams;
type tGetEquipmentsResponse = { count: number; results: tReadEquipment[] };

export type tUpdateEquipmentRequest = tWriteEquipment;
type tUpdateEquipmentResponse = tReadEquipment;

const equipment = {
  deleteEquipment: (equipmentId: string) =>
    fetchProvider.delete<tDeleteEquipmentResponse>(`${microservicePath}/${equipmentId}`),
  addEquipment: (bodyRequest: tAddEquipmentRequest) =>
    fetchProvider.post<tAddEquipmentResponse>(`${microservicePath}`, bodyRequest),
  getEquipment: (equipmentId: string, cancelToken: CancelToken) =>
    fetchProvider.get<tGetEquipmentResponse>(`${microservicePath}/${equipmentId}`, { cancelToken }),
  getEquipments: (params: tGetEquipmentsRequest, cancelToken: CancelToken) =>
    fetchProvider.get<tGetEquipmentsResponse>(`${microservicePath}`, { params, cancelToken }),
  updateEquipment: (equipmentId: string, bodyRequest: tUpdateEquipmentRequest) =>
    fetchProvider.put<tUpdateEquipmentResponse>(`${microservicePath}/${equipmentId}`, bodyRequest),
};

export default equipment;
