import { useCallback, useEffect } from 'react';
import axios, { AxiosError } from 'axios';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import notificationsService from 'services/notifications';
import { onError, setInfiniteQueryData } from 'utils/queryClient';
import { defaultCacheStaleTime } from 'services/utils/constants';
import { transformMutationsErrors } from 'services/utils/errorsHandler';
import notify from 'utils/notify';
import i18n from 'i18next';
import { tNotification } from 'types/services/notifications';

type tUseNotificationArgs = {
  canGetNotifications: boolean;
};

export const queryCacheName = 'getNotification';

const useNotification = ({ canGetNotifications }: tUseNotificationArgs) => {
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey: [queryCacheName],
    queryFn: ({ signal }) => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      const promise: Promise<number> = notificationsService.getUnreadNotificationsCount(
        source.token,
      );

      // Cancel the request if TanStack Query signals to abort
      signal?.addEventListener('abort', () => {
        source.cancel('Query was cancelled by TanStack Query');
      });

      return promise;
    },
    staleTime: defaultCacheStaleTime,
    enabled: canGetNotifications,
  });

  const refetch = useCallback(
    () => queryClient.invalidateQueries({ queryKey: [queryCacheName] }),
    [queryCacheName],
  );

  const invalidateQuery = () =>
    queryClient.invalidateQueries({ queryKey: [queryCacheName], refetchType: 'none' });

  useEffect(() => {
    if (query.isError) onError(query.error);
  }, [query.isError, query.error]);

  return {
    ...query,
    refetch,
    invalidateQuery,
  };
};

export const useSetNotificationAsRead = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: notificationsService.setNotificationAsRead,
    onError: (error: AxiosError) => {
      const { errors } = transformMutationsErrors({
        error,
        defaultErrorMessage: i18n.t('Cannot change', { value: i18n.t('status form') }),
      });
      errors.forEach(({ msg }) => notify({ copy: msg, type: 'error' }));
    },
    onSuccess: (_, id) => {
      // set notification as read
      setInfiniteQueryData<tNotification[]>(queryClient, ['getNotifications'], id, {
        unread: false,
      });
      // decrease unread notifications counter
      queryClient.setQueryData(['getNotification'], (oldData: number) => oldData - 1);
    },
  });
};

export const useSetNotificationAsUnRead = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: notificationsService.setNotificationAsUnread,
    onError: (error: AxiosError) => {
      const { errors } = transformMutationsErrors({
        error,
        defaultErrorMessage: i18n.t('Cannot register', { value: i18n.t('status form') }),
      });
      errors.forEach(({ msg }) => notify({ copy: msg, type: 'error' }));
    },
    onSuccess: (_, id) => {
      // set notification as unread
      setInfiniteQueryData<tNotification[]>(queryClient, ['getNotifications'], id, {
        unread: true,
      });
      // increase unread notifications counter
      queryClient.setQueryData(['getNotification'], (oldData: number) => oldData + 1);
    },
  });
};

export default useNotification;
