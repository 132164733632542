import axios from 'axios';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';

import servicesService from 'services/services';
import { defaultCacheStaleTime } from 'services/utils/constants';
import { onError } from 'utils/queryClient';

type tUseConfirmationMethodsArgs = {
  enabled?: boolean;
};

export const queryCacheName = 'getConfirmationMethods';

const useConfirmationMethods = ({ enabled = true }: tUseConfirmationMethodsArgs) => {
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey: [queryCacheName],
    queryFn: ({ signal }) => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      const promise = servicesService.getConfirmationMethods(source.token);

      // Cancel the request if TanStack Query signals to abort
      signal?.addEventListener('abort', () => {
        source.cancel('Query was cancelled by TanStack Query');
      });

      return promise;
    },
    enabled,
    staleTime: defaultCacheStaleTime,
  });

  const refetchList = () => queryClient.invalidateQueries({ queryKey: [queryCacheName] });

  useEffect(() => {
    if (query.isError) onError(query.error);
  }, [query.isError, query.error]);

  return {
    ...query,
    refetchList,
  };
};

export default useConfirmationMethods;
