import { CancelToken } from 'axios';
import { isSameDay, addDays, format } from 'date-fns';
import i18n from 'i18next';

import { fetchProviderForAttachment } from 'utils/Axios';
import schedulesAgent, { microservicePath, tGetSchedulesRequest } from 'agents/schedules';
import { tMobileGroupSchedule, tScheduleEntry } from 'types/services/schedules';
import errorsHandler from 'services/utils/errorsHandler';
import { convertAttachmentOnApiFormat } from './attachments';
import { tWriteAttachment } from 'agents/attachments';

export const getDates = (startDate: Date, stopDate: Date): Date[] => {
  const dateArray: Date[] = [];
  let currentDate = addDays(startDate, 1);
  while (currentDate <= addDays(stopDate, -1)) {
    dateArray.push(currentDate);
    currentDate = addDays(currentDate, 1);
  }
  return dateArray;
};

const getSchedules = ({
  limit = 10,
  offset = 0,
  search = '',
  since,
  to,
  qualifications = '',
  equipments = '',
  mobile_groups = '',
  areas = '',
  auditor_id = '',
  cancelToken,
}: tGetSchedulesRequest & { cancelToken: CancelToken }) => {
  const params: tGetSchedulesRequest = { offset, since, to };

  if (limit) params.limit = limit;
  if (search) params.search = search;
  if (qualifications) params.qualifications = qualifications;
  if (equipments) params.equipments = equipments;
  if (mobile_groups) params.mobile_groups = mobile_groups;
  if (areas) params.areas = areas;
  if (auditor_id) params.auditor_id = auditor_id;

  return schedulesAgent.getSchedules(params, cancelToken).then((response) => {
    let transformedData: tMobileGroupSchedule[] = [];
    const {
      data: { count, results },
    } = response;

    if (!!count) {
      transformedData = results.map((task) => {
        const { id, name, entries } = task;

        const entriesHelper: tScheduleEntry[] = [];
        entries.forEach(
          ({
            since,
            to,
            id,
            service: {
              id: serviceId,
              name: serviceName,
              location: {
                address: { formatted_address: address = '' },
              },
            },
          }) => {
            if (!isSameDay(new Date(since), new Date(to))) {
              const days = getDates(new Date(since), new Date(to));
              const startTo = new Date(since);
              startTo.setHours(23, 59, 59, 999);

              const endSince = new Date(to);
              endSince.setHours(0, 0, 0, 0);

              entriesHelper.push({
                id: serviceId,
                name: serviceName,
                address,
                since,
                to: format(startTo, `yyyy-MM-dd'T'HH:mm:ssxxx`),
                scheduleId: id,
              });

              days.forEach((day) => {
                const start = new Date(day);
                start.setHours(0, 0, 0, 0);
                const end = new Date(day);
                end.setHours(23, 59, 59, 999);
                entriesHelper.push({
                  id: serviceId,
                  name: serviceName,
                  address,
                  since: format(start, `yyyy-MM-dd'T'HH:mm:ssxxx`),
                  to: format(end, `yyyy-MM-dd'T'HH:mm:ssxxx`),
                  scheduleId: id,
                });
              });
              entriesHelper.push({
                id: serviceId,
                name: serviceName,
                address,
                since: format(endSince, `yyyy-MM-dd'T'HH:mm:ssxxx`),
                to,
                scheduleId: id,
              });
            } else {
              entriesHelper.push({
                id: serviceId,
                name: serviceName,
                address,
                since,
                to,
                scheduleId: id,
              });
            }
          },
        );

        return {
          id,
          name,
          entries: entriesHelper,
        };
      });
    }
    return { count, results: transformedData };
  });
};

const deleteSchedule = (id: string) => {
  const defaultError = [
    { key: '__general__', msg: i18n.t('Cannot remove', { value: i18n.t('schedule entry form') }) },
  ];

  return schedulesAgent
    .deleteSchedule(id)
    .then(() => ({ state: true, errors: [], data: {} }))
    .catch((error) => ({ state: false, errors: error?.response?.data ?? defaultError, data: {} }));
};

const postAttachment = (data) => {
  return fetchProviderForAttachment(`${microservicePath}/upload`, data, {}, 'POST')
    .then(() => ({ state: true, errors: [], data: {} }))
    .catch((error) =>
      errorsHandler({
        defaultErrorMessage: i18n.t('Cannot add', { value: i18n.t('schedule entry form') }),
        error,
      }),
    );
};

const uploadSchedule = async (file: File) => {
  const attachment: tWriteAttachment | null = await convertAttachmentOnApiFormat(file);
  if (!!attachment) {
    const apiReq = await postAttachment(attachment);
    return apiReq;
  }
  throw new Error(i18n.t('Cannot convert file'));
};

export default {
  uploadSchedule,
  getSchedules,
  deleteSchedule,
};
