import styled, { css } from 'styled-components';
import * as ButtonStyles from 'styles/button';
import {
  colorSlateBlue,
  colorWhite,
  colorMagnolia,
  colorEchoBlue,
  colorSolitude,
} from 'styles/constants';

type tButton = {
  disabled: boolean;
};

export const Button = styled(ButtonStyles.ButtonStyle)<tButton>`
  line-height: 12px;
  ${({ disabled }) =>
    disabled
      ? css`
          background: ${colorSolitude};
          color: ${colorEchoBlue};
          border: 2px solid ${colorEchoBlue};
          cursor: unset;
        `
      : css`
          background: ${colorWhite};
          border: 2px solid ${colorSlateBlue};
          color: ${colorSlateBlue};
          &:hover {
            background: ${colorMagnolia};
          }
        `}
`;
