import styled from 'styled-components';

type tPageWrapper = {
  $wideMenu: boolean;
};
export const Wrapper = styled.div<tPageWrapper>`
  padding: 96px 32px 32px 300px;
  @media screen and (min-width: 1001px) {
    padding-left: ${({ $wideMenu }) => ($wideMenu ? '100px' : '300px')};
    transition: all 0.5s ease-out;
  }
  @media screen and (max-width: 1000px) {
    padding-left: 32px;
  }
  @media screen and (max-width: 520px) {
    padding: 96px 16px 16px;
  }
`;
