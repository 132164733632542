import { Component, ReactNode } from 'react';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { ai } from 'services/telemetry';
import { createBrowserHistory, History } from 'history';

interface IProps {
  after: () => void;
  instrumentationKey: string | null;
  children?: ReactNode;
}

type tState = {
  initialized: boolean;
};
/**
 * This Component provides telemetry with Azure App Insights
 * NOTE: the package '@microsoft/applicationinsights-react-js' has a HOC withAITracking
 * that requires this to be a Class Component rather than a Functional Component
 */
class TelemetryProvider extends Component<IProps, tState> {
  constructor(props) {
    super(props);
    this.state = { initialized: false };
  }

  componentDidMount() {
    const { initialized } = this.state;
    const browserHistory: History = createBrowserHistory();
    const { instrumentationKey: AppInsightsInstrumentationKey, after } = this.props;
    if (
      !Boolean(initialized) &&
      Boolean(AppInsightsInstrumentationKey) &&
      Boolean(browserHistory)
    ) {
      ai.initialize(AppInsightsInstrumentationKey, browserHistory);
      this.setState({ initialized: true });
    }
    after();
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

export default withAITracking(ai.reactPlugin, TelemetryProvider);
