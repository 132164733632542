import { useState, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { VariableSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

import { useCurrentUser } from 'contexts/currentUser';
import { useMaps } from 'contexts/maps';
import { ButtonPrimary, Icon, Spinner, DeleteTaskModalWithData, Search } from 'components';
import Task from './Task';
import AddAdHocTaskForm from './AddAdHocTaskForm';
import { Container, Header, Title, NoTasks, Text } from './Tasks.style';
import useEditUi from 'hooks/useEditUi';
import { tGetServiceData } from 'types/services/services';
import { queryCacheName } from 'services/hooks/useTasks';
import { useQueryClient } from '@tanstack/react-query';

type tShowDeleteModal = {
  state: boolean;
  task: tGetServiceData | null;
};

const initialShowDeleteModal = {
  state: false,
  task: null,
};

const Tasks = () => {
  const { t } = useTranslation();
  const { user } = useCurrentUser();
  const queryClient = useQueryClient();
  const {
    tasks,
    loadingTasks,
    getTasks,
    loadMoreTasks,
    search,
    setSearch,
    refetchPageDelete,
    refetchEditedPage,
  } = useMaps();
  const [showDeleteModal, setShowDeleteModal] = useState<tShowDeleteModal>(initialShowDeleteModal);

  const listRef = useRef<VariableSizeList>(null);
  const sizeMap = useRef({});

  const { editMode, openEditForm, closeEditForm, editItem } = useEditUi();

  const onDeleteCancelHandler = () => {
    setShowDeleteModal(initialShowDeleteModal);
  };

  const onSuccessDeleteHandler = () => {
    onDeleteCancelHandler();
    const item = tasks.find((item) => item.id === showDeleteModal.task?.id);
    if (item) {
      refetchPageDelete(item);
      queryClient.invalidateQueries({ queryKey: [queryCacheName] }); // invalidate query for tasks list (Task statuses view)
    }
  };

  const onSuccessAddHandler = () => {
    getTasks(); // invalidate query for infinity scroll tasks list
    queryClient.invalidateQueries({ queryKey: [queryCacheName] }); // invalidate query for tasks list (Task statuses view)
    closeEditForm();
  };

  const onSuccessEditHandler = (taskId: string) => {
    const item = tasks.find((item) => item.id === taskId);

    if (item) {
      refetchEditedPage(item);
      queryClient.invalidateQueries({ queryKey: [queryCacheName] }); // invalidate query for tasks list (Task statuses view)
    }
    closeEditForm();
  };

  const handleScroll = ({ target }) => {
    const { scrollTop, scrollHeight, clientHeight } = target;

    if (!!listRef?.current) {
      listRef.current.scrollTo(scrollTop);

      const pad = 100; // 100px of the bottom
      const bottom = (scrollTop + pad) / (scrollHeight - clientHeight);
      if (bottom > 1) {
        loadMoreTasks();
      }
    }
  };

  const getSize = useCallback((index) => {
    return sizeMap.current[index] || 190;
  }, []);

  const setSize = useCallback((index, size) => {
    if (sizeMap.current[index] === size) return;
    sizeMap.current = { ...sizeMap.current, [index]: size + 20 };
    if (!!listRef?.current) {
      listRef.current.resetAfterIndex(0);
    }
  }, []);
  return (
    <Container>
      {loadingTasks && <Spinner />}
      {editMode.state && (
        <AddAdHocTaskForm
          onSuccess={onSuccessAddHandler}
          onEditSuccess={onSuccessEditHandler}
          onCancel={(state) => (state ? openEditForm() : closeEditForm())}
          editMode={editMode}
        />
      )}
      {showDeleteModal.state ? (
        <DeleteTaskModalWithData
          onSuccess={onSuccessDeleteHandler}
          onCancel={onDeleteCancelHandler}
          text={`${t('Are you sure you want to delete the job')}?`}
          task={showDeleteModal.task}
        />
      ) : null}
      <Header>
        <Title>{t('Tasks')}</Title>
        {!user?.isReadOnly ? (
          <ButtonPrimary handleClick={openEditForm} text={t('Add task')} />
        ) : null}
      </Header>
      <div style={{ marginBottom: '24px' }}>
        <Search
          value={search}
          onChange={({ target: { value } }) => setSearch(value)}
          resetValue={() => setSearch('')}
          forTasksList
        />
      </div>
      {!!tasks.length ? (
        <Scrollbars onScroll={handleScroll}>
          <AutoSizer>
            {({ height, width }) => (
              <VariableSizeList
                ref={listRef}
                height={height}
                itemCount={tasks.length}
                itemSize={getSize}
                style={{ overflow: 'unset' }}
                width={width - 30}
              >
                {({ index, style }) => (
                  <div style={{ ...style }}>
                    <Task
                      key={tasks[index].id}
                      task={tasks[index]}
                      setSize={(height: number) => setSize(index, height)}
                      remove={(task: tGetServiceData) =>
                        setShowDeleteModal({ ...showDeleteModal, state: true, task })
                      }
                      edit={editItem}
                      isReadOnly={!!user?.isReadOnly}
                    />
                  </div>
                )}
              </VariableSizeList>
            )}
          </AutoSizer>
        </Scrollbars>
      ) : (
        <NoTasks>
          <Icon iconName='noTasks' width='11.25rem' height='6.25rem' />
          <Text>{t('No tasks')}</Text>
        </NoTasks>
      )}
    </Container>
  );
};

export default Tasks;
