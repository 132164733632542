const convertFileType = (fileExtensions: string): string => {
  switch (fileExtensions) {
    case 'image/png':
    case 'png':
      return 'png';
    case 'image/jpeg':
    case 'jpg':
      return 'jpg';
    case 'application/pdf':
    case 'pdf':
      return 'pdf';
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    case 'application/msword':
    case 'docx':
    case 'doc':
      return 'doc';
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    case 'application/vnd.ms-excel':
    case 'xlsx':
    case 'xls':
      return 'xls';
    default:
      return fileExtensions;
  }
};

export default convertFileType;
