import { FC } from 'react';
import { createPortal } from 'react-dom';
import { ReactTooltipContainer, Title } from './Tooltip.style';

type tTooltip = {
  content: JSX.Element[] | JSX.Element | string;
  children: JSX.Element[] | JSX.Element | string;
  tipId: string | number;
  direction?: 'top' | 'right' | 'bottom' | 'left';
  title?: string;
  show?: boolean;
  renderInPortal?: boolean;
  offset?: number;
};

const Tooltip: FC<tTooltip> = ({
  children,
  tipId,
  content,
  direction = 'top',
  title = '',
  show = true,
  renderInPortal = true,
  offset,
}) => {
  const modalRoot = document.getElementById('modal-root') as HTMLElement;
  const tooltipComponent = (
    <ReactTooltipContainer id={`tip-${tipId}`} place={direction} offset={offset}>
      {!!title && <Title>{title}</Title>}
      {content}
    </ReactTooltipContainer>
  );
  return (
    <>
      <span data-tooltip-id={`tip-${tipId}`}>{children}</span>
      {show && (renderInPortal ? createPortal(tooltipComponent, modalRoot) : tooltipComponent)}
    </>
  );
};

export default Tooltip;
