import styled, { css } from 'styled-components';

type tIconButton = {
  $inline?: boolean;
};

export const Button = styled.button`
  background: transparent;
  border: 0;
  cursor: pointer;
  outline: 0;
`;

export const ButtonStyle = styled(Button)`
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 15px;
  padding: 8px 32px;
  font-weight: bold;
  outline: none;
  text-transform: uppercase;
  border-radius: 1rem;
`;

export const IconButton = styled(ButtonStyle)<tIconButton>`
  padding: 0;
  ${({ $inline }) =>
    $inline
      ? css`
          display: inline-block;
        `
      : css`
          display: inherit;
        `}
`;
