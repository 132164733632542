import { CancelToken } from 'axios';
import { format } from 'date-fns';
import i18n from 'i18next';

import setNotificationType from 'utils/setNotificationType';
import notificationsAgent, { tCreateDeviceRequest } from 'agents/notifications';
import { tNotification } from 'types/services/notifications';
import { tListParams } from 'types/global';
import { defaultDateFormat } from 'utils/constants';
import errorsHandler from './utils/errorsHandler';

const createDevice = (bodyRequest: tCreateDeviceRequest) =>
  notificationsAgent
    .createDevice(bodyRequest)
    .then(() => ({ state: true, errors: [], data: {} }))
    .catch((error) =>
      errorsHandler({
        defaultErrorMessage: i18n.t('Cannot register', { value: i18n.t('device form') }),
        error,
      }),
    );

const getNotifications = ({
  limit = 10,
  offset = 0,
  cancelToken,
}: tListParams & { cancelToken: CancelToken }) => {
  const params: tListParams = { offset };

  if (limit) params.limit = limit;

  return notificationsAgent.getNotifications(params, cancelToken).then((response) => {
    let transformedData: tNotification[] = [];

    const {
      data: { count, results },
    } = response;

    if (!!count) {
      transformedData = results.map((notificationData) => {
        const { created_at, id, message, read_at, type } = notificationData;

        return {
          id,
          type: setNotificationType(type),
          unread: !read_at,
          time: format(new Date(created_at), 'HH:mm'),
          date: format(new Date(created_at), defaultDateFormat),
          msg: message,
        };
      });
    }

    return {
      count,
      results: transformedData,
    };
  });
};

const getUnreadNotificationsCount = (cancelToken: CancelToken) =>
  notificationsAgent.getUnreadNotificationsCount(cancelToken).then((response) => {
    const {
      data: { unread_count },
    } = response;

    return unread_count;
  });

const setNotificationAsRead = (notificationId: string) =>
  notificationsAgent.setNotificationAsRead(notificationId);

const setNotificationAsUnread = (notificationId: string) =>
  notificationsAgent.setNotificationAsUnread(notificationId);

export default {
  createDevice,
  getNotifications,
  getUnreadNotificationsCount,
  setNotificationAsRead,
  setNotificationAsUnread,
};
