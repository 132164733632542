import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

import auditsService from 'services/audits';
import { tErrorsHandlerResponse } from 'services/utils/errorsHandler';
import { DeleteTaskModal, DisplayTaskData } from 'components';
import { Title } from 'components/Tooltip/Tooltip.style';
import notify from 'utils/notify';
import { tError } from 'types/global';
import { tTaskShortData } from 'types/services/tasks';
import { defaultDateFormat } from 'utils/constants';

import { ActionButton } from './AuditActionButtons.style';

type tAuditActionButtons = {
  task: tTaskShortData;
  onSuccess?: () => void;
  edit?: () => void;
};

const AuditActionButtons = ({
  task,
  onSuccess = () => {},
  edit = () => {},
}: tAuditActionButtons) => {
  const { t } = useTranslation();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const openDeleteModal = () => setShowDeleteModal(true);

  const closeDeleteModal = () => setShowDeleteModal(false);

  const removeAudit = async () => {
    if (!task.audit?.id) return;
    const result: tErrorsHandlerResponse = await auditsService.deleteAudit(task.audit.id);

    if (result.state) {
      onSuccess();
      closeDeleteModal();
    } else {
      const errors: string[] = result.errors.map((error: tError) => error.msg);

      if (!!errors.length) {
        errors.forEach((error) => notify({ copy: error, type: 'error' }));
      }
    }
  };

  return (
    <>
      {showDeleteModal ? (
        <DeleteTaskModal
          onConfirm={removeAudit}
          onCancel={closeDeleteModal}
          text={`${t('Are you sure you want to delete the audit')}?`}
          withReason={false}
        >
          <Title>{task?.audit?.survey?.name ?? '-'}</Title>
          <DisplayTaskData text={task.address} icon='place' />
          {!!task.audit?.planned_start_time && (
            <DisplayTaskData
              text={`${format(
                new Date(task.audit.planned_start_time),
                defaultDateFormat,
              )}; ${format(new Date(task.audit.planned_start_time), 'HH:mm')}`}
              icon='dateRange'
            />
          )}
          <div style={{ marginBottom: '1rem' }} />
        </DeleteTaskModal>
      ) : null}
      <ActionButton onClick={edit}>{t('Edit audit')}</ActionButton>
      <ActionButton onClick={openDeleteModal}>{t('Remove audit')}</ActionButton>
    </>
  );
};

export default AuditActionButtons;
