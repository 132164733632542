import { toast } from 'react-toastify';
import styled from 'styled-components';

import { toastAutoCloseTime } from 'utils/constants';
import { Icon } from 'components';
import { colorEclipse, colorLightSlateGrey } from 'styles/constants';
import { Flex, FlexElement } from 'styles/GlobalStyledComponents';
import setToastIconFill from 'utils/setToastIconFill';

export type tToastTypes =
  | 'alert'
  | 'error'
  | 'info'
  | 'success'
  | 'notificationAlarm'
  | 'notificationTimeDelay'
  | 'notificationAlarmResolved'
  | 'notificationStartAfterTime'
  | 'notificationDefault'
  | 'notificationMobileGroupAssigned'
  | 'notificationServiceAssigned'
  | 'notificationAuditNextDay';

type tNotify = {
  autoClose?: boolean;
  copy: string;
  type?: tToastTypes;
  bolded?: boolean;
  additionalInformation?: string | JSX.Element;
};

type tCopy = {
  $bolded: boolean;
};

const CopyWrapper = styled(Flex)`
  padding: 0 1rem;
  font-size: 14px;
  color: ${colorEclipse};
  line-height: 17px;
  display: flex;
  flex-direction: column;
`;

const Copy = styled.span<tCopy>`
  font-weight: ${({ $bolded }) => ($bolded ? 'bold' : 'normal')};
`;

const AdditionalInformation = styled.span`
  font-size: 12px;
  line-height: 15px;
  color: ${colorLightSlateGrey};
  margin-top: 3px;
`;

const notify = ({
  autoClose = false,
  copy,
  type = 'info',
  bolded = false,
  additionalInformation = '',
}: tNotify) =>
  toast(
    () => (
      <FlexElement>
        <Flex $flex={1}>
          <Icon iconName={type} width='1.5rem' height='1.5rem' fill={setToastIconFill(type)} />
        </Flex>
        <CopyWrapper $flex={11}>
          <Copy $bolded={bolded}>{copy}</Copy>
          {!!additionalInformation && (
            <AdditionalInformation>{additionalInformation}</AdditionalInformation>
          )}
        </CopyWrapper>
      </FlexElement>
    ),
    {
      ...(autoClose && { autoClose: toastAutoCloseTime }),
    },
  );

export default notify;
