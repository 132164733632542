import { ReactNode, FC } from 'react';

import { useSidebar } from 'contexts/sidebar';
import { Wrapper } from './PageWrapper.style';

type tPageWrapper = {
  children: ReactNode;
};

const PageWrapper: FC<tPageWrapper> = ({ children }): JSX.Element => {
  const { wideMenu } = useSidebar();
  return <Wrapper $wideMenu={wideMenu}>{children}</Wrapper>;
};

export default PageWrapper;
