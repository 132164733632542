import { useTranslation } from 'react-i18next';

// useDynamicTranslation hook is copied from https://dev.azure.com/gidcoders/Portal_Klienta/_git/web?path=/src/hooks/useDynamicTranslation.ts
// TODO: delete after openapi validation errors will be fixed

const valueRegex = /{{[^{{]+}}:[^{{]+:|{{[^{{]+}}/g;

// To extract values first we are replacing all the fragments in translation key
// with '[[;]]' (just a placeholder to split by), then we split resulting string using
// '[[;]]' as a separator.
export const extractValues = (
  row: { key: string; value: string; fragments: string[] },
  src: string,
) => {
  let tempString = src;
  row.fragments.forEach((fragment) => {
    tempString = tempString.replace(fragment, '[[;]]');
  });
  return tempString.split('[[;]]').filter((el) => !!el);
};

const useDynamicTranslation = () => {
  const i18nObj = useTranslation();
  const { i18n } = i18nObj;
  const translations = i18n?.getDataByLanguage(i18n.language)?.translation || {};

  // tMatrix - translations with added "fragments" key.
  // Fragments is an array with translation keys split by placeholder value.
  const tMatrix = Object.keys(translations).map((key) => ({
    key,
    value: translations[key],
    fragments: !!key.match(valueRegex) ? key.split(valueRegex).filter((el) => !!el) : [],
  }));

  const dt = (source: string) => {
    let selectedIndex = -1;

    // loop instead of array method because break keyword is needed
    for (const [index, row] of tMatrix.entries()) {
      // break the loop if source is not a dynamic translation and its translation is found
      if (row.key === source) break;
      // regExArray is an array of optional regex patterns used to describe what kind of
      // values we want with a particular translation
      const regExArray =
        row.key
          .match(valueRegex)
          ?.filter((match) => match.includes(':'))
          .map((match) => new RegExp(match.split(':')[1], 'g')) || [];
      // array of values to check against regex array
      const valuesArray = extractValues(row, source);
      // If source string includes all values and values match with regex array
      // selected index is set and we break the loop.
      //
      // Else if only fragments align with source we set the index and continue the loop
      // to see if there is any translation with regex matching value.
      if (
        row.fragments.length &&
        row.fragments.every((fragment) => source.includes(fragment)) &&
        regExArray.length &&
        valuesArray.every((value, index) => value.match(regExArray[index]))
      ) {
        selectedIndex = index;
        break;
      } else if (
        row.fragments.length &&
        row.fragments.every((fragment) => source.includes(fragment))
      ) {
        selectedIndex = index;
      }
    }

    if (selectedIndex === -1) return i18nObj.t(source);

    const row = tMatrix[selectedIndex];
    // sourceValues is a helper object used to store values with index and key for
    // the row they should end up in
    const sourceValuesObject: { key: string; value: string }[] = [];
    const matches = row.key.match(valueRegex);

    matches?.forEach((match) => {
      const valueName = match.replace('{{', '').replace('}}', '').split(':')[0];
      if (valueName) {
        sourceValuesObject.push({ key: valueName, value: '' });
      }
    });

    const extractedValues = extractValues(row, source);

    // If any value in extractedValues is in csv format we try to translate every value
    // separately, else we just assign the value to sourceValuesObject at correct index
    extractedValues.forEach((val, index) => {
      let tempValue = val;
      if (val.includes(',')) {
        tempValue = val
          .split(',')
          .map((string) =>
            i18nObj.t(string.substring(string.indexOf(`'`) + 1, string.lastIndexOf(`'`))),
          )
          .join(', ');
      }
      if (sourceValuesObject[index]) {
        sourceValuesObject[index].value = tempValue;
      }
    });

    // replacing placeholder values with real values
    let translatedString = row.value;
    sourceValuesObject.forEach(({ key, value }) => {
      translatedString = translatedString.replace(`{{${key}}}`, value);
    });
    return translatedString;
  };

  return { ...i18nObj, dt };
};

export default useDynamicTranslation;
