import { useTranslation } from 'react-i18next';

import { tPhotoModalDetails } from 'types/services/audits';
import { Icon } from 'components';
import { IconButton } from 'styles/button';
import {
  Container,
  DetailPropTitle,
  DetailPropValue,
  Title,
  TopSection,
  Wrapper,
} from './PhotoDetailsModal.style';

type tPhotoDetailsModal = {
  onClose: () => void;
  details: tPhotoModalDetails;
};

const PhotoDetailsModal = ({ onClose, details }: tPhotoDetailsModal) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Container>
        <TopSection>
          <Title>{t('Image')}</Title>
          <IconButton onClick={() => onClose()}>
            <Icon key='closePhotoDetailsModal' iconName='close' />
          </IconButton>
        </TopSection>
        <DetailPropTitle>{t('Audit name')}</DetailPropTitle>
        <DetailPropValue>{details.name}</DetailPropValue>
        <DetailPropTitle>{t('Question')}</DetailPropTitle>
        <DetailPropValue>{details.question}</DetailPropValue>
        <DetailPropTitle>{t('Image')}</DetailPropTitle>
        <img alt={details.name} src={`data:image/jpeg;charset=utf-8;base64,${details.imgBase64}`} />
      </Container>
    </Wrapper>
  );
};

export default PhotoDetailsModal;
