import { Error, Label } from 'components';
import { Container, Picker } from './RadioPicker.style';
import PickerItem from './PickerItem';
import { tDropdownOption } from 'types/global';

export type tOption<T extends string | number> = tDropdownOption<T>;

type tProps<T extends string | number> = {
  label?: string;
  options: tOption<T>[];
  value?: tOption<T> | null;
  onChange: (option: tOption<T>) => void;
  direction?: 'column' | 'row';
  error?: string;
};

const RadioPicker = <T extends string | number>({
  label = '',
  options = [],
  value = null,
  onChange = () => {},
  direction = 'column',
  error = '',
}: tProps<T>) => (
  <Container>
    <Label>{label}</Label>
    <Picker $direction={direction}>
      {options.map((option) => (
        <PickerItem
          key={option.value}
          option={option}
          checked={!!value ? value.value === option.value : false}
          onChange={onChange}
        />
      ))}
    </Picker>
    {!!error && <Error text={error} />}
  </Container>
);

export default RadioPicker;
