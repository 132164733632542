import { tTableTransformedItem } from 'types/global';

const transformDataForDropdownComponent = (array: readonly tTableTransformedItem[]) =>
  array.map((item: tTableTransformedItem) => {
    const {
      id: value,
      itemData: [, nameObj],
    } = item;
    const { value: label } = nameObj;

    return { value, label };
  });

export default transformDataForDropdownComponent;
