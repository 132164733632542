export const matchPriority = (priority: string) => {
  switch (priority) {
    case 'NORMALNY':
      return 'NORMAL' as const;
    case 'WYSOKI':
      return 'HIGH' as const;
    default:
      return priority;
  }
};
