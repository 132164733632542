import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import errorsSetter, { tErrorsSetter } from 'utils/errorsSetter';
import handleUnexpectedFormErrors, {
  handleExpectedFormErrors,
} from 'utils/handleUnexpectedFormErrors';
import notify from 'utils/notify';
import { Button } from 'styles/button';
import { useCurrentQueryCacheName } from 'services/hooks/useUser';

const ClearCacheButton = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  return (
    <Button
      style={{ padding: 0, color: 'inherit' }}
      onClick={async () => {
        queryClient.removeQueries({ queryKey: [useCurrentQueryCacheName], exact: true });
        await queryClient.refetchQueries();
      }}
    >
      {t('Clean cache')}
    </Button>
  );
};

const formErrorsHandler = (
  errors: tErrorsSetter['errors'],
  requiredFormFields: string[],
  formFields: tErrorsSetter['formFields'],
  dispatch: tErrorsSetter['dispatch'],
  matchFields = [] as tErrorsSetter['matchFields'],
) => {
  const unexpectedErrors = handleUnexpectedFormErrors(errors, requiredFormFields);
  const expectedErrors = handleExpectedFormErrors(errors, requiredFormFields);

  if (!!expectedErrors.length) {
    errorsSetter({
      errors: expectedErrors,
      formFields,
      dispatch,
      matchFields,
    });
  }

  if (!!unexpectedErrors.length) {
    unexpectedErrors.forEach((error) => {
      const additionalInformation = error.data?.status === 404 ? <ClearCacheButton /> : undefined;
      notify({
        copy: error.msg,
        type: 'error',
        additionalInformation,
      });
    });
  }
};

export default formErrorsHandler;
