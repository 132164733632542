import styled, { css } from 'styled-components';

import { colorGhostWhite, colorSlateBlue, colorSolitude } from 'styles/constants';

type tTableProps = {
  $stickyHeader?: boolean;
};

export const NoResultsCopy = styled.div`
  color: ${colorSlateBlue};
  font-size: 14px;
  font-weight: bold;
  margin-top: 12px;
`;

export const NoResultsCell = styled.td`
  padding: 10rem 1rem;
  text-align: center;
  @media screen and (max-height: 960px) {
    padding: 1rem;
  }
`;

export const TableActionCell = styled.td`
  padding: 16px;
  text-align: right;
`;

export const TableContainer = styled.table`
  min-width: 800px;
  border-collapse: collapse;
  border: 0.5px solid ${colorSolitude};
  font-size: 12px;
  width: 100%;
`;

export const TableCell = styled.td`
  padding: 16px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ParagraphWithSmallMargin = styled.p`
  margin: 2px 0;
`;

export const TableExpandCell = styled.td`
  padding: 16px;
  text-align: center;
`;

export const TableHead = styled.thead<tTableProps>`
  background: ${colorGhostWhite};
  color: ${colorSlateBlue};
  font-weight: bold;
  font-size: 14px;
  ${({ $stickyHeader }) =>
    $stickyHeader &&
    css`
      position: sticky;
      top: 0;
    `}
`;

export const TableRow = styled.tr`
  border-bottom: 0.5px solid ${colorSolitude};
`;

export const TableWrapper = styled.div<tTableProps>`
  ${({ $stickyHeader }) => !$stickyHeader && 'overflow-x: auto'};
`;
