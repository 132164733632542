import { ReactNode, useEffect, useReducer, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import axios from 'axios';
import { IMask } from 'react-imask';

import auditService from 'services/audits';
import { tResponse } from 'types/global';
import RadioPicker from 'components/RadioPicker/RadioPicker';
import { tScheduleEntry } from 'types/services/schedules';
import { ERROR, SAVE, invalidTimeFormat, timeFormatRegex } from 'utils/constants';
import { ButtonCancel, ButtonPrimary, DropDown, InputWithMask, FormModal } from 'components';
import addAssignAuditToSchedule, { initialState } from './reducers/AssignAuditToScheduleReducer';
import { Loader } from 'components/Spinner/Spinner.style';
import formErrorsHandler from 'utils/formErrorsHandler';
import { useAuditTemplatesForDropdown } from 'services/hooks/useAuditTemplates';
import auditTemplatesService from 'services/auditTemplates';
import { Wrapper } from './AssignAuditToSchedule.style';
import { tAddAuditRequest } from 'agents/audits';
import { InputFormContainer } from 'styles/GlobalStyledComponents';

type tAssignAuditToSchedule = {
  children: ReactNode;
  task: tScheduleEntry;
  onCancel?: () => void;
  onSuccess?: () => void;
};

const confirmationMethod = [
  { value: 'signature' as const, label: 'Podpis' },
  { value: 'no_confirmation' as const, label: 'bez potwierdzenia' },
];

const requiredFormFields = [
  'name',
  'confirmation_method',
  'planned_start_time',
  'start_time', // API response with start_time instead planned_start_time - twine
];

const AssignAuditToSchedule = ({
  children,
  task,
  onCancel = () => {},
  onSuccess = () => {},
}: tAssignAuditToSchedule) => {
  const { t } = useTranslation();
  const [formFields, dispatch] = useReducer(addAssignAuditToSchedule, initialState);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingData, setLoadingData] = useState<boolean>(true);

  const {
    auditTemplatesFullOptions: auditsTemplatesFullList,
    auditTemplatesOptions: auditsTemplatesList,
    fetchMoreAuditTemplates,
    auditTemplatesSearch,
    setAuditTemplatesSearch,
    isFetching: auditsTemplatesLoading,
  } = useAuditTemplatesForDropdown({});

  const isEdit = useMemo(() => !!task.audit, [task.audit]);

  useEffect(() => {
    if (!!task.audit) {
      const confirm_method = confirmationMethod.find(
        (method) => method.value === task.audit?.confirmation_method,
      );

      if (confirm_method) {
        dispatch({ type: SAVE, name: 'confirmation_method', value: confirm_method });
      }

      if (task.audit?.audit_survey_template_id) {
        dispatch({
          type: SAVE,
          name: 'audit',
          value: {
            value: task.audit.audit_survey_template_id,
            label: task.audit?.audit_survey_template_data?.survey_template?.name ?? '',
          },
        });
      }

      dispatch({
        type: SAVE,
        name: 'start_time',
        value: format(new Date(task.audit.planned_start_time), 'HH:mm'),
      });
    }
  }, [auditsTemplatesFullList]);

  const submit = async () => {
    setLoading(true);
    let errors = false;
    const {
      audit: { value: audit },
      start_time: { value: start_time },
      confirmation_method: { value: confirmation_method },
    } = formFields;

    if (!audit?.value) {
      dispatch({ type: ERROR, name: 'audit', error: t('Field required') });
      errors = true;
    }

    if (!start_time) {
      dispatch({ type: ERROR, name: 'start_time', error: t('Field required') });
      errors = true;
    } else if (!timeFormatRegex.test(start_time)) {
      dispatch({ type: ERROR, name: 'start_time', error: t(invalidTimeFormat) });
      errors = true;
    }

    if (!confirmation_method?.value) {
      dispatch({
        type: ERROR,
        name: 'confirmation_method',
        error: t('Field required'),
      });
      errors = true;
    }

    if (errors) {
      setLoading(false);
      return;
    }

    let singleAuditTemplate;
    if (!audit?.value) {
      dispatch({
        type: ERROR,
        name: 'audit',
        error: t('Field required'),
      });
      errors = true;
    } else {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      singleAuditTemplate = await auditTemplatesService
        .getAuditTemplate(
          {
            auditTemplateId: audit.value,
          },
          source.token,
        )
        .catch(() => {
          dispatch({
            type: ERROR,
            name: 'audit',
            error: t('No defined questions were found in the selected template'),
          });
          errors = true;
        });
    }

    if (errors) {
      setLoading(false);
      return;
    }

    const bodyRequest: tAddAuditRequest = {
      name: audit!.label,
      service: task.id,
      planned_start_time: new Date(
        `${format(new Date(task.since), 'yyyy-MM-dd')} ${start_time}:00`,
      ),

      survey: {
        template: audit!.value,
        questions:
          singleAuditTemplate?.survey_template?.question_templates?.map((question) => ({
            kind: question.kind,
            title: question.title,
            template: question.id,
          })) ?? [],
      },
      confirmation_method: confirmation_method!.value,
    };

    let result: tResponse | null = null;
    if (!!task.audit?.id) {
      result = await auditService.updateAudit(task.audit.id, bodyRequest);
    } else {
      result = await auditService.addAudit(bodyRequest);
    }

    const { state } = result;

    if (!state) {
      const matchFields = [
        { apiField: 'name', formField: 'audit' },
        { apiField: 'service', formField: 'audit' },
        { apiField: 'survey', formField: 'audit' },
        { apiField: 'planned_start_time', formField: 'start_time' },
      ];

      formErrorsHandler(
        result.errors,
        requiredFormFields,
        requiredFormFields,
        dispatch,
        matchFields,
      );
    }

    if (state) {
      onSuccess();
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!auditsTemplatesLoading) {
      setLoadingData(false);
    }
  }, [auditsTemplatesLoading]);

  return (
    <FormModal
      title={t(isEdit ? 'Edit audit' : 'Add audit')}
      buttons={
        !loadingData && (
          <>
            <ButtonCancel onClick={onCancel}>{t('Cancel')}</ButtonCancel>
            <ButtonPrimary
              handleClick={submit}
              disabled={loading}
              text={t(isEdit ? 'Save' : 'Add')}
            />
          </>
        )
      }
    >
      {loadingData ? (
        <Loader />
      ) : (
        <>
          <Wrapper>{children}</Wrapper>
          <DropDown
            value={formFields.audit.value}
            options={auditsTemplatesList}
            onChange={(option) => dispatch({ type: SAVE, name: 'audit', value: option })}
            label={t('Audit name')}
            isLoading={auditsTemplatesLoading}
            error={formFields.audit.error}
            isInfinityScroll
            infinityScrollFetchFunction={fetchMoreAuditTemplates}
            searchValue={auditTemplatesSearch}
            setSearchValue={setAuditTemplatesSearch}
            dataTestId='auditName__dropdownInput'
          />
          <InputFormContainer>
            <InputWithMask
              onChange={(value) => {
                dispatch({ type: SAVE, name: 'start_time', value });
              }}
              label='Audit start time'
              mask='HH:MM'
              blocks={{
                HH: { mask: IMask.MaskedRange, from: 0, to: 23 },
                MM: { mask: IMask.MaskedRange, from: 0, to: 59 },
              }}
              placeholder='hh:mm'
              value={formFields.start_time.value}
              error={formFields.start_time.error}
              dataTestId='auditStartTime__input'
            />
          </InputFormContainer>
          <RadioPicker
            label={t('The method of confirming the completion of the task')}
            options={confirmationMethod}
            onChange={(option) =>
              dispatch({ type: SAVE, name: 'confirmation_method', value: option })
            }
            value={formFields.confirmation_method.value}
            error={formFields.confirmation_method.error}
          />
        </>
      )}
    </FormModal>
  );
};

export default AssignAuditToSchedule;
