import { Tooltip as ReactTooltip } from 'react-tooltip';
import styled from 'styled-components';

import { boxShadowCommon, colorMagnolia, colorSlateBlue, zIndexTooltip } from 'styles/constants';

export const ReactTooltipContainer = styled(ReactTooltip)`
  background: ${colorMagnolia} !important;
  box-shadow: ${boxShadowCommon};
  color: ${colorSlateBlue} !important;
  font-size: 12px !important;
  line-height: 16px !important;
  max-width: 300px !important;
  opacity: 1 !important;
  padding: 12px !important;
  z-index: ${zIndexTooltip} !important;
  &.place-left {
    &:after {
      border-left-color: ${colorMagnolia} !important;
    }
  }
  &.place-right {
    &:after {
      border-right-color: ${colorMagnolia} !important;
    }
  }
  &.place-top {
    &:after {
      border-top-color: ${colorMagnolia} !important;
    }
  }
  &.place-bottom {
    &:after {
      border-bottom-color: ${colorMagnolia} !important;
    }
  }
`;

export const Title = styled.h3`
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 2px;
  &:first-letter {
    text-transform: uppercase;
  }
`;
