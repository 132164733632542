import styled from 'styled-components';

import { boxShadowTimeline, colorSlateBlue, zIndexFixed } from 'styles/constants';

export const Bullet = styled.div`
  background: ${colorSlateBlue};
  box-shadow: ${boxShadowTimeline};
  border-radius: 8px;
  height: 8px;
  left: -6.5px;
  position: absolute;
  top: -3px;
  width: 8px;
`;

export const Line = styled.div`
  background: ${colorSlateBlue};
  box-shadow: ${boxShadowTimeline};
  height: 2px;
  position: absolute;
  right: 0;
  width: 75%;
  z-index: ${zIndexFixed};
`;
