import { useTranslation } from 'react-i18next';

import { Icon, Modal, ButtonCancel, ButtonConfirm } from 'components';
import { Actions, Content, Text } from './ConfirmDelete.style';

type tProps = {
  onCancel: () => void;
  onConfirm: () => void;
  text: string;
  disabled?: boolean;
};

const ConfirmDelete = ({ onCancel, onConfirm, text, disabled = false }: tProps) => {
  const { t } = useTranslation();

  return (
    <Modal visible>
      <Content>
        <Icon iconName='deleteGraphic' width='11.25rem' height='6.25rem' />
        <Text>{text}</Text>
        <Actions>
          <ButtonCancel onClick={onCancel}>{t('Cancel')}</ButtonCancel>
          <ButtonConfirm disabled={disabled} onClick={onConfirm}>
            {t('Delete')}
          </ButtonConfirm>
        </Actions>
      </Content>
    </Modal>
  );
};

export default ConfirmDelete;
