import { AxiosResponse, CancelToken } from 'axios';
import i18n from 'i18next';

import qualificationsAgent, {
  tAddQualificationRequest,
  tGetQualificationsRequest,
  tUpdateQualificationRequest,
} from 'agents/qualifications';
import { tGetQualification } from 'types/services/qualifications';
import errorsHandler from './utils/errorsHandler';

const addQualification = (bodyRequest: tAddQualificationRequest) =>
  qualificationsAgent
    .addQualification(bodyRequest)
    .then((resp: AxiosResponse) => ({ state: true, errors: [], data: {}, status: resp.status }))
    .catch((error) =>
      errorsHandler({
        defaultErrorMessage: i18n.t('Cannot add', { value: i18n.t('qualification form') }),
        error,
      }),
    );

const updateQualification = (qualificationId: string, bodyRequest: tUpdateQualificationRequest) =>
  qualificationsAgent
    .updateQualification(qualificationId, bodyRequest)
    .then((resp: AxiosResponse) => ({ state: true, errors: [], data: {}, status: resp.status }))
    .catch((error) =>
      errorsHandler({
        defaultErrorMessage: i18n.t('Cannot edit', { value: i18n.t('qualification form') }),
        error,
      }),
    );

const deleteQualification = (qualificationId: string) => {
  const defaultError = [
    { key: '__general__', msg: i18n.t('Cannot remove', { value: i18n.t('qualification form') }) },
  ];

  return qualificationsAgent
    .deleteQualification(qualificationId)
    .then((resp: AxiosResponse) => ({ state: true, errors: [], data: {}, status: resp.status }))
    .catch((error) => ({ state: false, errors: error?.response?.data ?? defaultError, data: {} }));
};

const getQualification = ({ qualificationId }: { qualificationId: string }, cancelToken) =>
  qualificationsAgent.getQualification(qualificationId, cancelToken).then((response) => {
    const {
      data: { name },
    } = response;

    return {
      name,
    };
  });

const getQualifications = ({
  limit = 10,
  offset = 0,
  search = '',
  cancelToken,
}: tGetQualificationsRequest & { cancelToken: CancelToken }) => {
  const params: tGetQualificationsRequest = { offset };

  if (limit) params.limit = limit;
  if (search) params.search = search;

  return qualificationsAgent.getQualifications(params, cancelToken).then((response) => {
    let transformedData: tGetQualification[] = [];

    const {
      data: { count, results },
    } = response;

    if (!!count) {
      transformedData = results.map((qualificationData, index) => {
        const { id, name } = qualificationData;

        return {
          id,
          itemData: [
            { key: 'order', value: index + 1 + offset },
            { key: 'name', value: name },
          ],
        };
      });
    }

    return { count, results: transformedData };
  });
};

export default {
  addQualification,
  updateQualification,
  deleteQualification,
  getQualification,
  getQualifications,
};
