import styled from 'styled-components';

export const Actions = styled.div`
  align-self: flex-end;
  display: flex;
  margin-top: 1rem;
  & > * {
    margin-left: 0.5rem;
  }
`;

export const Content = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem 1.5rem;
  > svg {
    align-self: center;
  }
`;

export const Text = styled.p`
  margin: 1rem 0;
`;
