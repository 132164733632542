import styled, { css } from 'styled-components';
import {
  colorLightSlateGrey,
  colorWhite,
  colorSolitude,
  colorEchoBlue,
  colorRadicalRed,
} from 'styles/constants';
import { textInput, tProps } from 'styles/input';

export const Container = styled.div`
  margin-bottom: 1rem;
`;

export const TextArea = styled.textarea<tProps>`
  ${textInput};
  background: ${colorWhite} 0% 0% no-repeat padding-box;
  border: 1px solid ${({ $error }) => ($error ? colorRadicalRed : colorLightSlateGrey)};
  border-radius: 16px;
  outline: 0;
  ${({ disabled }) =>
    disabled &&
    css`
      background: ${colorSolitude};
      border-color: ${colorEchoBlue};
    `}
`;

export const Text = styled.p<tProps>`
  ${textInput};
  padding-left: 0;
  font-size: 12px;
  line-height: 16px;
  ${({ $error }) =>
    $error &&
    css`
      color: ${colorRadicalRed};
    `}
`;
