import styled from 'styled-components';
import {
  colorBlack,
  colorDodgerBlue,
  colorGrandis,
  colorLightSlateGrey,
  colorMediumSeaGreen,
  colorRadicalRed,
  colorSlateBlue,
  colorWhite,
} from 'styles/constants';
import { tStatuses } from 'agents/mobileGroups';

type tStatus = {
  $status?: tStatuses;
  $online?: boolean;
};

export const CalendarContainer = styled.div`
  .react-datepicker-wrapper {
    button {
      cursor: default;
    }
  }
`;

export const Container = styled.div`
  margin-top: 1rem;
`;

export const Header = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const HeaderSection = styled.div`
  align-items: flex-start;
  display: flex;
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 8px;
  svg {
    max-height: 14px;
    margin-right: 2px;
    fill: ${colorSlateBlue};
  }
`;

export const Item = styled.div`
  font-size: 12px;
  line-height: 15px;
  padding-left: 6px;
`;

export const List = styled.ul`
  margin: 0;
  padding-left: 20px;
`;

export const ListItem = styled.li`
  font-size: 12px;
  line-height: 15px;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`;

export const SectionTitle = styled.div`
  align-items: flex-start;
  color: ${colorSlateBlue};
  display: flex;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 8px;
  text-transform: uppercase;
  svg {
    max-height: 14px;
    margin-right: 2px;
    fill: ${colorSlateBlue};
  }
`;

export const Separator = styled.div`
  border-bottom: 1px solid ${colorSlateBlue};
`;

export const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`;

export const Status = styled.div<tStatus>`
  background: ${({ $status }) => {
    if ($status === 'free') return colorMediumSeaGreen;
    if ($status === 'temporarily_free') return colorGrandis;
    if ($status === 'accident') return colorRadicalRed;
    if ($status === 'busy') return colorDodgerBlue;
    return colorBlack;
  }};
  border-radius: 16px;
  color: ${colorWhite};
  display: flex;
  flex-direction: column;
  font-size: 10px;
  font-weight: bold;
  line-height: 18px;
  margin-bottom: 8px;
  padding: 0 10px;
  text-transform: uppercase;
`;

export const CircleOnlineStatus = styled.div<tStatus>`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: ${(props) => (props.$online ? colorMediumSeaGreen : colorRadicalRed)};
`;

export const OnlineStatus = styled.p`
  margin: 0;
  margin-left: 8px;
  font-size: 12px;
  color: ${colorBlack};
`;

export const Title = styled.div`
  font-size: 14px;
  font-weight: bold;
  line-height: 17px;
  margin-bottom: 8px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Lato, sans-serif;
  height: 100%;
  width: 100%;
`;

export const RedirectSvgContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: -5px;
  svg {
    fill: ${colorLightSlateGrey};
    max-height: 100%;
  }
`;
