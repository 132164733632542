import { BrowserRouter } from 'react-router-dom';
import initializeInternalization from 'i18n';

import { initSentry } from 'utils/sentry';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createRoot } from 'react-dom/client';
import { tGenericObject } from 'types/global';

declare global {
  interface Window {
    __env__: tGenericObject & {
      FIREBASE?: tGenericObject;
    };
  }
}

initSentry();
initializeInternalization();

const element = document.getElementById('root');
const root = createRoot(element as HTMLElement);

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
