import { CancelToken } from 'axios';
import i18n from 'i18next';

import locationsAgent, {
  tAddLocationRequest,
  tAddress,
  tGetLocationsRequest,
  tPhoneNumber,
  tUpdateLocationRequest,
} from 'agents/locations';
import { tDictionary, tDropdownOption } from 'types/global';
import errorsHandler from './utils/errorsHandler';
import { helperPhones } from 'views/Locations/reducers/addLocationFormReducer';
import { tGetLocationsList } from 'types/services/locations';

type tSearch = {
  search: string;
};

const deleteLocation = (locationId: string) => {
  const defaultError = [
    { key: '__general__', msg: i18n.t('Cannot remove', { value: i18n.t('location form') }) },
  ];

  return locationsAgent
    .deleteLocation(locationId)
    .then((resp) => ({ state: true, errors: [], data: {}, status: resp.status }))
    .catch((error) => ({ state: false, errors: error?.response?.data ?? defaultError, data: {} }));
};

const addLocation = (bodyRequest: tAddLocationRequest) =>
  locationsAgent
    .addLocation(bodyRequest)
    .then((resp) => ({ state: true, errors: [], data: {}, status: resp.status }))
    .catch((error) =>
      errorsHandler({
        defaultErrorMessage: i18n.t('Cannot add', { value: i18n.t('location form') }),
        error,
      }),
    );

const updateLocation = (locationId: string, bodyRequest: tUpdateLocationRequest) =>
  locationsAgent
    .updateLocation(locationId, bodyRequest)
    .then((resp) => ({ state: true, errors: [], data: {}, status: resp.status }))
    .catch((error) =>
      errorsHandler({
        defaultErrorMessage: i18n.t('Cannot edit', { value: i18n.t('location form') }),
        error,
      }),
    );

const getLocation = ({ locationId }: { locationId: string }, cancelToken) =>
  locationsAgent.getLocation(locationId, cancelToken).then((response) => {
    const {
      data: { name, address, phone_numbers, contracts, external_system_id, commercial_district },
    } = response;

    const transformedContracts: tDropdownOption<string>[] = !contracts.length
      ? []
      : contracts.map(({ id, name }) => ({
          label: name,
          value: id,
        }));

    const idx = phone_numbers.findIndex((item) => item.main === true);
    const transformedPhoneNumbers: tPhoneNumber[] = [...phone_numbers];
    if (idx !== -1) {
      transformedPhoneNumbers.splice(idx, 1);
      transformedPhoneNumbers.splice(0, 0, phone_numbers[idx]);
    } else {
      transformedPhoneNumbers.splice(0, 0, helperPhones);
    }
    return {
      name,
      address: { label: address.formatted_address, value: address },
      phone_numbers: transformedPhoneNumbers,
      contracts: transformedContracts,
      external_system_id,
      commercial_district,
    };
  });

const getLocations = ({
  limit = 10,
  offset = 0,
  search = '',
  product_id = '',
  contract_id = '',
  cancelToken,
}: tGetLocationsRequest & { cancelToken: CancelToken }) => {
  const params: tGetLocationsRequest = { offset };

  if (limit) params.limit = limit;
  if (search) params.search = search;
  if (product_id) params.product_id = product_id;
  if (contract_id) params.contract_id = contract_id;

  return locationsAgent.getLocations(params, cancelToken).then((response) => {
    let transformedData: tGetLocationsList[] = [];

    const {
      data: { count, results },
    } = response;

    if (!!count) {
      transformedData = results.map((locationData, index) => {
        const {
          id,
          name,
          phone_numbers,
          address: { formatted_address },
          contracts,
          commercial_district,
        } = locationData;

        return {
          id,
          itemData: [
            { key: 'order', value: index + 1 + offset },
            { key: 'name', value: name },
            {
              key: 'address',
              value: formatted_address ?? '-',
            },
            { key: 'phoneNumbers', value: phone_numbers },
          ],
          extraInfo: {
            contracts: !!contracts.length
              ? contracts.map((contract: tDictionary) => ({
                  id: contract.id,
                  name: contract.name,
                }))
              : [],
            commercial_district,
          },
        };
      });
    }

    return { count, results: transformedData };
  });
};

const getAddresses = ({ search, cancelToken }: tSearch & { cancelToken: CancelToken }) => {
  const params: tSearch = { search };

  return locationsAgent.getAddresses(params, cancelToken).then((response) => {
    let transformedData: tDropdownOption<tAddress>[] = [];

    const {
      data: { count, results },
    } = response;

    if (!!count) {
      transformedData = results.map((addressData) => {
        const { formatted_address } = addressData;

        return {
          label: formatted_address || '',
          value: addressData,
        };
      });
    }

    return { count, results: transformedData };
  });
};

export default {
  deleteLocation,
  addLocation,
  updateLocation,
  getLocation,
  getLocations,
  getAddresses,
};
