import i18n from 'i18next';

import { fieldRequired, invalidTimeFormat, timeFormatRegex } from 'utils/constants';
import { addAdHocTaskFormError } from 'components/Tasks/utils/addAdHocTaskFormError';
import { ERROR } from 'utils/constants';

export const timeTest = (fieldValue, fieldName, dispatch) => {
  if (!fieldValue) {
    dispatch({ type: ERROR, name: fieldName, error: i18n.t(fieldRequired) });
    addAdHocTaskFormError[fieldName].errors = true;
  } else if (!timeFormatRegex.test(fieldValue)) {
    dispatch({ type: ERROR, name: fieldName, error: i18n.t(invalidTimeFormat) });
    addAdHocTaskFormError[fieldName].errors = true;
  } else {
    addAdHocTaskFormError[fieldName].errors = false;
  }
};

export const checkNestedValueField = (fieldValue, fieldName, dispatch) => {
  if (!fieldValue?.value?.length) {
    dispatch({ type: ERROR, name: fieldName, error: i18n.t(fieldRequired) });
    addAdHocTaskFormError[fieldName].errors = true;
  } else {
    addAdHocTaskFormError[fieldName].errors = false;
  }
};

export const checkValueField = (fieldValue, fieldName, dispatch) => {
  if (!fieldValue?.value && !fieldValue?.length) {
    dispatch({ type: ERROR, name: fieldName, error: i18n.t(fieldRequired) });
    addAdHocTaskFormError[fieldName].errors = true;
  } else {
    addAdHocTaskFormError[fieldName].errors = false;
  }
};

export const checkValueArrayField = (fieldValue, fieldName, dispatch) => {
  if (!fieldValue || !fieldValue?.length) {
    dispatch({ type: ERROR, name: fieldName, error: i18n.t(fieldRequired) });
    addAdHocTaskFormError[fieldName].errors = true;
  } else {
    addAdHocTaskFormError[fieldName].errors = false;
  }
};

export const checkValueTimeField = (fieldValue, fieldName, dispatch) => {
  if (!fieldValue) {
    dispatch({ type: ERROR, name: fieldName, error: i18n.t(fieldRequired) });
    addAdHocTaskFormError[fieldName].errors = true;
  } else {
    addAdHocTaskFormError[fieldName].errors = false;
  }
};

export const checkValueFieldWithCondition = (fieldValue, fieldName, condition, dispatch) => {
  if (condition && !fieldValue?.value) {
    dispatch({ type: ERROR, name: fieldName, error: i18n.t(fieldRequired) });
    addAdHocTaskFormError[fieldName].errors = true;
  } else {
    addAdHocTaskFormError[fieldName].errors = false;
  }
};

export const checkServiceName = (fieldValue, fieldName, helpingValue, condition, dispatch) => {
  if (!helpingValue && !condition && !fieldValue) {
    dispatch({ type: ERROR, name: fieldName, error: i18n.t(fieldRequired) });
    addAdHocTaskFormError[fieldName].errors = true;
  } else {
    addAdHocTaskFormError[fieldName].errors = false;
  }
};

export const checkStepsField = (fieldValue, fieldName, dispatch) => {
  if (!fieldValue.every((step) => !!step.type?.value && !!step.name)) {
    dispatch({ type: ERROR, name: fieldName, error: i18n.t(fieldRequired) });
    addAdHocTaskFormError[fieldName].errors = true;
  } else {
    addAdHocTaskFormError[fieldName].errors = false;
  }
};

export const checkErrorsInForm = (object, value) =>
  !!Object.keys(object).find((key) => object[key].errors === value);
