import { useState } from 'react';
import { tEditMode, tTableTransformedItem } from 'types/global';

const useEditUi = () => {
  const [editMode, setEditMode] = useState<tEditMode>({
    state: false,
    itemId: '',
  });

  const openEditForm = () => setEditMode({ state: true, itemId: '' });

  const closeEditForm = () => setEditMode({ state: false, itemId: '' });

  const editItem = (item: tTableTransformedItem) => {
    setEditMode({ state: true, itemId: item.id });
  };

  return { editMode, openEditForm, closeEditForm, editItem };
};

export default useEditUi;
