import { createContext, useContext } from 'react';
import { tDropdownOption } from 'types/global';
import { tCurrentUser } from 'types/services/users';

export type tCurrentUserContextType = {
  user: tCurrentUser | null;
  roles: tDropdownOption<string>[];
};

export const CurrentUserContext = createContext<tCurrentUserContextType>({ user: null, roles: [] });
export const useCurrentUser = () => useContext(CurrentUserContext);
