import { ReactNode, FC } from 'react';

import setChipBackground from './utils/setChipBackground';
import { ChipElement } from './Chip.style';

type tChip = {
  buttons?: ReactNode;
  copy?: string;
  isUpper?: boolean;
  variant?: string;
};

const Chip: FC<tChip> = ({ buttons = null, copy = '', isUpper = false, variant = 'purple' }) => (
  <ChipElement
    $isUpper={isUpper}
    style={{
      background: setChipBackground(variant),
    }}
  >
    {copy}
    {!!buttons && <>{buttons}</>}
  </ChipElement>
);

export default Chip;
