import { CancelToken } from 'axios';
import fetchProvider from 'utils/Axios';
import { tDictionary, tListParams } from 'types/global';

const microservicePath = '/qualifications';

type tReadQualification = tDictionary;
type tWriteQualification = Pick<tDictionary, 'name'>;

type tDeleteQualificationResponse = tReadQualification;

export type tAddQualificationRequest = tWriteQualification;
type tAddQualificationResponse = tReadQualification;

type tGetQualificationResponse = tReadQualification;

export type tGetQualificationsRequest = tListParams;
type tGetQualificationsResponse = { count: number; results: tReadQualification[] };

export type tUpdateQualificationRequest = tWriteQualification;
type tUpdateQualificationResponse = tReadQualification;

const qualifications = {
  deleteQualification: (qualificationId: string) =>
    fetchProvider.delete<tDeleteQualificationResponse>(`${microservicePath}/${qualificationId}`),
  addQualification: (bodyRequest: tAddQualificationRequest) =>
    fetchProvider.post<tAddQualificationResponse>(`${microservicePath}`, bodyRequest),
  getQualification: (qualificationId: string, cancelToken: CancelToken) =>
    fetchProvider.get<tGetQualificationResponse>(`${microservicePath}/${qualificationId}`, {
      cancelToken,
    }),
  getQualifications: (params: tGetQualificationsRequest, cancelToken: CancelToken) =>
    fetchProvider.get<tGetQualificationsResponse>(`${microservicePath}`, { params, cancelToken }),
  updateQualification: (qualificationId: string, bodyRequest: tUpdateQualificationRequest) =>
    fetchProvider.put<tUpdateQualificationResponse>(
      `${microservicePath}/${qualificationId}`,
      bodyRequest,
    ),
};

export default qualifications;
