import styled from 'styled-components';
import {
  colorSlateBlue,
  gradientPurple,
  colorWhite,
  colorRadicalRed,
  colorGhostWhite,
  boxShadowLight,
} from 'styles/constants';
import { Button } from 'styles/button';

type tContainer = {
  $active: boolean;
};
export const Container = styled.div<tContainer>`
  background: ${colorGhostWhite} 0% 0% no-repeat padding-box;
  box-shadow: ${boxShadowLight};
  border: 2px solid ${({ $active }) => ($active ? colorSlateBlue : 'transparent')};
  border-radius: 8px;
  opacity: 1;
  padding: 16px;
  margin-bottom: 12px;
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${colorSlateBlue};
`;

export const Title = styled.h3`
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  flex: 1;
  text-align: left;
`;

export const Actions = styled.div`
  display: flex;
  margin-bottom: 0.5rem;
`;

const ButtonStyle = styled(Button)`
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  svg {
    fill: ${colorWhite};
  }
`;

export const Edit = styled(ButtonStyle)`
  background: ${gradientPurple};
`;

export const Remove = styled(ButtonStyle)`
  background: ${colorRadicalRed};
  margin-left: 8px;
`;

export const Main = styled.main`
  display: flex;
  flex-direction: column;
`;
