import { FC, useMemo } from 'react';

import { useCurrentUser } from 'contexts/currentUser';
import { Icon, NotificationsPanel } from 'components';
import { FlexElement } from 'styles/GlobalStyledComponents';
import { IconButton } from 'styles/button';
import { colorSlateBlue } from 'styles/constants';
import { isAuditor, isOperator } from 'utils/roleHelpers';
import {
  Bar,
  IconsContainer,
  Logo,
  LogoContainer,
  MenuButtonContainer,
  RoleCopy,
  UserCopy,
  UserPanel,
} from './Topbar.style';

type tTopbar = {
  setShowRwdMenu: () => void;
};

const Topbar: FC<tTopbar> = ({ setShowRwdMenu }) => {
  const { user } = useCurrentUser();

  const canGetNotifications = useMemo(
    () => !!user?.systemRole && (isOperator(user?.systemRole) || isAuditor(user?.systemRole)),
    [user?.systemRole],
  );

  return (
    <Bar>
      <LogoContainer>
        <Logo />
      </LogoContainer>
      <FlexElement>
        <IconsContainer>
          {canGetNotifications && <NotificationsPanel />}
          <MenuButtonContainer>
            <IconButton onClick={setShowRwdMenu}>
              <Icon fill={colorSlateBlue} iconName='menu' />
            </IconButton>
          </MenuButtonContainer>
        </IconsContainer>
        {!!user && (
          <FlexElement>
            <Icon iconName='userAccount' width='2rem' height='2rem' fill={colorSlateBlue} />
            <UserPanel>
              <UserCopy>
                {user.firstName} {user.lastName}
              </UserCopy>
              <RoleCopy>{user.role}</RoleCopy>
            </UserPanel>
          </FlexElement>
        )}
      </FlexElement>
    </Bar>
  );
};

export default Topbar;
