import styled, { css } from 'styled-components';

import { colorEchoBlue, colorRadicalRed } from 'styles/constants';

type tContainer = {
  $gap: boolean;
  $isRemovable: boolean;
  $error: boolean;
};

export const Container = styled.div<tContainer>`
  border: 1px solid ${({ $error }) => ($error ? colorRadicalRed : colorEchoBlue)};
  border-radius: 8px;
  padding: 12px;
  padding-top: ${({ $isRemovable }) => ($isRemovable ? '36px' : '12px')};
  ${({ $gap }) =>
    $gap &&
    css`
      margin-bottom: 1rem;
    `};
`;
