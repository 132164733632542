import { Icon } from 'components';
import { IconButton } from 'styles/button';
import { colorSlateBlue } from 'styles/constants';
import { tDetail } from './Table';

type tExpandingButtonPanelProps = {
  detail: null | tDetail;
  onClick: () => void;
  active: boolean;
  dataId?: string;
};

const ExpandingButtonPanel = ({ detail, onClick, active, dataId }: tExpandingButtonPanelProps) => {
  if (!detail) return null;
  const { iconDefault = 'arrowDown', iconOnShow = 'arrowUp' } = detail;

  return (
    <IconButton onClick={onClick}>
      <Icon
        iconName={active ? iconOnShow : iconDefault}
        fill={colorSlateBlue}
        extraStyles={{ margin: '0 8px' }}
        dataId={dataId ? `${dataId}-${active ? 'open' : 'close'}` : undefined}
      />
    </IconButton>
  );
};

export default ExpandingButtonPanel;
