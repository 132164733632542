import { useEffect } from 'react';
import axios from 'axios';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import taskService from 'services/services';
import { onError } from 'utils/queryClient';
import { defaultCacheStaleTime } from 'services/utils/constants';

type tUseTaskArgs = {
  itemId: string;
};

export const queryCacheName = 'getTask';

const useTask = ({ itemId }: tUseTaskArgs) => {
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey: [queryCacheName, itemId],
    queryFn: ({ signal }) => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      const promise = taskService.getService(
        {
          id: itemId,
        },
        source.token,
      );

      // Cancel the request if TanStack Query signals to abort
      signal?.addEventListener('abort', () => {
        source.cancel('Query was cancelled by TanStack Query');
      });

      return promise;
    },
    staleTime: defaultCacheStaleTime,
    enabled: !!itemId,
  });

  const refetch = () => queryClient.invalidateQueries({ queryKey: [queryCacheName] });

  const invalidateQuery = () =>
    queryClient.invalidateQueries({ queryKey: [queryCacheName, itemId], refetchType: 'none' });

  useEffect(() => {
    if (query.isError) onError(query.error);
  }, [query.isError, query.error]);

  return {
    ...query,
    refetch,
    invalidateQuery,
  };
};

export default useTask;
