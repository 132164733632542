import { FC } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';

import { pl } from 'date-fns/locale/pl';
import { defaultDateFormat } from 'utils/constants';
import { Container, DatePickerWrapper } from './Datepicker.style';
import CustomDatePickerContainer from './utils/CustomDatePickerContainer';
import CustomDatePickerInput from './utils/CustomDatePickerInput';
import DatePickerPrevNextArrows from './utils/DatePickerPrevNextArrows';
import setDay from './utils/setDay';

require('react-datepicker/dist/react-datepicker.css');

const daysPL: string[] = ['ND', 'PN', 'WT', 'ŚR', 'CZ', 'PT', 'SO'];

registerLocale('_pl', {
  ...pl,
  localize: { ...pl.localize, day: (day: number) => daysPL[day] },
});

type tCalendarDatePicker = {
  dateFormat?: string;
  inline?: boolean;
  onChange: (dates: [Date, null | Date]) => void;
  startDate: Date;
  endDate?: null | Date;
  selectsRange?: boolean;
  showArrows?: boolean;
  disabled?: boolean;
  center?: boolean;
  readOnly?: boolean;
  minDate?: Date;
  position?: string;
};

const CalendarDatePicker: FC<tCalendarDatePicker> = ({
  dateFormat = defaultDateFormat,
  inline = false,
  onChange,
  startDate,
  endDate = null,
  selectsRange = false,
  showArrows = true,
  disabled = false,
  center = true,
  readOnly = false,
  minDate = null,
  position = '',
}) => (
  <Container>
    {!(inline || selectsRange || !showArrows) && (
      <DatePickerPrevNextArrows
        onClick={() => onChange([setDay(startDate, -1), null])}
        type='prev'
      />
    )}
    <DatePickerWrapper $center={center} $position={position}>
      <DatePicker
        calendarContainer={!(inline || selectsRange) ? CustomDatePickerContainer : null}
        customInput={<CustomDatePickerInput center={center} disabled={disabled} />}
        selected={startDate}
        startDate={startDate}
        endDate={endDate}
        inline={inline || selectsRange}
        onChange={selectsRange ? onChange : (date: Date) => onChange([date, null])}
        dateFormat={dateFormat}
        locale='_pl'
        showPopperArrow={false}
        selectsRange={selectsRange}
        disabled={disabled}
        readOnly={readOnly}
        minDate={minDate}
      />
    </DatePickerWrapper>
    {!(inline || selectsRange || !showArrows) && (
      <DatePickerPrevNextArrows
        onClick={() => onChange([setDay(startDate, 1), null])}
        type='next'
      />
    )}
  </Container>
);

export default CalendarDatePicker;
