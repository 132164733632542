import { FC } from 'react';

import { Icon } from 'components';
import { IconButton } from 'styles/button';
import { colorSlateBlue } from 'styles/constants';

type tDatePickerPrevNextArrows = {
  onClick: () => void;
  type: 'prev' | 'next';
};

const DatePickerPrevNextArrows: FC<tDatePickerPrevNextArrows> = ({ onClick, type = 'prev' }) => (
  <IconButton $inline onClick={onClick} style={{ marginTop: '3px', marginRight: '4px' }}>
    <Icon iconName={type === 'prev' ? 'navigateBefore' : 'navigateNext'} fill={colorSlateBlue} />
  </IconButton>
);

export default DatePickerPrevNextArrows;
