import styled, { css } from 'styled-components';

import iconDone from 'icons/done-24px.svg';
import { colorEchoBlue, colorSlateBlue } from 'styles/constants';

type tInputStyle = {
  checked: boolean;
  disabled: boolean;
};

type tLabel = {
  $isBold: boolean;
};

export const InputContainer = styled.label`
  align-items: center;
  cursor: pointer;
  display: flex;
`;

export const InputStyle = styled.input.attrs({ type: 'checkbox' })<tInputStyle>`
  appearance: none;
  border: 2px solid ${colorEchoBlue};
  border-radius: 2px;
  cursor: pointer;
  display: block;
  height: 16px;
  outline: 0;
  position: relative;
  transition: all 150ms ease;
  min-width: 16px;
  ${({ checked }) =>
    checked
      ? css`
          background: ${colorSlateBlue};
          border-color: ${colorSlateBlue};
          &:after {
            background: url(${iconDone}) center center / 16px no-repeat;
            content: '';
            height: 100%;
            position: absolute;
            width: 100%;
          }
        `
      : css``}
  ${({ disabled }) =>
    disabled
      ? css`
          border-color: ${colorEchoBlue};
          cursor: not-allowed;
          &:checked {
            background: ${colorEchoBlue};
          }
        `
      : css``}
`;

export const Label = styled.div<tLabel>`
  font-size: 12px;
  margin-left: 12px;
  ${({ $isBold }) =>
    $isBold
      ? css`
          font-weight: bold;
        `
      : css`
          font-weight: normal;
        `}
`;
