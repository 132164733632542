import React, { StrictMode } from 'react';
import * as Sentry from '@sentry/react';

import { ToastContainer } from 'react-toastify';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';

import 'react-toastify/dist/ReactToastify.css';

import { Icon, KeycloakProvider, ErrorBoundary } from 'components';
import { colorRadicalRed } from 'styles/constants';
import { getAppInsights } from 'services/telemetry';
import TelemetryProvider from 'utils/telemetry-provider';

import { IconButtonContainer } from 'components/Maps/GroupSchedule.style';
import AppRouter from './AppRouter';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => {
  return (
    <KeycloakProvider>
      <StrictMode>
        <TelemetryProvider
          // eslint-disable-next-line no-underscore-dangle
          instrumentationKey={window?.__env__?.INSTRUMENTATION_KEY ?? null}
          after={getAppInsights}
        >
          <ErrorBoundary>
            <QueryClientProvider client={queryClient}>
              <ToastContainer
                autoClose={false}
                closeButton={({ closeToast }) => (
                  <IconButtonContainer
                    onClick={(e: React.MouseEvent<HTMLElement>) => closeToast(e)}
                    style={{ top: '12px', right: '12px' }}
                  >
                    <Icon
                      iconName='close'
                      width='1.25rem'
                      height='1.25rem'
                      fill={colorRadicalRed}
                    />
                  </IconButtonContainer>
                )}
                closeOnClick={false}
                draggable={false}
                hideProgressBar
                newestOnTop
                position='bottom-right'
                rtl={false}
              />
              <AppRouter />
            </QueryClientProvider>
          </ErrorBoundary>
        </TelemetryProvider>
      </StrictMode>
    </KeycloakProvider>
  );
};

export default Sentry.withProfiler(App, { name: 'App' });
