import styled, { css } from 'styled-components';
import { colorRadicalRed, colorBlack } from 'styles/constants';

export type tProps = {
  disabled?: boolean;
  $error?: boolean;
};

export const textInput = css`
  min-height: 24px;
  width: 100%;
  box-sizing: border-box;
  padding: 9px 16px;
`;

export const ReadOnly = styled.p<tProps>`
  ${textInput}
  padding: 0;
  font-size: 12px;
  line-height: 16px;
  color: ${colorBlack};
  margin: 0;
  ${({ $error }) =>
    $error &&
    css`
      color: ${colorRadicalRed};
    `}
`;
