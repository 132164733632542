import styled from 'styled-components';
import { colorRadicalRed } from 'styles/constants';

export const Wrapper = styled.div`
  align-items: flex-start;
  display: flex;
  margin-top: 4px;
  svg {
    fill: ${colorRadicalRed};
  }
`;

export const Text = styled.span`
  color: ${colorRadicalRed};
  flex: 1;
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  margin-left: 4px;
`;
