import { useCallback, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { useInfiniteQuery, useQuery, useQueryClient } from '@tanstack/react-query';

import usersService from 'services/users';
import { tParams } from 'types/global';
import { defaultCacheStaleTime } from 'services/utils/constants';
import { onError } from 'utils/queryClient';
import useDebounce from 'hooks/useDebounce';
import { formDataLimit } from 'utils/constants';
import transformDataForDropdownComponent from 'utils/transformDataForDropdownComponent';

type tUseUsersArgs = {
  params: tParams;
};

export const queryCacheName = 'getUsers';

type tParamForDropdown = {
  enabled?: boolean;
};

type tUseCarsForDropdown = {
  enabled: boolean;
  roleId: string;
};
const useUsers = ({ params }: tUseUsersArgs) => {
  const queryClient = useQueryClient();
  const query = useQuery({
    // eslint-disable-next-line max-len
    queryKey: [queryCacheName, params], // TODO name kind depends on service method or create in service name constants????
    queryFn: ({ signal }) => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      const promise = usersService.getUsers({
        ...params,
        cancelToken: source.token,
      });

      // Cancel the request if TanStack Query signals to abort
      signal?.addEventListener('abort', () => {
        source.cancel('Query was cancelled by TanStack Query');
      });

      return promise;
    },
    staleTime: defaultCacheStaleTime, // TODO what length to set by default or per view
  });

  const count = useMemo(() => query?.data?.count ?? 0, [query?.data]);

  const refetchList = () => queryClient.invalidateQueries({ queryKey: [queryCacheName] });

  const invalidateQuery = () =>
    queryClient.invalidateQueries({ queryKey: [queryCacheName], refetchType: 'none' });

  useEffect(() => {
    if (query.isError) onError(query.error);
  }, [query.isError, query.error]);

  return {
    ...query,
    data: query?.data?.results ?? [],
    count,
    refetchList,
    invalidateQuery,
  };
};

export const queryCacheNameForRoles = 'getRoles';

export const useRolesForDropdown = ({ enabled = true }: tParamForDropdown) => {
  const [roleSearch, setRoleSearch] = useState('');
  const debouncedRoleSearch = useDebounce({ searchValue: roleSearch });

  const { data, isFetching, isError, error } = useQuery({
    queryKey: [queryCacheNameForRoles, debouncedRoleSearch],
    queryFn: async ({ signal }) => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      const promise = usersService.getRoles({
        offset: 0,
        limit: 100,
        search: debouncedRoleSearch,
        cancelToken: source.token,
      });

      // Cancel the request if TanStack Query signals to abort
      signal?.addEventListener('abort', () => {
        source.cancel('Query was cancelled by TanStack Query');
      });

      return promise;
    },
    staleTime: defaultCacheStaleTime, // TODO what length to set by default or per view
    enabled,
  });

  const rolesOptions = useMemo(() => data?.results || [], [data]);

  useEffect(() => {
    if (isError) onError(error);
  }, [isError, error]);

  return {
    data,
    rolesOptions,
    isFetching,
    roleSearch,
    setRoleSearch,
  };
};

export const queryCacheNameForInfiniteUsers = 'getInfiniteUsers';

export const useUsersForDropdown = ({ enabled = true, roleId = '' }: tUseCarsForDropdown) => {
  const [userSearch, setUserSearch] = useState('');
  const debouncedUserSearch = useDebounce({ searchValue: userSearch });

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching, isError, error } =
    useInfiniteQuery({
      queryKey: [queryCacheNameForInfiniteUsers, roleId, debouncedUserSearch],
      initialPageParam: { offset: 0, limit: formDataLimit },
      queryFn: async ({ signal, pageParam }) => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        const promise = usersService.getUsers({
          ...pageParam,
          search: debouncedUserSearch,
          role_id: roleId,
          cancelToken: source.token,
        });

        // Cancel the request if TanStack Query signals to abort
        signal?.addEventListener('abort', () => {
          source.cancel('Query was cancelled by TanStack Query');
        });

        return promise.then(({ count, results }) => ({
          count,
          results: transformDataForDropdownComponent(results),
        }));
      },
      getNextPageParam: (lastPage, allPages, lastPageParam) => {
        if (lastPageParam.offset + lastPageParam.limit >= lastPage.count) return null;
        return {
          offset: lastPageParam.offset + formDataLimit,
          limit: formDataLimit,
        };
      },
      staleTime: defaultCacheStaleTime, // TODO what length to set by default or per view
      enabled,
    });

  const usersOptions = useMemo(() => data?.pages.flatMap(({ results }) => results) || [], [data]);

  const fetchMoreUsers = useCallback(async () => {
    if (hasNextPage && !isFetchingNextPage) await fetchNextPage();
  }, [fetchNextPage, hasNextPage, isFetchingNextPage]);

  useEffect(() => {
    if (isError) onError(error);
  }, [isError, error]);

  return {
    data,
    usersOptions,
    fetchMoreUsers,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    userSearch,
    setUserSearch,
  };
};

export const queryCacheNameForInfiniteAreas = 'getInfiniteAreas';

export const useAreasForDropdown = ({ enabled = true }: tParamForDropdown) => {
  const [areaSearch, setAreaSearch] = useState('');
  const debouncedAreaSearch = useDebounce({ searchValue: areaSearch });

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching, isError, error } =
    useInfiniteQuery({
      queryKey: [queryCacheNameForInfiniteAreas, debouncedAreaSearch],
      initialPageParam: { offset: 0, limit: formDataLimit },
      queryFn: async ({ signal, pageParam }) => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        const promise = usersService.getAreas({
          ...pageParam,
          search: debouncedAreaSearch,
          cancelToken: source.token,
        });

        // Cancel the request if TanStack Query signals to abort
        signal?.addEventListener('abort', () => {
          source.cancel('Query was cancelled by TanStack Query');
        });

        return promise.then(({ count, results }) => ({
          count,
          results,
        }));
      },
      getNextPageParam: (lastPage, allPages, lastPageParam) => {
        if (lastPageParam.offset + lastPageParam.limit >= lastPage.count) return null;
        return {
          offset: lastPageParam.offset + formDataLimit,
          limit: formDataLimit,
        };
      },
      staleTime: defaultCacheStaleTime, // TODO what length to set by default or per view
      enabled,
    });

  const areasOptions = useMemo(() => data?.pages.flatMap(({ results }) => results) || [], [data]);

  const fetchMoreAreas = useCallback(async () => {
    if (hasNextPage && !isFetchingNextPage) await fetchNextPage();
  }, [fetchNextPage, hasNextPage, isFetchingNextPage]);

  useEffect(() => {
    if (isError) onError(error);
  }, [isError, error]);

  return {
    data,
    areasOptions,
    fetchMoreAreas,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    areaSearch,
    setAreaSearch,
  };
};

export default useUsers;
