import { Error } from 'components';

type tProps = {
  errors: string[];
};

const Errors = ({ errors = [] }: tProps) => (
  <>
    {errors.map((error: string, index: number) => (
      <Error key={index} text={error} />
    ))}
  </>
);

export default Errors;
