import { CancelToken } from 'axios';
import { format } from 'date-fns';
import i18n from 'i18next';

import auditsAgent, {
  tAddAuditRequest,
  tAudit,
  tGetAuditListRequest,
  tUpdateAuditRequest,
} from 'agents/audits';
import { tAuditList } from 'types/services/audits';
import { defaultDateFormat } from 'utils/constants';
import errorsHandler from './utils/errorsHandler';

type tGetAuditList<T> = T extends true ? tAuditList[] : tAudit[];

const getAuditsList = <T extends boolean>({
  limit = 10,
  offset = 0,
  search = '',
  cancelToken,
  since = null,
  to = null,
  finished_since = null,
  finished_to = null,
  start_since = null,
  auditor_id = '',
  forList,
  finished = false,
  mobile_group_ids = '',
  location_ids = '',
  result = '',
}: tGetAuditListRequest & { cancelToken: CancelToken; forList: T }) => {
  const params: tGetAuditListRequest = { offset };

  if (limit) params.limit = limit;
  if (search) params.search = search;
  if (since) params.since = since;
  if (to) params.to = to;
  if (finished_since) params.finished_since = finished_since;
  if (finished_to) params.finished_to = finished_to;
  if (start_since) params.start_since = start_since;
  if (auditor_id) params.auditor_id = auditor_id;
  if (finished) params.finished = true;
  if (mobile_group_ids) params.mobile_group_ids = mobile_group_ids;
  if (location_ids) params.location_ids = location_ids;
  if (result) params.result = result;

  return auditsAgent.getAuditsList(params, cancelToken).then((response) => {
    const {
      data: { count, results },
    } = response;

    if (forList) {
      const listData: tAuditList[] = results.map((auditData, index) => {
        const {
          id,
          survey: { questions },
          name,
          end_time,
          location: {
            address: { formatted_address },
          },
          result: score,
          remarks,
          mobile_group,
        } = auditData;

        return {
          id,
          itemData: [
            {
              key: 'order',
              value: index + 1 + offset,
            },
            {
              key: 'name',
              value: name,
            },
            {
              key: 'mobileGroup',
              value: mobile_group?.name ?? '-',
            },
            {
              key: 'location',
              value: formatted_address ?? '-',
            },
            {
              key: 'date',
              value: !!end_time ? format(new Date(end_time), defaultDateFormat) : null,
            },
            {
              key: 'score',
              value: typeof score === 'number' ? `${score}%` : '-',
            },
          ],
          extraInfo: {
            name,
            additional_information: remarks ?? '-',
            questions: questions.map((question) => ({
              id: question.id,
              type: question.kind,
              question: question.title,
              answer: question.answer?.value ?? '',
            })),
          },
        };
      });
      return { count, results: listData as tGetAuditList<T> };
    }

    return { count, results: results as tGetAuditList<T> };
  });
};

const addAudit = (bodyRequest: tAddAuditRequest) =>
  auditsAgent
    .addAudit(bodyRequest)
    .then(() => ({ state: true, errors: [], data: {} }))
    .catch((error) =>
      errorsHandler({
        defaultErrorMessage: i18n.t('Cannot add', { value: i18n.t('audit form') }),
        error,
      }),
    );

const updateAudit = (id: string, bodyRequest: tUpdateAuditRequest) =>
  auditsAgent
    .updateAudit(id, bodyRequest)
    .then(() => ({ state: true, errors: [], data: {} }))
    .catch((error) =>
      errorsHandler({
        defaultErrorMessage: i18n.t('Cannot edit', { value: i18n.t('audit form') }),
        error,
      }),
    );

const deleteAudit = (auditId: string) => {
  const defaultError = [
    { key: '__general__', msg: i18n.t('Cannot remove', { value: i18n.t('audit form') }) },
  ];

  return auditsAgent
    .deleteAudit(auditId)
    .then(() => ({ state: true, errors: [], data: {} }))
    .catch((error) => ({ state: false, errors: error?.response?.data ?? defaultError, data: {} }));
};

export const getAuditReport = (id: string) =>
  auditsAgent
    .getAuditReport(id)
    .then(({ data }) => ({
      error: false,
      errorMessage: '',
      data: URL.createObjectURL(new Blob([data])),
    }))
    .catch(() => ({
      error: true,
      errorMessage: i18n.t('Report failed to download'),
      data: '',
    }));

export default {
  getAuditsList,
  addAudit,
  updateAudit,
  deleteAudit,
  getAuditReport,
};
