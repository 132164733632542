import { mockServiceTypes } from '../utils/mocks';
import { tDropdownOption, tPeriodicityData, tStep } from 'types/global';
import { tFile } from 'components/FileUpload/FileUpload';
import { tErrorAction, tSaveAction } from 'types/form';
import { ERROR, SAVE, SAVE_TASK_DATA } from 'utils/constants';
import { tOption } from 'components/RadioPicker/RadioPicker';
import { tReadAttachment } from 'agents/attachments';
import { tConfirmationMethod } from 'agents/services';

export type tAdHocTaskFormState = {
  confirmation_method: {
    value: null | tOption<tConfirmationMethod>;
    error: string;
  };
  methodOfService: {
    value: tOption<number>;
    error: string;
  };
  description: {
    value: string;
    error: string;
  };
  estimatedTime: {
    value: string;
    error: string;
  };
  priority: {
    value: null | tOption<'NORMAL' | 'HIGH'>;
    error: string;
  };
  client: {
    value: null | tDropdownOption<string>;
    error: string;
  };
  product: {
    value: null | tDropdownOption<string>;
    error: string;
  };
  location: {
    value: null | tDropdownOption<string>;
    error: string;
  };
  contract: {
    value: null | tDropdownOption<string>;
    error: string;
  };
  qualifications: {
    value: tDropdownOption<string>[];
    error: string;
  };
  equipments: {
    value: tDropdownOption<string>[];
    error: string;
  };
  service: {
    value: null | tDropdownOption<string>;
    error: string;
  };
  serviceName: {
    value: string;
    error: string;
  };
  startDate: {
    value: Date;
    error: string;
  };
  endDate: {
    value: Date;
    error: string;
  };
  startTime: {
    value: string;
    error: string;
  };
  endTime: {
    value: string;
    error: string;
  };
  files: {
    value: tFile[];
    error: string;
  };
  savedFiles: {
    value: tReadAttachment[];
    error: string;
  };
  periodicity: {
    value: null | tPeriodicityData;
    error: string;
  };
  steps: {
    value: tStep[];
    error: string;
  };
  zco: {
    value: string;
    error: string;
  };
};

export const createInitialState: (day: Date) => tAdHocTaskFormState = (day: Date) => ({
  confirmation_method: {
    value: null,
    error: '',
  },
  methodOfService: {
    value: mockServiceTypes[0],
    error: '',
  },
  description: {
    value: '',
    error: '',
  },
  estimatedTime: {
    value: '00:30',
    error: '',
  },
  priority: {
    value: null,
    error: '',
  },
  client: {
    value: null,
    error: '',
  },
  product: {
    value: null,
    error: '',
  },
  location: {
    value: null,
    error: '',
  },
  contract: {
    value: null,
    error: '',
  },
  qualifications: {
    value: [],
    error: '',
  },
  equipments: {
    value: [],
    error: '',
  },
  service: {
    value: null,
    error: '',
  },
  serviceName: {
    value: '',
    error: '',
  },
  startDate: {
    value: day,
    error: '',
  },
  endDate: {
    value: day,
    error: '',
  },
  startTime: {
    value: '',
    error: '',
  },
  endTime: {
    value: '',
    error: '',
  },
  files: {
    value: [],
    error: '',
  },
  savedFiles: {
    value: [],
    error: '',
  },
  periodicity: {
    value: null,
    error: '',
  },
  steps: {
    value: [],
    error: '',
  },
  zco: {
    value: '',
    error: '',
  },
});

type tSaveTaskData = {
  type: typeof SAVE_TASK_DATA;
  value: Partial<tAdHocTaskFormState>;
};

type tActions =
  | tSaveAction<tAdHocTaskFormState>
  | tErrorAction<tAdHocTaskFormState>
  | tSaveTaskData;

function addAdHocTaskFormReducer(state: tAdHocTaskFormState, action: tActions) {
  switch (action.type) {
    case SAVE:
      return {
        ...state,
        [action.name]: { ...state[action.name], value: action.value, error: '' },
      };
    case ERROR:
      return { ...state, [action.name]: { ...state[action.name], error: action.error } };
    case SAVE_TASK_DATA:
      return { ...state, ...action.value };

    default:
      throw new Error();
  }
}

export default addAdHocTaskFormReducer;
