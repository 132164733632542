import { CancelToken } from 'axios';
import fetchProvider from 'utils/Axios';
import { tDictionary, tListParams } from 'types/global';

const microservicePath = '/users';

type tRoleWithPermissions = tDictionary & { permissions: tDictionary[] };

type tGetCurrentResponse = {
  id: string;
  first_name: string;
  last_name: string;
  name: string | null;
  phone_number: string;
  email: string;
  username: string;
  role: tRoleWithPermissions;
};

type tUser = {
  email: string;
  first_name: string;
  last_name: string;
  phone_number: string;
};

export type tReadUser = tUser & {
  id: string;
  role: tDictionary;
  areas: tDictionary[];
  username: string;
};

type tWriteUpdateUser = tUser & {
  role_id: string;
  area_ids?: string[];
};

export type tGetUsersRequest = tListParams & { role_id?: string };
type tGetUsersResponse = { count: number; results: tReadUser[] };

type tGetUserByIdResponse = tReadUser;

type tDeleteUserResponse = tReadUser;

export type tAddUserRequest = tWriteUpdateUser;
type tAddUserResponse = tReadUser;

export type tUpdateUserRequest = tWriteUpdateUser;
type tUpdateUserResponse = tReadUser;

export type tGetRolesRequest = tListParams;
type tGetRolesResponse = { count: number; results: tDictionary[] };

export type tGetAreasRequest = tListParams;
type tGerAreasResponse = { count: number; results: tDictionary[] };

const users = {
  getCurrent: (cancelToken: CancelToken) =>
    fetchProvider.get<tGetCurrentResponse>(`${microservicePath}/current`, { cancelToken }),
  getUsers: (params: tGetUsersRequest, cancelToken: CancelToken) =>
    fetchProvider.get<tGetUsersResponse>('/manager_users', { params, cancelToken }),
  getUser: (userId: number | string, cancelToken: CancelToken) =>
    fetchProvider.get<tGetUserByIdResponse>(`manager_users/${userId}`, { cancelToken }),
  deleteUser: (userId: string) =>
    fetchProvider.delete<tDeleteUserResponse>(`/manager_users/${userId}`),
  addUser: (bodyRequest: tAddUserRequest) =>
    fetchProvider.post<tAddUserResponse>('/manager_users', bodyRequest),
  updateUser: (userId: string, bodyRequest: tUpdateUserRequest) =>
    fetchProvider.put<tUpdateUserResponse>(`/manager_users/${userId}`, bodyRequest),
  getRoles: (params: tGetRolesRequest, cancelToken: CancelToken) =>
    fetchProvider.get<tGetRolesResponse>(`${microservicePath}/roles`, { params, cancelToken }),
  getAreas: (params: tGetAreasRequest, cancelToken: CancelToken) =>
    fetchProvider.get<tGerAreasResponse>('/digital_map/areas', { params, cancelToken }),
};

export default users;
