import styled, { css } from 'styled-components';
import * as ButtonStyles from 'styles/button';
import { colorRadicalRed, colorBrickRed, colorWhite, colorEchoBlue } from 'styles/constants';

type tButton = {
  disabled: boolean;
};

export const Button = styled(ButtonStyles.ButtonStyle)<tButton>`
  border: 2px solid transprent;
  color: ${colorWhite};
  ${({ disabled }) =>
    disabled
      ? css`
          background: ${colorEchoBlue};
          cursor: unset;
        `
      : css`
          background: ${colorRadicalRed};
          &:hover {
            background: ${colorBrickRed};
          }
        `}
`;
