import styled from 'styled-components';
import { colorSlateBlue } from 'styles/constants';

type tSection = {
  $schedule: boolean;
};
export const Section = styled.div<tSection>`
  padding-left: ${({ $schedule }) => ($schedule ? 0 : '8px')};
  margin-top: 5px;
  margin-bottom: 5px;
  align-items: center;
  display: flex;
  svg {
    max-height: 18px;
    fill: ${colorSlateBlue};
    margin-right: ${({ $schedule }) => ($schedule ? '0.25rem' : '12px')};
  }
`;

export const Text = styled.div`
  font-size: 12px;
  line-height: 15px;
`;
