import { FC, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useSidebar } from 'contexts/sidebar';
import { useCurrentUser } from 'contexts/currentUser';
import { Tooltip } from 'components';
import menuRoutes, { tRoute } from './routes';
import { MenuItem, MenuItemCopy } from './Menu.style';

type tMenu = {
  closeMenu: () => void;
};
const Menu: FC<tMenu> = ({ closeMenu }) => {
  const { t } = useTranslation();
  const { user } = useCurrentUser();
  const { wideMenu } = useSidebar();

  const userRole = useMemo(() => user?.systemRole ?? '', [user?.systemRole]);

  const userMenuRoutes = menuRoutes[userRole];

  return (
    <>
      {!!userMenuRoutes &&
        userMenuRoutes.map((menuItem: tRoute) => {
          const { icon, id, path, sidebar } = menuItem;

          return (
            <MenuItem key={id}>
              <Tooltip
                show={wideMenu}
                tipId={id}
                content={<MenuItemCopy>{t(sidebar)}</MenuItemCopy>}
                direction='right'
              >
                <NavLink to={path} onClick={closeMenu}>
                  {icon}
                  {!wideMenu && <MenuItemCopy>{t(sidebar)}</MenuItemCopy>}
                </NavLink>
              </Tooltip>
            </MenuItem>
          );
        })}
    </>
  );
};

export default Menu;
