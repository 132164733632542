import { useState, useEffect, FC } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';

import Icon from 'components/Icon';
import { IconButton } from 'styles/button';
import { colorLightSlateGrey, colorMelrose, colorSlateBlue } from 'styles/constants';
import countOffset from './utils/countOffset';
import countPage from './utils/countPage';
import {
  IconButtonInput,
  JumpToPageText,
  JumpToPageInput,
  JumpToPageBlock,
  PaginationContainer,
  Pages,
  Results,
  ResultsButton,
  Wrapper,
  ResultsTitle,
  ResultsContainer,
  JumpToPageContainer,
} from './Pagination.style';

type tPagination = {
  totalRecords: number;
  setParams: Function;
  limit?: number;
  offset?: number;
};

const Pagination: FC<tPagination> = ({
  totalRecords,
  setParams,
  limit = 10,
  offset = 0,
}): JSX.Element => {
  const { t } = useTranslation();

  const [page, setPage] = useState<number>(0);
  const [showLimit, setShowLimit] = useState<boolean>(false);
  const [jumpPage, setJumpPage] = useState<number>(1);

  const limits = [5, 10, 20];
  const pageCountHelper = Math.ceil(totalRecords / limit);

  useEffect(() => {
    if (typeof offset === 'number' && typeof limit === 'number') {
      const pageNumber: number = countPage(offset, limit);

      if (page === pageNumber) return;

      setPage(pageNumber);
      setParams(countOffset(pageNumber, limit), limit);
    }
  }, [offset, limit]);

  const correctJumpPage = (newPage: number) => {
    const max: number = pageCountHelper;
    const pageValue: number = newPage >= max ? max : newPage;

    setJumpPage(pageValue <= 0 ? 1 : pageValue);
  };

  const handlePageClick = (data: { selected: number }) => {
    setPage(data.selected);
    setParams(countOffset(data.selected, limit), limit);
  };

  const setLimitHandler = (newLimit: number) => {
    if (limit === newLimit) {
      setParams(0, newLimit);

      return;
    }

    setPage(0);
    setJumpPage(1);
    setShowLimit(false);
    setParams(countOffset(page, newLimit), newLimit);
  };

  return (
    <Wrapper style={!pageCountHelper ? { height: '33px' } : {}}>
      {!!pageCountHelper && (
        <>
          <Results>
            <ResultsTitle>{t('Results on page')}:</ResultsTitle>
            <ResultsContainer>
              <span data-testid='Pagination-span'>{limit}</span>
              <IconButton onClick={() => setShowLimit(true)}>
                <Icon
                  iconName='arrowDropDown'
                  data-testid='Pagination-ResultsButton'
                  fill={colorLightSlateGrey}
                />
              </IconButton>
            </ResultsContainer>
            {showLimit && (
              <ul>
                {limits.map((limitItem) => (
                  <li key={limitItem}>
                    <ResultsButton
                      data-testid={`Pagination-ResultsButton-${limitItem}`}
                      type='button'
                      onClick={() => setLimitHandler(limitItem)}
                    >
                      {limitItem}
                    </ResultsButton>
                  </li>
                ))}
              </ul>
            )}
          </Results>
          <PaginationContainer>
            <Pages>
              <IconButton
                disabled={page === 0}
                onClick={page === 0 ? () => {} : () => handlePageClick({ selected: 0 })}
              >
                <Icon iconName='firstPage' fill={page === 0 ? colorMelrose : colorSlateBlue} />
              </IconButton>
              <ReactPaginate
                previousLabel={<Icon iconName='navigateBefore' />}
                nextLabel={<Icon iconName='navigateNext' />}
                breakLabel={<Icon iconName='moreHoriz' fill={colorMelrose} />}
                breakClassName='break-me'
                pageCount={pageCountHelper}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                onPageChange={handlePageClick}
                containerClassName='pagination'
                activeClassName='active'
                forcePage={page}
              />
              <IconButton
                disabled={page === pageCountHelper - 1}
                onClick={
                  page === pageCountHelper - 1
                    ? () => {}
                    : () => handlePageClick({ selected: pageCountHelper - 1 })
                }
              >
                <Icon
                  iconName='lastPage'
                  fill={page === pageCountHelper - 1 ? colorMelrose : colorSlateBlue}
                />
              </IconButton>
            </Pages>
            <JumpToPageBlock>
              <JumpToPageText>{t('Jump to page')}:</JumpToPageText>
              <JumpToPageContainer>
                <JumpToPageInput
                  type='number'
                  min={1}
                  value={jumpPage}
                  placeholder=''
                  onChange={({ target: { value } }) => correctJumpPage(Number(value))}
                />
                <IconButtonInput
                  onClick={() => handlePageClick({ selected: Number(jumpPage) - 1 })}
                >
                  <Icon
                    iconName='navigateNext'
                    fill={colorLightSlateGrey}
                    extraStyles={{ position: 'absolute', right: '24px' }}
                  />
                </IconButtonInput>
              </JumpToPageContainer>
            </JumpToPageBlock>
          </PaginationContainer>
        </>
      )}
    </Wrapper>
  );
};

export default Pagination;
