import { FC, ReactNode } from 'react';

import { BottomBlock, ImageContainer, LoginImage, Tint, Wrapper } from './LoginPageWrapper.style';

const LoginPageWrapper: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Wrapper>
      <ImageContainer>
        <LoginImage />
        <Tint />
      </ImageContainer>
      <BottomBlock />
      {children}
    </Wrapper>
  );
};

export default LoginPageWrapper;
