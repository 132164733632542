import styled from 'styled-components';

import { colorBlack } from 'styles/constants';

export const ButtonsSection = styled.div`
  align-items: center;
  display: flex;
  > * {
    margin-left: 16px;
  }
  @media screen and (max-width: 520px) {
    > * {
      margin: 0;
    }
  }
`;

export const Title = styled.h2`
  color: ${colorBlack};
  font-size: 24px;
  letter-spacing: 0px;
  line-height: 32px;
  margin: 0;
  @media screen and (max-width: 520px) {
    margin-bottom: 1rem;
  }
`;

export const TopSection = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  @media screen and (max-width: 520px) {
    align-items: flex-start;
    flex-direction: column;
  }
`;
