import { useCallback, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { useInfiniteQuery, useQuery, useQueryClient } from '@tanstack/react-query';

import locationService from 'services/locations';
import { tParams } from 'types/global';
import { defaultCacheStaleTime } from 'services/utils/constants';
import { onError } from 'utils/queryClient';
import transformDataForDropdownComponent from 'utils/transformDataForDropdownComponent';
import useDebounce from 'hooks/useDebounce';
import { formDataLimit } from 'utils/constants';

type tUseLocationsArgs = {
  params: tParams;
};

export const queryCacheName = 'getLocations';

const useLocations = ({ params }: tUseLocationsArgs) => {
  const queryClient = useQueryClient();
  const query = useQuery({
    // eslint-disable-next-line max-len
    queryKey: [queryCacheName, params], // TODO name kind depends on service method or create in service name constants????
    queryFn: ({ signal }) => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      const promise = locationService.getLocations({
        ...params,
        cancelToken: source.token,
      });

      // Cancel the request if TanStack Query signals to abort
      signal?.addEventListener('abort', () => {
        source.cancel('Query was cancelled by TanStack Query');
      });

      return promise;
    },
    staleTime: defaultCacheStaleTime, // TODO what length to set by default or per view
  });

  const count = useMemo(() => query?.data?.count ?? 0, [query?.data]);

  const refetchList = () => queryClient.invalidateQueries({ queryKey: [queryCacheName] });

  const invalidateQuery = () =>
    queryClient.invalidateQueries({ queryKey: [queryCacheName], refetchType: 'none' });

  useEffect(() => {
    if (query.isError) onError(query.error);
  }, [query.isError, query.error]);

  return {
    ...query,
    data: query?.data?.results ?? [],
    count,
    refetchList,
    invalidateQuery,
  };
};

export default useLocations;

type tUseLocationsForDropdown = {
  enabled?: boolean;
  contractId?: string;
};

export const queryCacheNameForInfiniteLocations = 'getInfiniteLocations';

export const useLocationsForDropdown = ({
  enabled = true,
  contractId,
}: tUseLocationsForDropdown) => {
  const [locationSearch, setLocationSearch] = useState('');
  const debouncedlocationSearch = useDebounce({ searchValue: locationSearch });

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching, isError, error } =
    useInfiniteQuery({
      queryKey: [queryCacheNameForInfiniteLocations, debouncedlocationSearch, contractId],
      initialPageParam: { offset: 0, limit: formDataLimit },
      queryFn: async ({ signal, pageParam }) => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        const promise = locationService.getLocations({
          ...pageParam,
          contract_id: contractId,
          search: debouncedlocationSearch,
          cancelToken: source.token,
        });

        // Cancel the request if TanStack Query signals to abort
        signal?.addEventListener('abort', () => {
          source.cancel('Query was cancelled by TanStack Query');
        });

        return promise.then(({ count, results }) => ({
          count,
          results,
        }));
      },
      getNextPageParam: (lastPage, allPages, lastPageParam) => {
        if (lastPageParam.offset + lastPageParam.limit >= lastPage.count) return null;
        return {
          offset: lastPageParam.offset + formDataLimit,
          limit: formDataLimit,
        };
      },
      staleTime: defaultCacheStaleTime, // TODO what length to set by default or per view
      enabled,
    });

  const locationsOptions = useMemo(
    () => data?.pages.flatMap(({ results }) => transformDataForDropdownComponent(results)) || [],
    [data],
  );

  const locationsFullOptions = useMemo(
    () => data?.pages.flatMap(({ results }) => results) || [],
    [data],
  );

  const fetchMoreLocations = useCallback(async () => {
    if (hasNextPage && !isFetchingNextPage) await fetchNextPage();
  }, [fetchNextPage, hasNextPage, isFetchingNextPage]);

  useEffect(() => {
    if (isError) onError(error);
  }, [isError, error]);

  return {
    data,
    locationsOptions,
    locationsFullOptions,
    fetchMoreLocations,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    locationSearch,
    setLocationSearch,
  };
};

export const queryCacheNameForInfiniteAddresses = 'getInfiniteAddresses';

export const useAddressesForDropdown = () => {
  const queryClient = useQueryClient();
  const [addressesSearch, setAddressesSearch] = useState('');
  const debouncedAddressesSearch = useDebounce({ searchValue: addressesSearch });

  const query = useQuery({
    queryKey: [queryCacheNameForInfiniteAddresses, debouncedAddressesSearch],
    queryFn: async ({ signal }) => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      const promise = locationService.getAddresses({
        search: debouncedAddressesSearch,
        cancelToken: source.token,
      });

      // Cancel the request if TanStack Query signals to abort
      signal?.addEventListener('abort', () => {
        source.cancel('Query was cancelled by TanStack Query');
      });

      return promise.then(({ count, results }) => ({
        count,
        results,
      }));
    },
    staleTime: defaultCacheStaleTime, // TODO what length to set by default or per view
    enabled: !!debouncedAddressesSearch,
  });

  const refetch = () => queryClient.invalidateQueries({ queryKey: [queryCacheName] });

  const invalidateQuery = () =>
    queryClient.invalidateQueries({ queryKey: [queryCacheName], refetchType: 'none' });

  useEffect(() => {
    if (query.isError) onError(query.error);
  }, [query.isError, query.error]);

  return {
    ...query,
    data: query?.data?.results ?? [],
    refetch,
    invalidateQuery,
    addressesSearch,
    setAddressesSearch,
  };
};
