import styled, { css } from 'styled-components';
import * as ButtonStyles from 'styles/button';
import { colorEchoBlue, colorSlateBlue, colorWhite, gradientPurple } from 'styles/constants';

type tButton = {
  disabled: boolean;
};

export const Button = styled(ButtonStyles.ButtonStyle)<tButton>`
  color: ${colorWhite};
  min-height: 32px;
  ${({ disabled }) =>
    disabled
      ? css`
          background: ${colorEchoBlue};
          cursor: not-allowed;
          &:hover {
            background: ${colorEchoBlue};
          }
        `
      : css`
          background: ${gradientPurple};
          &:hover {
            background: ${colorSlateBlue};
          }
        `}
`;
