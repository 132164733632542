import { ReactNode } from 'react';

import { boxShadowCommon } from 'styles/constants';

type tCustomDatePickerContainer = {
  children: ReactNode;
  className: string;
};

const CustomDatePickerContainer = ({ className, children }: tCustomDatePickerContainer) => (
  <div className={className} style={{ boxShadow: boxShadowCommon }}>
    {children}
  </div>
);

export default CustomDatePickerContainer;
