import { tKeyValueGeneric } from 'types/global';

const setHumanFileSize = (fileSize: number): tKeyValueGeneric<string> => {
  let size = Math.abs(fileSize);

  if (Number.isNaN(size)) {
    return {
      information: 'Invalid file size',
      sizeTypeMessage: 'error',
    };
  }

  if (size === 0) {
    return {
      information: '0 bytes',
      sizeTypeMessage: 'info',
    };
  }

  const units = ['B', 'kB', 'MB', 'GB', 'TB'];
  let quotient = Math.floor(Math.log2(size) / 10);

  quotient = quotient < units.length ? quotient : units.length - 1;
  size /= 1024 ** quotient;

  return {
    information: `${+size.toFixed(2)} ${units[quotient]}`,
    sizeTypeMessage: 'info',
  };
};

export default setHumanFileSize;
