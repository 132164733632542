import styled from 'styled-components';

import { IconButton } from 'styles/button';
import {
  boxShadowGoogleMap,
  colorGhostWhite,
  colorLightSlateGrey,
  zIndexDropdown,
  zIndexModal,
  zIndexTimeSpanHover,
} from 'styles/constants';

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: auto;
  overflow: hidden;
  position: relative;
  top: -1px;
`;

export const GridItem = styled.div`
  display: flex;
`;

export const HourCopy = styled.div`
  font-size: 12px;
  line-height: 16px;
  padding-right: 8px;
  position: relative;
  text-align: right;
  top: -8px;
`;

export const HoursSection = styled.div`
  flex: 1;
`;

export const IconButtonContainer = styled(IconButton)`
  position: absolute;
  right: 0;
  top: 4px;
`;

export const Task = styled.div`
  border-radius: 8px;
  left: 6px;
  position: absolute;
  right: 6px;
  z-index: ${zIndexDropdown};
  &:hover {
    z-index: ${zIndexTimeSpanHover};
  }
`;

export const TaskActions = styled.div`
  background: ${colorGhostWhite};
  border-radius: 8px;
  box-shadow: ${boxShadowGoogleMap};
  display: block;
  font-size: 12px;
  font-weight: 400;
  padding: 4px 8px;
  position: absolute;
  right: 30px;
  text-align: left;
  text-transform: none;
  width: 104px;
  z-index: ${zIndexModal};
`;

export const TaskHours = styled.div`
  bottom: 0;
  display: block;
  left: 0px;
  position: absolute;
  right: 0px;
  top: 0px;
  width: 100%;
`;

export const TaskSection = styled.div`
  border-left: 1px solid ${colorLightSlateGrey}99;
  border-top: 1px solid ${colorLightSlateGrey}99;
  flex: 3;
  height: 35px;
  padding: 6px;
  position: relative;
`;

export const TaskTimes = styled.div`
  font-size: 12px;
  height: 100%;
  padding: 6px;
  width: 100%;
`;

export const Wrapper = styled.div`
  border-top: 1px solid ${colorLightSlateGrey}99;
  height: calc(100% + 17px);
  margin: 0 -1rem;
`;
