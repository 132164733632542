import { ApplicationInsights, DistributedTracingModes } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import i18n from 'i18next';
import { History } from 'history';

// TODO: Remove any when issue will be resolved (https://github.com/microsoft/applicationinsights-react-js/issues/32)
// eslint-disable-next-line @typescript-eslint/no-explicit-any
let reactPlugin: any;
let appInsights: ApplicationInsights;

const createTelemetryService = () => {
  const initialize = (instrumentationKey: string | null, browserHistory: History | undefined) => {
    if (!browserHistory) {
      throw new Error(i18n.t('Could not initialize Telemetry Service'));
    }
    if (!instrumentationKey) {
      throw new Error(i18n.t('Instrumentation key not provided in telemetry provider!'));
    }

    reactPlugin = new ReactPlugin();
    appInsights = new ApplicationInsights({
      config: {
        instrumentationKey,
        maxBatchInterval: +(window?.__env__?.MAX_BATCH_INTERVAL ?? '15000'),
        samplingPercentage: +(window?.__env__?.SAMPLING_PERCENTAGE ?? '1'),
        disableFetchTracking: false,
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: {
            history: browserHistory,
          },
        },
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true,
        distributedTracingMode: DistributedTracingModes.W3C,
      },
    });

    appInsights.loadAppInsights();
    appInsights.addTelemetryInitializer((telemetryItem) => {
      if (!!telemetryItem?.tags) {
        telemetryItem.tags['ai.cloud.role'] = window?.__env__?.AI_CLOUD_ROLE ?? 'not_given';
      }
    });
  };

  return { reactPlugin, appInsights, initialize };
};

export const ai = createTelemetryService();
export const getAppInsights = () => appInsights;
