import { ChangeEvent, MutableRefObject, FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Error, Label } from 'components';
import { InputElement, Readonly } from './Input.style';

export type tInput = {
  additionalLabelText?: string;
  disabled?: boolean;
  error?: string;
  label?: string;
  optionalLabel?: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  readonly?: boolean;
  search?: boolean;
  type?: string;
  // TODO: Add type to input value (GMROZW-1084)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  onFocus?: (event: ChangeEvent<HTMLInputElement>) => void;
  ref?: MutableRefObject<HTMLInputElement> | null;
  min?: number;
  dataTestId?: string;
};

const Input: FC<tInput> = ({
  additionalLabelText = '',
  disabled = false,
  error = '',
  label = '',
  optionalLabel = false,
  onChange = () => {},
  placeholder = '',
  readonly = false,
  search = false,
  type = 'text',
  value = '',
  onFocus = () => {},
  ref = null,
  min = 0,
  dataTestId,
}) => {
  const { t } = useTranslation();

  const renderInput = (type: string) => {
    if (type === 'number') {
      return (
        <InputElement
          $error={!!error.length}
          disabled={disabled}
          type={type}
          placeholder={t(placeholder)}
          $search={search}
          value={value}
          onChange={onChange}
          onFocus={onFocus}
          ref={ref}
          min={min}
          data-testid={dataTestId}
        />
      );
    }

    return (
      <InputElement
        $error={!!error.length}
        disabled={disabled}
        type={type}
        placeholder={t(placeholder)}
        $search={search}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        ref={ref}
        data-testid={dataTestId}
      />
    );
  };

  return (
    <>
      {!!label && (
        <Label optional={optionalLabel} optionalText={additionalLabelText}>
          {t(label)}
        </Label>
      )}
      {readonly ? <Readonly>{value}</Readonly> : renderInput(type)}
      {!!error && <Error text={error} />}
    </>
  );
};

export default Input;
