import { ComponentType, InputHTMLAttributes, Ref } from 'react';
import { useTranslation } from 'react-i18next';
import { IMaskInputProps, ReactMaskOpts } from 'react-imask';
import MaskedPattern, { Definitions } from 'imask/masked/pattern';
import { FactoryStaticOpts } from 'imask';

import { Error, Label } from 'components';
import { tInput } from 'components/Input/Input';
import { ReadOnly } from 'styles/input';
import { MaskedStyledInput, tMaskInputProps } from 'components/InputWithMask/InputWithMask.style';

type tInputWithMask = Omit<InputHTMLAttributes<HTMLInputElement>, 'value' | 'onChange' | 'type'> &
  Pick<tInput, 'error' | 'disabled' | 'label' | 'readonly' | 'dataTestId' | 'optionalLabel'> & {
    ref?: Ref<ComponentType<IMaskInputProps<HTMLInputElement> & tMaskInputProps>>;
    mask: FactoryStaticOpts['mask'];
    blocks?: MaskedPattern['blocks'];
    definitions?: Definitions;
    unmask?: ReactMaskOpts['unmask'];
    lazy?: MaskedPattern['lazy'];
    eager?: ReactMaskOpts['eager'];
    onChange: (value: string) => void;
    value?: string;
  };

const InputWithMask = ({
  disabled = false,
  error = '',
  label = '',
  placeholder = '',
  readonly = false,
  value = '',
  ref = null,
  optionalLabel = false,
  dataTestId,
  mask,
  blocks,
  definitions,
  lazy = true,
  eager = true,
  unmask,
  onChange,
  ...props
}: tInputWithMask) => {
  const { t } = useTranslation();
  return (
    <>
      {!!label && <Label optional={optionalLabel}>{t(label)}</Label>}
      {readonly ? (
        <ReadOnly $error={!!error}>{value || '-'}</ReadOnly>
      ) : (
        <MaskedStyledInput
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          mask={mask as any} // TODO: remove any when imask types will be fixed
          blocks={blocks}
          definitions={definitions}
          lazy={lazy}
          eager={eager}
          disabled={disabled}
          placeholder={placeholder}
          value={value}
          onAccept={(value, mask, event) => {
            // prevent calling onChange on mask initialization && make the controlled input to work properly
            if (event) onChange(value);
          }}
          ref={ref}
          error={error}
          data-testid={dataTestId}
          unmask={unmask}
          type='text'
          {...props}
        />
      )}
      {!!error && <Error text={error} />}
    </>
  );
};

export default InputWithMask;
