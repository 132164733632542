import { useCallback, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { useInfiniteQuery, useQuery, useQueryClient } from '@tanstack/react-query';

import productsService from 'services/products';
import { tParams } from 'types/global';
import { defaultCacheStaleTime } from 'services/utils/constants';
import { onError } from 'utils/queryClient';
import transformDataForDropdownComponent from 'utils/transformDataForDropdownComponent';
import useDebounce from 'hooks/useDebounce';
import { formDataLimit } from 'utils/constants';

type tUseProductsArgs = {
  params: tParams;
};

export const queryCacheName = 'getProducts';

const useProducts = ({ params }: tUseProductsArgs) => {
  const queryClient = useQueryClient();
  const query = useQuery({
    // eslint-disable-next-line max-len
    queryKey: [queryCacheName, params], // TODO name kind depends on service method or create in service name constants????
    queryFn: ({ signal }) => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      const promise = productsService.getProducts({
        ...params,
        cancelToken: source.token,
      });

      // Cancel the request if TanStack Query signals to abort
      signal?.addEventListener('abort', () => {
        source.cancel('Query was cancelled by TanStack Query');
      });

      return promise;
    },
    staleTime: defaultCacheStaleTime, // TODO what length to set by default or per view
  });
  const count = useMemo(() => query?.data?.count ?? 0, [query?.data]);

  const refetchList = () => queryClient.invalidateQueries({ queryKey: [queryCacheName] });

  const invalidateQuery = () =>
    queryClient.invalidateQueries({ queryKey: [queryCacheName], refetchType: 'none' });

  useEffect(() => {
    if (query.isError) onError(query.error);
  }, [query.isError, query.error]);

  return {
    ...query,
    data: query?.data?.results ?? [],
    count,
    refetchList,
    invalidateQuery,
  };
};

export default useProducts;

type tUseProductsForDropdown = {
  enabled?: boolean;
  clientId?: string;
};

export const queryCacheNameForInfiniteProducts = 'getInfiniteProducts';

export const useProductsForDropdown = ({ enabled = true, clientId }: tUseProductsForDropdown) => {
  const [productSearch, setProductSearch] = useState('');
  const debouncedProductSearch = useDebounce({ searchValue: productSearch });

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching, isError, error } =
    useInfiniteQuery({
      queryKey: [queryCacheNameForInfiniteProducts, debouncedProductSearch, clientId],
      initialPageParam: { offset: 0, limit: formDataLimit },
      queryFn: async ({ signal, pageParam }) => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        const promise = productsService.getProducts({
          ...pageParam,
          client_id: clientId,
          search: debouncedProductSearch,
          cancelToken: source.token,
        });

        // Cancel the request if TanStack Query signals to abort
        signal?.addEventListener('abort', () => {
          source.cancel('Query was cancelled by TanStack Query');
        });

        return promise.then(({ count, results }) => ({
          count,
          results,
        }));
      },
      getNextPageParam: (lastPage, allPages, lastPageParam) => {
        if (lastPageParam.offset + lastPageParam.limit >= lastPage.count) return null;
        return {
          offset: lastPageParam.offset + formDataLimit,
          limit: formDataLimit,
        };
      },
      staleTime: defaultCacheStaleTime, // TODO what length to set by default or per view
      enabled,
    });

  const productsOptions = useMemo(
    () => data?.pages.flatMap(({ results }) => transformDataForDropdownComponent(results)) || [],
    [data],
  );

  const productsFullOptions = useMemo(
    () => data?.pages.flatMap(({ results }) => results) || [],
    [data],
  );

  const fetchMoreProducts = useCallback(async () => {
    if (hasNextPage && !isFetchingNextPage) await fetchNextPage();
  }, [fetchNextPage, hasNextPage, isFetchingNextPage]);

  useEffect(() => {
    if (isError) onError(error);
  }, [isError, error]);

  return {
    data,
    productsOptions,
    productsFullOptions,
    fetchMoreProducts,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    productSearch,
    setProductSearch,
  };
};
