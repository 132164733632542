import { FC } from 'react';

import formatDate from 'utils/formatDate';
import { DisplayTaskData } from 'components';
import { tTaskShortData } from 'types/services/tasks';

type tDisplayTaskLocationAndScheduler = {
  task: tTaskShortData | null;
};
export const DisplayTaskLocationAndScheduler: FC<tDisplayTaskLocationAndScheduler> = ({ task }) => {
  if (task === null) return null;
  return (
    <>
      <DisplayTaskData text={task.address} icon='place' />
      <DisplayTaskData text={formatDate(task.start, task.end)} icon='dateRange' />
    </>
  );
};

export default DisplayTaskLocationAndScheduler;
