import styled from 'styled-components';

import { colorLightSlateGrey, colorRadicalRed, colorSlateBlue } from 'styles/constants';

type tSearchContainer = {
  $forTasksList?: boolean;
};

export const SearchContainer = styled.div<tSearchContainer>`
  align-items: center;
  border: 1px solid ${colorLightSlateGrey};
  border-radius: 32px;
  display: flex;
  line-height: 25px;
  margin-right: auto;
  padding: 0px 42px 0px 12px;
  position: relative;
  @media screen and (min-width: 1200px) {
    width: ${({ $forTasksList }) => ($forTasksList ? '288px' : '344px')};
    max-width: 100%;
  }
  > input {
    width: 100%;
  }
  &:hover,
  &:focus-within {
    border-color: ${colorSlateBlue};
    svg {
      fill: ${colorSlateBlue};
      .closePath {
        fill: ${colorRadicalRed};
      }
    }
  }
`;
