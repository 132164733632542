import { useMemo, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { differenceInSeconds, isSameDay } from 'date-fns';

import { tGetServiceData } from 'types/services/services';
import { eStatuses } from 'agents/services';

import { Icon, Tooltip } from 'components';
import formatDate from 'utils/formatDate';
import convertSecondsToTimeText from 'utils/convertSecondsToTimeText';
import { Danger, ContentContainer } from './ExecutionTime.style';

type tExecutionTime = {
  status: string;
  service: tGetServiceData;
  today?: Date;
};

export const getFieldsName = (status) => {
  switch (status) {
    case eStatuses.WAITING_FOR_ACCEPTANCE:
    case eStatuses.ASSIGNED:
      return ['schedule_start_time', 'schedule_end_time'];

    case eStatuses.STARTED:
      return ['start_time', 'schedule_end_time'];

    case eStatuses.FINISHED:
      return ['start_time', 'end_time'];

    case eStatuses.ABORTED:
      return ['start_time', 'aborted_at'];

    default:
      return ['start', 'end'];
  }
};

const ExecutionTime: FC<tExecutionTime> = ({ status, service, today = new Date() }) => {
  const { t } = useTranslation();

  const [startField, endField] = useMemo(() => getFieldsName(status), [status]);

  const getTime = () => {
    switch (status) {
      case eStatuses.NEW: {
        const differnce = differenceInSeconds(today, service[startField]);
        return `${
          differnce > 0 ? t('Execution date exceeded by') : t('Execution date for')
        }: ${convertSecondsToTimeText(Math.abs(differnce))}`;
      }
      case eStatuses.ASSIGNED: {
        const differnce = differenceInSeconds(today, service[startField]);
        return `${t('Start delayed at')}: ${convertSecondsToTimeText(Math.abs(differnce))}`;
      }
      case eStatuses.STARTED: {
        if (!service.schedule_end_time) return '-';
        const differnce = differenceInSeconds(today, service[endField]);
        return `${t('Finish delayed at')}: ${convertSecondsToTimeText(Math.abs(differnce))}`;
      }

      default:
        return `-`;
    }
  };

  const checkIfRed = () => {
    switch (status) {
      case eStatuses.NEW: {
        const difference = differenceInSeconds(today, service[startField]);
        return difference > -120 * 60; // dwie godziny przed
      }
      case eStatuses.ASSIGNED: {
        // 15 min po czasie rozpoczęcia
        return differenceInSeconds(today, service[startField]) > 15 * 60;
      }

      case eStatuses.STARTED: {
        if (!service.schedule_end_time) return '-';
        // 15 min po czasie zakończenia
        return differenceInSeconds(today, service[endField]) > 15 * 60;
      }

      default:
        return false;
    }
  };

  if (!isSameDay(service[startField], today) || !checkIfRed()) {
    return <>{formatDate(service[startField], service[endField])}</>;
  }
  return (
    <Tooltip tipId={service.id} content={<span>{getTime()}</span>} direction='bottom'>
      <Danger>
        <ContentContainer>
          <Icon iconName='error' width='15px' height='15px' fill='white' />
          {formatDate(service[startField], service[endField])}
        </ContentContainer>
      </Danger>
    </Tooltip>
  );
};

export default ExecutionTime;
