import { ChangeEvent, ReactNode, useEffect, useReducer, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import isEqual from 'lodash.isequal';

import servicesService from 'services/services';
import attachmentsService from 'services/attachments';

import { tGetServiceTemplate } from 'types/services/serviceTemplates';
import { tGetProduct } from 'types/services/products';

import {
  ButtonCancel,
  ButtonPrimary,
  DropDown,
  MultiSelect,
  FileUpload,
  FormModal,
  Input,
  InputWithMask,
  Label,
  Periodicity,
  RadioPicker,
  Steps,
  TextArea,
  Icon,
  DatesPicker,
} from 'components';
import { tFile } from 'components/FileUpload/FileUpload';
import FileWrapper from 'components/FileUpload/FileWrapper';
import { tOption } from 'components/RadioPicker/RadioPicker';

import { BottomGap, InputFormContainer } from 'styles/GlobalStyledComponents';
import { tStep, tResponse, tEditMode, tDropdownOption } from 'types/global';
import { addAdHocTaskFormError } from 'components/Tasks/utils/addAdHocTaskFormError';
import {
  checkErrorsInForm,
  checkNestedValueField,
  checkServiceName,
  checkStepsField,
  checkValueArrayField,
  checkValueField,
  checkValueFieldWithCondition,
  checkValueTimeField,
  timeTest,
} from 'components/Tasks/utils/checkFieldForm';
import { mockCustomSteps, mockServiceTypes } from './utils/mocks';
import { Info, InfoText } from './AddAdHocTaskForm.style';
import { matchPriority } from './utils';
import addAdHocTaskFormReducer, {
  createInitialState,
  tAdHocTaskFormState,
} from './reducers/addAdHocTaskFormReducer';
import useClientsPriorities from 'services/hooks/useClientsPriorities';
import useTask from 'services/hooks/useTask';
import formErrorsHandler from 'utils/formErrorsHandler';
import { useClientsForDropdown } from 'services/hooks/useClients';
import { useProductsForDropdown } from 'services/hooks/useProducts';
import { useContractsForDropdown } from 'services/hooks/useContracts';
import { useLocationsForDropdown } from 'services/hooks/useLocations';
import useConfirmationMethods from 'services/hooks/useConfirmationMethods';
import useQuestionTypes from 'services/hooks/useQuestionTypes';
import { useServiceTemplatesForDropdown } from 'services/hooks/useServiceTemplates';
import { useQualificationsForDropdown } from 'services/hooks/useQualifications';
import { useEquipmentsForDropdown } from 'services/hooks/useEquipments';
import { ERROR, SAVE, SAVE_TASK_DATA } from 'utils/constants';
import { tAddServiceRequest, tPeriodicity } from 'agents/services';
import { tReadAttachment } from 'agents/attachments';
import { IMask } from 'react-imask';

type tAddAdHocTaskForm = {
  onSuccess: () => void;
  onCancel: (state: boolean) => void;
  onEditSuccess: (taskId: string) => void;
  editMode: tEditMode;
};

const requiredFormFields = [
  'location',
  'contract',
  'product',
  'priority',
  'estimated_execution_time',
  'equipments',
  'qualifications',
  'confirmation_method',
  'description',
  'name',
  'survey',
];

const isRemovable = false; // Can remove question from template

const AddAdHocTaskForm = ({
  onCancel = () => {},
  onSuccess = () => {},
  onEditSuccess = () => {},
  editMode,
}: tAddAdHocTaskForm) => {
  const today = useMemo(() => new Date(), []);
  const { t } = useTranslation();
  const [exactDate, setExactDate] = useState<boolean>(true);
  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false);
  const [formFields, dispatch] = useReducer(addAdHocTaskFormReducer, createInitialState(today));

  const {
    data: currentTask,
    invalidateQuery: invalidateUseClientQuery,
    isFetching: currentTaskLoading,
  } = useTask({
    itemId: editMode.itemId,
  });

  // fetching helper data - required to safely render form
  const { data: dataPriorities, isFetching: clientsPrioritiesLoading } = useClientsPriorities({});
  const priorities = useMemo(() => dataPriorities?.results ?? [], [dataPriorities]);

  const { data: questionListData, isFetching: questionTypesLoading } = useQuestionTypes({});
  const questionTypesList = useMemo(() => questionListData?.results ?? [], [questionListData]);

  const { data: confirmationMethodsData, isFetching: confirmationMethodsLoading } =
    useConfirmationMethods({});
  const confirmationMethodsList = useMemo(
    () => confirmationMethodsData?.results ?? [],
    [confirmationMethodsData],
  );

  const isHelperDataLoading =
    clientsPrioritiesLoading || questionTypesLoading || confirmationMethodsLoading;

  // fetching data for dropdown options
  const {
    clientsFullOptions: clientsFullList,
    clientsOptions: clientsList,
    fetchMoreClients,
    clientSearch,
    setClientSearch,
    isFetching: clientsLoading,
  } = useClientsForDropdown({});

  const {
    qualificationsOptions,
    fetchMoreQualifications,
    setQualificationsSearch,
    qualificationsSearch,
    isFetching: qualificationsLoading,
  } = useQualificationsForDropdown({});

  const {
    equipmentsOptions,
    fetchMoreEquipments,
    setEquipmentsSearch,
    equipmentsSearch,
    isFetching: equipmentsLoading,
  } = useEquipmentsForDropdown({});

  const {
    productsFullOptions: productsFullList,
    productsOptions: productsList,
    fetchMoreProducts,
    productSearch,
    setProductSearch,
    isFetching: productsLoading,
  } = useProductsForDropdown({
    enabled: !!formFields.client.value?.value,
    clientId: formFields.client.value?.value,
  });

  const {
    contractsOptions: contractsList,
    fetchMoreContracts,
    contractSearch,
    setContractSearch,
    isFetching: contractsLoading,
  } = useContractsForDropdown({
    enabled: !!formFields.client.value?.value && !!formFields.product.value?.value,
    clientId: formFields.client.value?.value,
    productId: formFields.product.value?.value,
  });

  const {
    locationsOptions: locationsList,
    fetchMoreLocations,
    locationSearch,
    setLocationSearch,
    isFetching: locationsLoading,
  } = useLocationsForDropdown({
    enabled: !!formFields.contract.value?.value,
    contractId: formFields.contract.value?.value,
  });

  const {
    serviceTemplatesOptions: serviceTemplatesList,
    serviceTemplatesFullOptions: serviceTemplatesFullList,
    isFetching: serviceTemplatesLoading,
    fetchMoreServiceTemplates,
    serviceTemplatesSearch,
    setServiceTemplatesSearch,
  } = useServiceTemplatesForDropdown({});

  const isServiceTemplate = useMemo(() => {
    const {
      methodOfService: { value: methodOfService },
    } = formFields;

    return methodOfService.value === 1;
  }, [JSON.stringify(formFields.methodOfService.value)]);

  const { itemId: taskId } = editMode;
  const isEditMode = !!taskId;

  const saveSteps = (id: string) => {
    const chosenService: tGetServiceTemplate | undefined = serviceTemplatesFullList.find(
      (service: tGetServiceTemplate) => service.id === id,
    );
    if (!!chosenService) {
      const {
        extraInfo: { steps },
      } = chosenService;
      const value: tStep[] = [];
      steps.forEach((step) => {
        const type = questionTypesList.find((typeItem) => typeItem.value === step.kind);
        if (type) {
          value.push({ id: step.id, name: step.name, isRemovable, type });
        }
      });
      dispatch({ type: SAVE, name: 'steps', value });
    }
  };

  const pickServiceTemplate = (option: tDropdownOption<string> | null) => {
    if (isEqual(option, formFields.service.value)) return;
    dispatch({ type: SAVE, name: 'service', value: option });

    if (!!option && option.value) {
      saveSteps(option.value);
    }
  };

  const renderService = (): ReactNode => {
    const {
      service: { value: service, error: serviceError },
      serviceName: { value: serviceName, error: serviceNameError },
      steps: { value: steps, error: stepsError },
    } = formFields;
    if (isServiceTemplate) {
      return (
        <BottomGap $gap='1rem'>
          <DropDown
            value={service}
            options={serviceTemplatesList}
            onChange={pickServiceTemplate}
            label={t('Service name')}
            isLoading={serviceTemplatesLoading}
            error={serviceError}
            isInfinityScroll
            infinityScrollFetchFunction={fetchMoreServiceTemplates}
            searchValue={serviceTemplatesSearch}
            setSearchValue={setServiceTemplatesSearch}
          />
          {!!steps.length && service?.value && (
            <>
              <Label>{t('Tasks')}</Label>
              <Steps
                steps={steps}
                setSteps={(stepsItem: tStep[]) => {
                  dispatch({ type: SAVE, name: 'steps', value: stepsItem });
                }}
                readonly
                questionTypes={questionTypesList}
                error={stepsError}
              />
            </>
          )}
        </BottomGap>
      );
    }

    return (
      <BottomGap $gap='1rem'>
        <InputFormContainer>
          <Input
            label='Service name'
            placeholder='Np. Podstawowe prace porządkowe'
            value={serviceName}
            type='text'
            onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
              dispatch({ type: SAVE, name: 'serviceName', value })
            }
            error={serviceNameError}
          />
        </InputFormContainer>
        <Label>{t('Tasks')}</Label>
        <Steps
          steps={steps}
          setSteps={(stepsItem: tStep[]) => {
            dispatch({ type: SAVE, name: 'steps', value: stepsItem });
          }}
          questionTypes={questionTypesList}
          error={stepsError}
        />
      </BottomGap>
    );
  };

  const submit = async () => {
    setIsFormSubmitting(true);
    const {
      contract: { value: contract },
      location: { value: location },
      product: { value: product },
      priority: { value: priority },
      qualifications: { value: qualifications },
      equipments: { value: equipments },
      estimatedTime: { value: estimatedTime },
      description: { value: description },
      confirmation_method: { value: confirmation_method },
      startDate: { value: startDate },
      endDate: { value: endDate },
      startTime: { value: startTime },
      endTime: { value: endTime },
      service: { value: service },
      serviceName: { value: serviceName },
      files: { value: files },
      savedFiles: { value: savedFiles },
      periodicity: { value: periodicity },
      steps: { value: steps },
      zco: { value: zco },
    } = formFields;

    Object.keys(formFields).forEach((field) => {
      switch (field) {
        case 'client':
        case 'contract':
        case 'location':
        case 'product':
          checkNestedValueField(formFields[field].value, field, dispatch);
          break;
        case 'qualifications':
        case 'equipments':
          checkValueArrayField(formFields[field].value, field, dispatch);
          break;
        case 'priority':
        case 'confirmation_method':
          checkValueField(formFields[field].value, field, dispatch);
          break;
        case 'serviceName':
          checkServiceName(formFields[field].value, field, service, isServiceTemplate, dispatch);
          break;
        case 'service':
          checkValueFieldWithCondition(formFields[field].value, field, isServiceTemplate, dispatch);
          break;
        case 'steps':
          checkStepsField(formFields[field].value, field, dispatch);
          break;
        case 'endDate':
        case 'startDate':
          checkValueTimeField(formFields[field].value, field, dispatch);
          break;
        case 'startTime':
        case 'endTime':
        case 'estimatedTime':
          timeTest(formFields[field].value, field, dispatch);
          break;
        default:
          break;
      }
    });

    let uploadedFiles: tReadAttachment[] = [];
    if (!!files.length && !checkErrorsInForm(addAdHocTaskFormError, true)) {
      try {
        uploadedFiles = await attachmentsService.sendAttachments(files);
      } catch (error) {
        dispatch({ type: ERROR, name: 'files', error: t('Field required') });
        addAdHocTaskFormError['files'].errors = true;
      }
    }

    const errors = checkErrorsInForm(addAdHocTaskFormError, true);
    if (errors) {
      setIsFormSubmitting(false);
      return;
    }

    const survey: tAddServiceRequest['survey'] = isServiceTemplate
      ? {
          service_template: service?.value ?? '',
          questions: steps.map((step) => ({
            kind: step.type && step.type.value,
            title: step.name,
            template: step.id,
          })),
        }
      : {
          questions: steps.map((step) => ({
            kind: step.type && step.type.value,
            title: step.name,
          })),
        };

    const data: tAddServiceRequest = {
      location: location?.value ?? '',
      contract: contract?.value ?? '',
      product: product?.value ?? '',
      priority: priority!.value as 'HIGH' | 'NORMAL',
      estimated_execution_time: `${estimatedTime}:00`,
      qualifications: qualifications.map((qualification) => qualification.value),
      confirmation_method: confirmation_method?.value ?? null,
      description,
      name: isServiceTemplate ? service?.label ?? '' : serviceName,
      survey,
      equipments: equipments.map((equipment) => equipment.value),
      zco,
    };

    if (startDate && startTime) {
      data.earliest_start_time = new Date(`${format(startDate, 'yyyy-MM-dd')} ${startTime}`);
    }

    if (endDate && endTime) {
      data.latest_finish_time = new Date(
        `${format(exactDate ? startDate : endDate, 'yyyy-MM-dd')} ${endTime}`,
      );
    }

    if (uploadedFiles.length && uploadedFiles[0]?.id) {
      data.attachment = uploadedFiles[0].id;
    }

    if (savedFiles.length && isEditMode) {
      data.attachment = savedFiles[0].id;
    }

    if (!uploadedFiles.length && !savedFiles.length && isEditMode) {
      data.attachment = null;
    }

    if (!!periodicity?.repeatabilityValue && !!periodicity?.repeatabilityType && !isEditMode) {
      const begin = periodicity.startDate!;
      const beginTime = startTime.split(':');
      begin.setHours(Number(beginTime[0]) || 0, Number(beginTime[1]) || 0, 0, 0);

      const end = periodicity.endDate!;
      end.setHours(23, 59, 59, 999);

      const periodicityHelper: tPeriodicity = {
        interval_value: +periodicity.repeatabilityValue,
        interval_type: periodicity.repeatabilityType,
        begin,
        end,
      };

      if (periodicity.repeatabilityType === 'week') {
        periodicityHelper.days = periodicity.days;
      }

      data.periodicity = {
        ...periodicityHelper,
      };
    }

    const result: tResponse = isEditMode
      ? await servicesService.updateService(taskId, data)
      : await servicesService.addService(data);

    const { state } = result;
    if (!state) {
      const matchFields = [
        { apiField: 'attachment', formField: 'files' },
        { apiField: 'service_template', formField: 'service' },
        { apiField: 'name', formField: 'serviceName' },
        { apiField: 'earliest_start_time', formField: 'startTime' },
        { apiField: 'latest_finish_time', formField: 'endTime' },
      ];

      formErrorsHandler(
        result.errors,
        requiredFormFields,
        [...requiredFormFields, 'earliest_start_time', 'latest_finish_time', 'priority'],
        dispatch,
        matchFields,
      );
    }

    if (state && isEditMode) {
      onCancel(false);
      onEditSuccess(taskId);
      invalidateUseClientQuery();
    } else if (state) {
      onCancel(false);
      onSuccess();
    }

    setIsFormSubmitting(false);
  };

  const saveAttachments = async (files: tFile[]) => {
    dispatch({ type: SAVE, name: 'files', value: files });
  };

  const pickClient = (option: tDropdownOption<string> | null) => {
    if (isEqual(option, formFields.client.value)) return;
    dispatch({ type: SAVE, name: 'client', value: option });
    dispatch({ type: SAVE, name: 'product', value: null });
    dispatch({ type: SAVE, name: 'location', value: null });
    dispatch({ type: SAVE, name: 'contract', value: null });

    if (typeof option?.value === 'string') {
      const client = clientsFullList.find((clientItem) => clientItem.id === option.value);
      if (client && !isEditMode) {
        const priority = priorities?.find(
          (priorityItem) => priorityItem.value === matchPriority(client?.itemData[4].value),
        );
        if (priority) {
          dispatch({
            type: SAVE,
            name: 'priority',
            value: priority,
          }); // Set prority from client
        }
      }
    }
  };

  const pickProduct = (option: tDropdownOption<string> | null) => {
    if (isEqual(option, formFields.product.value)) return;
    dispatch({
      type: SAVE,
      name: 'product',
      value: option,
    });
    dispatch({ type: SAVE, name: 'location', value: null });
    dispatch({ type: SAVE, name: 'contract', value: null });
    if (typeof option?.value === 'string' && !!formFields.client.value?.value) {
      const productHelper: tGetProduct | undefined = productsFullList.find(
        (product: tGetProduct) => product.id === option?.value,
      );

      if (!!productHelper && !isEditMode) {
        const {
          extraInfo: { qualifications, equipments },
        } = productHelper;

        const markedQualifications = qualifications.map((qualification) => ({
          label: qualification.name,
          value: qualification.id,
        }));
        dispatch({ type: SAVE, name: 'qualifications', value: markedQualifications });

        const markedEquipments = equipments.map((equipment) => ({
          label: equipment.name,
          value: equipment.id,
        }));
        dispatch({ type: SAVE, name: 'equipments', value: markedEquipments });
      }
    }
  };

  const pickLocation = (option: tDropdownOption<string> | null) => {
    if (isEqual(option, formFields.location.value)) return;
    dispatch({
      type: SAVE,
      name: 'location',
      value: option,
    });
  };

  const pickContract = (option: tDropdownOption<string> | null) => {
    if (isEqual(option, formFields.contract.value)) return;
    dispatch({
      type: SAVE,
      name: 'contract',
      value: option,
    });
    dispatch({ type: SAVE, name: 'location', value: null });
  };

  const pickMethodOfService = (option: tOption<number>) => {
    if (isEqual(option, formFields.methodOfService.value)) return;
    dispatch({ type: SAVE, name: 'methodOfService', value: option });
    if (option.value === 1 && !!formFields.service.value?.value) {
      saveSteps(formFields.service.value?.value);
    } else {
      dispatch({ type: SAVE, name: 'steps', value: [...mockCustomSteps] });
      dispatch({ type: SAVE, name: 'service', value: null });
    }
  };

  useEffect(() => {
    dispatch({ type: ERROR, name: 'serviceName', error: '' });
  }, [formFields.serviceName.value]);

  useEffect(() => {
    if (!isEditMode) {
      dispatch({ type: SAVE, name: 'startDate', value: today });
      dispatch({ type: SAVE, name: 'endDate', value: today });
    }
  }, []);

  useEffect(() => {
    if (
      formFields.methodOfService.value.value === 1 &&
      !!formFields.service.value?.value &&
      formFields.steps.value.length === 0
    ) {
      dispatch({ type: SAVE, name: 'methodOfService', value: mockServiceTypes[1] });
    }
  }, [formFields.steps.value, formFields.methodOfService.value]);

  useEffect(() => {
    // check if form is in edit mode and wait for required data to be loaded
    if (!currentTask?.id || currentTaskLoading || isHelperDataLoading || serviceTemplatesLoading) {
      return;
    }
    const startDate = new Date(currentTask.earliest_start_time);
    const endDate = new Date(currentTask.latest_finish_time);
    const startTime = format(new Date(currentTask.earliest_start_time), 'HH:mm');
    const endTime = format(new Date(currentTask.latest_finish_time), 'HH:mm');
    const priority = priorities?.find(
      (priorityItem) => priorityItem.value === currentTask.priority,
    );
    const confirmationMethod = confirmationMethodsList?.find(
      (method) => currentTask.confirmation_method === method.value,
    );

    const taskHelper: Partial<tAdHocTaskFormState> = {
      confirmation_method: {
        value: confirmationMethod ?? null,
        error: '',
      },
      description: {
        value: currentTask.description,
        error: '',
      },
      estimatedTime: {
        value: String(currentTask.estimated_execution_time).slice(0, -3),
        error: '',
      },
      priority: {
        value: (priority as tDropdownOption<'NORMAL' | 'HIGH'>) ?? null,
        error: '',
      },
      client: {
        value: {
          value: currentTask.client.id,
          label: currentTask.client.name,
        },
        error: '',
      },
      product: {
        value: {
          value: currentTask.product.id,
          label: currentTask.product.name,
        },
        error: '',
      },
      location: {
        value: {
          value: currentTask.location.id,
          label: currentTask.location.name,
        },
        error: '',
      },
      contract: {
        value: {
          value: currentTask.contract.id,
          label: currentTask.contract.name,
        },
        error: '',
      },
      qualifications: {
        value: currentTask.qualifications.map((qualification) => ({
          value: qualification.id,
          label: qualification.name,
        })),
        error: '',
      },
      equipments: {
        value: currentTask.equipments.map((equipment) => ({
          value: equipment.id,
          label: equipment.name,
        })),
        error: '',
      },
      startDate: {
        value: startDate,
        error: '',
      },
      endDate: {
        value: endDate,
        error: '',
      },
      startTime: {
        value: startTime,
        error: '',
      },
      endTime: {
        value: endTime,
        error: '',
      },
      zco: {
        value: currentTask.zco ?? '',
        error: '',
      },
    };

    if (currentTask.attachment?.id) {
      taskHelper.savedFiles = {
        value: [{ ...currentTask.attachment }],
        error: '',
      };
    }

    if (currentTask.mobile_group_service_template) {
      taskHelper.service = {
        value: {
          value: currentTask.mobile_group_service_template.id,
          label: currentTask.mobile_group_service_template.survey_template?.name,
        },
        error: '',
      };
    } else {
      taskHelper.methodOfService = {
        value: mockServiceTypes[1],
        error: '',
      };
      taskHelper.serviceName = {
        value: currentTask.name,
        error: '',
      };
    }

    const stepsHelper = currentTask.survey.questions.map((question) => {
      const type = questionTypesList?.find((typeItem) => typeItem.value === question.kind);
      return {
        id: question.id,
        name: question.title,
        isRemovable,
        type: type ?? null,
      };
    });

    if (!!stepsHelper.length) {
      taskHelper.steps = {
        value: stepsHelper,
        error: '',
      };
    }

    dispatch({ type: SAVE_TASK_DATA, value: taskHelper });

    if (currentTask?.rangeDate) {
      setExactDate(false);
    }
  }, [currentTask, isHelperDataLoading, serviceTemplatesList]);

  const isLoading =
    clientsLoading ||
    qualificationsLoading ||
    equipmentsLoading ||
    productsLoading ||
    contractsLoading ||
    locationsLoading ||
    serviceTemplatesLoading ||
    confirmationMethodsLoading;

  return (
    <FormModal
      title={t(isEditMode ? 'Edit task' : 'Add task')}
      isLoading={currentTaskLoading || isFormSubmitting || isHelperDataLoading}
      buttons={
        <>
          <ButtonCancel onClick={() => onCancel(false)}>{t('Cancel')}</ButtonCancel>
          <ButtonPrimary
            handleClick={submit}
            disabled={isLoading}
            text={t(isEditMode ? 'Save' : 'Add')}
          />
        </>
      }
    >
      {isEditMode && (
        <Info>
          <Icon iconName='info' />
          <InfoText>
            {t(
              'Editing does not take into account recurring tasks. You are only editing the selected task',
            )}
            .
          </InfoText>
        </Info>
      )}
      <DropDown
        value={formFields.client.value}
        options={clientsList}
        onChange={pickClient}
        label={t('Client')}
        isLoading={clientsLoading}
        error={formFields.client.error}
        isInfinityScroll
        infinityScrollFetchFunction={fetchMoreClients}
        searchValue={clientSearch}
        setSearchValue={setClientSearch}
      />
      <DropDown
        value={formFields.product.value}
        options={productsList}
        onChange={pickProduct}
        label={t('Product')}
        isLoading={productsLoading}
        error={formFields.product.error}
        isInfinityScroll
        infinityScrollFetchFunction={fetchMoreProducts}
        searchValue={productSearch}
        setSearchValue={setProductSearch}
        disabled={productsLoading || formFields.client.value === null}
      />
      <DropDown
        value={formFields.contract.value}
        options={contractsList}
        onChange={pickContract}
        label={t('Contract')}
        isLoading={contractsLoading}
        error={formFields.contract.error}
        isInfinityScroll
        infinityScrollFetchFunction={fetchMoreContracts}
        searchValue={contractSearch}
        setSearchValue={setContractSearch}
        disabled={contractsLoading || formFields.product.value === null}
      />
      <DropDown
        value={formFields.location.value}
        options={locationsList}
        onChange={pickLocation}
        label={t('Location')}
        isLoading={locationsLoading}
        error={formFields.location.error}
        isInfinityScroll
        infinityScrollFetchFunction={fetchMoreLocations}
        searchValue={locationSearch}
        setSearchValue={setLocationSearch}
        disabled={locationsLoading || formFields.contract.value === null}
      />

      <BottomGap $gap='1rem'>
        <RadioPicker
          label={t('Priority')}
          options={priorities}
          onChange={(option) => dispatch({ type: SAVE, name: 'priority', value: option })}
          value={formFields.priority.value}
          error={formFields.priority.error}
        />
      </BottomGap>
      <BottomGap $gap='1rem'>
        <RadioPicker
          label={t('Service')}
          direction='row'
          options={mockServiceTypes}
          onChange={pickMethodOfService}
          value={formFields.methodOfService.value}
        />
      </BottomGap>
      {renderService()}
      <DatesPicker
        setDate={({ date, name }: { date: Date; name: 'startDate' | 'endDate' }) =>
          dispatch({ type: SAVE, name, value: date })
        }
        setTime={({ time, name }: { time: string; name: 'startTime' | 'endTime' }) =>
          dispatch({ type: SAVE, name, value: time })
        }
        startDate={formFields.startDate}
        endDate={formFields.endDate}
        startTime={formFields.startTime}
        endTime={formFields.endTime}
        exactDate={exactDate}
        setExactDate={(value: boolean) => setExactDate(value)}
      />
      {!isEditMode && (
        <BottomGap $gap='1rem'>
          <Label optional>{t('Periodicity')}</Label>
          <Periodicity
            today={today}
            setPeriodicityData={(value) => {
              dispatch({ type: SAVE, name: 'periodicity', value });
            }}
          />
        </BottomGap>
      )}
      <InputFormContainer>
        <InputWithMask
          onChange={(value) => dispatch({ type: SAVE, name: 'estimatedTime', value })}
          label={t('Estimated time to complete the task')}
          mask='HH:MM'
          blocks={{
            HH: { mask: IMask.MaskedRange, from: 0, to: 23 },
            MM: { mask: IMask.MaskedRange, from: 0, to: 59 },
          }}
          placeholder='hh:mm'
          value={formFields.estimatedTime.value}
          error={formFields.estimatedTime.error}
        />
      </InputFormContainer>
      <BottomGap $gap='1rem'>
        <MultiSelect
          label={t('Qualifications')}
          options={qualificationsOptions}
          value={formFields.qualifications.value}
          onChange={(options) => {
            dispatch({ type: SAVE, name: 'qualifications', value: options });
          }}
          error={formFields.qualifications.error}
          isLoading={qualificationsLoading}
          searchValue={qualificationsSearch}
          setSearchValue={setQualificationsSearch}
          isInfinityScroll
          infinityScrollFetchFunction={fetchMoreQualifications}
        />
      </BottomGap>
      <BottomGap $gap='1rem'>
        <MultiSelect
          label={t('Equipment')}
          options={equipmentsOptions}
          value={formFields.equipments.value}
          onChange={(options) => {
            dispatch({ type: SAVE, name: 'equipments', value: options });
          }}
          error={formFields.equipments.error}
          isLoading={equipmentsLoading}
          searchValue={equipmentsSearch}
          setSearchValue={setEquipmentsSearch}
          isInfinityScroll
          infinityScrollFetchFunction={fetchMoreEquipments}
        />
      </BottomGap>
      <TextArea
        isOptional
        label={t('The method of execution')}
        value={formFields.description.value}
        errors={formFields.description.error ? [formFields.description.error] : []}
        onChange={({ target: { value } }: ChangeEvent<HTMLTextAreaElement>) =>
          dispatch({ type: SAVE, name: 'description', value })
        }
      />
      <RadioPicker
        label={t('The method of confirming the completion of the task')}
        options={confirmationMethodsList}
        onChange={(option) => dispatch({ type: SAVE, name: 'confirmation_method', value: option })}
        value={formFields.confirmation_method.value}
        error={formFields.confirmation_method.error}
      />
      {!!formFields.savedFiles.value.length && isEditMode ? (
        <>
          <Label optional>{t('Attachment')}</Label>
          {formFields.savedFiles.value.map((file) => (
            <FileWrapper
              key={file.id}
              downloadUrl={file.url.replace('api/mobile_groups/', '')}
              id={file.id}
              name={file.name}
              removeItem={(id: string | number) => {
                dispatch({
                  type: SAVE,
                  name: 'savedFiles',
                  value: [...formFields.savedFiles.value.filter((fileItem) => fileItem.id !== id)],
                });
              }}
            />
          ))}
        </>
      ) : (
        <FileUpload optional extensions={['pdf']} onChange={saveAttachments} />
      )}
      <InputFormContainer style={{ marginTop: '1rem' }}>
        <Input
          label='ZCO'
          value={formFields.zco.value}
          type='text'
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            dispatch({ type: SAVE, name: 'zco', value: e.target.value })
          }
          error={formFields.zco.error}
          optionalLabel
        />
      </InputFormContainer>
    </FormModal>
  );
};

export default AddAdHocTaskForm;
