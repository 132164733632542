import { ReactNode, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

import { tResponse } from 'types/global';
import servicesService from 'services/services';
import { ButtonCancel, ButtonPrimary, Icon, Modal, DatesPicker } from 'components';
import { Buttons, Container, IconContainer, Title } from './AssignTaskModal.style';
import addAssignTaskModalReducer, {
  createInitialState,
} from './reducers/addAssignTaskModalReducer';
import { ERROR, invalidTimeFormat, SAVE, timeFormatRegex } from 'utils/constants';
import formErrorsHandler from 'utils/formErrorsHandler';
import { tAssignServiceToMobileGroupRequest } from 'agents/services';

type tAssignTaskModal = {
  children: ReactNode;
  taskId: string;
  group: {
    id: string;
    name: string;
  };
  executionTime: Date;
  onCancel: () => void;
  onSuccess: () => void;
};

const requiredFormFields = ['start_time', 'end_time'];

const AssignTaskModal = ({
  children,
  taskId,
  group,
  executionTime,
  onCancel = () => {},
  onSuccess = () => {},
}: tAssignTaskModal) => {
  const { t } = useTranslation();
  const [formFields, dispatch] = useReducer(
    addAssignTaskModalReducer,
    createInitialState(executionTime),
  );
  const [exactDate, setExactDate] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);

  const submit = async () => {
    setLoading(true);
    let errors = false;

    const {
      startDate: { value: startDate },
      endDate: { value: endDate },
      startTime: { value: startTime },
      endTime: { value: endTime },
    } = formFields;

    if (!startTime) {
      dispatch({ type: ERROR, name: 'startTime', error: t('Field required') });
      errors = true;
    } else if (!timeFormatRegex.test(startTime)) {
      dispatch({ type: ERROR, name: 'startTime', error: t(invalidTimeFormat) });
      errors = true;
    }

    if (!endTime) {
      dispatch({ type: ERROR, name: 'endTime', error: t('Field required') });
      errors = true;
    } else if (!timeFormatRegex.test(endTime)) {
      dispatch({ type: ERROR, name: 'endTime', error: t(invalidTimeFormat) });
      errors = true;
    }

    if (!startDate) {
      dispatch({ type: ERROR, name: 'startDate', error: t('Field required') });
      errors = true;
    }

    if (!endDate && !exactDate) {
      dispatch({ type: ERROR, name: 'endDate', error: t('Field required') });
      errors = true;
    }

    const start_time = new Date(`${format(startDate, 'yyyy-MM-dd')} ${startTime}`);

    const end_time = new Date(
      `${format(exactDate ? startDate : endDate, 'yyyy-MM-dd')} ${endTime}`,
    );

    if (errors) {
      setLoading(false);
      return;
    }

    const bodyRequest: tAssignServiceToMobileGroupRequest = {
      mobile_group: group.id,
      start_time,
      end_time,
    };

    const result: tResponse = await servicesService.assignServiceToMobileGroup({
      serviceId: taskId,
      bodyRequest,
    });
    const { state } = result;

    if (!state) {
      const matchFields = [
        { apiField: 'start_time', formField: 'startTime' },
        { apiField: 'end_time', formField: 'endTime' },
      ];

      formErrorsHandler(
        result.errors,
        requiredFormFields,
        requiredFormFields,
        dispatch,
        matchFields,
      );
    }

    if (state) {
      onSuccess();
    }
    setLoading(false);
  };

  return (
    <Modal visible>
      <Container>
        <IconContainer>
          <Icon iconName='assignTask' width='11.25rem' height='6.25rem' />
        </IconContainer>
        <Title>
          {t('Are you sure you want to assign task to group {{group}}?', { group: group.name })}
        </Title>
        {children}
        <DatesPicker
          setDate={({ date, name }) => dispatch({ type: SAVE, name, value: date })}
          setTime={({ time, name }) => dispatch({ type: SAVE, name, value: time })}
          startDate={formFields.startDate}
          endDate={formFields.endDate}
          startTime={formFields.startTime}
          endTime={formFields.endTime}
          exactDate={exactDate}
          setExactDate={(value: boolean) => setExactDate(value)}
          labelText='Execution time'
        />
        <Buttons>
          <ButtonCancel onClick={onCancel}>{t('Cancel')}</ButtonCancel>
          <ButtonPrimary handleClick={submit} disabled={loading} text={t('Assign')} />
        </Buttons>
      </Container>
    </Modal>
  );
};

export default AssignTaskModal;
