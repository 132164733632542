import styled, { css } from 'styled-components';

import { Button } from 'styles/button';
import {
  boxShadowCommon,
  colorLightSlateGrey,
  colorRadicalRed,
  colorSlateBlue,
  colorWhite,
  zIndexModalBackdrop,
  gradientPurple,
  zIndexFixed,
} from 'styles/constants';

type tSidebarContainer = {
  $showRwdMenu: boolean;
  $wideMenu: boolean;
};

export const BottomContainer = styled.div`
  bottom: 24px;
  left: 24px;
  position: absolute;
  right: 24px;
  @media screen and (max-width: 1000px), (max-height: 960px) {
    bottom: 0;
    left: 0;
    margin: 3rem auto 0;
    max-width: 400px;
    padding: 0;
    position: relative;
    right: 0;
    width: 100%;
  }
`;

export const GidLogoContainer = styled.div`
  align-items: flex-end;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 32px;
  position: relative;
  text-align: right;
  @media screen and (max-width: 1000px) {
    justify-content: center;
  }
`;

export const LogoutButton = styled(Button)`
  align-items: center;
  display: flex;
  padding: 0;
  padding-left: 24px;
  &:hover {
    > div {
      color: ${colorRadicalRed};
    }
  }
  @media screen and (max-width: 1000px) {
    justify-content: flex-start;
  }
`;

export const LogoutCopy = styled.p`
  color: ${colorLightSlateGrey};
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
  margin-left: 12px;
`;

export const LogoutContainer = styled.div`
  margin: 0 -24px;
  margin-bottom: 64px;
  > a {
    &:hover {
      ${LogoutCopy} {
        color: ${colorRadicalRed};
      }
    }
  }
  @media screen and (max-width: 1000px) {
    margin-bottom: 3rem;
  }
`;

export const MenuCloseButtonContainer = styled.div`
  position: fixed;
  right: 32px;
  top: 17px;
`;

export const SidebarContainer = styled.aside<tSidebarContainer>`
  background: ${colorWhite};
  bottom: 0;
  box-shadow: ${boxShadowCommon};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  left: 0;
  padding: 24px;
  position: fixed;
  top: 64px;
  width: 220px;
  z-index: ${zIndexFixed};
  @media screen and (min-width: 1001px) {
    transition: all 0.5s ease-out;
    width: ${({ $wideMenu }) => ($wideMenu ? '20px' : '220px')};
  }
  @media screen and (max-width: 1000px) {
    bottom: 0;
    right: 0;
    height: auto;
    overflow-y: scroll;
    top: 0;
    width: auto;
    z-index: ${zIndexModalBackdrop};
    ${({ $showRwdMenu }) =>
      $showRwdMenu
        ? css`
            display: flex;
          `
        : css`
            display: none;
          `}
  }
  @media (max-height: 800px) {
    overflow-y: scroll;
  }
`;

export const TopSection = styled.div`
  text-align: center;
  @media screen and (max-width: 1000px) {
    margin: 0 auto;
    max-width: 400px;
    padding: 0;
    width: 100%;
  }
`;

export const VersionCaption = styled.div`
  color: ${colorSlateBlue};
  font-size: 12px;
  font-weight: bold;
  text-align: right;
`;

type tCollapse = {
  $wideMenu: boolean;
};

export const Collapse = styled(Button)<tCollapse>`
  display: none;
  @media screen and (min-width: 1001px) {
    position: absolute;
    top: 50%;
    right: -12px;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    background: ${gradientPurple};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    svg {
      transform: rotate(${({ $wideMenu }) => ($wideMenu ? '180deg' : '0deg')});
    }
  }
`;

type tBottomMeta = {
  $wideMenu: boolean;
};

export const BottomMeta = styled.div<tBottomMeta>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ $wideMenu }) =>
    $wideMenu
      ? css`
          flex-direction: column;
        `
      : css`
          flex-direction: row;
        `}
`;
