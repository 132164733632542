import { useEffect, useCallback, useMemo } from 'react';
import axios, { CancelToken } from 'axios';
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';

import notificationsService from 'services/notifications';
import { defaultCacheStaleTime } from 'services/utils/constants';
import { onError } from 'utils/queryClient';
import { tListParams } from 'types/global';
import { formDataLimit } from 'utils/constants';

export const queryCacheName = 'getNotifications';

export const useNotifications = (showNotificationsPanel: boolean) => {
  const queryClient = useQueryClient();
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching, isError, error } =
    useInfiniteQuery({
      queryKey: [queryCacheName],
      initialPageParam: { offset: 0, limit: formDataLimit },
      queryFn: async ({ signal, pageParam }) => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        const query: tListParams & { cancelToken: CancelToken } = {
          ...pageParam,
          cancelToken: source.token,
        };

        const promise = notificationsService.getNotifications(query);

        // Cancel the request if TanStack Query signals to abort
        signal?.addEventListener('abort', () => {
          source.cancel('Query was cancelled by TanStack Query');
        });

        return promise.then(({ count, results }) => {
          const dataWithOffset = results.map((result) => ({ ...result, offset: pageParam.offset }));
          return { count, results: dataWithOffset };
        });
      },
      getNextPageParam: (lastPage, allPages, lastPageParam) => {
        if (lastPageParam.offset + lastPageParam.limit >= lastPage.count) return null;
        return {
          offset: lastPageParam.offset + formDataLimit,
          limit: formDataLimit,
        };
      },
      staleTime: defaultCacheStaleTime, // TODO what length to set by default or per view
      enabled: showNotificationsPanel,
    });

  const notifications = useMemo(() => data?.pages.flatMap(({ results }) => results) || [], [data]);

  const loadMoreNotifications = useCallback(async () => {
    if (hasNextPage && !isFetchingNextPage) await fetchNextPage();
  }, [fetchNextPage, hasNextPage, isFetchingNextPage]);

  const refetchList = useCallback(
    () => queryClient.invalidateQueries({ queryKey: [queryCacheName] }),
    [queryCacheName],
  );

  useEffect(() => {
    if (isError) onError(error);
  }, [isError, error]);

  return {
    notifications,
    loadMoreNotifications,
    hasNextPage,
    isFetchingNextPage,
    loadingNotifications: isFetching,
    refetchList,
  };
};

export default useNotifications;
