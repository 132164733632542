import React, { CSSProperties, useEffect, useRef } from 'react';

import { useWindowResize } from 'hooks/useWindowResize';
import { CheckboxContainer } from './MultiSelect.style';
import { Checkbox } from 'components';

type tVListItem = {
  style: CSSProperties;
  checked: boolean;
  onChange: () => void;
  label: string;
  setItemSize: (size: number) => void;
};

const VListItem = ({ checked, label, style, onChange, setItemSize }: tVListItem) => {
  const [windowWidth] = useWindowResize();
  const labelRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setItemSize(labelRef.current?.getBoundingClientRect().height ?? 0);
  }, [labelRef, windowWidth]);

  return (
    <CheckboxContainer style={style} $gap='0px' $display='flex'>
      <Checkbox checked={checked} onChange={onChange} label={label} ref={labelRef} />
    </CheckboxContainer>
  );
};

export default VListItem;
