import { AxiosResponse, CancelToken } from 'axios';
import i18n from 'i18next';

import clientsAgent, { tAddOrUpdateClientRequest } from 'agents/clients';
import { tListParams } from 'types/global';
import errorsHandler, { tErrorsHandlerResponse } from './utils/errorsHandler';
import { tGetClients } from 'types/services/clients';

const getClientPriorities = (cancelToken: CancelToken) =>
  clientsAgent.getClientPriorities(cancelToken).then((response) => {
    const {
      data: { count = 0, results = [] },
    } = response;

    return { count, results };
  });

const addClient = (bodyRequest: tAddOrUpdateClientRequest): Promise<tErrorsHandlerResponse> =>
  clientsAgent
    .addClient(bodyRequest)
    .then((resp: AxiosResponse) => ({
      state: true,
      errors: [],
      data: resp.data,
      status: resp.status,
    }))
    .catch((error) =>
      errorsHandler({
        defaultErrorMessage: i18n.t('Cannot add', { value: i18n.t('client form') }),
        error,
      }),
    );

const updateClient = (
  clientId: string,
  bodyRequest: tAddOrUpdateClientRequest,
): Promise<tErrorsHandlerResponse> =>
  clientsAgent
    .updateClient(clientId, bodyRequest)
    .then((resp: AxiosResponse) => ({
      state: true,
      errors: [],
      data: resp.data,
      status: resp.status,
    }))
    .catch((error) =>
      errorsHandler({
        defaultErrorMessage: i18n.t('Cannot edit', { value: i18n.t('client form') }),
        error,
      }),
    );

const deleteClient = (clientId: string): Promise<tErrorsHandlerResponse> =>
  clientsAgent
    .deleteClient(clientId)
    .then((resp: AxiosResponse) => ({ state: true, errors: [], data: {}, status: resp.status }))
    .catch((error) =>
      errorsHandler({
        defaultErrorMessage: i18n.t('Cannot remove', { value: i18n.t('client form') }),
        error,
      }),
    );

const getClient = ({ clientId }: { clientId: string }, cancelToken: CancelToken) =>
  clientsAgent.getClient(clientId, cancelToken).then((response) => {
    const {
      data: { external_system_id = '', name = '', priority, brand, id = '' },
    } = response;

    return {
      external_system_id,
      name,
      priority,
      brand,
      id,
    };
  });

const getClients = ({
  limit = 10,
  offset = 0,
  search = '',
  cancelToken,
}: tListParams & { cancelToken: CancelToken }) => {
  const params: tListParams = { offset };

  if (limit) params.limit = limit;
  if (search) params.search = search;

  return clientsAgent.getClients(params, cancelToken).then((response) => {
    const {
      data: { count = 0, results = [] },
    } = response;

    const transformedData: tGetClients[] = results.map(
      ({ id, external_system_id, name, priority, brand }, index) => {
        return {
          id: id || '',
          itemData: [
            { key: 'order', value: index + 1 + offset },
            { key: 'name', value: name ?? '-' },
            { key: 'brand', value: brand ?? '-' },
            { key: 'externalCustomerNumber', value: external_system_id ?? '-' },
            { key: 'priority', value: priority ?? '-' },
          ],
        };
      },
    );

    return { count, results: transformedData };
  });
};

export default {
  addClient,
  updateClient,
  deleteClient,
  getClient,
  getClients,
  getClientPriorities,
};
