import { ChangeEvent, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { InputContainer, InputStyle, Label } from './Checkbox.style';

type tCheckbox = {
  checked: boolean;
  disabled?: boolean;
  isBold?: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  label?: string;
};

const Checkbox = forwardRef<HTMLDivElement, tCheckbox>(
  ({ checked, disabled = false, isBold = false, onChange, label = '' }, ref) => {
    const { t } = useTranslation();

    return (
      <InputContainer>
        <InputStyle
          disabled={disabled}
          checked={checked}
          onChange={disabled ? () => {} : onChange}
        />
        {!!label && (
          <Label ref={ref} $isBold={isBold}>
            {t(label)}
          </Label>
        )}
      </InputContainer>
    );
  },
);

export default Checkbox;
