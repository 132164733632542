import { useEffect, useState } from 'react';

import { timelinePositionInterval } from 'utils/constants';

import { setTimelinePosition } from './utils';
import { Bullet, Line } from './Timeline.style';

const Timeline = () => {
  const [timelinePoint, setTimelinePoint] = useState(setTimelinePosition());

  useEffect(() => {
    const timelinePositionUpdater = setInterval(() => {
      setTimelinePoint(setTimelinePosition());
    }, timelinePositionInterval);

    return () => clearInterval(timelinePositionUpdater);
  });

  return (
    <Line style={{ top: `${timelinePoint}px` }}>
      <Bullet />
    </Line>
  );
};

export default Timeline;
