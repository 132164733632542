import { ReactNode } from 'react';
import { Button } from './ButtonCancel.style';

type tButton = {
  onClick: () => void;
  children: ReactNode;
  disabled?: boolean;
};

const ButtonCancel = ({ onClick = () => {}, children = 'Cancel', disabled = false }: tButton) => (
  <Button type='button' onClick={onClick} disabled={disabled}>
    {children}
  </Button>
);

export default ButtonCancel;
