import { CancelToken } from 'axios';

import { tListParams } from 'types/global';
import fetchProvider from 'utils/Axios';
import { tAddress } from 'agents/locations';

export const microservicePath = '/schedules';

export type tGetSchedulesRequest = tListParams & {
  since: Date;
  to: Date;
  qualifications?: string;
  equipments?: string;
  mobile_groups?: string;
  areas?: string;
  auditor_id?: string;
};

type tGetSchedulesResponse = {
  count: number;
  results: {
    id: string;
    name: string;
    entries: {
      id: string;
      since: string;
      to: string;
      service: {
        id: string;
        name: string;
        location: { id: string; name: string; address: tAddress };
      };
    }[];
  }[];
};

const schedules = {
  getSchedules: (params: tGetSchedulesRequest, cancelToken: CancelToken) =>
    fetchProvider.get<tGetSchedulesResponse>(microservicePath, { params, cancelToken }),
  deleteSchedule: (id: string) => fetchProvider.delete<void>(`${microservicePath}/${id}`),
};

export default schedules;
