import { useTranslation } from 'react-i18next';
import { IMask } from 'react-imask';

import { Checkbox, Datepicker, Fieldset, InputWithMask, Label } from 'components';
import {
  BottomGap,
  Flex,
  FlexBoxWithCalendar,
  FlexElement,
  InputFormContainer,
} from 'styles/GlobalStyledComponents';
import { tDates } from 'types/global';

type tDatesPicker = {
  today?: Date;
  // eslint-disable-next-line no-unused-vars
  setDate: ({ date, name }: { date: Date; name: 'startDate' | 'endDate' }) => void;
  // eslint-disable-next-line no-unused-vars
  setTime: ({ time, name }: { time: string; name: 'startTime' | 'endTime' }) => void;
  startDate: { value: Date | null; error: string };
  endDate: { value: Date | null; error: string };
  startTime: { value: string; error: string };
  endTime: { value: string; error: string };
  exactDate: boolean;
  // eslint-disable-next-line no-unused-vars
  setExactDate: (value: boolean) => void;
  labelText?: string;
};

const DatesPicker = ({
  today = new Date(),
  setDate,
  setTime,
  startDate,
  endDate,
  startTime,
  endTime,
  exactDate,
  setExactDate,
  labelText = 'Preferred task completion time',
}: tDatesPicker) => {
  const { t } = useTranslation();

  return (
    <BottomGap $gap='1rem'>
      <Label>{t(labelText)}</Label>
      <Fieldset>
        <BottomGap $gap='1rem'>
          <Checkbox
            checked={exactDate}
            onChange={() => setExactDate(!exactDate)}
            label='Exact date'
          />
        </BottomGap>
        <FlexElement style={{ marginBottom: '1rem' }}>
          <FlexBoxWithCalendar
            style={{ marginRight: '6px', flex: exactDate ? '0.5' : '1', maxWidth: '216px' }}
          >
            <Label>{exactDate ? t('Date') : t('Start date')}</Label>
            <Datepicker
              center={false}
              showArrows={false}
              startDate={startDate.value || today}
              onChange={([startDate]: tDates) => setDate({ date: startDate, name: 'startDate' })}
              minDate={today}
            />
          </FlexBoxWithCalendar>
          {!exactDate && (
            <FlexBoxWithCalendar style={{ marginLeft: '6px' }}>
              <Label>{t('End date')}</Label>
              <Datepicker
                center
                showArrows={false}
                startDate={endDate.value || today}
                onChange={([endDate]: tDates) => setDate({ date: endDate, name: 'endDate' })}
                minDate={today}
              />
            </FlexBoxWithCalendar>
          )}
        </FlexElement>
        <FlexElement style={{ marginBottom: '-1rem' }}>
          <Flex $flex={1} style={{ marginRight: '6px' }}>
            <InputFormContainer>
              <InputWithMask
                onChange={(value) => {
                  setTime({ time: value, name: 'startTime' });
                }}
                label='Start time'
                mask='HH:MM'
                blocks={{
                  HH: { mask: IMask.MaskedRange, from: 0, to: 23 },
                  MM: { mask: IMask.MaskedRange, from: 0, to: 59 },
                }}
                placeholder='hh:mm'
                value={startTime.value}
                error={startTime.error}
              />
            </InputFormContainer>
          </Flex>
          <Flex $flex={1} style={{ marginLeft: '6px' }}>
            <InputFormContainer>
              <InputWithMask
                onChange={(value) => setTime({ time: value, name: 'endTime' })}
                label='End time'
                mask='HH:MM'
                blocks={{
                  HH: { mask: IMask.MaskedRange, from: 0, to: 23 },
                  MM: { mask: IMask.MaskedRange, from: 0, to: 59 },
                }}
                placeholder='hh:mm'
                value={endTime.value}
                error={endTime.error}
              />
            </InputFormContainer>
          </Flex>
        </FlexElement>
      </Fieldset>
    </BottomGap>
  );
};

export default DatesPicker;
