import { RefObject, useCallback, useRef } from 'react';
import { VariableSizeList as VList } from 'react-window';

export const useSetSizeRowVList = (listRef: RefObject<VList>) => {
  const sizeMap = useRef({});

  const setSize = useCallback((index: number, size: number) => {
    if (size + 16 < 30) {
      sizeMap.current = { ...sizeMap.current, [index]: 30 };
    } else {
      sizeMap.current = { ...sizeMap.current, [index]: size + 16 };
    }
    listRef.current?.resetAfterIndex(index);
  }, []);

  const getSize = useCallback((index: number) => {
    return sizeMap.current[index] || 30;
  }, []);

  return { setSize, getSize };
};
