import { useTranslation } from 'react-i18next';
import notify from 'utils/notify';
import schedulesService from 'services/schedules';

import { ButtonCancel, ButtonPrimary, Icon, Modal } from 'components';
import { tError } from 'types/global';
import { tTaskShortData } from 'types/services/tasks';
import { tErrorsHandlerResponse } from 'services/utils/errorsHandler';

import { DisplayTaskLocationAndScheduler } from 'components/Tasks/DisplayTaskData';

import {
  Buttons,
  Container,
  IconContainer,
  Title,
  Task,
} from './UnassignTaskFromGroupConfirmation.style';

type tUnassignTaskFromGroupConfirmation = {
  task: tTaskShortData;
  group: { id: string | number; name: string };
  onSuccess: () => void;
  onCancel: () => void;
};

const UnassignTaskFromGroupConfirmation = ({
  task,
  group,
  onSuccess,
  onCancel,
}: tUnassignTaskFromGroupConfirmation) => {
  const { t } = useTranslation();

  const unAssignTask = async () => {
    if (!task.scheduleId) return;

    const result: tErrorsHandlerResponse = await schedulesService.deleteSchedule(task.scheduleId);

    if (result.state) {
      onSuccess();
    } else {
      const errors: string[] = result.errors.map((error: tError) => error.msg);

      if (!!errors.length) {
        errors.forEach((error) => notify({ copy: error, type: 'error' }));
      }
    }
  };

  return (
    <Modal visible>
      <Container>
        <IconContainer>
          <Icon iconName='unassignTask' width='11.25rem' height='6.25rem' />
        </IconContainer>
        <Title>
          {t('Are you sure you want to unassign the task from mobile group {{group}}', {
            group: group.name,
          })}
          ?
        </Title>
        <Task>{task?.name ?? '-'}</Task>
        <DisplayTaskLocationAndScheduler task={task} />
        <div style={{ marginBottom: '1rem' }} />
        <Buttons>
          <ButtonCancel onClick={onCancel}>{t('Cancel')}</ButtonCancel>
          <ButtonPrimary handleClick={unAssignTask} text={t('Undo')} />
        </Buttons>
      </Container>
    </Modal>
  );
};

export default UnassignTaskFromGroupConfirmation;
