import fetchProvider, { fetchProviderForAttachment } from 'utils/Axios';

const microservicePath = '/attachments';

type tAttachment = { mime_type: string; name: string };

export type tReadAttachment = tAttachment & { id: string; url: string; size: number };
export type tWriteAttachment = tAttachment & { content: string };

const attachments = {
  get: (url: string) => fetchProviderForAttachment(url, {}, { responseType: 'blob' }, 'GET'),
  getContent: (id: string) =>
    fetchProviderForAttachment(
      `${microservicePath}/${id}/content`,
      {},
      { responseType: 'arraybuffer' },
      'GET',
    ),
  add: (data: tWriteAttachment) => fetchProvider.post<tReadAttachment>(microservicePath, data),
};

export default attachments;
