import { CancelToken } from 'axios';
import fetchProvider from 'utils/Axios';
import { tDictionary, tListParams } from 'types/global';

const microservicePath = '/products';

type tWriteProduct = {
  name: string;
  client_system_id?: string | null;
  equipments: string[];
  qualifications: string[];
};

type tReadProduct = {
  id: string;
  name: string;
  client_system_id?: string | null;
  equipments: tDictionary[];
  qualifications: tDictionary[];
};

type tDeleteProductResponse = tReadProduct;

export type tAddProductRequest = tWriteProduct;
type tAddProductResponse = tReadProduct;

type tGetProductResponse = tReadProduct;

export type tGetProductsRequest = tListParams & { client_id?: string; client_system_id?: string };
type tGetProductsResponse = { count: number; results: tReadProduct[] };

export type tUpdateProductRequest = tWriteProduct;
type tUpdateProductResponse = tReadProduct;

const products = {
  deleteProduct: (productId: string) =>
    fetchProvider.delete<tDeleteProductResponse>(`${microservicePath}/${productId}`),
  addProduct: (bodyRequest: tAddProductRequest) =>
    fetchProvider.post<tAddProductResponse>(`${microservicePath}`, bodyRequest),
  getProduct: (productId: string, cancelToken: CancelToken) =>
    fetchProvider.get<tGetProductResponse>(`${microservicePath}/${productId}`, { cancelToken }),
  getProducts: (params: tGetProductsRequest, cancelToken: CancelToken) =>
    fetchProvider.get<tGetProductsResponse>(`${microservicePath}`, { params, cancelToken }),
  updateProduct: (productId: string, bodyRequest: tUpdateProductRequest) =>
    fetchProvider.patch<tUpdateProductResponse>(`${microservicePath}/${productId}`, bodyRequest),
};

export default products;
