import { CancelToken } from 'axios';

import fetchProvider from 'utils/Axios';
import { operations } from 'types/api';
import { tOption } from 'components/RadioPicker/RadioPicker';

const microservicePath = '/clients';

type tClient = {
  external_system_id: string;
  name: string;
  priority: 'NORMAL' | 'HIGH' | null;
  brand?: string | null;
};

type tGetClientsRequest = operations['listClients']['parameters']['query'];
type tGetClientsResponse = { count: number; results: (tClient & { id: string })[] };

type tGetClientResponse = tClient & { id: string };

type tGetClientPrioritiesResponse = {
  count: number;
  results: tOption<'NORMAL' | 'HIGH'>[];
};

export type tAddOrUpdateClientRequest = tClient;
type tAddOrUpdateClientResponse = tClient & { id: string };

type tDeleteClientResponse = tClient & { id: string };

const clients = {
  addClient: (bodyRequest: tAddOrUpdateClientRequest) =>
    fetchProvider.post<tAddOrUpdateClientResponse>(`${microservicePath}`, bodyRequest),
  deleteClient: (clientId: string) =>
    fetchProvider.delete<tDeleteClientResponse>(`${microservicePath}/${clientId}`),
  getClients: (params: tGetClientsRequest, cancelToken: CancelToken) =>
    fetchProvider.get<tGetClientsResponse>(`${microservicePath}`, { params, cancelToken }),
  getClientPriorities: (cancelToken: CancelToken) =>
    fetchProvider.get<tGetClientPrioritiesResponse>('/client_priorities', { cancelToken }),
  getClient: (clientId: string, cancelToken: CancelToken) =>
    fetchProvider.get<tGetClientResponse>(`${microservicePath}/${clientId}`, { cancelToken }),
  updateClient: (clientId: string, bodyRequest: tAddOrUpdateClientRequest) =>
    fetchProvider.put<tAddOrUpdateClientResponse>(`${microservicePath}/${clientId}`, bodyRequest),
};

export default clients;
