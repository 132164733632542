import styled from 'styled-components';

import { colorRadicalRed, colorWhite } from 'styles/constants';

export const BadgeElement = styled.div`
  align-items: center;
  background: ${colorRadicalRed};
  border-radius: 16px;
  color: ${colorWhite};
  display: flex;
  font-size: 10px;
  height: 16px;
  justify-content: center;
  padding: 0 4px;
  position: absolute;
  right: -6px;
  top: 0;
  min-width: 8px;
`;
