import { CancelToken } from 'axios';
import i18n from 'i18next';

import mobileGroupsAgent, {
  tAddMobileGroupRequest,
  tGetMobileGroupsRequest,
  tUpdateMobileGroupRequest,
} from 'agents/mobileGroups';
import { tDropdownOption } from 'types/global';
import { tGetMobileGroup } from 'types/services/mobileGroups';
import errorsHandler from './utils/errorsHandler';

const deleteMobileGroup = (mobileGroupId: string) =>
  mobileGroupsAgent
    .deleteMobileGroup(mobileGroupId)
    .then(() => ({ state: true, errors: [], data: {} }))
    .catch((error) =>
      errorsHandler({
        defaultErrorMessage: i18n.t('Cannot remove', { value: i18n.t('mobile group form') }),
        error,
      }),
    );

const addMobileGroup = (bodyRequest: tAddMobileGroupRequest) =>
  mobileGroupsAgent
    .addMobileGroup(bodyRequest)
    .then(() => ({ state: true, errors: [], data: {} }))
    .catch((error) => {
      return errorsHandler({
        error,
        defaultErrorMessage: i18n.t('Cannot add', { value: i18n.t('mobile group form') }),
      });
    });

const updateMobileGroup = (mobileGroupId: string, bodyRequest: tUpdateMobileGroupRequest) =>
  mobileGroupsAgent
    .updateMobileGroup(mobileGroupId, bodyRequest)
    .then(() => ({ state: true, errors: [], data: {} }))
    .catch((error) =>
      errorsHandler({
        error,
        defaultErrorMessage: i18n.t('Cannot edit', { value: i18n.t('mobile group form') }),
      }),
    );

const getMobileGroup = ({
  mobileGroupId,
  cancelToken,
}: {
  mobileGroupId: string;
  cancelToken: CancelToken;
}) =>
  mobileGroupsAgent.getMobileGroup(mobileGroupId, cancelToken).then((response) => {
    const {
      data: {
        name,
        email,
        phone_number,
        service_managers,
        profit_center,
        area,
        auditor,
        equipments,
        qualifications,
        vehicle,
        status,
        online,
        ro_object,
      },
    } = response;

    const transformedServiceManagers: tDropdownOption<string>[] = !service_managers.length
      ? []
      : service_managers.map((item: { id: string; first_name: string; last_name: string }) => ({
          label: `${item.first_name} ${item.last_name}`,
          value: item.id,
        }));

    const transformedEquipments: tDropdownOption<string>[] = !equipments.length
      ? []
      : equipments.map((item) => ({
          label: item.name,
          value: item.id,
        }));

    const transformedQualifications: tDropdownOption<string>[] = !qualifications.length
      ? []
      : qualifications.map((item) => ({
          label: item.name,
          value: item.id,
        }));

    return {
      name,
      email,
      phone_number,
      service_manager_ids: transformedServiceManagers,
      profit_center: !!profit_center ? profit_center : '',
      area_id: {
        value: area.id,
        label: area.name,
      },
      auditor_id: auditor
        ? {
            label: `${auditor?.first_name} ${auditor?.last_name}`,
            value: auditor?.id,
          }
        : null,
      equipment_ids: transformedEquipments,
      qualification_ids: transformedQualifications,
      vehicle_id: vehicle
        ? {
            label: `${vehicle.maker} ${vehicle.model}, ${vehicle.registration_number}`,
            value: vehicle.id,
          }
        : null,
      status,
      online,
      // !! address >> API doesn't return >> to explain
      // !! update 2021-09-16 >> to explain after MVP
      address: null, // !! TODO >> to change when problem with API will be resolved
      ro_object: ro_object ?? '',
    };
  });

const getMobileGroups = ({
  limit = 10,
  offset = 0,
  search = '',
  cancelToken,
  auditor_id = '',
}: tGetMobileGroupsRequest & { cancelToken: CancelToken }) => {
  const params: tGetMobileGroupsRequest = { offset };

  if (limit) params.limit = limit;
  if (search) params.search = search;
  if (auditor_id) params.auditor_id = auditor_id;

  return mobileGroupsAgent.getMobileGroups(params, cancelToken).then((response) => {
    let transformedData: tGetMobileGroup[] = [];

    const {
      data: { count, results },
    } = response;

    if (!!count) {
      transformedData = results.map((mobileGroupData, index) => {
        const {
          id,
          name,
          email,
          phone_number,
          area: { name: areaName },
          auditor,
          service_managers,
          profit_center,
          equipments,
          qualifications,
          vehicle,
        } = mobileGroupData;

        return {
          id,
          itemData: [
            { key: 'order', value: index + 1 + offset },
            { key: 'name', value: name },
            { key: 'email', value: email },
            { key: 'phoneNumber', value: phone_number },
            { key: 'department', value: areaName },
            {
              key: 'auditor',
              value: !auditor ? '-' : `${auditor.first_name} ${auditor.last_name}`,
            },
          ],
          extraInfo: {
            service_manager: service_managers,
            profit_center: !profit_center ? '-' : profit_center,
            equipment: equipments,
            qualifications,
            car: !vehicle
              ? '-'
              : `${vehicle.maker} ${vehicle.model}, ${vehicle.registration_number}`,
          },
        };
      });
    }

    return { count, results: transformedData };
  });
};

export default {
  deleteMobileGroup,
  addMobileGroup,
  updateMobileGroup,
  getMobileGroup,
  getMobileGroups,
};
