import { AxiosResponse, CancelToken } from 'axios';
import i18n from 'i18next';

import serviceTemplatesAgent, {
  tAddServiceTemplateRequest,
  tGetServiceTemplatesRequest,
  tUpdateServiceTemplateRequest,
} from 'agents/serviceTemplates';
import { tGetServiceTemplate } from 'types/services/serviceTemplates';
import errorsHandler from './utils/errorsHandler';

const deleteServiceTemplate = (serviceTemplateId: string) => {
  const defaultError = [
    {
      key: '__general__',
      msg: i18n.t('Cannot remove', { value: i18n.t('service templates form') }),
    },
  ];
  return serviceTemplatesAgent
    .deleteServiceTemplate(serviceTemplateId)
    .then((resp: AxiosResponse) => ({ state: true, errors: [], data: {}, status: resp.status }))
    .catch((error) => ({ state: false, errors: error?.response?.data ?? defaultError, data: {} }));
};

const getServiceTemplates = ({
  limit = 10,
  offset = 0,
  search = '',
  cancelToken,
}: tGetServiceTemplatesRequest & { cancelToken: CancelToken }) => {
  const params: tGetServiceTemplatesRequest = { offset };

  if (limit) params.limit = limit;
  if (search) params.search = search;

  return serviceTemplatesAgent.getServiceTemplates(params, cancelToken).then((response) => {
    let transformedData: tGetServiceTemplate[] = [];

    const {
      data: { count, results },
    } = response;

    if (!!count) {
      transformedData = results.map((serviceTemplateData, index) => {
        const {
          id,
          survey_template: { name, question_templates },
          product: { name: productName },
        } = serviceTemplateData;

        return {
          id,
          itemData: [
            { key: 'order', value: index + 1 + offset },
            { key: 'name', value: name },
            { key: 'productName', value: productName },
          ],
          extraInfo: {
            steps: question_templates.map((template) => ({
              id: template.id,
              name: template.title,
              kind: template.kind,
            })),
          },
        };
      });
    }

    return { count, results: transformedData };
  });
};

const getQuestionTypes = (cancelToken: CancelToken) =>
  serviceTemplatesAgent.getQuestionTypes(cancelToken).then((response) => response.data);

const addServiceTemplates = (bodyRequest: tAddServiceTemplateRequest) =>
  serviceTemplatesAgent
    .addServiceTemplate(bodyRequest)
    .then((resp: AxiosResponse) => ({ state: true, errors: [], data: {}, status: resp.status }))
    .catch((error) =>
      errorsHandler({
        defaultErrorMessage: i18n.t('Cannot add', { value: i18n.t('service template form') }),
        error,
      }),
    );

const getServiceTemplate = ({ serviceTemplateId }: { serviceTemplateId: string }, cancelToken) =>
  serviceTemplatesAgent.getServiceTemplate(serviceTemplateId, cancelToken).then((response) => {
    const { data } = response;
    return {
      ...data,
    };
  });

const updateServiceTemplate = (
  serviceTemplateId: string,
  bodyRequest: tUpdateServiceTemplateRequest,
) =>
  serviceTemplatesAgent
    .updateServiceTemplate(serviceTemplateId, bodyRequest)
    .then((resp: AxiosResponse) => ({ state: true, errors: [], data: {}, status: resp.status }))
    .catch((error) =>
      errorsHandler({
        defaultErrorMessage: i18n.t('Cannot edit', { value: i18n.t('service template form') }),
        error,
      }),
    );

export default {
  deleteServiceTemplate,
  getServiceTemplates,
  getServiceTemplate,
  getQuestionTypes,
  addServiceTemplates,
  updateServiceTemplate,
};
