import { useState, useRef, MutableRefObject, useMemo, useEffect } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useTranslation } from 'react-i18next';
import { FixedSizeList as VariableSizeList } from 'react-window';

import useOnClickOutside from 'hooks/useOnClickOutside';
import { colorSlateBlue } from 'styles/constants';
import { IconButton } from 'styles/button';
import { Icon, Spinner } from 'components';
import {
  NotificationsContainer,
  NoResultsCopy,
  Panel,
  Title,
  EmptyNotificationsState,
  ListContainer,
  SpinnerContainer,
} from './NotificationsPanel.style';
import SingleNotification from './SingleNotification';
import Badge from './Badge';
import useNotifications from 'services/hooks/useNotifications';
import { useCurrentUser } from 'contexts/currentUser';
import useNotification from 'services/hooks/useNotification';
import { isAuditor, isOperator } from 'utils/roleHelpers';

const NotificationsPanel = () => {
  const ref = useRef() as MutableRefObject<HTMLDivElement>;
  const { t } = useTranslation();
  const listRef = useRef<VariableSizeList>(null);
  const { user } = useCurrentUser();
  const [showNotificationsPanel, setShowNotificationsPanel] = useState<boolean>(false);

  const canGetNotifications: boolean = useMemo(
    () => !!user?.systemRole && (isOperator(user?.systemRole) || isAuditor(user?.systemRole)),
    [user?.systemRole],
  );

  const {
    notifications: data,
    loadMoreNotifications,
    loadingNotifications,
    refetchList,
    isFetchingNextPage,
  } = useNotifications(showNotificationsPanel);

  // TO DO GET THIS PROP FROM useNotifications WHEN API READY
  const { data: notificationsCount, refetch: refetchNotificationCount } = useNotification({
    canGetNotifications,
  });

  useEffect(() => {
    const updateNotification = () => {
      refetchNotificationCount();
      refetchList();
    };

    navigator.serviceWorker.addEventListener('message', updateNotification);
    return () => navigator.serviceWorker.removeEventListener('message', updateNotification);
  }, []);

  useOnClickOutside(ref, () => setShowNotificationsPanel(false));

  const handleScroll = ({ top }) => {
    const formatedTopValue = Number(parseFloat(top).toFixed(2));
    if (formatedTopValue > 0.98) {
      loadMoreNotifications();
    }
  };

  const renderSpinnerContainer = () => (
    <SpinnerContainer>
      <Spinner withContainer={false} />
    </SpinnerContainer>
  );

  const renderPanelContent = () => {
    if (loadingNotifications && !isFetchingNextPage) {
      return renderSpinnerContainer();
    }

    if (!data.length) {
      return (
        <EmptyNotificationsState>
          <Icon iconName='noResults' width='11.25rem' height='6.25rem' />
          <NoResultsCopy>{t('No notifications')}</NoResultsCopy>
        </EmptyNotificationsState>
      );
    }

    return (
      <Scrollbars onScrollFrame={handleScroll} autoHide autoHeight autoHeightMax={400}>
        <VariableSizeList
          ref={listRef}
          height={data.length * 120}
          itemCount={data.length}
          itemSize={20}
          style={{ overflow: 'unset' }}
          width='100%'
        >
          {({ index }) => {
            return (
              <ListContainer>
                <SingleNotification key={data[index].id} {...data[index]} />
              </ListContainer>
            );
          }}
        </VariableSizeList>
      </Scrollbars>
    );
  };

  return (
    <NotificationsContainer ref={ref}>
      <IconButton
        onClick={() =>
          setShowNotificationsPanel((showNotificationsPanel: boolean) => !showNotificationsPanel)
        }
      >
        <Icon fill={colorSlateBlue} iconName='notifications' />
        <Badge count={typeof notificationsCount === 'number' ? notificationsCount : 0} />
      </IconButton>
      {showNotificationsPanel && (
        <Panel>
          <Title>{t('Notifications')}</Title>
          {renderPanelContent()}
          {isFetchingNextPage && !!data.length && renderSpinnerContainer()}
        </Panel>
      )}
    </NotificationsContainer>
  );
};

export default NotificationsPanel;
