import styled from 'styled-components';

import { IconButton } from 'styles/button';

import {
  boxShadowLight,
  colorGhostWhite,
  colorLightSlateGrey,
  colorMagnolia,
  colorMelrose,
  colorSlateBlue,
  colorWhite,
  gradientPurple,
  zIndexSticky,
} from 'styles/constants';

export const IconButtonInput = styled(IconButton)`
  position: absolute;
  right: -20px;
`;

export const JumpToPageText = styled.div`
  color: ${colorSlateBlue};
  font-size: 12px;
  font-weight: bold;
  margin-right: 12px;
`;

export const JumpToPageInput = styled.input`
  border: none;
  font-size: 12px;
  max-width: 44px;
  outline: none;
`;

export const JumpToPageBlock = styled.div`
  align-items: center;
  display: flex;
  margin-left: 48px;
  @media screen and (max-width: 1320px) {
    justify-content: center;
    margin-bottom: 1rem;
    margin-left: 0;
  }
`;

export const PaginationContainer = styled.div`
  border-radius: 8px;
  display: flex;
  .pagination {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 8px;
    li {
      align-items: center;
      background: ${colorWhite};
      border: 1px solid ${colorLightSlateGrey};
      border-radius: 8px;
      cursor: pointer;
      display: flex;
      font-size: 12px;
      margin-right: 4px;
      &:hover:not(.active) {
        background: ${colorMagnolia};
        border: 1px solid blue;
      }
      &.next {
        background: transparent;
        margin-right: 0;
        &:hover {
          background: none;
          border: 0;
        }
        @media screen and (max-width: 580px) {
          display: none;
        }
      }
      &.previous {
        background: transparent;
        &:hover {
          background: none;
          border: 0;
        }
        @media screen and (max-width: 580px) {
          display: none;
        }
      }
      svg {
        fill: ${colorSlateBlue};
      }
      &.disabled {
        pointer-events: none;
        svg {
          fill: ${colorMelrose};
        }
      }
      &.break-me {
        background: transparent;
        border: none;
        pointer-events: none;
        @media screen and (max-width: 420px) {
          width: 10px;
          svg {
            width: 10px !important;
          }
        }
        svg {
          fill: ${colorMelrose};
        }
        a {
          align-items: flex-end;
        }
      }
      a {
        align-items: center;
        display: flex;
        font-size: 12px;
        height: 32px;
        justify-content: center;
        outline: none;
        width: 32px;
        @media screen and (max-width: 1366px) {
          height: 28px;
          width: 28px;
        }
      }
      &:last-child {
        border: none;
      }
      &:first-child {
        border: none;
      }
      &.active {
        background: ${gradientPurple} no-repeat padding-box;
        border: none;
        height: 34px;
        width: 34px;
        a {
          color: ${colorWhite};
          height: 34px;
          width: 34px;
        }
      }
    }
  }
  @media screen and (max-width: 1320px) {
    display: block;
  }
`;

export const Pages = styled.div`
  align-items: center;
  display: flex;
  @media screen and (max-width: 1320px) {
    justify-content: center;
    margin-bottom: 1rem;
  }
  @media screen and (max-width: 580px) {
    > button {
      display: none;
    }
  }
`;

export const Results = styled.div`
  align-items: center;
  display: flex;
  font-size: 12px;
  line-height: 19px;
  position: relative;
  ul {
    background: ${colorWhite};
    border-radius: 8px;
    box-shadow: ${boxShadowLight};
    list-style: none;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: absolute;
    right: 0;
    width: 96px;
    li {
      button {
        background: ${colorWhite};
        border: none;
        height: 32px;
        outline: none;
        padding: 0 16px;
      }
      &:hover {
        button {
          background: ${colorSlateBlue};
          color: ${colorWhite};
          cursor: pointer;
        }
      }
    }
  }
  @media screen and (max-width: 1320px) {
    justify-content: center;
  }
`;

export const ResultsButton = styled.button`
  align-items: center;
  display: flex;
  font-size: 12px;
  width: 100%;
  svg {
    margin-left: 5px;
  }
  span {
    font-size: 12px;
    margin-left: 6px;
  }
`;

export const Wrapper = styled.footer`
  align-items: center;
  background: ${colorGhostWhite};
  border-radius: 0 0 8px 8px;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  padding: 4px 16px;
  position: sticky;
  right: 0;
  z-index: ${zIndexSticky};
  @media screen and (max-width: 1320px) {
    display: block;
  }
`;

export const ResultsContainer = styled.p`
  align-items: center;
  background: ${colorWhite};
  border: 1px solid ${colorLightSlateGrey};
  border-radius: 16px;
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  height: 30px;
  padding: 0 8px 0 16px;
  width: 62px;
  &:hover {
    border-color: ${colorSlateBlue};
  }
  @media screen and (max-width: 1320px) {
    max-width: 62px;
  }
`;

export const ResultsTitle = styled.p`
  color: ${colorSlateBlue};
  font-size: 12px;
  font-weight: bold;
  margin-right: 12px;
`;

export const JumpToPageContainer = styled.div`
  align-items: center;
  background: ${colorWhite};
  border: 1px solid ${colorLightSlateGrey};
  border-radius: 16px;
  box-sizing: border-box;
  display: flex;
  height: 30px;
  justify-content: space-between;
  padding: 0 8px 0 16px;
  position: relative;
  width: 92px;
  &:hover {
    border-color: ${colorSlateBlue};
  }
`;
