import { useRef, useEffect, MutableRefObject } from 'react';

import { useMaps } from 'contexts/maps';
import { Icon, Button, DisplayTaskData } from 'components';
import DisplayTaskLocationAndScheduler from './DisplayTaskData';

import { Container, Header, Title, Actions, Edit, Remove, Main } from './Task.style';
import { tTableTransformedItem } from 'types/global';
import { tGetServiceData } from 'types/services/services';

type tProps = {
  task: tGetServiceData;
  // eslint-disable-next-line no-unused-vars
  setSize?: (height: number) => void;
  // eslint-disable-next-line no-unused-vars
  remove: (task: tGetServiceData) => void;
  // eslint-disable-next-line no-unused-vars
  edit?: (item: tTableTransformedItem) => void;
  isReadOnly?: boolean;
};

const Task = ({
  task,
  setSize = () => {},
  remove,
  edit = () => {},
  isReadOnly = false,
}: tProps) => {
  const { task: activeTask, setActiveTask } = useMaps();
  const taskRef = useRef() as MutableRefObject<HTMLInputElement>;

  const removeHandler = () => remove(task);
  const editHandler = () => edit({ itemData: [], id: String(task.id) });

  useEffect(() => {
    setSize(+taskRef.current.clientHeight);
  }, [taskRef.current]);

  return (
    <Container ref={taskRef} $active={activeTask?.id === task.id}>
      <Header>
        <Button onClick={() => setActiveTask(task)}>
          <Title>{task.name}</Title>
        </Button>
        {!isReadOnly ? (
          <Actions>
            <Edit data-id='task-edit' type='button' onClick={editHandler}>
              <Icon iconName='edit' />
            </Edit>
            <Remove type='button' data-id='task-remove' onClick={removeHandler}>
              <Icon iconName='remove' />
            </Remove>
          </Actions>
        ) : null}
      </Header>
      <Main>
        <DisplayTaskLocationAndScheduler task={task} />
        <DisplayTaskData
          text={
            !!task.qualifications.length
              ? task.qualifications.map((qualification) => qualification.name).join(', ')
              : '-'
          }
          icon='howToReg'
        />
        <DisplayTaskData
          text={
            !!task.equipments.length
              ? task.equipments.map((equipment) => equipment.name).join(', ')
              : '-'
          }
          icon='homeRepairService'
        />
      </Main>
    </Container>
  );
};

export default Task;
