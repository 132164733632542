import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

import { Label } from 'components';
import DisplayTaskLocationAndScheduler from 'components/Tasks/DisplayTaskData';
import { BottomGap } from 'styles/GlobalStyledComponents';
import { defaultDateFormat } from 'utils/constants';

import { AssignTaskContainer } from './AssignTaskToGroup.style';
import { tTaskShortData } from 'types/services/tasks';

type tAssignTaskToGroup = {
  executionTime?: Date | null;
  task: tTaskShortData;
};

const AssignTaskToGroup = ({ executionTime = new Date(), task }: tAssignTaskToGroup) => {
  const { t } = useTranslation();

  return (
    <AssignTaskContainer>
      <Label>{task.name}</Label>
      <BottomGap $gap='12px'>
        <DisplayTaskLocationAndScheduler task={task} />
      </BottomGap>
      {!!executionTime && (
        <>
          <Label>{t('Execution time')}</Label>
          {format(executionTime, defaultDateFormat)}
        </>
      )}
    </AssignTaskContainer>
  );
};

export default AssignTaskToGroup;
