import styled, { keyframes } from 'styled-components';
import { zIndexTooltip, colorSlateBlue } from 'styles/constants';

export const Container = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: ${zIndexTooltip};
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Loader = styled.div`
  border-radius: 50%;
  color: ${colorSlateBlue};
  font-size: 11px;
  text-indent: -99999em;
  margin: 55px auto;
  position: relative;
  width: 10em;
  height: 10em;
  box-shadow: inset 0 0 0 1em;
  transform: translateZ(0);
  &:before,
  &:after {
    position: absolute;
    content: '';
    border-radius: 50%;
  }
  &:before {
    width: 5.2em;
    height: 10.2em;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 10.2em 0 0 10.2em;
    top: -0.1em;
    left: -0.1em;
    transform-origin: 5.1em 5.1em;
    animation: ${rotate} 2s infinite ease 1.5s;
  }
  &:after {
    width: 5.2em;
    height: 10.2em;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 0 10.2em 10.2em 0;
    top: -0.1em;
    left: 4.9em;
    transform-origin: 0.1em 5.1em;
    animation: ${rotate} 2s infinite ease;
  }
`;
