import { useState, useEffect, useRef, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { FixedSizeList as VList } from 'react-window';
import { positionValues, Scrollbars } from 'react-custom-scrollbars-2';
import debounce from 'lodash.debounce';

import useOnClickOutside from 'hooks/useOnClickOutside';
import { Error, Icon, Label, Spinner } from 'components';
import { tDropdownOption } from 'types/global';
import {
  Container,
  DownSvg,
  DownButton,
  List,
  Option,
  OptionButton,
  NoMatch,
  Input,
  RemoveSelectButton,
  InputContainer,
} from './DropDown.style';

export type tDropdown<T> = {
  options: tDropdownOption<T>[];
  onChange: (val: tDropdownOption<T> | null) => void;
  value: tDropdownOption<T> | null;
  error?: string;
  label?: string;
  disabled?: boolean;
  isOptional?: boolean;
  searchValue?: string;
  setSearchValue?: (search: string) => void;
  marginBottom?: string;
  showDeleteSelectedValue?: boolean;
  isInfinityScroll?: boolean;
  infinityScrollFetchFunction?: () => Promise<void>;
  isLoading?: boolean;
  dataTestId?: string;
  positionList?: string;
  menuPlacement?: 'top' | 'bottom';
};

const DropDown = <T,>({
  options,
  onChange,
  value,
  error = '',
  menuPlacement = 'bottom',
  label = '',
  disabled = false,
  isOptional = false,
  marginBottom = '1rem',
  showDeleteSelectedValue = true,
  searchValue = '',
  setSearchValue,
  isInfinityScroll = false,
  infinityScrollFetchFunction = async () => {},
  isLoading = false,
  dataTestId,
  positionList = '',
}: tDropdown<T>) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLInputElement>(null);
  const listRef = useRef<VList>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [show, setShow] = useState<boolean>(false);

  const debouncedFetchFunction = debounce(infinityScrollFetchFunction, 1000, {
    leading: true,
    trailing: false,
  });

  const handleScroll = ({ scrollTop, scrollHeight, clientHeight }: positionValues) => {
    // scrollTop / (scrollHeight - clientHeight) = 1 if scroll bar reach bottom
    const padding = 100;
    const result = (scrollTop + padding) / (scrollHeight - clientHeight);
    if (!!listRef.current) listRef.current.scrollTo(scrollTop);
    if (result >= 1 && isInfinityScroll) {
      debouncedFetchFunction();
    }
  };

  const select = (value: tDropdownOption<T> | null) => {
    onChange(value);
    setShow(false);
  };

  useOnClickOutside(ref, () => setShow(false));

  useEffect(() => {
    if (show && inputRef?.current) {
      inputRef.current.focus();
    }
  }, [show, isLoading]);

  return (
    <Container $marginBottom={marginBottom} ref={ref}>
      {!!label && <Label optional={isOptional}>{label}</Label>}
      <InputContainer $isLoading={isLoading}>
        <Input
          $show={show}
          value={show ? searchValue : value?.label || t('Select')}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            if (setSearchValue) {
              setSearchValue(e.target.value);
            }
          }}
          type='text'
          $error={!!error}
          onFocus={() => {
            setShow(true);
          }}
          ref={inputRef}
          disabled={disabled}
          readOnly={!setSearchValue}
          data-testid={dataTestId}
        />
        {isLoading ? (
          <Spinner withContainer={false} />
        ) : (
          <>
            {!!value && showDeleteSelectedValue ? (
              <RemoveSelectButton onClick={() => select(null)}>
                <Icon iconName='close' width='1rem' height='1rem' />
              </RemoveSelectButton>
            ) : null}
            <DownButton $show={show} type='button' onClick={() => !disabled && setShow(!show)}>
              <DownSvg />
            </DownButton>
          </>
        )}
      </InputContainer>
      {!!error && <Error text={error} />}
      {show && !disabled && (
        <List label={label} $menuPlacement={menuPlacement} $positionList={positionList}>
          {options.length > 0 && (
            <Scrollbars autoHeight autoHeightMax={300} onUpdate={handleScroll}>
              <VList
                height={options.length > 5 ? 150 : options.length * 40}
                itemCount={options.length}
                itemSize={40}
                width='100%'
                ref={listRef}
                style={{ overflow: 'unset' }}
              >
                {({ index, style }) => (
                  <Option style={{ ...style }} $active={value?.value === options[index].value}>
                    <OptionButton type='button' onClick={() => select(options[index])}>
                      {options[index].label}
                    </OptionButton>
                  </Option>
                )}
              </VList>
            </Scrollbars>
          )}
          {options.length === 0 && !isLoading && <NoMatch>{t('No match')}</NoMatch>}
          {options.length === 0 && isLoading && <NoMatch>{t('Loading')}...</NoMatch>}
        </List>
      )}
    </Container>
  );
};

export default DropDown;
