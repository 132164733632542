/* eslint no-underscore-dangle: 0 */
import { useEffect } from 'react';
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
  Routes,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { appVersion } from 'utils/constants';

export let SentryRoutes = Routes;

const initSentry = () => {
  if (!!window?.__env__?.SENTRY_URL) {
    const sampling = isNaN(parseFloat(window.__env__?.SENTRY_SAMPLING))
      ? 1.0
      : parseFloat(window.__env__?.SENTRY_SAMPLING);
    Sentry.init({
      dsn: window.__env__.SENTRY_URL,
      environment: window?.__env__?.ENVIRONMENT ?? 'unknown',
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV6Instrumentation(
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
          ),
        }),
        Sentry.breadcrumbsIntegration({
          dom: { serializeAttribute: ['data-testid'] },
        }),
      ],
      tracePropagationTargets: [new RegExp(window.__env__.API || 'api')],
      tracesSampleRate: sampling,
    });

    Sentry.setTag('app_type', 'web');
    Sentry.setTag('app_version', appVersion ?? 'unknown');
    Sentry.setTag('release', appVersion ?? 'unknown');

    // Higher order component, which will enable Sentry to reach router context
    SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
  }
};

const setSentryError = (error, errorInfo) => {
  if (!!window?.__env__?.SENTRY_URL) {
    if (errorInfo) {
      Sentry.withScope((scope) => {
        scope.setExtras(errorInfo);
        Sentry.captureException(error);
      });
    } else Sentry.captureException(error);
  }
};

export { initSentry, setSentryError };
