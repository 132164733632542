import { produce } from 'immer';
import { ERROR, OPTION_PHONE_NUMBER, RESET_FORM, SAVE, SAVE_PHONE_NUMBER } from 'utils/constants';
import { tErrorAction, tResetForm, tSaveAction } from 'types/form';
import { tDropdownOption } from 'types/global';
import { tAddress, tPhoneNumber } from 'agents/locations';

export type tLocationFormState = {
  name: {
    value: string;
    error: string;
  };
  address: {
    value: null | tDropdownOption<tAddress>;
    error: string;
  };
  phone_numbers: {
    value: tPhoneNumber[];
    error: string;
  };
  contracts: {
    value: tDropdownOption<string>[];
    error: string;
  };
  external_system_id: {
    value: null | string;
    error: string;
  };
  commercial_district: {
    value: string;
    error: string;
  };
};

export const helperPhones: tPhoneNumber = {
  number: '',
  notification_seven_days_before: false,
  notification_one_day_before: false,
  notification_on_start: false,
  main: false,
};

export const initialState: tLocationFormState = {
  name: {
    value: '',
    error: '',
  },
  address: {
    value: null,
    error: '',
  },
  phone_numbers: {
    value: Array(5).fill(helperPhones),
    error: '', // phone number errors are handled directly in form
  },
  contracts: {
    value: [],
    error: '',
  },
  external_system_id: {
    value: null,
    error: '',
  },
  commercial_district: {
    value: '',
    error: '',
  },
};

type tSavePhoneNumberAction = {
  type: typeof SAVE_PHONE_NUMBER;
  name: 'phone_numbers';
  index: number;
  value: string;
};

type tSavePhoneOptionAction = {
  type: typeof OPTION_PHONE_NUMBER;
  name: 'phone_numbers';
  index: number;
  toChange: string;
  value: boolean;
};

type tActions =
  | tSavePhoneNumberAction
  | tSavePhoneOptionAction
  | tSaveAction<tLocationFormState>
  | tErrorAction<tLocationFormState>
  | tResetForm;

const addLocationFormReducer = produce((state: tLocationFormState, action: tActions) => {
  const { type } = action;
  switch (type) {
    case SAVE_PHONE_NUMBER:
      state[action.name].value[action.index].number = action.value;
      state[action.name].value[action.index].main = action.index === 0;
      break;
    case OPTION_PHONE_NUMBER:
      state[action.name].value[action.index][action.toChange] = action.value;
      break;
    case SAVE:
      if (action.name === 'phone_numbers') {
        if (action.value.length < 5) {
          for (let i = action.value.length; i < 5; i++) {
            action.value.push(helperPhones);
          }
        }
        state[action.name].value = action.value;
      } else {
        state[action.name].value = action.value;
        state[action.name].error = '';
      }
      break;
    case ERROR:
      state[action.name].error = action.error;
      break;
    case RESET_FORM:
      return { ...initialState };
    default:
      throw new Error();
  }
});

export default addLocationFormReducer;
