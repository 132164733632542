import styled from 'styled-components';

export const Buttons = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
  & > * {
    margin-left: 0.5rem;
  }
`;

export const Container = styled.div`
  padding: 24px;
`;

export const IconContainer = styled.div`
  margin-bottom: 1rem;
  text-align: center;
`;

export const Title = styled.div`
  padding-bottom: 16px;
  font-size: 14px;
  line-height: 17px;
`;

export const Task = styled.div`
  font-size: 12px;
  line-height: 15px;
  font-weight: bold;
`;
