import styled from 'styled-components';

import {
  colorLightSlateGrey,
  colorMagnolia,
  colorSlateBlue,
  gradientPurple,
} from 'styles/constants';

export const MenuItem = styled.div`
  height: 48px;
  margin: 0 -24px;
  position: relative;
  a {
    align-items: center;
    color: ${colorLightSlateGrey};
    display: flex;
    font-size: 14px;
    font-weight: bold;
    height: 100%;
    padding-left: 24px;
    text-transform: uppercase;
    &:hover,
    &.active {
      background: ${colorMagnolia};
      color: ${colorSlateBlue};
      &:before {
        background: ${gradientPurple};
        bottom: 0;
        content: '';
        position: absolute;
        left: 0;
        width: 4px;
        top: 0;
      }
      svg {
        fill: ${colorSlateBlue};
      }
    }
  }
  @media screen and (max-width: 1000px) {
    a {
      &.active,
      &:hover {
        background: transparent;
        &:before {
          background: transparent;
        }
      }
    }
  }
`;

export const MenuItemCopy = styled.h3`
  font-size: 14px;
  margin: 0 0 0 16px;
`;
