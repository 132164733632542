import { CancelToken } from 'axios';
import i18n from 'i18next';

import servicesAgent, {
  tAddServiceRequest,
  tAssignServiceToMobileGroupRequest,
  tGetServicesRequest,
  tGetServicesSummaryRequest,
  tUpdateServiceRequest,
} from 'agents/services';
import { tGetServiceData } from 'types/services/services';
import { eStatuses } from 'agents/services';
import errorsHandler from './utils/errorsHandler';

const getConfirmationMethods = (cancelToken: CancelToken) =>
  servicesAgent.getConfirmationMethods(cancelToken).then((response) => response.data);

const getPeriodicityIntervalTypes = (cancelToken: CancelToken) =>
  servicesAgent.getPeriodicityIntervalTypes(cancelToken).then((response) => response.data);

const getServices = ({
  limit = 10,
  offset = 0,
  search = '',
  cancelToken,
  status,
  earliest_start_time_after,
  earliest_start_time_before,
  latest_finish_time_after,
  mobile_group_ordering,
  start_time_after,
  start_time_before,
  finish_time_after,
  finish_time_before,
  sort_by,
  sort_order,
  planned_start_time_after,
  planned_start_time_before,
}: tGetServicesRequest & { cancelToken: CancelToken }) => {
  const params: tGetServicesRequest = { offset };

  if (limit) params.limit = limit;
  if (status) params.status = status;
  if (search) params.search = search;
  if (earliest_start_time_after) params.earliest_start_time_after = earliest_start_time_after;
  if (earliest_start_time_before) params.earliest_start_time_before = earliest_start_time_before;
  if (latest_finish_time_after) params.latest_finish_time_after = latest_finish_time_after;
  if (status !== eStatuses.NEW && mobile_group_ordering)
    params.mobile_group_ordering = mobile_group_ordering;
  if (start_time_after) params.start_time_after = start_time_after;
  if (start_time_before) params.start_time_before = start_time_before;
  if (finish_time_after) params.finish_time_after = finish_time_after;
  if (finish_time_before) params.finish_time_before = finish_time_before;
  if (sort_by) params.sort_by = sort_by;
  if (sort_order) params.sort_order = sort_order;
  if (planned_start_time_after) params.planned_start_time_after = planned_start_time_after;
  if (planned_start_time_before) params.planned_start_time_before = planned_start_time_before;
  return servicesAgent.getServices(params, cancelToken).then((response) => {
    let transformedData: tGetServiceData[] = [];
    const {
      data: { count, results },
    } = response;

    if (!!count) {
      transformedData = results.map((task) => {
        const {
          id,
          name,
          earliest_start_time,
          latest_finish_time,
          location: {
            address: {
              formatted_address,
              gps_location: { latitude, longitude },
            },
          },
          qualifications,
          equipments,
          client,
          priority,
          mobile_group,
          abortion_reason,
          cancellation_reason,
          schedule_start_time,
          schedule_end_time,
          start_time,
          end_time,
          aborted_at,
          confirmation: { confirmation_result, no_pin_reason },
        } = task;

        return {
          id,
          name,
          start: new Date(earliest_start_time),
          end: new Date(latest_finish_time),
          address: formatted_address || '',
          position: { lat: latitude!, lng: longitude! }, // TODO: Remove nonnull assertion. Atm latitude and longitude in openapi are optional
          equipments: equipments ?? [],
          qualifications: qualifications ?? [],
          client,
          priority,
          mobile_group,
          abortion_reason,
          cancellation_reason,
          schedule_start_time: schedule_start_time ? new Date(schedule_start_time) : null,
          schedule_end_time: schedule_end_time ? new Date(schedule_end_time) : null,
          start_time: start_time ? new Date(start_time) : null,
          end_time: end_time ? new Date(end_time) : null,
          aborted_at: aborted_at ? new Date(aborted_at) : null,
          confirmation: { confirmation_result, no_pin_reason },
        };
      });
    }
    return { count, results: transformedData };
  });
};

const getServicesSummary = (params: tGetServicesSummaryRequest) => {
  return servicesAgent.getServicesSummary(params).then((response) => response.data);
};

const getService = ({ id }: { id: string }, cancelToken) =>
  servicesAgent.getService(id, cancelToken).then((response) => {
    const {
      data: {
        id,
        name,
        earliest_start_time,
        latest_finish_time,
        location,
        qualifications,
        equipments,
        client,
        priority,
        attachment,
        description,
        survey,
        product,
        contract,
        confirmation_method,
        estimated_execution_time,
        mobile_group_service_template,
        zco,
      },
    } = response;

    const startTime = earliest_start_time.split('T')[0];
    const endTime = latest_finish_time.split('T')[0];
    const rangeDate = !(startTime === endTime);

    return {
      id,
      name,
      equipments,
      qualifications,
      priority,
      attachment,
      location,
      client,
      latest_finish_time,
      earliest_start_time,
      description,
      survey,
      product,
      contract,
      confirmation_method,
      estimated_execution_time,
      mobile_group_service_template,
      rangeDate,
      zco,
    };
  });

const addService = (bodyRequest: tAddServiceRequest) =>
  servicesAgent
    .addService(bodyRequest)
    .then(() => ({ state: true, errors: [], data: {} }))
    .catch((error) =>
      errorsHandler({
        defaultErrorMessage: i18n.t('Cannot add', { value: i18n.t('service form') }),
        error,
      }),
    );

const updateService = (id: string, bodyRequest: tUpdateServiceRequest) =>
  servicesAgent
    .updateService(id, bodyRequest)
    .then(() => ({ state: true, errors: [], data: {} }))
    .catch((error) =>
      errorsHandler({
        defaultErrorMessage: i18n.t('Cannot edit', { value: i18n.t('service form') }),
        error,
      }),
    );

const cancel = ({ id, reason }: { id: string; reason: string }) =>
  servicesAgent
    .cancel(id, reason)
    .then(() => ({ state: true, errors: [], data: {} }))
    .catch((error) =>
      errorsHandler({
        defaultErrorMessage: i18n.t('Cannot cancel', { value: i18n.t('service form') }),
        error,
      }),
    );

const assignServiceToMobileGroup = ({
  serviceId,
  bodyRequest,
}: {
  serviceId: string;
  bodyRequest: tAssignServiceToMobileGroupRequest;
}) =>
  servicesAgent
    .assignServiceToMobileGroup(serviceId, bodyRequest)
    .then(() => ({ state: true, errors: [], data: {} }))
    .catch((error) =>
      errorsHandler({
        defaultErrorMessage: i18n.t('Cannot assign', { value: i18n.t('service form') }),
        error,
      }),
    );

export const getTaskReport = (id: string) =>
  servicesAgent
    .getTaskReport(id)
    .then(({ data }) => ({
      error: false,
      errorMessage: '',
      data: URL.createObjectURL(new Blob([data])),
    }))
    .catch(() => ({
      error: true,
      errorMessage: i18n.t('Report failed to download'),
      data: '',
    }));

export default {
  getConfirmationMethods,
  getPeriodicityIntervalTypes,
  getServices,
  getServicesSummary,
  getService,
  addService,
  updateService,
  cancel,
  assignServiceToMobileGroup,
  getTaskReport,
};
