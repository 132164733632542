import styled, { css } from 'styled-components';

import { Button } from 'styles/button';
import {
  boxShadowNotificationsPanel,
  colorGhostWhite,
  colorEclipse,
  colorLightSlateGrey,
  colorSlateBlue,
  colorMagnolia,
} from 'styles/constants';

type tNotificationStatus = {
  $unread: boolean;
};

export const Container = styled.div<tNotificationStatus>`
  border-radius: 8px;
  display: flex;
  padding: 12px;
  position: relative;
  ${({ $unread }) =>
    $unread &&
    css`
      background: ${colorGhostWhite};
      box-shadow: ${boxShadowNotificationsPanel};
    `}
`;

export const Message = styled.div`
  flex: 1;
  padding: 0 78px 0 12px;
  color: ${colorEclipse};
  font-size: 12px;
`;

export const StateButton = styled(Button)<tNotificationStatus>`
  position: absolute;
  right: 12px;
  top: 12px;
  background: ${colorMagnolia};
  width: 18px;
  height: 18px;
  border-radius: 18px;
  ${({ $unread }) =>
    $unread &&
    css`
      &:after {
        content: '';
        background: ${colorSlateBlue};
        width: 10px;
        height: 10px;
        position: absolute;
        border-radius: 10px;
        top: 4px;
        left: 4px;
      }
    `}
`;

export const Time = styled.div`
  color: ${colorLightSlateGrey};
  margin-top: 12px;
`;

export const NotificationMessage = styled.span`
  word-break: break-word;
`;
