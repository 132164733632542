import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import attachmentsService from 'services/attachments';

import { Icon } from 'components';
import { FlexElement } from 'styles/GlobalStyledComponents';
import createDownloadContent from 'utils/createDownloadContent';

import { colorSlateBlue } from 'styles/constants';
import notify from 'utils/notify';
import {
  FileDetailsCopy,
  FileInformationContainer,
  FileItem,
  FileNameCopy,
  CloseButton,
} from './FileWrapper.style';

export type tFile = {
  id: string | number;
  file: File;
};

type tFileWrapper = {
  id: string | number;
  name: string;
  sizeTypeMessage?: string;
  information?: string;
  downloadUrl?: string;
  // eslint-disable-next-line no-unused-vars
  removeItem: (id: number | string) => void;
};

export const FileWrapper: FC<tFileWrapper> = ({
  id,
  name,
  sizeTypeMessage = '',
  information = '',
  downloadUrl = '',
  removeItem,
}) => {
  const { t } = useTranslation();

  const download = () =>
    attachmentsService.getAttachment(downloadUrl).then((response) => {
      const { error, errorMessage } = response;

      if (error) {
        notify({ copy: errorMessage, type: 'error' });
      } else {
        createDownloadContent(response.data, name);
      }
    });

  return (
    <FileItem data-testid={`fileWrapper__${id}`}>
      <Icon iconName='filePresent' fill={colorSlateBlue} height='2rem' width='2rem' />
      <FileInformationContainer>
        <FileNameCopy>{name.replace(/(\w{15}).+([.]\w*)/g, '$1..$2')}</FileNameCopy>
        <FileDetailsCopy $type={sizeTypeMessage}>{t(information)}</FileDetailsCopy>
      </FileInformationContainer>
      <FlexElement>
        {!!downloadUrl && (
          <CloseButton onClick={download}>
            <Icon iconName='fileDownload' fill={colorSlateBlue} />
          </CloseButton>
        )}

        <CloseButton onClick={() => removeItem(id)}>
          <Icon iconName='close' />
        </CloseButton>
      </FlexElement>
    </FileItem>
  );
};

export default FileWrapper;
