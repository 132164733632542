import { forwardRef, MouseEvent } from 'react';

import { Icon } from 'components';
import { colorSlateBlue } from 'styles/constants';
import { DateCopy, PickerBox } from '../Datepicker.style';

type tCustomDatePickerInput = {
  center: boolean;
  disabled: boolean;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  value?: string;
};

const CustomDatePickerInput = forwardRef<HTMLButtonElement, tCustomDatePickerInput>(
  ({ center, disabled, value = '01.01.1970', onClick = () => {} }, ref) => (
    <PickerBox $inline ref={ref} onClick={onClick} disabled={disabled} $center={center}>
      <Icon iconName='calendarToday' width='1rem' height='1rem' fill={colorSlateBlue} />
      <DateCopy $disabled={disabled}>{value}</DateCopy>
    </PickerBox>
  ),
);

export default CustomDatePickerInput;
