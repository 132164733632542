import { CancelToken } from 'axios';
import i18n from 'i18next';

import contractsAgent, {
  tAddContractRequest,
  tGetContractsRequest,
  tUpdateContractRequest,
} from 'agents/contracts';
import { tContractTableData } from 'types/services/contracts';
import errorsHandler from './utils/errorsHandler';

const addContract = (bodyRequest: tAddContractRequest) =>
  contractsAgent
    .addContract(bodyRequest)
    .then(() => ({ state: true, errors: [], data: {} }))
    .catch((error) =>
      errorsHandler({
        defaultErrorMessage: i18n.t('Cannot add', { value: i18n.t('contract form') }),
        error,
      }),
    );

const updateContract = (contractId: string, bodyRequest: tUpdateContractRequest) =>
  contractsAgent
    .updateContract(contractId, bodyRequest)
    .then(() => ({ state: true, errors: [], data: {} }))
    .catch((error) =>
      errorsHandler({
        defaultErrorMessage: i18n.t('Cannot edit', { value: i18n.t('contract form') }),
        error,
      }),
    );

const deleteContract = (contractId: string) => {
  const defaultError = [
    { key: '__general__', msg: i18n.t('Cannot remove', { value: i18n.t('contract form') }) },
  ];

  return contractsAgent
    .deleteContract(contractId)
    .then(() => ({ state: true, errors: [], data: {} }))
    .catch((error) => ({ state: false, errors: error?.response?.data ?? defaultError, data: {} }));
};

const getContract = ({ contractId }: { contractId: string }, cancelToken: CancelToken) =>
  contractsAgent.getContract(contractId, cancelToken).then((response) => {
    const {
      data: {
        name,
        number,
        client: { id: clientId, name: clientName },
        product: { id: productId, name: productName },
      },
    } = response;

    return {
      name,
      number,
      client_id: { value: clientId, label: clientName },
      product_id: { value: productId, label: productName },
    };
  });

const getContracts = ({
  limit = 10,
  offset = 0,
  search = '',
  location_id = '',
  client_id = '',
  product_id = '',
  cancelToken,
}: tGetContractsRequest & { cancelToken: CancelToken }) => {
  const params: tGetContractsRequest = { offset };

  if (limit) params.limit = limit;
  if (search) params.search = search;
  if (location_id) params.location_id = location_id;
  if (client_id) params.client_id = client_id;
  if (product_id) params.product_id = product_id;

  return contractsAgent.getContracts(params, cancelToken).then((response) => {
    let transformedData: tContractTableData[] = [];

    const {
      data: { count, results },
    } = response;

    if (!!count) {
      transformedData = results.map((contractData, index) => {
        const {
          id,
          name,
          number: contractNumber,
          client: { name: clientName },
          product: { name: productName },
        } = contractData;

        return {
          id,
          itemData: [
            { key: 'order', value: index + 1 + offset },
            { key: 'name', value: name },
            { key: 'contractNumber', value: contractNumber },
            { key: 'clientName', value: clientName },
            { key: 'productName', value: productName },
          ],
        };
      });
    }

    return { count, results: transformedData };
  });
};

export default {
  addContract,
  updateContract,
  deleteContract,
  getContract,
  getContracts,
};
