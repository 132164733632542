import axios, {
  AxiosRequestConfig,
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import i18n from 'i18n';
import { baseURL } from 'utils/constants';

const options: AxiosRequestConfig = {
  baseURL,
  timeout: 10000,
  headers: { 'Content-Type': 'application/json' },
};

const fetchProvider = axios.create(options);
const { interceptors } = fetchProvider;
const { language } = i18n();

const getRequestConfig = (config: InternalAxiosRequestConfig) => {
  const tokenGM: string | null = window.localStorage.getItem('tokenGM');

  if (config.headers) {
    config.headers['Accept-Language'] = language;
    if (tokenGM) {
      config.headers['Authorization'] = `Bearer ${tokenGM}`;
    }
  }
  return config;
};

const getRequestError = (error: AxiosError) => Promise.reject(error);

const getResponse = (response: AxiosResponse) => response;

const getResponseError = (error: AxiosError) => Promise.reject(error);

interceptors.request.use(getRequestConfig, getRequestError);
interceptors.response.use(getResponse, getResponseError);

export const fetchProviderForAttachment = (
  url: string,
  // TODO: Add types (GMROZW-1091)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: any = {},
  method: 'GET' | 'POST' = 'GET',
) => {
  const token: string | null = window.localStorage.getItem('tokenGM');

  const optionsHandler: AxiosRequestConfig = {
    ...options,
    url: `${baseURL}${url}`,
    method,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
      'Accept-Language': language,
    },
  };

  return axios(optionsHandler);
};

export default fetchProvider;
