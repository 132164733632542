import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonsSection, Title, TopSection } from './PageWrapperTopSection.style';

type tPageWrapperTopSection = {
  actions?: JSX.Element[];
  title?: string;
};

const PageWrapperTopSection: FC<tPageWrapperTopSection> = ({
  actions = [],
  title = 'Dashboard',
}) => {
  const { t } = useTranslation();

  return (
    <TopSection>
      <Title>{t(title)}</Title>
      {!!actions.length && (
        <ButtonsSection>{actions.map((action: JSX.Element) => action)}</ButtonsSection>
      )}
    </TopSection>
  );
};

export default PageWrapperTopSection;
