import axios from 'axios';
import { useEffect } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import clientsService from 'services/clients';
import { defaultCacheStaleTime } from 'services/utils/constants';
import { onError } from 'utils/queryClient';

type tUseClientsPrioritiesArgs = {
  enabled?: boolean;
};

export const queryCacheName = 'getClientPriorities';

const useClientsPriorities = ({ enabled = true }: tUseClientsPrioritiesArgs) => {
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey: [queryCacheName],
    queryFn: ({ signal }) => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      const promise = clientsService.getClientPriorities(source.token);

      // Cancel the request if TanStack Query signals to abort
      signal?.addEventListener('abort', () => {
        source.cancel('Query was cancelled by TanStack Query');
      });

      return promise;
    },
    enabled,
    staleTime: defaultCacheStaleTime,
  });

  const refetchList = () => queryClient.invalidateQueries({ queryKey: [queryCacheName] });

  useEffect(() => {
    if (query.isError) onError(query.error);
  }, [query.isError, query.error]);

  return {
    ...query,
    refetchList,
  };
};

export default useClientsPriorities;
