import { tFile } from 'components/FileUpload/FileUpload';
import i18n from 'i18next';
import attachmentsAgent, { tWriteAttachment } from 'agents/attachments';

// TODO: Add types for attachments (GMROZW-1086)
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const toBase64 = (file: File): any =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const getContentAttachment = (id: string) =>
  attachmentsAgent
    .getContent(id)
    .then((response) => {
      const { data } = response;

      return {
        error: false,
        errorMessage: '',
        data,
      };
    })
    .catch(() => {
      return {
        error: true,
        errorMessage: i18n.t('Image failed to download'),
        data: '',
      };
    });

export const getAttachment = (attachmentUrl: string) =>
  attachmentsAgent
    .get(attachmentUrl)
    .then((response) => {
      const { data } = response;

      return {
        error: false,
        errorMessage: '',
        data: URL.createObjectURL(new Blob([data])),
      };
    })
    .catch(() => {
      return {
        error: true,
        errorMessage: i18n.t('Image failed to download'),
        data: '',
      };
    });

export const postAttachment = (data) => attachmentsAgent.add(data).then(({ data }) => data);

export const convertAttachmentOnApiFormat = async (file: File) => {
  let base64: null | tWriteAttachment = null;
  if (!!file) {
    const base64FullData = await toBase64(file);
    const content = base64FullData.split('base64,');
    base64 = {
      name: file.name,
      content: content[1],
      mime_type: file.type,
    };
  }
  return base64;
};

export const sendAttachments = async (files: tFile[]) => {
  const result = await Promise.all(
    files.map(async (file: tFile) => {
      const attachment: tWriteAttachment | null = await convertAttachmentOnApiFormat(file.file);
      if (!!attachment) {
        const apiReq = await postAttachment(attachment);
        return apiReq;
      }
      throw new Error(i18n.t('Cannot convert file'));
    }),
  );
  return result;
};

const attachments = {
  convertAttachmentOnApiFormat,
  getAttachment,
  sendAttachments,
  getContentAttachment,
  toBase64,
};

export default attachments;
