import axios from 'axios';
import { useEffect } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import mobileGroupService from 'services/mobileGroups';
import { onError } from 'utils/queryClient';
import { defaultCacheStaleTime } from 'services/utils/constants';

export const queryCacheName = 'getMobileGroup';

const useMobileGroup = ({ itemId }) => {
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey: [queryCacheName, itemId],
    queryFn: ({ signal }) => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      const promise = mobileGroupService.getMobileGroup({
        mobileGroupId: itemId,
        cancelToken: source.token,
      });

      // Cancel the request if TanStack Query signals to abort
      signal?.addEventListener('abort', () => {
        source.cancel('Query was cancelled by TanStack Query');
      });

      return promise;
    },
    staleTime: defaultCacheStaleTime,
    enabled: !!itemId,
  });

  const refetch = () => queryClient.invalidateQueries({ queryKey: [queryCacheName] });

  const invalidateQuery = (queryKey = [queryCacheName, itemId]) =>
    queryClient.invalidateQueries({ queryKey, refetchType: 'none' });

  useEffect(() => {
    if (query.isError) onError(query.error);
  }, [query.isError, query.error]);

  return {
    ...query,
    refetch,
    invalidateQuery,
  };
};

export default useMobileGroup;
