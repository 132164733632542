import { AxiosError } from 'axios';
import { tError } from 'types/global';

type tErrorsHandler = {
  defaultErrorMessage: string;
  error?: AxiosError;
};

export type tErrorsHandlerResponse = {
  state: boolean;
  errors: tError[];
  // TODO: Remove any from data type. Investigate if data can be deleted (GMROZW-1087)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  status?: number;
};

export type tConflictErrorsHandlerResponse = {
  data: tError[];
  status: number;
};

export const transformMutationsErrors = ({ error, defaultErrorMessage }: tErrorsHandler) => {
  const defaultError = {
    key: '__general__',
    loc: ['__general__'],
    msg: error?.message ?? defaultErrorMessage,
    status: error?.response?.status,
  };

  if (error?.response) {
    const apiErrors = error?.response?.data;
    if (error?.response?.status >= 500 && !Array.isArray(apiErrors)) {
      return {
        status: error.response.status,
        errors: [{ ...defaultError, msg: error.message }],
      };
    }

    return {
      status: error.response.status,
      errors: Array.isArray(apiErrors)
        ? apiErrors.map((item: tError) => {
            if (
              item.msg === 'User with specified email address already exists' ||
              item.msg === 'Użytkownik o podanym adresie email już istnieje' ||
              item.msg === 'User exists with same username'
            ) {
              return { ...item, key: 'email' };
            }

            if (item.msg === 'Kwalifikacja o podanej nazwie już istnieje') {
              return { ...item, key: 'name' };
            }

            if (item.msg === 'not_authorized') {
              return { ...item, msg: 'Brak permisji' };
            }

            return { ...item, data: { status: error?.response?.status } };
          })
        : [defaultError],
    };
  }

  return { status: error?.response?.status, errors: [defaultError] };
};

// TODO: delete when every post/put/delete request will be handled with react-query useMutation
const errorsHandler = ({ error, defaultErrorMessage }: tErrorsHandler): tErrorsHandlerResponse => {
  const defaultError = {
    key: '__general__',
    loc: ['__general__'],
    msg: error?.message ?? defaultErrorMessage,
    status: error?.response?.status,
  };

  if (error?.response) {
    const apiErrors = error?.response?.data;
    if (error?.response?.status >= 500 && !Array.isArray(apiErrors)) {
      return {
        status: error.response.status,
        state: false,
        errors: [{ ...defaultError, msg: error.message }],
        data: { status: error.response.status },
      };
    }

    return {
      status: error.response.status,
      state: false,
      errors: Array.isArray(apiErrors)
        ? apiErrors.map((item: tError) => {
            if (
              item.msg === 'User with specified email address already exists' ||
              item.msg === 'Użytkownik o podanym adresie email już istnieje' ||
              item.msg === 'User exists with same username'
            ) {
              return { ...item, key: 'email' };
            }

            if (item.msg === 'Kwalifikacja o podanej nazwie już istnieje') {
              return { ...item, key: 'name' };
            }

            if (item.msg === 'not_authorized') {
              return { ...item, msg: 'Brak permisji' };
            }

            return { ...item, data: { status: error?.response?.status } };
          })
        : [defaultError],
      data: {},
    };
  }

  return { status: error?.response?.status, state: false, errors: [defaultError], data: {} };
};

export default errorsHandler;
