import {
  colorGrandis,
  colorMediumSeaGreen,
  colorRadicalRed,
  colorMajorelleBlue,
} from 'styles/constants';

// on Push notifications type is taking from icons: src\components\NotificationsPanel\SingleNotification.tsx
const setToastIconFill = (type: string): string => {
  switch (type) {
    case 'error':
    case 'notificationAlarm':
      return colorRadicalRed;
    case 'alert':
      return colorGrandis;
    case 'success':
      return colorMediumSeaGreen;
    default:
      return colorMajorelleBlue;
  }
};

export default setToastIconFill;
