import { checkFileExtension, checkFileSize, convertFileType } from '../utils';

const checkFileError = (file: File, extensions: string[]): string[] => {
  const errors: string[] = [];

  const { type, size } = file;

  const fileSizeInMb = checkFileSize(size);
  const convertedFileType = convertFileType(type);
  const fileType = checkFileExtension(convertedFileType, extensions);

  if (fileSizeInMb) {
    errors.push('The file size is too large');
  }

  if (!fileType) {
    errors.push('Incorrect file extension');
  }

  return errors;
};

export default checkFileError;
