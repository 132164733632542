import styled, { css } from 'styled-components';

import {
  colorEchoBlue,
  colorLightSlateGrey,
  colorRadicalRed,
  colorSlateBlue,
  colorSolitude,
  colorWhite,
} from 'styles/constants';

export const Container = styled.div`
  margin-bottom: 1rem;
`;

export type tInputStyle = {
  disabled: boolean;
  $error: boolean;
  $search?: boolean;
};

export const InputStyle = css<tInputStyle>`
  background-color: ${colorWhite};
  border-color: ${colorLightSlateGrey};
  color: black;
  border-radius: 16px;
  border-style: solid;
  border-width: 1px;
  font-size: 12px;
  line-height: 30px;
  outline: none;
  padding: 0 16px;
  &:focus,
  &:hover {
    border-color: ${colorSlateBlue};
  }
  ${({ disabled }) =>
    disabled
      ? css`
          background-color: ${colorSolitude};
          border-color: ${colorEchoBlue};
          color: ${colorEchoBlue};
          &:focus,
          &:hover {
            border-color: ${colorEchoBlue};
          }
        `
      : css``}
  ${({ $error }) =>
    $error
      ? css`
          border-color: ${colorRadicalRed};
          &:focus,
          &:hover {
            border-color: ${colorRadicalRed};
          }
        `
      : css``}
      ${({ $search }) =>
    $search
      ? css`
          border: none;
        `
      : css``}
`;

export const InputElement = styled.input<tInputStyle>`
  ${InputStyle};
`;

export const Readonly = styled.div`
  font-size: 12px;
`;
