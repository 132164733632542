import styled from 'styled-components';

import {
  colorLightSlateGrey,
  colorRadicalRed,
  colorWhite,
  modalBackgroundTint,
  zIndexModal,
} from 'styles/constants';

export const Wrapper = styled.section`
  background: ${modalBackgroundTint};
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: ${zIndexModal};
`;

export const Container = styled.div`
  background: ${colorWhite};
  border-radius: 8px;
  left: 50%;
  max-width: 470px;
  min-width: 200px;
  padding: 24px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  > img {
    max-height: 320px;
    max-width: 470px;
  }
`;

export const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  svg {
    > path {
      fill: ${colorLightSlateGrey};
    }
    &:hover {
      > path {
        fill: ${colorRadicalRed};
      }
    }
  }
`;

export const Title = styled.h3`
  font-size: 18px;
`;

export const DetailPropTitle = styled.h4`
  margin-bottom: 6px;
`;

export const DetailPropValue = styled.div`
  font-size: 12px;
  margin-bottom: 6px;
`;
