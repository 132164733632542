import i18n from 'i18next';
import { AxiosResponse, CancelToken } from 'axios';

import equipmentAgent, {
  tAddEquipmentRequest,
  tGetEquipmentsRequest,
  tUpdateEquipmentRequest,
} from 'agents/equipment';
import errorsHandler from './utils/errorsHandler';
import { tEquipmentTableData } from 'types/services/equipment';

const addEquipment = (bodyRequest: tAddEquipmentRequest) =>
  equipmentAgent
    .addEquipment(bodyRequest)
    .then((resp: AxiosResponse) => ({ state: true, errors: [], data: {}, status: resp.status }))
    .catch((error) =>
      errorsHandler({
        defaultErrorMessage: i18n.t('Cannot add', { value: i18n.t('equipment form') }),
        error,
      }),
    );

const updateEquipment = (equipmentId: string, bodyRequest: tUpdateEquipmentRequest) =>
  equipmentAgent
    .updateEquipment(equipmentId, bodyRequest)
    .then((resp: AxiosResponse) => ({ state: true, errors: [], data: {}, status: resp.status }))
    .catch((error) =>
      errorsHandler({
        defaultErrorMessage: i18n.t('Cannot edit', { value: i18n.t('equipment form') }),
        error,
      }),
    );

const deleteEquipment = (equipmentId: string) => {
  const defaultError = [
    { key: '__general__', msg: i18n.t('Cannot remove', { value: i18n.t('equipment form') }) },
  ];

  return equipmentAgent
    .deleteEquipment(equipmentId)
    .then((resp: AxiosResponse) => ({ state: true, errors: [], data: {}, status: resp.status }))
    .catch((error) => ({ state: false, errors: error?.response?.data ?? defaultError, data: {} }));
};

const getEquipment = ({ equipmentId }: { equipmentId: string }, cancelToken) =>
  equipmentAgent.getEquipment(equipmentId, cancelToken).then((response) => {
    const {
      data: { name },
    } = response;

    return {
      name,
    };
  });

const getEquipments = ({
  limit = 10,
  offset = 0,
  search = '',
  cancelToken,
}: tGetEquipmentsRequest & { cancelToken: CancelToken }) => {
  const params: tGetEquipmentsRequest = { offset };

  if (limit) params.limit = limit;
  if (search) params.search = search;

  return equipmentAgent.getEquipments(params, cancelToken).then((response) => {
    let transformedData: tEquipmentTableData[] = [];

    const {
      data: { count, results },
    } = response;

    if (!!count) {
      transformedData = results.map((equipmentData, index) => {
        const { id, name } = equipmentData;

        return {
          id,
          itemData: [
            { key: 'order', value: index + 1 + offset },
            { key: 'name', value: name },
          ],
        };
      });
    }

    return { count, results: transformedData };
  });
};

export default {
  addEquipment,
  updateEquipment,
  deleteEquipment,
  getEquipment,
  getEquipments,
};
