import styled from 'styled-components';

export const LabelStyle = styled.label`
  font-size: 14px;
  line-height: 17px;
  font-weight: bold;
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
`;

export const OptionalText = styled.span`
  font-weight: normal;
  margin-left: 0.25rem;
`;
