import { ReactNode } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import Modal from 'components/Modal';

import { FieldsWrapper, Title, Buttons } from './FormModal.style';
import { Loader } from 'components/Spinner/Spinner.style';

type tFormModal = {
  customMinHeight?: number;
  title?: string;
  children?: ReactNode;
  buttons?: ReactNode;
  boxMaxWidth?: string;
  customTopElement?: ReactNode;
  paddingVertical?: boolean;
  isLoading?: boolean;
};

const FormModal = ({
  customMinHeight = 0,
  paddingVertical = false,
  title = '',
  children = null,
  buttons = null,
  boxMaxWidth,
  customTopElement = null,
  isLoading = false,
}: tFormModal) => (
  <Modal visible boxMaxWidth={boxMaxWidth}>
    {!!title && <Title>{title}</Title>}
    {customTopElement}
    <Scrollbars
      autoHide
      autoHeight
      autoHeightMin={!!customMinHeight ? customMinHeight : 50}
      autoHeightMax='55vh'
      autoHideTimeout={1000}
      autoHideDuration={200}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <FieldsWrapper $paddingVertical={paddingVertical}>{children}</FieldsWrapper>
      )}
    </Scrollbars>
    {!!buttons && !isLoading && <Buttons>{buttons}</Buttons>}
  </Modal>
);

export default FormModal;
