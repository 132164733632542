import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Datepicker, DropDown, Input, Label } from 'components';
import { tPeriodicityData, tDates, tDropdownOption } from 'types/global';
import { BottomGap, FlexBoxWithCalendar, FlexElement } from 'styles/GlobalStyledComponents';
import {
  ButtonsContainer,
  Container,
  DayButton,
  DropdownContainer,
  InputContainer,
} from './Periodicity.style';
import usePeriodicityIntervalTypes from 'services/hooks/usePeriodicityIntervalTypes';

type tDay = {
  value: number;
  name: string;
  active: boolean;
};

type tPeriodicity = {
  periodicityData?: tPeriodicityData | null;
  /* eslint-disable-next-line no-unused-vars */
  setPeriodicityData: (data: tPeriodicityData) => void;
  today?: Date;
  centerCalendarInput?: boolean;
};

const Periodicity: FC<tPeriodicity> = ({
  periodicityData = null,
  setPeriodicityData,
  today = new Date(),
  centerCalendarInput = false,
}) => {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState<Date>(today);
  const [endDate, setEndDate] = useState<Date>(today);
  const [repeatabilityValue, setRepeatabilityValue] = useState<string>('');
  const [repeatabilityType, setRepeatabilityType] = useState<tDropdownOption<string> | null>(null);
  const [days, setDays] = useState<tDay[]>([
    { value: 1, name: 'Mo', active: false },
    { value: 2, name: 'Tu', active: false },
    { value: 3, name: 'We', active: false },
    { value: 4, name: 'Th', active: false },
    { value: 5, name: 'Fr', active: false },
    { value: 6, name: 'Sa', active: false },
    { value: 7, name: 'Su', active: false },
  ]);

  const { data, isFetching: loading } = usePeriodicityIntervalTypes({});
  const periodicityIntervalTypesList = useMemo(() => data?.results ?? [], [data]);

  useEffect(() => {
    if (repeatabilityType?.value === 'day') {
      const nonActiveDays = days.map((day: tDay) => ({ ...day, active: false }));

      setDays(nonActiveDays);
    }
  }, [repeatabilityType?.value]);

  useEffect(() => {
    setPeriodicityData({
      repeatabilityType: repeatabilityType?.value ?? '',
      days: days
        .filter((day: Pick<tDay, 'active'>) => day.active)
        .map((day: Pick<tDay, 'value'>) => day.value),
      startDate,
      endDate,
      repeatabilityValue,
    });
  }, [repeatabilityType?.value, days, startDate, endDate, repeatabilityValue]);

  useEffect(() => {
    // TODO add when do the edit mode
  }, [periodicityData]);

  return (
    <Container>
      <BottomGap $gap='1rem'>
        <Label>{t('Repeat every')}</Label>
        <FlexElement>
          <InputContainer>
            <Input
              onChange={({ target: { value } }) => setRepeatabilityValue(value)}
              value={repeatabilityValue}
              placeholder='Np. 12'
            />
          </InputContainer>
          <DropdownContainer>
            <DropDown
              value={repeatabilityType}
              options={periodicityIntervalTypesList ?? []}
              onChange={setRepeatabilityType}
              isLoading={loading}
              showDeleteSelectedValue={false}
            />
          </DropdownContainer>
        </FlexElement>
      </BottomGap>
      {repeatabilityType?.value === 'week' && (
        <BottomGap $gap='1rem'>
          <Label>{t('Repeat in')}</Label>
          <ButtonsContainer>
            {days.map((day: tDay) => {
              const { name, value, active } = day;

              return (
                <DayButton
                  key={value}
                  $active={active}
                  onClick={() => {
                    const updatedDays = days.filter(
                      (day: Pick<tDay, 'value'>) => day.value !== value,
                    );
                    const newDays = [...updatedDays, { value, active: !active, name }].sort(
                      (day: Pick<tDay, 'value'>, nextDay: Pick<tDay, 'value'>) =>
                        day.value - nextDay.value,
                    );

                    setDays([...newDays]);
                  }}
                >
                  <span>{t(name)}</span>
                </DayButton>
              );
            })}
          </ButtonsContainer>
        </BottomGap>
      )}
      <FlexElement>
        <FlexBoxWithCalendar style={{ marginRight: '6px' }}>
          <Label>{t('Start date')}</Label>
          <Datepicker
            center={centerCalendarInput}
            showArrows={false}
            startDate={startDate}
            onChange={([startDate]: tDates) => setStartDate(startDate)}
          />
        </FlexBoxWithCalendar>
        <FlexBoxWithCalendar style={{ marginLeft: '6px' }}>
          <Label>{t('End date')}</Label>
          <Datepicker
            center
            showArrows={false}
            startDate={endDate}
            onChange={([endDate]: tDates) => setEndDate(endDate)}
          />
        </FlexBoxWithCalendar>
      </FlexElement>
    </Container>
  );
};

export default Periodicity;
