import styled, { css } from 'styled-components';
import { ReactComponent as Down } from 'icons/arrow_drop_down-24px.svg';
import { InputElement } from 'components/Input/Input.style';
import { Button } from 'styles/button';
import { boxShadowCommon, colorWhite, colorSlateBlue, colorLightSlateGrey } from 'styles/constants';

type tDownButton = {
  $show: boolean;
};

type tList = {
  $menuPlacement?: 'top' | 'bottom';
  label?: string;
  $positionList?: string;
};

type tOption = {
  $active: boolean;
};

type tInput = {
  $show: boolean;
};

type tContainer = {
  $marginBottom: string;
};

export const Container = styled.div<tContainer>`
  position: relative;
  margin-bottom: ${({ $marginBottom }) => $marginBottom ?? '1rem'};
`;

export const List = styled.div<tList>`
  position: absolute;
  left: 0;
  top: ${({ label = '' }) => (!label ? '32px' : '57px')};
  width: calc(100% - 4px);
  background: ${colorWhite};
  z-index: 1;
  border-radius: 8px;
  box-shadow: ${boxShadowCommon};
  overflow: hidden;
  margin: 4px 2px 0;
  ${({ $menuPlacement }) =>
    $menuPlacement === 'top' &&
    css`
      top: unset;
      bottom: 40px;
    `};
  @media screen and (max-width: 1000px) {
    ${({ $positionList }) =>
      $positionList &&
      css`
        position: ${$positionList};
        top: 0;
      `}
  }
`;

export const Option = styled.div<tOption>`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  ${({ $active }) =>
    $active
      ? css`
          background-color: ${colorSlateBlue};
          & > * {
            color: ${colorWhite};
          }
        `
      : css`
          &:hover {
            background-color: rgba(107, 82, 226, 0.1);
          }
        `}
`;

export const OptionButton = styled(Button)`
  font-size: 12px;
  line-height: 15px;
  width: 100%;
  text-align: left;
  padding: 8px 16px;
`;

export const DownButton = styled(Button)<tDownButton>`
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  padding: 0;
  ${({ $show }) =>
    $show &&
    css`
      > svg {
        transform: rotate(180deg);
      }
    `};
`;

export const DownSvg = styled(Down)`
  fill: ${colorLightSlateGrey};
`;

export const NoMatch = styled.div`
  padding: 8px 16px;
  font-size: 12px;
  line-height: 15px;
  &:hover {
    background-color: rgba(107, 82, 226, 0.1);
  }
`;
export const Input = styled(InputElement)<tInput>`
  width: 100%;
  box-sizing: border-box;
  padding: 0 16px;
  ${({ $show }) =>
    $show &&
    css`
      border-color: ${colorSlateBlue};
    `};
`;

export const RemoveSelectButton = styled(Button)`
  height: 16px;
  padding: 0;
  position: absolute;
  right: 39px;
  top: 50%;
  transform: translateY(-50%);
`;

export const InputContainer = styled.div<{ $isLoading: boolean }>`
  position: relative;
  ${({ $isLoading }) =>
    $isLoading &&
    css`
      > div {
        margin: auto;
        transform: scale(0.2);
        position: absolute;
        top: -39px;
        right: -27px;
      }
    `}
`;
