import styled from 'styled-components';

import { ReactComponent as GMLogo } from 'assets/svg/logo.svg';
import { boxShadowCommon, colorLightSlateGrey } from 'styles/constants';

export const GidLogoContainer = styled.div`
  align-items: flex-end;
  bottom: 3rem;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
`;

export const LoginButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 3.6rem;
  @media screen and (max-height: 540px) {
    margin-top: 2rem !important;
  }
  > button {
    line-height: 18px;
  }
`;

export const LoginPanel = styled.section`
  background-color: white;
  border-radius: 8px;
  box-shadow: ${boxShadowCommon};
  height: 90%;
  left: 50%;
  max-height: 540px;
  max-width: 960px;
  min-height: 50vh;
  min-width: 50vw;
  padding: 1rem;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
`;

export const Logo = styled(GMLogo)`
  width: 470px;
  @media screen and (max-width: 640px) {
    width: 100%;
  }
`;

export const LogoContainer = styled.div`
  margin-top: 3rem;
  text-align: center;
  @media screen and (max-width: 640px) {
    margin-top: 1rem;
  }
`;

export const LogoCaption = styled.div`
  color: ${colorLightSlateGrey};
  font-size: 12px;
  margin-right: 1rem;
`;
