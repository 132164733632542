// backgrounds
export const modalBackgroundTint = 'rgba(114, 132, 158, 0.8)';

// box shadows
export const boxShadowCommon = '0px 0px 8px #0000000a';
export const boxShadowGoogleMap = 'rgb(0 0 0 / 30%) 0px 1px 4px -1px';
export const boxShadowInfoWindow = '-4px 0px 8px #0000000a';
export const boxShadowLight = '0px 4px 8px #0000000a';
export const boxShadowTimeline = '0px 0px 4px #6b52e266';
export const boxShadowNotificationsPanel = '0px 4px 8px #00000029';

// colors
export const colorGhostWhite = '#F6F8FF';
export const colorMelrose = '#BEB5F3';
export const colorLightSlateBlue = '#A48BFB';
export const colorLightSlateGrey = '#72849E';
export const colorMagnolia = '#F0EDFC';
export const colorRadicalRed = '#EF4E57';
export const colorBrickRed = '#BF3E46';
export const colorSlateBlue = '#6B52E2';
export const colorWhite = '#FFFFFF';
export const colorBisonHide = '#BBAD93';
export const colorSealBrown = '#201717';
export const colorRockBlue = '#8f9fc0';
export const colorWhiteSmoke = '#f2f2f2';
export const colorWhisper = '#E6E6E6';
export const colorLinkWater = '#c8cccf';
export const colorPaleCornflowerBlue = '#ABD2FB';
export const colorSolitude = '#F1F2F6';
export const colorHawkesBlue = '#CED4EA';
export const colorMediumSeaGreen = '#2EB56C';
export const colorGrandis = '#FCC165';
export const colorDodgerBlue = '#2579E8';
export const colorRichBlue = '#5642B5';
export const colroAliceBlue = '#F9FBFF';
export const colorEchoBlue = '#AAB5C5';
export const colorEclipse = '#383838';
export const colorBlack = '#000000';
export const colorBisque = '#FEE6C1';
export const colorAeroBlue = '#C0DBCC';
export const colorElectricIndigo = '#5E00FF';
export const colorHanPurple = '#2C00FF';
export const colorMajorelleBlue = '#6b52e2';

// gradients
export const gradientPurple = `linear-gradient(180deg, ${colorSlateBlue} 0%, ${colorLightSlateBlue} 100%)`;

// z-index
export const zIndexBackground = -1;
export const zIndexDropdown = 1000;
export const zIndexSticky = 1020;
export const zIndexFixed = 1030;
export const zIndexModalBackdrop = 1040;
export const zIndexPopover = 1060;
export const zIndexTooltip = 1070;
export const zIndexTimeSpanHover = 1100;
export const zIndexModal = 1200;
