import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from 'components';
import { tKeyValueGeneric, tTableTransformedItem, tTableTransformedItemData } from 'types/global';
import { tIconEnum } from 'components/Icon/Icon';
import TableRowWithDetails from './TableRowWithDetails';
import {
  NoResultsCopy,
  NoResultsCell,
  TableActionCell,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableWrapper,
} from './Table.style';
import renderActions from './utils/renderActions';

export type tAction<T extends tTableTransformedItem> = {
  actionTrigger: (item: T) => void;
  id: number;
  name: 'edit' | 'delete' | 'reportDownload';
};

type tTable<T extends readonly tTableTransformedItemData[]> = {
  actions?: tAction<tTableTransformedItem<T>>[];
  data: tTableItem<T>[];
  expandPanelsMode?: boolean;
  tableHead: tKeyValueGeneric<string>[];
  stickyHeader?: boolean;
};

export type tPhoneNumbers = {
  number: string;
  notification_seven_days_before: boolean;
  notification_one_day_before: boolean;
  notification_on_start: boolean;
  main: boolean;
};

export type tDetail = {
  iconDefault?: tIconEnum;
  iconOnShow?: tIconEnum;
  content: ReactNode;
};

export type tTableItem<
  T extends readonly tTableTransformedItemData[] = readonly tTableTransformedItemData[],
> = tTableTransformedItem<T> & {
  detail?: tDetail;
};

const Table = <T extends readonly tTableTransformedItemData[]>({
  actions = [],
  data = [],
  expandPanelsMode = false,
  stickyHeader = false,
  tableHead = [],
}: tTable<T>) => {
  const { t } = useTranslation();
  return (
    <TableWrapper $stickyHeader={stickyHeader}>
      <TableContainer>
        <TableHead $stickyHeader={stickyHeader}>
          <tr>
            {expandPanelsMode && <TableCell style={{ width: '60px' }} />}
            {!!tableHead.length &&
              tableHead.map((head: tKeyValueGeneric<string>) => {
                const { key: headKey, label } = head;

                return (
                  <TableCell key={headKey} style={headKey === 'order' ? { width: '60px' } : {}}>
                    {t(label)}
                  </TableCell>
                );
              })}
            {!!actions.length && <TableCell style={{ width: '100px' }} />}
          </tr>
        </TableHead>
        <tbody>
          {!data.length && (
            <tr>
              <NoResultsCell
                colSpan={Number(!!actions.length) + Number(expandPanelsMode) + tableHead.length}
              >
                <Icon iconName='noResults' width='11.25rem' height='6.25rem' />
                <NoResultsCopy>{t('No results')}</NoResultsCopy>
              </NoResultsCell>
            </tr>
          )}
          {!!data.length &&
            data.map((item) => {
              const { detail, itemData, id: itemKey } = item;

              if (!expandPanelsMode) {
                return (
                  <TableRow key={itemKey}>
                    {itemData.map((itemCell) => {
                      const { key: itemCellKey, value } = itemCell;
                      return <TableCell key={itemCellKey}>{value}</TableCell>;
                    })}
                    {!!actions.length && (
                      <TableActionCell>{renderActions({ actions, item })}</TableActionCell>
                    )}
                  </TableRow>
                );
              }

              return (
                <TableRowWithDetails
                  data={itemData}
                  item={item}
                  key={itemKey}
                  detail={detail ?? null}
                  actions={actions}
                />
              );
            })}
        </tbody>
      </TableContainer>
    </TableWrapper>
  );
};

export default Table;
