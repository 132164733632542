import styled, { css } from 'styled-components';

import { colorWhite } from 'styles/constants';

type tChipElement = {
  $isUpper: boolean;
};

export const ChipElement = styled.div<tChipElement>`
  align-items: center;
  border-radius: 16px;
  color: ${colorWhite};
  display: inline-flex;
  font-size: 12px;
  margin-bottom: 4px;
  margin-right: 8px;
  min-height: 16px;
  padding: 2px 10px 1px;
  ${({ $isUpper }) =>
    $isUpper
      ? css`
          text-transform: uppercase;
        `
      : css`
          text-transform: none;
        `}
`;
