import { ChangeEvent } from 'react';
import { Label, Errors } from 'components';
import { Container, TextArea as TextAreaStyle, Text } from './TextArea.style';

type tProps = {
  label?: string;
  errors?: string[];
  disabled?: boolean;
  readOnly?: boolean;
  value: string;
  /* eslint-disable-next-line no-unused-vars */
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  isOptional?: boolean;
  dataTestId?: string;
};
const TextArea = ({
  label = '',
  errors = [],
  disabled = false,
  readOnly = false,
  value = '',
  onChange = () => {},
  isOptional = false,
  dataTestId,
}: tProps) => (
  <Container>
    <Label optional={isOptional}>{label}</Label>
    {readOnly ? (
      <Text $error={!!errors.length}>{value || '-'}</Text>
    ) : (
      <TextAreaStyle
        $error={!!errors.length}
        disabled={disabled}
        onChange={onChange}
        defaultValue={value}
        data-testid={dataTestId}
      />
    )}
    <Errors errors={errors} />
  </Container>
);

export default TextArea;
