export const addAdHocTaskFormError = {
  confirmation_method: {
    errors: false,
  },
  methodOfService: {
    errors: false,
  },
  description: {
    errors: false,
  },
  estimatedTime: {
    errors: false,
  },
  priority: {
    errors: false,
  },
  client: {
    errors: false,
  },
  product: {
    errors: false,
  },
  location: {
    errors: false,
  },
  contract: {
    errors: false,
  },
  qualifications: {
    errors: false,
  },
  equipments: {
    errors: false,
  },
  service: {
    errors: false,
  },
  serviceName: {
    errors: false,
  },
  startDate: {
    errors: false,
  },
  endDate: {
    errors: false,
  },
  startTime: {
    errors: false,
  },
  endTime: {
    errors: false,
  },
  files: {
    errors: false,
  },
  savedFiles: {
    errors: false,
  },
  periodicity: {
    errors: false,
  },
  steps: {
    errors: false,
  },
};
