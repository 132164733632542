import i18n from 'i18next';

import { Icon, Tooltip } from 'components';
import { IconButton } from 'styles/button';
import { colorRadicalRed, colorSlateBlue } from 'styles/constants';

import { tAction } from '../Table';
import { tTableTransformedItem } from 'types/global';

const renderActions = <T extends tTableTransformedItem>({
  actions = [],
  item,
}: {
  actions: tAction<T>[];
  item: T;
}) => {
  if (!actions.length) return null;

  return actions.map((action) => {
    const { actionTrigger, id: actionId, name } = action;

    if (name === 'edit') {
      return (
        <IconButton key={actionId} $inline onClick={() => actionTrigger(item)}>
          <Icon
            key='editAction'
            fill={colorSlateBlue}
            iconName='edit'
            extraStyles={{ marginLeft: '16px' }}
          />
        </IconButton>
      );
    }

    if (name === 'delete') {
      return (
        <IconButton key={actionId} $inline onClick={() => actionTrigger(item)}>
          <Icon
            key='deleteAction'
            fill={colorRadicalRed}
            iconName='remove'
            extraStyles={{ marginLeft: '16px' }}
          />
        </IconButton>
      );
    }

    if (name === 'reportDownload') {
      return (
        <Tooltip
          key={actionId}
          tipId={actionId}
          content={<>{i18n.t('Download report')}</>}
          title=''
          direction='bottom'
        >
          <IconButton $inline onClick={() => actionTrigger(item)}>
            <Icon
              key='reportDownloadAction'
              fill={colorSlateBlue}
              iconName='reportDownload'
              extraStyles={{ marginLeft: '16px' }}
            />
          </IconButton>
        </Tooltip>
      );
    }

    return null;
  });
};

export default renderActions;
