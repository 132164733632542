import { CancelToken } from 'axios';

import fetchProvider from 'utils/Axios';
import { tListParams } from 'types/global';
import { components } from 'types/api';
import { tReadContract } from 'agents/contracts';

const microservicePath = '/locations';

export type tAddress = components['schemas']['Address'];
export type tPhoneNumber = {
  number: string;
  notification_seven_days_before: boolean;
  notification_one_day_before: boolean;
  notification_on_start: boolean;
  main: boolean;
};

export type tReadLocation = {
  id: string;
  name: string;
  phone_number?: string | null;
  phone_numbers: (tPhoneNumber & { id: string })[];
  address: tAddress;
  contracts: tReadContract[];
  external_system_id: string | null;
  commercial_district: string;
};

type tWriteLocation = {
  name: string;
  phone_number?: string | null;
  phone_numbers: tPhoneNumber[];
  address: tAddress | null;
  contracts: string[];
  external_system_id?: string | null;
  commercial_district?: string;
};

type tDeleteLocationResponse = tReadLocation;

export type tAddLocationRequest = tWriteLocation;
type tAddLocationResponse = tReadLocation;

type tGetLocationResponse = tReadLocation;

export type tGetLocationsRequest = tListParams & {
  client_id?: string;
  product_id?: string;
  contract_id?: string;
  external_system_id?: string;
};
type tGetLocationsResponse = { count: number; results: tReadLocation[] };

export type tUpdateLocationRequest = tWriteLocation;
type tUpdateLocationResponse = tReadLocation;

type tGetAddressesResponse = { count: number; results: tAddress[] };

const locations = {
  deleteLocation: (locationId: string) =>
    fetchProvider.delete<tDeleteLocationResponse>(`${microservicePath}/${locationId}`),
  addLocation: (bodyRequest: tAddLocationRequest) =>
    fetchProvider.post<tAddLocationResponse>(`${microservicePath}`, bodyRequest),
  getLocation: (locationId: string, cancelToken: CancelToken) =>
    fetchProvider.get<tGetLocationResponse>(`${microservicePath}/${locationId}`, { cancelToken }),
  getLocations: (params: tGetLocationsRequest, cancelToken: CancelToken) =>
    fetchProvider.get<tGetLocationsResponse>(`${microservicePath}`, { params, cancelToken }),
  getAddresses: (params: { search: string }, cancelToken: CancelToken) =>
    fetchProvider.get<tGetAddressesResponse>(`${microservicePath}/address`, {
      params,
      cancelToken,
    }),
  updateLocation: (locationId: string, bodyRequest: tUpdateLocationRequest) =>
    fetchProvider.put<tUpdateLocationResponse>(`${microservicePath}/${locationId}`, bodyRequest),
};

export default locations;
