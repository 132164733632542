import styled from 'styled-components';

import { colorWhite, zIndexModal, boxShadowCommon, modalBackgroundTint } from 'styles/constants';

type tContent = {
  $boxMaxWidth: string | undefined;
};

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: ${modalBackgroundTint};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${zIndexModal};
`;

export const Content = styled.div<tContent>`
  background: ${colorWhite} 0% 0% no-repeat padding-box;
  box-shadow: ${boxShadowCommon};
  border-radius: 8px;
  max-height: 80vh;
  overflow: auto;
  max-width: ${({ $boxMaxWidth }) => $boxMaxWidth ?? '518px'};
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 540px) {
    max-width: 90vw;
  }
  @media screen and (max-height: 800px) {
    max-height: 90vh;
  }
  @media screen and (max-height: 520px) {
    max-height: 95vh;
  }
`;
