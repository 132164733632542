import {
  roleOperator,
  roleAuditor,
  roleSystemAdministrator,
  roleLocalAdministrator,
  roleServiceManager,
  roleReadOnlyOperator,
} from 'utils/constants';

const expression = /[ąćęłńóśźż]/gi;

const replacements = {
  ą: 'a',
  ć: 'c',
  ę: 'e',
  ł: 'l',
  ń: 'n',
  ó: 'o',
  ś: 's',
  ź: 'z',
  ż: 'z',
  Ą: 'A',
  Ć: 'C',
  Ę: 'E',
  Ł: 'L',
  Ń: 'N',
  Ó: 'O',
  Ś: 'S',
  Ź: 'Z',
  Ż: 'Z',
};

export const replacePolishLetters = (text) => {
  return text.replace(expression, (letter) => replacements[letter]);
};

export const isOperator = (role = '' as string) => role === roleOperator;
export const isReadOnlyOperator = (role = '' as string) => role === roleReadOnlyOperator;
export const isAuditor = (role = '' as string) => role === roleAuditor;
export const isSystemAdministrator = (role = '' as string) => role === roleSystemAdministrator;
export const isLocalAdministrator = (role = '' as string) => role === roleLocalAdministrator;
export const isServiceManager = (role = '' as string) => role === roleServiceManager;

export const transformRoleName = (role = '' as string) =>
  replacePolishLetters(role).toLowerCase().split(' ').join('_');
